.modal-overlay-3 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content-3 {
    background: var(--red);
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    max-width: 500px;

  }
  
  .modal-content-3 > button {
      padding: 10px 30px;
      border: none;
      background-color: black;
      border: 1px solid grey;
      margin: 10px;
      border-radius: 100px;
      color: white;
      font-family: 'Jost';
  }

  .modal-content-3 > button:hover {
      cursor: pointer;
  }

.artist-otd-container {
    height: 550px;
    position: relative;
    display: flex;
    justify-content: center; /* Zentriert das Kind-Element horizontal */
    align-items: top; /* Zentriert das Kind-Element vertikal */
    overflow: hidden;
    background-color: black;
    background-color: var(--black);
  }

  
.artist-otd-image, .skeleton-image {
    width: 95%;
    height: 500px;
    object-fit: cover;
    border-radius: 30px; /* Stellt sicher, dass das Bild den Container ausfüllt, ohne seine Proportionen zu verzerren */
}

.artist-otd-image {
  animation: fade-in 200ms ease-in-out
}


/* Skeleton Animation */
.skeleton-image {
  background: rgb(31, 31, 31);
  animation: shimmer 2s infinite;
  background: linear-gradient(to right, #252525 8%, #3f3f3f 18%, #252525 33%);
  background-size: 1000px 100%;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
  
.audio-bar-enter {
    transform: translateY(100%);
  }
  .audio-bar-enter-active {
    transform: translateY(0);
    transition: transform 500ms;
  }
  .audio-bar-exit {
    transform: translateY(0);
  }
  .audio-bar-exit-active {
    transform: translateY(100%);
    transition: transform 500ms;
  }

  .artist-otd-text {
    display: flex;
    text-align: left;
    align-items: left;
    justify-content: left;
    position: absolute;
    bottom: 100px; /* Positioniert den Text 20px vom unteren Rand */
    left: 6%; /* Positioniert den Text 20px vom linken Rand */
    color: white; /* Textfarbe, anpassbar nach Bedarf */
    flex-direction: column;
  }

.artist-otd-cta {
    display: flex;
    align-items: center;
}

  .feature-artist-button {
      padding: 10px 20px;
      background-color: var(--black);
      z-index: 10;
      border-radius: 100px;
      width: fit-content;
      color: white;
      text-decoration: none;
      margin-top: 10px;
      border: 1px solid white;
     
  }
  
 .artist-otd-text h2 {
    margin: 0; /* Entfernt Standard-Margins */
  }

  .artist-otd-text {
margin-right: 30px;
  }

  #image-mobile {
    display: none;
  }

  
  .artist-otd-text  p {
      font-size: 16px;
      margin-right: 15px;
      margin-bottom: 15px;
      margin-top: 5px;
      width: 100%;
      max-width: 500px;
      color: rgb(209, 209, 209);
  }

  .artist-otd-text h2 {
    font-size: 62px;
    width: 100%;
}

.spotlight-apply-button {
  position: absolute;
  top: 40px;
  right: 50px;
  padding: 10px 20px;
  text-transform: uppercase;
  background-color: var(--blue);
  color: white;
  background: linear-gradient(90deg, var(--blue), rgba(0, 0, 255, 0.5), rgba(14, 14, 24, 0.5), rgba(0, 0, 255, 0.74), var(--blue));
  background-size: 200% 200%;
  animation: moveSpotlight 8s linear infinite;
  border-radius: 100px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.253);
  font-family: 'Jost';
  transition: background-color 0.3s ease;
  z-index: 10; /* Sicherstellen, dass der Button oben angezeigt wird */
}

@media (max-width: 800px) {
  .artist-otd-image, .skeleton-image {
    width: 95%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px; /* Stellt sicher, dass das Bild den Container ausfüllt, ohne seine Proportionen zu verzerren */
}

#image-mobile {
  display: block;
}

#image-desktop {
  display: none;
}

.artist-otd-text {
  bottom: 40px; /* Positioniert den Text 20px vom unteren Rand */
}

.artist-otd-container {
  width: 100%;
  padding: 0px;
}
}

@media (max-width: 500px) {
  .artist-otd-container {
    height: 80vh;
    display: flex;
  
}
}

.footer {
    background-color: var(--black);
    color: white;
    padding: 0px;
  }
  
  .footer-upper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid rgb(53, 53, 53);
    margin-top: -2px;
  }
  
  .footer-logo img {
    width: 150px; /* oder die gewünschte Breite */
    margin-left: var(--edge);

  }

  .footer-middle {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid rgb(53, 53, 53);
  }

  .footer-middle-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    margin-left: 3%;
    }

    .fotter-button-img {
      width: 22px;
      filter: invert(1);
      margin: 0 10px;
    }

    .footer-button > div {
      background-color: rgb(0, 0, 0);
      height: 35px;
      width: 35px;
      margin-left: 5px;
      margin-right: 10px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
  .footer-middle-left > p {
    margin-bottom: 10px;
    text-align: left;
    color: rgb(170, 170, 170);
    font-weight: 300;
  }
  
 .footer-help-button {
    background-color: rgb(49, 49, 49);
    color: black;
    border-radius: 100px;
    padding: 15px 20px;
    color: white;
    border: none;
    margin-top: 10px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-family: 'Jost';
    font-weight: 500;
    margin-bottom: px;
  }

  .footer-help-button > p {
    margin: 0;
  }

  .footer-help-button > img {
    width: 20px;
    margin-right: 10px;
    filter: invert(1);
  }
  

  .footer-middle-right {
    display: flex;
    flex-direction: column ;
    align-items: flex-end; /* Sorgt dafür, dass die Buttons am linken Rand bleiben */
  }
  
  .footer-button {
    width: 250px;
    display: flex;
    border-radius: 12px;
    align-items: center;
    background-color: rgb(20, 20, 20);
    justify-content: left;
    height: 50px;
    margin: 10px 30px;
    font-family: 'Jost';
    color: white;
    font-size: 14px;
    font-weight: 500;
    border: none;
    box-shadow: rgba(149, 157, 165, 0.05) 0px 8px 24px;

  }

  #footer-award-button {
    background-image: linear-gradient(to right, black, var(--blue));
  }

  #footer-market-button {
    background-image: linear-gradient(to right, black, var(--red));
  }

  #footer-tiktok-button {
    background-image: linear-gradient(to right, black, rgb(37, 37, 37));
  }


  
  .footer-middle-right .icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 50px;
    padding: 15px 20px;
    margin: 10px 0;
    background: linear-gradient(to right, #1e3c72, #2a5298); /* Blauer Farbverlauf für den ersten Button */
    color: white;
    font-family: 'Jost';
    text-transform: uppercase;
    cursor: pointer;
  }
  
  .footer-middle-right .icon-button.icon-music {
    background: linear-gradient(to right, #b24592, #f15f79); /* Farbverlauf für den zweiten Button */
  }
  
  .footer-middle-right .icon-button.icon-tiktok {
    background: linear-gradient(to right, #1f1c18, #8e44ad); /* Farbverlauf für den dritten Button */
  }

  
  .footer-links {
    display: flex;
    gap: 10px; /* Abstand zwischen den Buttons */
    margin-right: var(--edge);
  }


  .footer-lower {
    margin-top: 0px; /* Abstand zwischen den Ebenen */
    display: flex;
    align-items: center;
margin-left: var(--edge);
    ;
  }
  
  .footer-lower a {
    color: white; /* Setzt die Linkfarbe */
    text-decoration: none; /* Entfernt die Unterstreichung */
    margin: 5px 25px; /* Abstand zwischen den Links */
  }

  .outline-button-3 {
      width: 150px;
      height: 50px;
      border-radius: 100px;
      background-color: var(--black);
      border: 1px solid grey;
      color: white;
      font-family: 'Jost';
      text-transform: uppercase;
  }
  
  .outline-button-3:hover {
      cursor: pointer;
  }


  @media (max-width: 1000px) {
    .footer-upper {
        flex-direction: column;
        padding-bottom: 60px;
      }

      .footer-logo img {
          margin-left: 0px;
          margin-bottom: 30px;
          margin-top: 30px;
          margin-right: 20px;
      }
      .footer-lower {
        margin-top: 0px;
        display: flex;
        align-items: center;
        justify-content: flex-start; /* Für horizontale Ausrichtung */
        overflow-x: auto; /* Ermöglicht horizontalen Scroll */
        margin-left: var(--edge);
        gap: 10px 20px;
        -ms-overflow-style: none; /* IE und Edge */
        scrollbar-width: none; /* Firefox */
    }
    
    .footer-lower::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }
    .footer-lower a {
        white-space: nowrap; /* Verhindert den Textumbruch */
        color: white; /* Setzt die Linkfarbe */
        text-decoration: none; /* Entfernt die Unterstreichung */
        margin: 5px 0; /* Abstand zwischen den Links */
    }

    
    .footer-lower p {
        white-space: nowrap; /* Verhindert den Textumbruch bei Paragraphen */
        /* Restliche Stile für p-Elemente */
    }


    
  }

  @media (max-width: 700px) {
    .footer-middle {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }

      
  .footer-button {
    width: 100%;
  }

  .footer-middle-right {
    margin: 0;
    width: 100%;
    display: flex;
    align-items: center;
  } 

  .footer-middle-right > a {
    width: 100%;
  }

  .footer-middle-right > a > button {
    width: 100%;
    margin: 10px 0;
    align-items: center;
  }

  .footer-middle-left {
    width: 100%;
  }
  .footer-links > a > button {
    width: 100%;
  }

  .footer-links > a {
    width: 100%;
  }

  .footer-links {
    width: 100%;
  }
  }
.buy-pro-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.76);
    backdrop-filter: blur(8px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5000;
}

.buy-pro-modal-content {
    background: #1b1b1bc9;
    padding: 30px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius: 30px;
    max-width: 640px;
    max-height: 80vh; /* Feste maximale Höhe für das Modal */
    width: 100%;
    color: #f0f0f0;
    border: 1px solid rgb(58, 58, 58);
    animation: slideInUp 300ms ease-in-out;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    backdrop-filter: blur(15px);
}



@keyframes slideInUp {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

.buy-pro-modal-left {
text-align: left;
}

.buy-pro-modal-left > p {
    margin-bottom: 40px;
}

.buy-pro-modal-left > h2 {
    font-family: 'Jost';
    font-weight: 600;
    font-size: 38px;
    color: #ffffff;
    margin: 0 !important;
    text-align: left;
    font-family: 'Wide';
}

.buy-pro-modal-body {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.buy-pro-modal-left {
    display: flex;
    flex-direction: column;
    width: 45%; /* Breiter machen, um mehr Platz für Features zu haben */
}

.buy-pro-modal-right {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.buy-pro-modal-price-options {
    gap: 15px;
    padding: 10px 0px;
    border-radius: 15px;
    margin-bottom: 20px; /* Abstand zwischen den Optionen und dem Preis */
}

.buy-pro-option {
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    padding: 15px 15px 15px 15px;
    cursor: pointer;
    margin-top: -15px;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-start;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: 0px;
    background-color: #202020;
    transition: border-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease; /* Added transition for transform and box-shadow */
    box-shadow: rgba(0, 0, 0, 0.35) 0px -15px 25px, rgba(0, 0, 0, 0.05) 0px -5px 10px;

}

.buy-pro-option:nth-child(2):hover {
    transform: translateY(-10px); /* Move the element upwards */
    box-shadow: rgba(0, 0, 0, 0.15) 0px -20px 30px, rgba(0, 0, 0, 0.05) 0px -10px 15px; /* Adjust shadow for hover effect */
}

.buy-pro-option:nth-child(1) {
    transform: translateY(-7px); /* Move the element upwards */
    box-shadow: rgba(0, 0, 0, 0.15) 0px -20px 30px, rgba(0, 0, 0, 0.05) 0px -10px 15px; /* Adjust shadow for hover effect */
}

.buy-pro-option:nth-child(2) {
    padding-bottom: 40px;
}
.buy-pro-option:nth-child(3) {
    padding-bottom: 20px;
    margin-top: -30px;
}

.buy-pro-option:nth-child(1) {
    padding-bottom: 30px;
    margin-bottom: -25px;
}


.buy-pro-option.selected {
    border-color: #0075d4;
    box-shadow: rgba(0, 0, 0, 0.3) 0px -19px 38px, rgba(0, 0, 0, 0.0522) 0px -15px 12px;
}


.buy-pro-option > img {
    width: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
    display: none;
}

.buy-pro-option.selected > img {
    display: flex;
}

.buy-pro-option > div {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding-left: 5px;
}

.buy-pro-option > div > div > h4 {
    text-align: left;
    width: 100%;
    font-family: 'Jost';
    font-weight: 600;
    color: #ffffff;
    margin: 0 !important;
    
}

.buy-pro-option > div  > h4 {
    text-align: left;
    width: 100%;
    font-family: 'Jost';
    font-weight: 600;
    color: #ffffff;
    margin: 0 !important;
    
}


.buy-pro-option > div > div {
    display: flex;
}

.buy-pro-option > div > div > img {
    width: 20px;
    margin-left: 10px;
}

.buy-pro-option > div > span {
    font-size: 14px;
    color: #d1d1d1;
}

.buy-pro-features {
    text-align: left;
    color: #d1d1d1;
}

.buy-pro-features div {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.buy-pro-features img {
    width: 20px;
    vertical-align: middle;
    margin-right: 10px;
}

.buy-pro-total {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 20px 0;
    color: #ffffff;
}

.buy-pro-modal-buttons {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
}


.buy-pro-modal-checkout-button > img {
    width: 17px;
    margin-left: 10px;
    filter: invert(1);
}
.buy-pro-modal-buttons > div > img {
    width: 140px;
    object-fit: contain;
    margin-top: 10px;
    border-radius: 2px;
}

.buy-pro-modal-checkout-button,
.buy-pro-modal-close-button {
    background-color: var(--blue);
    color: white;
    padding: 17px 20px;
    border: none;
    font-size: 16px;
    font-weight: 600;
    border-radius: 100px;
    font-family: 'Jost';
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buy-pro-modal-close-button {
    background-color: #444;
    margin-top: 3px;
    position: absolute;
    width: fit-content;
    top: -30px;
    right: -30px;
    padding: 0;
    rotate: 45deg;
}

.buy-pro-modal-close-button > img {
    width: 30px;
    filter: invert(1);
}

.buy-pro-modal-button-spinner {
    border: 2px solid rgba(255, 255, 255, 0.3); /* Light border */
    border-top: 2px solid #fff; /* White color for top */
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spinnez 1s linear infinite;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
  }
  
  @keyframes spinnez {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  

@media (max-width: 800px) {
    .buy-pro-modal-body {
        flex-direction: column;
        overflow-y: auto;
    }

    .buy-pro-modal-content {
        background: #1b1b1bc9;
        padding: 0px 30px;
        box-shadow: rgba(0, 0, 0, 0.5) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        border-radius: 0px;
        max-width: 640px;
        height: 100%;
        max-height: 100vh; /* Feste maximale Höhe für das Modal */
        width: calc(100% - 60px);
        color: #f0f0f0;
        border: none;
        animation: slideInUp 300ms ease-in-out;
        position: relative;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
        backdrop-filter: blur(15px);
        overflow-y: auto;
    }
    
    .buy-pro-modal-close-button {
        background-color: #444;
        margin-top: 3px;
        position: absolute;
        width: fit-content;
        top: 15px;
        right: 15px;
        padding: 0;
    }
    

    .buy-pro-modal-left {
        width: 100%;
        padding: 35px 0 0 0;
    }
    .buy-pro-modal-right {
        width: 100%;
        margin-top: 25px;
        padding: 0 0 50px 0; 
    }
}
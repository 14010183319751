/* EarnMoneySettings.css */
.create-earn-settings-container {
    padding: 15px;
    font-family: Arial, sans-serif;
}

.create-earn-settings-earn-amount {
    margin-bottom: 20px;
}

.create-earn-settings-features-container-2 {
    display: flex;
    flex-wrap: wrap;
    gap: 0px;
    justify-content: space-around;
    border-radius: 8px;

}

.gig-settings-price-slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    
}


.gig-settings-price-slider > div {
   display: flex; 
    width: 100%;
}

.gig-settings-price-slider input[type="range"]:active::-webkit-slider-thumb {
    background: #FFF; /* Farbänderung beim Bewegen zur Hervorhebung */
  }
  
  .gig-settings-price-slider input[type="range"]:active::before {
    background-position: 0% 0;
  }

.gig-settings-price-slider > div > input {
    width: 100%;
    background-color: black;
}

.gig-settings-price-slider > label {
    margin-bottom: 0px;
    color: white;
    font-family: 'Jost';
    font-size: 24px;
    font-family: 'Wide', 'Jost';
    font-weight: 500;
    margin-top: 30px;
}

.gig-settings-price-slider > p {
    margin-bottom: 30px;
    color: lightgrey;
    font-family: 'Jost';
    font-size: 16px;
    font-family: 'Jost';
    font-weight: 300;
}


.gig-settings-price-slider > div > span {
    color: white;
    display: flex;
    font-family: 'Jost', 'Josefin Sans';
    align-items: center;
    justify-content: center;
    font-size: 26px;
    width: 100px;
    background-color: rgba(0, 0, 0, 0.534);
    height: 50px;
    border-radius: 10px;
    margin-top: -5px;
    font-weight: 500;
    margin-left: 10px;
}



/* Grundlegende Stilisierung des Sliders */
.custom-range-slider {
  -webkit-appearance: none; /* Entfernt den Standardstil in WebKit-Browsern */
  appearance: none;
  margin-top: 15px;
  width: 100%; /* Volle Breite zur besseren Handhabung */
  height: 8px; /* Höhe des Slider-Tracks */
  border-radius: 5px; /* Abgerundete Ecken für den Track */
  background: rgb(61, 61, 61); /* Hintergrundfarbe des Tracks */
  outline: none; /* Entfernt den Outline-Fokus-Ring */
  opacity: 0.7; /* Leichte Transparenz */
  transition: opacity .2s; /* Weicher Übergang für die Transparenz */
}

/* Stil für den Slider-Thumb */
.custom-range-slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Entfernt den Standard-Webkit-Stil */
  appearance: none;
  width: 20px; /* Breite des Thumbs */
  height: 20px; /* Höhe des Thumbs */
  border-radius: 50%; /* Kreisförmiger Thumb */
  background: var(--blue); /* Grün, änderbar je nach Design */
  cursor: pointer; /* Cursor ändert sich zum Zeigehand-Symbol */
  box-shadow: 0 0 4px #000; /* Schatten für bessere Sichtbarkeit */
}

/* Stil für den Slider-Thumb beim Fokussieren (optional für bessere Zugänglichkeit) */
.custom-range-slider:focus::-webkit-slider-thumb {
  background: var(--blue); /* Grün, änderbar je nach Design */
}


  

.license-button {
    border: 1px solid gray;
    padding: 10px;
    background-color: white;
    cursor: pointer;
  }
  
  .license-button.active {
    background-color: #4CAF50; /* Grüner Hintergrund für den aktiven Button */
    color: white;
  }
  
  .license-button img {
    width: 50px;
    height: 50px;
  }
  
  .license-button span {
    display: block;
  }
  

.create-earn-settings-feature-button-2 {
    flex: 0 1 210px;
    background-color: #ffffff05; /* Slightly lighter background for the buttons */
    border: 1px solid rgba(43, 43, 43, 0);
    border-radius: 15px;
    color: #fff;
    padding: 10px;
    height: 200px;
    display: flex;
    margin-bottom: 15px;
    flex-direction: column;
    color: lightgray;
    font-family: 'Jost';
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease;
}

.create-earn-settings-feature-button-2.selected {
    background-color: rgba(0, 0, 0, 0.267); /* Highlight color when selected */
    transform: scale(1.03);
    border-color: rgb(131, 131, 131);
    color: white;
}

.create-earn-settings-feature-button-2:not(.selected):hover {
    transform: scale(1.02);
    cursor: pointer;
  }

.create-earn-settings-feature-button-2 img {
    width: 40px; /* Adjust size as needed */
    height: auto;
    filter: invert(1);
    margin-bottom: 0px;
    margin-top: 10px;
    opacity: 0.8;
}

.create-earn-settings-feature-button-2 > h4 {
    margin-bottom: 0px;
    font-size: 16px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.create-earn-settings-feature-button-2 > p {
    color: grey;
}

.create-earn-settings-contact-methods {
    margin-bottom: 20px;
}

.create-earn-settings-contact-input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
}

.create-earn-settings-submit-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
}

.create-earn-settings-error-message {
    color: red;
    margin-top: 20px;
}




@media (max-width: 800px) {
  
.create-earn-settings-feature-button-2 {
  flex: 0 1 300px;
}
.gig-settings-price-slider > label {
  margin-top: 60px;
}


}
/* General styling for the reply rate analytics cards */
.reply-rate-analytics {
    background-color: black; /* Dark background for a techy look */
    color: #ffffff; 
    border: 1px solid grey;/* White text for contrast */
    border-radius: 25px; /* Rounded corners for a sleek card look */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding: 20px; /* Spacing inside the card */
    margin: 10px; /* Spacing between cards when displayed side by side */
    transition: transform 0.3s ease; /* Animation for hover effect */
    display: flex;
    width: 310px;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Roboto', sans-serif; /* Modern font */
  }
  
  /* Title styling */
  .reply-rate-analytics h2 {
    font-size: 1.4em;
    margin-bottom: 20px;
    color: var(--red);
    border-bottom: 1px solid grey; /* Underline effect */
    padding-bottom: 10px;
  }
  
  /* Paragraphs and text styling */
  .reply-rate-analytics p {
    font-size: 1em;
    margin: 10px 0;
    line-height: 1.6;
    color: #cdd3e1; /* Lighter text for better readability */
  }
  
  /* Highlight important values */
  .reply-rate-analytics p strong {
    color: grey; /* Bright blue color for key metrics */
    font-weight: 400;
    font-size: 14px;
  }
  
  /* Add hover effect for a techy interaction */
  .reply-rate-analytics:hover {
    transform: scale(1.05); /* Slightly enlarge the card */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* More pronounced shadow on hover */
  }
  
  /* Flex container to align multiple cards side by side */
  .analytics-container {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping to the next line on smaller screens */
    justify-content: space-around; /* Space cards evenly */
  }
  
  /* Media query for responsiveness */
  @media screen and (max-width: 768px) {
    .reply-rate-analytics {
      width: 100%; /* Make the cards full width on smaller screens */
      margin: 10px 0;
    }
  }
  
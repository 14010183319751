/* General container for the artist dashboard */
.analysis-artist-dashboard {
    color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .analysis-artist-dashboard h2 {
    text-align: center;
    font-size: 24px;
    color: var(--blue);
    margin-bottom: 20px;
  }
  
  /* Container for all the analysis cards */
  .analysis-cards-container {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .analysis-top-artists-sessions-container {
    margin-top: 20px;
  }
  
  .analysis-top-artists-sessions-list {
    max-height: 250px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
  }
  
  .analysis-top-artists-sessions-item {
    display: flex;
    flex-direction: column;
    padding: 8px;
    border-bottom: 1px solid #eaeaea;
  }

  .analysis-top-artists-sessions-item:last-child {
    border-bottom: none;
  }
  
  .analysis-artist-name {
    font-weight: bold;
  }
  
  .analysis-session-count,
  .analysis-first-session,
  .analysis-last-session {
    color: gray;
  }
  
  
  /* Styling for each analysis card */
  .analysis-card {
    background-color: #333;
    padding: 20px;
    margin: 20px 0;
    border-radius: 10px;
    text-align: center;
    flex: 1;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 130px;
  }
  
  .analysis-card .analysis-number {
    font-size: 32px;
    font-weight: bold;
    color: #ffffff;
  }
  
  .analysis-card .analysis-description {
    margin-top: 10px;
    font-size: 16px;
    color: #b0b0b0;
  }
  


  
  /* On hover, cards get a slightly brighter effect */
  .analysis-card:hover {
    background-color: #4a4a4a;
    cursor: pointer;
  }
  
  .analysis-card.analysis-blue:hover {
    background-color: #003366; /* Darker shade of blue */
  }
  
  .analysis-card.analysis-red:hover {
    background-color: #990000; /* Darker shade of red */
  }
  

  .artist-page-visits-container {
    margin-top: 20px;
  }
  
  .artist-page-visits-list {
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid rgb(53, 53, 53);
    border-radius: 8px;
    padding: 10px;
  }
  
  .artist-page-visits-item {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    border-bottom: 1px solid #eaeaea;
  }
  
  .artist-page-visits-item:last-child {
    border-bottom: none;
  }
.page-preview-live {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; 
}


.page-preview {
    background-color: var(--color-2);
    transition: 0.2s ease-in-out;

}

.page-preview-main {
    width: 100%;
    position: relative;
    padding-top: 20px;
    display: flex;
}

.going-back-button > img {
    width: 15px;
    filter: var(--color-invert);
    margin-left: 65px;
    margin-right: 15px;
}

.going-back-button {
    display: flex;
    color: var(--color-oposite-1);
    font-family: 'Jost';
    align-items: center;
    justify-content: center;
    height: 50px;
    background: none;
    z-index: 100;
    font-size: 16px;
    position: absolute;
    top: -30px;
    left: 0px;
    margin-left: -40px;
    font-weight: 500;
}

.page-socials-top-mobile  > button:hover {
    cursor: pointer;
}


.page-preview-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}


.widget-splitter-hr {
    background-color: rgba(117, 117, 117, 0.205);
    height: 1px;
    border: none;
    margin: 10px;

}

.page-preview-bottom-mobile {
    display: none;
}

.page-preview-left {
    width: calc(95% - 500px);
    margin-left: 4%;
    margin-top: 40px;
    position: relative;
}

.page-preview-right {
    position: fixed;
    right: 0;
    width: 500px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    top: 90px !important; /* Start at 150px */
}

/* Für moderne Browser (Webkit-basierte Browser wie Chrome, Safari) */
.page-preview-left, .page-preview-right {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer und Edge */
}

.page-preview-left::-webkit-scrollbar, .page-preview-right::-webkit-scrollbar {
    display: none; /* Webkit-basierte Browser */
}


.page-socials-top-mobile {
    display: none;
}

.remove-widget-button > img {
    width: 30px;
    height: 30px;
}

.remove-widget-button {
    width: 32px;
    height: 32px;
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    border: none;
    top: -10px;
    position: absolute;
    right: 55px;
    background: none;
}

.remove-widget-button:hover {
    cursor: pointer;
}

.widget-container {
    position: relative;
}

.widget-container:hover > .remove-widget-button {
    display: flex;
}

.empty-widget-message {
    background-color: var(--color-2);
    border: 1px dashed rgb(53, 53, 53);
    height: 170px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    color: var(--color-oposite-1);
    justify-content: center;
    flex-direction: column;
    margin-bottom: 80px;
    font-weight: 500;
}

.empty-widget-message.selected {
    border: 1px solid white;
}

.empty-widget-message > img {
    width: 40px;
    margin-bottom: 20px;
}

.empty-widget-message:hover {
    transform: scale(1.02);
    cursor: pointer;
}

.empty-widget-message > p {
    margin: 0;
}


@media (max-width: 1300px) {
    .page-preview-left {
        width: calc(93% - 500px);
        margin-left: 4%;
    }
    .page-preview-left.live {
        width: calc(96% - 500px);
        margin-left: 4%;
        position: relative;
    }


}


@media (max-width: 1200px) {
    .page-preview-right.settings {
        display: none;
    }
    .page-preview-main.settings {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
    }

    .page-preview-bottom-mobile.settings {
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .page-socials-top-mobile.settings {
        display: flex;
        margin-top: 50px;
        position: relative;
        align-items: center;
        justify-content: center;
    }

 
    .page-preview-right.settings {
        width: 0%;
    }
    .page-preview-left.settings {
        width: 80%;
        margin-right: 4%;
        margin-left: 4%;
    }
}

@media (max-width: 900px) {
    .page-preview-right.live {
        display: none;
    }

    .going-back-button {
        top: 0px;
    }
    
 
    .page-preview-bottom-mobile.live {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .page-socials-top-mobile.live {
        display: flex;
        margin-top: 50px;
        position: relative;
        align-items: center;
        justify-content: center;
    }


.page-preview-header > button {
    top: 130px;
    left: 30px;
}


    .page-preview-main.live {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-left: 0px;
    }
    .page-preview-left.live {
        width: 86%;
        margin-right: 3%;
        margin-left: 3%;

    }
}


@media (max-height: 1150px) {
    .page-preview-right {
        position: absolute;
        bottom: 0; /* Stellt sicher, dass der Container innerhalb des Viewports bleibt */
        top: 75px !important;
        height: 1000px;
    }
    .page-preview-right.settings {
        position: absolute;
        bottom: 0; /* Stellt sicher, dass der Container innerhalb des Viewports bleibt */
        top: 80px !important;
    }
    

    
    
}

@media (max-width: 900px) {
    .social-links-container {
        right: 0px !important;
    }

}


@media (max-width: 500px) {

    .page-preview-left.settings {
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
    }
    .page-preview-left.live {
        width: 100%;
        margin-right: 0%;
        margin-left: 0%;
    }

    .page-preview-header > button {
        margin-left: 15px !important;
    }

    .page-preview-header > button > img {
        margin-left: 0;
    }
    
    .page-preview-header > button {
        width: fit-content;
    }

    .social-links-container.live {
        right: 0;
    }

    .social-links-container > a {
        margin: 3px 2px !important;
    }

  
}

@media (max-width: 420px) {
    .going-back-button {
        top: -50px;
    }
    
}
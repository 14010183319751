/* GeneralSettings.css */
.general-settings {
    margin-left: 200px !important;
    text-align: left;
    color: white;
    margin-top: -20px;
    padding-bottom: 150px;
    overflow: hidden;
}

.general-settings > h1 {
    font-size: 26px;
    font-family: 'Wide';
}

.danger-zone > p {
    max-width: 450px;
    margin-top: 0px;
    margin-bottom: 20px;
}

.danger-button {
    background-color: black;
    border: none;
    color: rgb(216, 216, 216);
    height: 50px;
    padding: 10px 20px;
    border-radius: 10px;
    margin-left: 40px ;
    font-family: 'Jost', 'Josefin Sans';
}

.danger-button:hover {
    cursor: pointer;
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 54px; /* Breite des Switches */
    height: 30px; /* Höhe des Switches */
    background-color: #ccc; /* Hintergrundfarbe des Switches */
    border-radius: 34px; /* Rundung des Switches */
    transition: background-color 0.4s;
}

.toggle-switch input {
    display: none;
}

.switch-slider {
    position: absolute;
    cursor: pointer;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%; /* Macht den Slider rund */
    width: 23px; /* Größe der Kugel */
    height: 23px; /* Größe der Kugel */
    transform: translateX(0px);
}

input:checked + .switch-slider {
    transform: translateX(23px); /* Bewegt den Slider nach rechts */
    background-color: #ff4136; /* Rote Farbe, wenn aktiv */
}

.toggle-switch {
    background-color: #414141; /* Grüne Farbe, wenn nicht aktiv */
}

input:checked + .toggle-switch {
    background-color: #ff4136; /* Rote Farbe, wenn aktiv */
}

.general-settings-textarea {
    width: 102%;
    background-color: var(--black);
    height: 70px;
    border-radius: 12px;
    border: 1px solid rgb(104, 104, 104);
    padding: 20px;
    color: white;
    outline: none;
    margin: 0px;
    resize: none; /* Verhindert das Verziehen des Textfeldes */
    font-family: 'Jost';
}


.general-settings-form {
    display: flex;
    flex-direction: column;
    color: white;
    margin-left: 40px;
}


.general-settings-input:disabled {
    background-color: rgb(39, 39, 39);
    color: grey !important;
}

.general-input-img {
    width: 20px;
    position: absolute;
    right: -38px;
    bottom: 16px;
    filter: invert(1);
    opacity: 0.9;
    padding-left: 30px;
    z-index: 100;
}

.save-status {
    margin-right: 20px;
}

.general-settings-input,
.general-settings-input:disabled {
    width: 100%;
    padding: 10px 40px 10px 10px;
    border-radius: 8px;
    border: none;
    height: 30px;
    color: black;
    font-family: 'Jost';
    letter-spacing: 0.3px;

}

.general-settings-input {
    outline: none;
    background-color: var(--black);
    border: 1px solid rgb(110, 110, 110);
    color: white;
}



.general-settings-double {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.general-settings-container {
    width: 450px;
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}  

@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}


.settings-save-bar {
    background-color: rgb(59, 59, 59);
    position: fixed;
    height: 70px;
    width: calc(100% - 200px);
    bottom: 0px;
    right: 0;
    display: flex;
    justify-content: right;
    align-items: center;
    z-index: 1000;
    animation: slideUp 0.3s ease-out forwards;
}


.settings-save-button {
    width: 200px;
    height: 50px;
    margin-right: 10px;
    border-radius: 12px;
    border: none;
    background-color: var(--blue);
    color: white;
    font-family: 'Jost';
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    transition: 200ms ease-in-out;
}

.settings-save-button:hover {
    cursor: pointer;
    transform: scale(1.01);
}

.general-settings-label {
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 10px;
    width: 100%;
    text-align: left;
}

.flex-row {
    display: flex;
    flex-direction: column;
    justify-content: left;
}


.general-input-box-2 {
    display: flex;
    flex-direction: column; 
    width: 50% !important;
    margin-top: 20px;
 
}

.general-input-box {
    display: flex;
    flex-direction: column;
    position: relative !important;
}




/* GeneralSettings.css */
.profile-image-container {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 10px;
}

.profile-picture-change-button {
    width: fit-content;
    background-color: rgb(32, 32, 32);
    border: none;
    height: 30px;
    border-radius: 5px;
    color: white;
    font-family: 'Jost';
    font-size: 12px;
    margin-bottom: 40px;
    margin-left: 13px;
}

.profile-picture-change-button:hover {
    cursor: pointer;
}

.profile-image-2 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.profile-image-container:hover {
    cursor: pointer;
    opacity: 1; /* Fügt einen leichten Opacity-Effekt beim Hover hinzu, um Klickbarkeit zu signalisieren */
}

.img-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block; /* Stellen Sie sicher, dass der Loader angezeigt wird, wenn imgLoading wahr ist */
    border: 5px solid #f3f3f3; /* Hellgrauer Rand */
    border-top: 5px solid #3498db; /* Blauer Rand */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin-2 1s linear infinite;
}

.modal-overlay-2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.61);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content-2 {
    background: var(--gray);
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    min-width: 350px;
    text-align: center;
}

.modal-content-2 > input {
    width: 300px;
    height: 15px;
    border-radius: 5px;
    border: 0px;
    padding: 10px;
}



.modal-content-2 > div > button {
    background-color: rgba(0, 0, 0, 0.295);
    border: none;
    color: white;
    margin: 10px;
    border-radius: 100px;
    height: 40px;
    width: 150px;
}

.modal-content-2 > div > button:hover {
    cursor: pointer;
    scale: 1.01;
}

.modal-content-2 > div {
    margin-top: 20px;
}



@keyframes spin-2 {
    0% { transform: translate(-50%, -50%) rotate(0deg); }
    100% { transform: translate(-50%, -50%) rotate(360deg); }
}




@media (max-width: 750px) {
    /* GeneralSettings.css */
.general-settings {
    margin-left: 0px !important;
    padding-top: 80px;
}



.settings-save-bar {
    width: 100%;
    bottom: unset;
    top: 0px;
    background-color: rgb(14, 14, 14);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    animation: slide 0.3s ease-out forwards;
}



}

@media (max-width: 550px) {
    .general-settings-container {
        width: 350px;
    }

    .general-settings-form {
        margin-top: 20px;
    }

    .danger-button {
        margin-left: 10px;
    }

    .general-settings-form {
        margin-left: 15px;
    }

    .general-settings-double {
        flex-direction: column;
    }

    .general-input-box-2 {
        margin: 0 !important;
        width: 100% !important;
    }

    .profile-picture-change-button {
        margin-left: 0px;
        margin-top: 10px;
    }

    #rep-name-label {
        margin-top: 10px;
    }
}

@media (max-width: 520px) {
    .general-settings-container {
        width: 84%;
    }
}

@media (max-width: 420px) {
    .general-settings-container {
        width: 82%;
    }
}

@media (max-width: 350px) {
    .general-settings-container {
        width: 80%;
    }
}

@media (max-width: 300px) {
    .general-settings-container {
        width: 75%;
    }
}
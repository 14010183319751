/* Hintergrundoverlay für das Modal */
.create-collab-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 10, 10, 0.726); /* Halbtransparentes Schwarz */
  display: flex;
  backdrop-filter: blur(10px);
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Über alle anderen Elemente */
}

.collab-button-loading-spinner {
  border: 3px solid rgba(255, 255, 255, 0.3); /* Heller Rand */
  border-top: 3px solid #ffffff; /* Dunklerer Rand oben */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spinnen 0.5s linear infinite;
  display: inline-block;
  margin-right: 0px; /* Abstand zum Text */
}

@keyframes spinnen {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* Wrapper um das Modal herum */
.create-collab-modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.custom-collab-textarea {
  width: calc(75%);
  max-width: 100%;
  padding: 20px 5% 30px 0;
  margin-left: -50px;
  background: none;
  border: none;
  font-family: 'Jost';
  color: rgb(241, 241, 241);
  height: 125px;
  box-shadow: none;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  resize: none; /* Verhindert manuelles Größenänderung */
  line-height: 1.5;
}

.custom-collab-textarea::-webkit-scrollbar {
  display: none; /* Für Chrome, Safari und Opera */
}

.custom-collab-textarea::placeholder {
  color: white;
}




#collab-ai-generate-button {
  background-color: rgba(0, 0, 0, 0.705);
  color: white;
  border: 1px solid var(--blue);
  border-radius: 100px;
  margin-left: 20px;
  height: 50px;
  font-weight: 500;
  display: flex;
  background: linear-gradient(90deg, var(--blue), rgba(0, 0, 255, 0.5), rgba(14, 14, 24, 0.5), rgba(0, 0, 255, 0.74), var(--blue));
  background-size: 200% 200%;
  animation: moveSpotlight 8s linear infinite;
  position: relative;
}

@keyframes moveSpotlight {
  0% {
      background-position: -200% 0%;
  }
  100% {
      background-position: 200% 0%;
  }
}



.create-collab-modal-button > img {
  width: 20px;
  margin-right: 10px;
  margin-top: 1px;
  margin-left: 10px;
  filter: invert(1);
}

.selected-count-circle {
  position: absolute;
  top: -5px;
  right: 0px;
  width: 20px;
  height: 20px;
  background-color: var(--blue);
  border-radius: 1px;
  border: 1px solid;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
}


#collab-write-generate-button {
  background-color: rgb(94, 94, 94);
  color: white;
  border-radius: 100px;
  margin-left: 10px;
  height: 50px;
  display: flex;
  
}

.text-generation-wrapper {
  display: flex;
  margin-top: -20px;
  min-height: 90px;
  align-items: center;
  justify-content: left;
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  flex-direction: column;
  background-color: rgb(49, 49, 49);
  position: relative;
  box-shadow: 
  rgba(24, 24, 24, 1.1) 0px -8px 24px, 
  rgba(24, 24, 24, 0.1) 0px -16px 56px, 
  rgba(24, 24, 24, 0.1) 0px -24px 80px;
}

/* Das eigentliche Modal */

@keyframes slideInUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.create-collab-modal {
  background-color: var(--black);
  border-radius: 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 550px;
  max-width: 90%;
  z-index: 1001;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px; 
  border: 1px solid rgba(107, 107, 107, 0.219);
  overflow: hidden;
  opacity: 1;
  animation: slideInUp 300ms ease-in-out;
}

/* Inhalt des Modals */
.create-collab-modal-content {
  padding: 0px;
}

/* Header des Modals */
.create-collab-modal-header {
  margin-bottom: 20px;
  text-align: center;
  margin-top: 30px;
  font-size: 12px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 400;
  flex-direction: column;
}
.create-collab-modal-header > h2 {
  font-weight: 500 !important;
  color: rgb(201, 201, 201);
}

.create-collab-modal-header > p  {
  margin-top: -10px;
  text-align: center;
  width: 100%;
  color: grey;
}

.create-collab-modal-header > img {
  opacity: 1;
  animation: 100ms shine-in;
}

@keyframes shine-in {
  0% {
    opacity: 0;
  } 

  100% {
    opacity: 1;
  }
}

/* Hauptbereich des Modals */
.create-collab-modal-body {
  margin-bottom: 0px;
  text-align: center;
}

/* Scrollbarer Container für die Auswahl-Bars */
.collab-container-scrollable {
  max-height: 320px;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 1.55) 0px -50px 36px -28px inset;
  margin-bottom: 0px;
  scrollbar-width: none; /* Für Firefox */
  -ms-overflow-style: none;  /* Für Internet Explorer und Edge */
}

.collab-container-scrollable::-webkit-scrollbar {
  display: none; /* Für Chrome, Safari und Opera */
}

.collab-send-button {
  background: none;
  border: none;
  position: absolute;
  right: 25px;
  top: 17px;
  background-color: var(--blue);
  height: 50px;
  width: 50px;
  border-radius: 100px;
  
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.collab-send-button.disabled {
  opacity: 0.5;
  pointer-events: none;
  background-color: rgba(128, 128, 128, 0.527);
}


.collab-send-button > img {
    width: 25px;
    margin-top: 2px;
    rotate: 270deg;
    filter: invert(1);
}


.collab-send-button:hover {
  cursor: pointer;
  transform: scale(1.02);
}



.collab-regen-button {
  background: none;
  position: absolute;
  right: 25px;
  top: 77px;
  background-color: rgb(51, 51, 51);
  height: 50px;
  border: 1px solid rgb(110, 110, 110);
  padding-top: 4px;
  width: 50px;
  border-radius: 100px;

}

.collab-regen-button:hover {
  border: 1px solid rgb(255, 255, 255);

}

.collab-send-button.disabled {
  opacity: 0.5;
  pointer-events: none;
}


.collab-regen-button:hover {
  cursor: pointer;
}

.collab-regen-button > img {
    width: 17px;
    margin-top: -3px;
    filter: invert(1);
}



.text-generation-buttons {
  display: flex;
  justify-content: left;
  margin-bottom: 0px;
  width: fit-content;
  background-color: red;
  width: 100%;
  background: none;
  margin-top: 18px;
z-index: 10;

}

.custom-text-input {
  width: calc(80%);
  padding: 10px;
  margin-left: -50px;
  background: none;
  height: 35px;
  margin-top: 7px;
  border: none;
  color: white;
  box-shadow: none;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
}

.custom-text-input::placeholder {
  color: white;
}

/* Allgemeiner Container-Stil */
.collab-container {
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid rgba(85, 85, 85, 0.342);
  border-radius: 18px;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
  position: relative;
  transition: background-color 0.2s ease;
}

.collab-container:last-child {
  margin-bottom: 50px;
}


.collab-container.selected {
  box-shadow: rgba(0, 0, 0, 1.1) 0px 4px 12px;
  background-color: #000000; /* Helle blaue Hintergrundfarbe, wenn ausgewählt */
}

.collab-container-image {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  margin-right: 15px;
  padding: 10px;
  filter: invert(1);
  opacity: 0.6;
}

.collab-container-text {
  flex-grow: 1;
}

.collab-container-text h4 {
  margin: 0 30px 0 0;
  font-size: 15px;
  font-weight: bold;
  color: white
}

.collab-container-text p {
  margin: 5px 30px 0 0;
  font-size: 14px;
  color: #666;
}

.collab-container-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.collab-container-icon > img {
  width: 20px; 
  height: 20px;
}




/* Fußbereich des Modals für die Buttons */
.create-collab-modal-footer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

/* Allgemeiner Button-Stil */
.create-collab-modal-button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Jost';
}

.create-collab-modal-button > p {
  width: fit-content;
  margin-top: 3px !important;
  margin-right: 10px !important;
}

/* Bestätigungsschaltfläche */
.create-collab-modal-confirm-button {
  background-color: #4CAF50; /* Grün */
  color: white;
}

/* Abbrechen-Schaltfläche */
.create-collab-modal-cancel-button {
  background-color: #f44336; /* Rot */
  color: white;
}

/* Button Hover-Effekte */
.create-collab-modal-button:hover {
  opacity: 0.9;
}


@media (max-width: 600px) {
  .create-collab-modal {
    max-width: 100%;
    height: 100%;
  }
  #collab-write-generate-button {
    display: none;
  }
  .text-generation-wrapper {
    display: flex;
  margin-top: 0px;
  position: absolute;
  bottom: 0;
}
.collab-container-scrollable {
  max-height: 60vh;
}
#collab-ai-generate-button {
  background-color: rgba(0, 0, 0, 0.705);
  color: white;
  border: 1px solid var(--blue);
  border-radius: 100px;
  padding-bottom: 12px;
  margin-left: 20px;
  height: 60px;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  display: flex;
  background: linear-gradient(90deg, var(--blue), rgba(0, 0, 255, 0.5), rgba(14, 14, 24, 0.5), rgba(0, 0, 255, 0.74), var(--blue));
  background-size: 200% 200%;
  animation: moveSpotlight 8s linear infinite;
  position: relative;
}

#collab-ai-generate-button > p {
  margin: 0px;
}
.text-generation-buttons {
  margin-top: 14px;
}
.custom-collab-textarea {
  height: 250px;
}
}
/* ListSongs.css */

.list-songs-container {
    padding: 20px;
  }

  .table-scroll-container {
    overflow-x: auto;
    margin-top: 20px;
  }

  .retargeting-button {
    height: 40px;
    border-radius: 10px;
    border: 1px white solid;
    background-color: var(--black);
    color: white;
    font-family: 'Jost';
    font-size: 16px;
    width: 150px;
    font-weight: 500;
  }

  .retargeting-button:hover {
    cursor: pointer;
  }

  .retargeting-button.disabled {
    border: 1px solid black;
    color: grey;
  }



  #add-table-button {
    background-color: var(--gray);
    border: 1px solid rgb(121, 121, 121);
    width: 100px;
    height: 40px;
    text-align: center;
    border-radius: 8px;
    color: white;
    font-family: 'Jost';
    font-weight: 600;
  }

  .red-dot-2 {
    height: 10px;
    width: 10px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
  }

  #add-table-button-2 {
    background-color: rgb(42, 42, 177);
    border: 1px solid rgb(121, 121, 121);
    width: 100px;
    height: 40px;
    text-align: center;
    border-radius: 8px;
    color: white;
    font-family: 'Jost';
    font-weight: 600;
  }

  #add-table-button-3 {
    background-color: rgb(173, 126, 65);
    border: 1px solid rgb(121, 121, 121);
    width: 100px;
    height: 40px;
    text-align: center;
    border-radius: 8px;
    color: white;
    font-family: 'Jost';
    font-weight: 600;
  }

  #delete-table-button {
    background-color: rgb(184, 51, 51);
    border: none;
    width: 65px;
    height: 24px;
    text-align: center;
    border-radius: 100px;
    color: white;
    font-family: 'Jost';
    font-weight: 600;
  }


  #add-table-button:hover {
    cursor: pointer;
    transform: scale(1.04);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;  }

  #add-table-button-2:hover {
    cursor: pointer;
    transform: scale(1.04);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;  }

  #add-table-button-3:hover {
    cursor: pointer;
    transform: scale(1.04);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;  }
  
  .manage-chartlist-button {
    height: 40px;
    width: 150px;
    font-family: 'Jost';
    font-size: 16px;
    font-weight: 500;
    border-radius: 7px;
    transition: 140ms ease-in-out;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--black);
    color: white;
    border: 1px solid white;
  }

  .manage-chartlist-button-review {
    height: 40px;
    width: 150px;
    font-family: 'Jost';
    font-size: 16px;
    font-weight: 500;
    border-radius: 7px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--blue);
    color: white;
  }

  .manage-chartlist-button-review:hover {
    cursor: pointer;
  }
  .manage-chartlist-button:hover {
    cursor: pointer;
    transform: scale(1.02);

  }

  .manage-chartlist-button > img {
    width: 20px;
    margin-left: 10px;
    filter: invert(1);
  }

  .manage-chartlist-button-review > img {
    width: 20px;
    filter: invert(1);
    margin-left: 10px;
  }
  
  .list-songs-table {
    width: 100%;
    /* Andere Stile für Ihre Tabelle */
  }

  .list-songs-modal-overlay {
    position: fixed; /* Positioniert relativ zum Viewport */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Abdunkelung des Hintergrunds */
    display: flex;
    justify-content: center; /* Zentriert den Inhalt horizontal */
    align-items: center; /* Zentriert den Inhalt vertikal */
    z-index: 1000; /* Stellt sicher, dass das Overlay über anderen Elementen liegt */
  }

  
  
  .list-songs-modal-content {
    background-color: rgb(46, 46, 46);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 90%; /* Anpassung der Breite, max. 500px */
    max-width: 500px;
  }
  
  .list-songs-modal-actions {
    margin-top: 20px;
    text-align: right;
  }
  
  .list-songs-modal button {
    margin-left: 10px;
  }
  
  
  .list-songs-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .list-songs-table, .list-songs-table th, .list-songs-table td {
    border: 1px solid rgba(224, 35, 35, 0);
  }
  
  .list-songs-table th, .list-songs-table td {
    padding: 8px;
    text-align: left;
  }
  
  .list-songs-table th {
    background-color: var(--black);
    height: 60px;
    font-family: 'Wide';
    font-size: 16px;
  }
  
  .pagination {
    display: flex;
  }

    
  .pagination > li > button {
    background: none;
    border: 1px solid rgb(95, 95, 95);
    color: white;
    font-size: 18px;
    font-family: 'Jost';
    border-radius: 5px;
    padding: 5px 15px;
    margin: 0 5px;
    border-radius: 100px;
  }

  .pagination > li > button:hover {
    cursor: pointer;
    background-color: black;
  }



  .list-songs-table tr:nth-child(even) {
    background-color: #1f1f1f;
    height: 60px;
  }
  
  .list-songs-table tr:hover {
    background-color: #474747;
  }
  
  .list-songs-table a {
    color: #cecece;
    text-decoration: underline;
  }
  
  .list-songs-table a:hover {
    text-decoration: underline;
  }
  



  
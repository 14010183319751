.select-genre-list {
    width: 100% !important;
    height: 54px;
    padding: 0 30px 0 15px; /* Angepasstes Padding für Inhalt */
    border-radius: 10px;
    color: white; /* Farbe für den ausgewählten Wert */
    background-color: rgba(0, 0, 0, 0.5);
    margin-top: 5px;
    font-family: 'Jost';
    font-size: 16px;
    font-size: 15px;
    outline: none;
    border: 1px solid rgb(66, 66, 66); /* Behalte den roten Rand bei Fokus bei */
}





@media (max-width: 500px) {
    .select-genre-list {
        width: 86vw;
        height: 54px;
        font-size: 16px;
        padding: 0 30px 0 10px; /* Angepasstes Padding für Inhalt */
        border-radius: 10px;
        color: white; /* Farbe für den ausgewählten Wert */
        margin-top: 5px;
        outline: none;
    }
}

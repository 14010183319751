.editor-quote {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--black);
    padding: 60px 0 40px 0;
    color: var(--white);
    position: relative;
    flex-direction: column;

}

.editor-quote > h2 {
    margin: 0px 20px;
    font-size: 52px;
    font-weight: 200;
    max-width: 1200px;
}

.img-editor-quote {
    width: 100px;
    position: absolute;
    left: var(--edge);
    top: 60px;
    opacity: 0.1;
}

.valerie-quote {
    display: flex;
    margin-top: 20px;
    margin-bottom: 80px;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.valerie-quote-text {
   text-align: right;
}


.valerie-quote-text > h6 {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
}

.valerie-quote-text > p {
    font-weight: 400;
    padding: 0;
    font-size: 12px;
    margin: 0 !important;
}

.valerie-quote-text > h5 {
    font-weight: 500;
    padding: 0;
    font-size: 20px;
    margin: 0 !important;
}

.img-valerie-quote {
    width: 60px;
    height: 60px;
    border-radius: 100px;
    margin-left: 20px;
    border: 1px solid grey;
    margin-top: 0px;
}

@media (max-width: 800px) {

.editor-quote > h2 {
    margin: 0px 20px;
    font-size: 32px;
    font-weight: 200;
}
.img-editor-quote {
    width: 100px;
    position: absolute;
    left: var(--edge);
    top: 0px;
    opacity: 0.1;
}

.valerie-quote {
    display: flex;
    margin-bottom: 30px;
}
}

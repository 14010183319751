.dummy-chat-input {
    background-color: rgb(41, 41, 41);
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 13px;
}

.dummy-chat-input > p {
    margin: 0 !important;
    font-size: 18px !important;
    font-weight: 600;
}

.dummy-chat-input > img {
    width: 25px;
    margin-left: 15px;
    filter: invert(1);
}

@media (max-width: 750px) {
    .dummy-chat-input {
        background-color: rgb(41, 41, 41);
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 13px;
        margin-bottom: 75px;
    }
}
.team-dashboard-container {
  display: flex;
  height: 100vh;
  background-color: var(--black);
  color: white;
}

.team-dashboard-sidebar {
  width: 230px;
  background-color: #1a1a1a;
  padding: 20px;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.team-dashboard-sidebar::-webkit-scrollbar {
  display: none;
}


.page-item::marker {
  color: transparent;
}


.team-dashboard-button {
  display: flex;
  font-family: 'Jost';
  align-items: center;
  padding: 15px;
  margin-bottom: 10px;
  background-color: var(--black);
  border: none;
  border-radius: 10px;
  color: rgb(189, 189, 189);
  text-align: left;
  font-size: 15px;
  transition: background-color 0.3s;
}

.team-dashboard-button:hover {
  cursor: pointer;
}

.team-dashboard-icon {
  margin-right: 15px;
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.team-dashboard-active-button {
  background-color:var(--black); /* Die Hintergrundfarbe des aktiven Buttons */
  border: 1px solid var(--blue);
  font-weight: 600;
  color: white;
}

.team-dashboard-content {
  flex: 1;
  padding: 20px;
  background-color: #121212;
  overflow-y: auto;
}

.submission-page {
  background-color: rgb(26, 26, 26);
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  position: relative;
}

.application-box > h2 {
  font-family: "Wide", "Jost", "Josefin Sans", sans-serif;
  font-size: 24px;
  margin-bottom: 0px;
}

.application-box:hover {
  border: 1px solid var(--red);
  cursor: pointer;
}

#application-box-blue:hover {
    border: 1px solid var(--blue);
    cursor: pointer;
}

.application-box:hover > button {
  border: 1px solid var(--red);
  cursor: pointer;
}

#application-box-blue:hover > button {
    border: 1px solid var(--blue);
    cursor: pointer;
  }

.submission-page > img {
  width: 300px;
  margin-bottom: 50px;
  z-index: 1000;
}

.submission-page > p {
  margin-bottom: 30px;
  margin-left: 20px;
  margin-right: 20px;
}

.application-box {
  border: 1px solid rgb(68, 68, 68);
  margin-bottom: 30px;
  background-color: rgba(14, 14, 14, 0.849);
  max-width: 500px;
  padding: 20px;
  margin: 15px;
  z-index: 10;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.application-box > button {
  font-size: 16px;
  border-radius: 100px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 0px;
}

@media (max-width: 800px) {
  .submission-page > img {
    width: 300px;
    margin-bottom: 50px;
    margin-top: 50px;
  }
}

.logs-view-container-dark {
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #e0e0e0;
  }
  
  .logs-view-wallet-container {
    border: 1px solid #333;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 20px;
  }

  /* Allgemeiner Stil */
.logs-view-transaction-card {
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  /* Kauf - Grün */
  .logs-view-transaction-card.activity-buy {
    background-color: #0f2005;
    color: white;
    z-index: 100;
  }
  
  /* Verkauf - Rot */
  .logs-view-transaction-card.activity-sell {
    background-color: #3f2123;
    color: white;
  }
  
  /* Double Trade - Gelb */
  .logs-view-transaction-card.activity-double-trade {
    background-color: #42121a;
    margin-bottom: -30px;
    color: white;
    z-index: 0;
  }
  
  
  .logs-view-activities-list {
    margin-top: 20px;
  }
  
  .logs-view-transaction-card {
    border: 1px solid #444;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 10px;
    background-color: #232323;
  }
  
  .logs-view-transaction-card h4 {
    margin-top: 10px;
    color: #ffffff;
  }
  
  .logs-view-routes-list {
    padding-left: 20px;
    list-style: none;
  }
  
  .logs-view-routes-list li {
    margin-bottom: 5px;
  }
  
  .logs-view-routes-list p {
    margin: 0;
    color: #b0b0b0;
  }
  
  .logs-view-wallet-container h2 {
    color: #ffffff;
  }
  
  .logs-view-wallet-container p strong,
  .logs-view-transaction-card p strong {
    color: #ffffff;
  }
  
  .logs-view-no-wallets,
  .logs-view-no-activities {
    text-align: center;
    color: #757575;
    margin-top: 20px;
  }
  
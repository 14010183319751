.gig-artist-basic-details > img,
.gig-gallery-image-prev {
    width: 100% !important;
    aspect-ratio: 18 / 13; /* Maintain the 4:3 aspect ratio */
    border-bottom-right-radius: 35px !important; 
    border-bottom-left-radius: 35px !important; 
    margin-top: 0px;
    object-fit: cover; /* Ensures the images fit well within the container */
    border-radius: 15px; /* Slightly rounded corners for images */
}




.custom-swiper-container {
    width: 100%;
    height: 100%; /* Ensures Swiper container does not exceed the parent container */
    overflow: hidden;
    position: relative;
}

.play-pause-btn-4 > img {
  width: 15px;
  filter: invert(1);
}

.artist-image-no-wrapper {
  display: flex;
  align-items: center;
  justify-content: left;
  position: absolute;
  bottom: 40px;
  left: 10px;
  z-index: 10;
}

  
  
.custom-swiper-button-prev, .custom-swiper-button-next {
  position: absolute;
  top: 31%;
  transform: translateY(-50%);
  z-index: 10;
  background-color: rgba(43, 43, 43, 0.205);
  border-radius: 100px;
  width: 45px;
  backdrop-filter: blur(5px);
  height: 45px;
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  box-shadow: var(--color-shadow);
}



.prev-gig-slide-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.prev-gig-slider-video-container {
    aspect-ratio: 18 / 13; /* Maintain the 4:3 aspect ratio */
}

.custom-swiper-button-prev {
  left: 8px;
}

.custom-swiper-button-prev:hover {
  cursor: pointer;
}
.custom-swiper-button-next:hover {
  cursor: pointer;
}

.custom-swiper-button-next {
  right: 8px;
}

.custom-swiper-button-prev > img {
  filter: invert(1) !important; 
  width: 23px;
  transform: rotate(90deg);
}

.custom-swiper-button-next > img {
  filter: invert(1) !important; 
  width: 23px;
  transform: rotate(270deg);
}


.gig-artist-basic-details {
  width: 100%; /* Make sure this is within a parent that has a defined width */
  max-width: 100%; /* Example width, adjust as needed */
  position: relative;
}



.skeleton-image-6 {
    background: #ddd;
    animation: shimmer 2s infinite;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 1000px 100%;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    background-repeat: no-repeat;
    animation: shimmer2 1s infinite;
    background-size: 200% 100%;
  }


  .skeleton-loader-5 {
    width: 97% !important;
    aspect-ratio: 18 / 12.4; /* Beibehaltung eines 4:3 Seitenverhältnisses */
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    animation: pulseAnimation 1.5s infinite ease-in-out;
  }
  


.gig-artist-basic-details p {
    margin: 5px 0 5px 12px; /* Abstand zwischen den Textzeilen */
    font-size: 14px; /* Lesbare Schriftgröße */
    text-align: left;
}

.gig-artist-basic-bottom {
  margin-top: 30px;
  flex-direction: column;
  align-items: left !important;
  justify-content: left;
}


.my-fav-artist-type {
    margin-left: 10px !important;
    margin-top:  5px !important;
}

.gig-artist-basic-details > div > hr {
    height: 10px;
    border: none;
    width: 1px;
    background-color: rgb(97, 97, 97);
    margin: 0px 20px;
}



.gig-artist-image-small {
    width:85px !important;
    border-radius: 100px;
    height: 85px !important;
    
    object-fit: cover;
    }

    .gig-artist-image-small-2 {
      width:95px !important;
      border-radius: 100px;
      height: 95px !important;
      object-fit: cover;
      margin-right: 10px;
      position: absolute;
      z-index: 100;
      top: -95px;
      left: 10px;
      }
  

.artist-image-small-wrapper {
  width: 95px !important;
  border-radius: 100px;
  height: 95px !important;
  object-fit: cover;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 100;
  top: -95px;
  left: 10px;
  background-color: rgb(97, 206, 97) !important;
  animation: pulsatori 2s infinite ease-in-out;
}
@keyframes pulsatori {
  0% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(94, 241, 94, 0.6);
  }
  50% {
    transform: scale(1.0);
    box-shadow: 0 0 20px rgba(94, 241, 94, 0.8);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(94, 241, 94, 0.6);
  }
}


.artist-image-small-wrapper > p {
  position: absolute;
  bottom: 0px;
  border-radius: 100px;
  left: 22px;
  border: 1px solid rgb(94, 241, 94);
  margin: 0px !important;
  font-size: 10px !important;
  background-color: var(--black);
  padding: 2px 10px;
  background: linear-gradient(135deg, rgb(87, 87, 87), rgb(59, 114, 59), black) !important;
  background-size: 200% 200%;
  background-position: 0% 50%;
}




 
    @media (max-width: 650px) {
      .gig-artist-basic-bottom {
          margin-top: 50px;
      }
  }
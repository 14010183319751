.chat-prev-uploaded-img {
    width: 40px;
    position: absolute;
    right: 170px;
    bottom: 20px;
    max-height: 40px;
    border-radius: 5px;
}

.chat-prev-uploaded-img-4 {
    width: 40px;
    position: absolute;
    right: 170px;
    bottom: 20px;
    max-height: 40px;
    border-radius: 5px;
}

.chat-prev-uploaded-img-2 {
    width:40px;
    right: 0px;
    bottom: 0px;
    max-height: 40px;
    border-radius: 5px;
}

.image-upload-component-chat-3 {
    display: flex;
}


@media (max-width: 800px) {
    .chat-prev-uploaded-img {
        right: 140px;
    }
}

@media (max-width: 700px) {
    .chat-prev-uploaded-img {
        display: none;
    }
}
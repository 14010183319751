


.widget-settings-container {
    width: 100%;
    max-width: 400px;
    margin-top: 100px;
}


@media (max-width: 900px) {
    .widget-settings-container {
        width: 100%;
        max-width: 1000px;
    }
    
}
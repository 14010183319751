.awards-cta-popup {
    position: fixed;
    bottom: 20px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 1000;
  }
  
  .cta-popup-content {
    display: flex;
    flex-direction: column;
    background-color: #141414c9;
    border-radius: 100px;
    backdrop-filter: blur(30px);
    border: 1px solid rgba(255, 255, 255, 0.178);
    padding: 15px 30px;
    width: 420px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  }
  
  .cta-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 100%;
    bottom: 72px;
  }
  
  .free-for-artists {
    background-color: #4a56e2;
    padding: 5px 10px;
    border-radius: 100px;
    font-size: 11px;
    font-weight: 500;
    margin-bottom: -5px;
    margin-left: 0px;
  }
  
  .cta-close-btn {
    background: none;
    border: none;
    color: white;
    font-size: 18px;
    margin-bottom: 0px;
    margin-right: 10px;
    margin-top: 5px;
    cursor: pointer;
  }

  .cta-close-btn > img {
      width: 15px;
      filter: invert(1);
  }
  
  .cta-popup-body {
    margin-top: 0px;
    text-align: left;
    width: 100%;
  }
  
  .cta-popup-body h4 {
    font-size: 16px;
    margin: 0 0 2px 0;
  }

  .cta-popup-centre {
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  
  .cta-popup-body p {
    font-size: 14px;
    margin: 0;
  }
  

  
  .cta-apply-btn {
    background-color: var(--red);
    color: white;
    padding: 14px 20px;
    font-size: 14px;
    border-radius: 50px;
    font-family: 'Wide';
    border: none;
    margin-right: -10px;
    text-decoration: none;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    width: 160px;
    justify-content: center;
  }
  
  .cta-apply-btn:hover {
    background-color: #d62839;
    cursor: pointer;
  }
  
  



  @media (max-width: 750px) {
    .cta-popup-content {
      width: 100%;
    
    }
    .awards-cta-popup {
      position: fixed;
      bottom: 0px;
      z-index: 1000;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    .cta-popup-body p {
      display: none;
    }
    .cta-popup-content {
      background-color: transparent;
      border: none;
      border-radius: 0px !important; 
      backdrop-filter: blur(0px);

    }

    .cta-popup-body h4 {
      font-size: 14px;
      margin: 0 0 5px 0;
    }

      
  .cta-apply-btn {
    width: 250px;
    height: 60px;
  }

  .cta-popup-body {
    text-align: center;
  }

    
  .cta-close-btn {
    position: absolute;
    right: 30px;
    top: -50px;
  }

  .cta-popup-centre {
    flex-direction: column;
  }
      
  .free-for-artists {
    display: none;
  }
  
  }
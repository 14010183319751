.collab-success-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.616);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

  }
  
  .collab-success-modal {
    background: rgb(0, 0, 0);
    padding: 40px 20px;
    border-radius: 35px;
    border: 1px solid rgb(90, 90, 90);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10001;
    flex-direction: column;
    width: 400px;
    color: white;
    max-width: 90%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;    position: relative;
  }
  
  .collab-success-header {
    text-align: center;
    margin-bottom: 20px;
  }

  .collab-success-header > img {
      width: 100px;
  }
  
  .start-over-button {
    position: relative;
  }
  
  .completed-stepper-progress-container {
    width: 100%;
    position: absolute;
    top: 1px;
    height: 60px;
    z-index: 1;
    background-color: #4e4e4e;
    border-radius: 100px;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    margin-bottom: 0px;
  }
  
  .completed-stepper-progress-bar {
    width: 0;
    height: 100%;
    background: linear-gradient(135deg, var(--blue) 25%, rgb(37, 116, 207) 75%, var(--blue) 50%, var(--blue) 50%, var(--blue) 75%, rgb(57, 73, 221) 75%, var(--blue));
    background-size: 20px 2px;
    border-radius: 100px;
    animation: loadCompletedStep5 2s forwards, wave 1s linear infinite;
  }
  
  @keyframes loadCompletedStep5 {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  
  @keyframes wave {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 40px 40px;
    }
  }
  
.save-payment-options-container {
    margin: 20px 0;
}

.payment-options {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.payment-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: rgb(145, 145, 145);
    border: 1px solid #ccc;
    border-radius: 100px;
    cursor: pointer;
    width: 80px;
    font-weight: 500;
    font-size: 14px;
    height: 80px;
    color: rgb(48, 48, 48);
    text-align: center;
    transition: background-color 0.3s ease;
}

.payment-option.selected {
    background-color: rgb(224, 224, 224);
    border-color: #007bff;
}

.payment-option img {
    width: 40px;
    max-height: 30px;
    object-fit: contain;
    margin-top: 10px;
    margin-bottom: 5px;
}

.money-back-guarantee {
    display: flex;
    margin-top: 40px;
    align-items: center;
    margin-bottom: 80px;
    flex-direction: column;
}



.guarantee-text {
    display: flex;
    flex-direction: column;
}

.guarantee-text p {
    margin: 0;
    font-weight: bold;
}

.guarantee-text small {
    color: #666;
}

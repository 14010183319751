.artist-top {
    color: white;
    text-align: center;
    padding: 0px 0 0px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }

  .artist-buttons {
      margin-bottom: 30px;
      margin-top: 30px;
      position: absolute;
      top: -100px;
      right: 20px;


  }

  .follow-social-button {
    padding: 13px 20px;
    border: 1px solid white;
    border-radius: 10px;
    position: relative;
  }

  .send-profile-message-button > img {
    width: 30px;
    margin-left: 15px;
    filter: invert(1);
  }

  .send-profile-message-button > p {
    font-weight: 500;
  }

  .disabled {
    cursor: not-allowed;
  }

  .send-profile-message-button {
    border: 1px solid rgba(119, 119, 119, 0.562);
    border-radius: 100px;
    font-family: 'Jost';
    width: fit-content;
    background-color: var(--black);
    height: 65px;
    display: flex;
    margin-top: -20px;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
    font-weight: 500;
    max-width: 90vw;
    font-size: 16px;
    text-transform: uppercase;
    color: white;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin-left: 0px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    background: linear-gradient(90deg, var(--blue), rgba(0, 0, 255, 0.5), rgba(14, 14, 24, 0.5), rgba(0, 0, 255, 0.74), var(--blue));
    background-size: 200% 200%;
    animation: moveSpotlight 8s linear infinite;
  
  }


.mobile-socials-artist {
  display: none;
}




  .send-profile-message-button:hover {
    transform: scale(1.02);
    cursor: pointer;

  }

  .follow-social-button:hover {
    transform: scale(1.02);
    cursor: pointer;
  }

  .award-label > p {
      font-weight: 200;
      font-family: 'Jost' !important;
    letter-spacing: 2px;
  }

  .award-label {
    display: flex;
    font-size: 18px;
    align-items: center; /* Zentriert die Elemente vertikal, falls gewünscht */
    gap: 8px; /* Optional: Fügt einen Abstand zwischen den Elementen hinzu */
  }
  
  .award-img {
      width: 25px;
  }

  .award-profile > hr {
      height: 20px;
      width: 1px;
      border: none;
      background-color: white;
      margin: 0 15px 0 15px;
  }

  .awards-profile-banner {
      display: flex;
      margin-top: 30px;
      width: 100%;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      flex-direction: column;
  }

    
  .award-profile {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0px 10px 0px;
    border: 1px solid rgba(114, 114, 114, 0.637);
    margin: -50px 0px 0px 0px;
    border-radius: 0px;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    width: calc(100%);
    height: 50px;
    z-index: 100;
    background-color: var(--black);
    font-family: 'Wide', 'Jost', 'Josefin Sans', sans-serif;
    animation: slideInFromTop 0.8s ease-out forwards;

  }

  .artist-social-medias {
    margin-top: 10px;
    position: absolute;
    right: 50px;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: flex-end;
    top: 25px;
  }

  .artist-social-medias > div {
    gap: 25px; /* Abstand zwischen den Icons */
    display: flex;
    justify-content: right;
  }

  .artist-social-medias > div > p {
    color: grey;
    font-size: 13px;
    text-align: right;

  }

  .artist-audience-size {
    display: flex !important;
    justify-content: right !important; 
  }

  .artist-vip-badge {
    display: flex !important;
    background-color: rgb(20, 20, 20);
    height: 42px;
    border: 1px solid rgb(73, 73, 73);
    padding-top: 1px;
    width: 60px;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 100px;
  }

  .artist-vip-badge > p > span {
    font-size: 13px !important;

  }

  .artist-vip-badge > img {
    margin-left: -16px;
    width: 20px;
    margin-top: -2px;
  }


  .artist-audience-size > img {
    filter: invert(1);
    margin-left: -16px;
    width: 12px;
    margin-top: -2px;
  }

  .artist-social-medias > div > p > span {
    font-size: 16px;
    color: white;
    font-weight: 600;
  }
  
  .artist-social-icon {
    width: 22px;
    height: 22px;
    cursor: pointer;
    filter: invert(1);
    opacity: 0.7;
  }

    
  .artist-social-icon:hover {
    opacity: 1;
  }
  
  .social-icon:hover {
    opacity: 0.8;
  }
  

  .award-profile:nth-child(2) {
    margin-top: -40px !important;
    z-index: 10;
    padding: 50px 0px 10px 0px;
  }

  @keyframes slideInFromTop {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .award-profile > h2 {
      font-size: 24px;
      font-weight: 200;
  }
  
  .award-label {
    font-weight: bold;
  }

  .profile-top-wrapper {
    width: 100%;
    border-top-right-radius: 55px;
    border-top-left-radius: 55px;
    margin-top: 150px;
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: column;
    position: relative;
    background-color: rgba(39, 39, 39, 0.322);



  }

  .artist-badge.newcomer {
    display: flex !important;
    background-color: rgb(32, 32, 32);
    height: 42px;
    padding-top: 1px;
    margin-bottom: 30px !important;
    margin-top: 5px;
    border: 1px solid rgb(71, 71, 71);
    padding: 1px 15px 0 15px;
    display: flex;
    font-family: 'Jost';
    text-transform: uppercase;
    font-weight: 500;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 100px;
  }
  .artist-badge.award.winner {
    display: flex !important;
    background-color: var(--black);
    height: 42px;
    padding-top: 1px;
    margin-top: 5px;
    padding: 1px 15px 0 15px;
    border: 1px solid rgb(71, 71, 71);
    display: flex;
    font-family: 'Jost';
    text-transform: uppercase;
    font-weight: 500;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 100px;
  }

  .artist-badge.nominee {
    display: flex !important;
    background-color: var(--black);
    height: 42px;
    padding-top: 1px;
    margin-top: 5px;
    padding: 1px 15px 0 15px;
    border: 1px solid rgb(71, 71, 71);
    display: flex;
    font-family: 'Jost';
    text-transform: uppercase;
    font-weight: 500;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 100px;
  }

  .artist-badge.nominee  > p  {
    color: white;
  }

  .artist-badge.award.winner  > p  {
    color: white;
  }

  .artist-badge.newcomer > p  {
    color: white;
  }

  
  .artist-image-2 {
    width: 200px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    height: 200px;
    z-index: 3;
    border-radius: 50%;
    margin: -100px 0 0 70px;
    display: block;
    object-fit: cover;
    position: relative;
  }

  .profile-top-image-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  
  .artist-info {
      display: flex;
      align-items: flex-start;
      justify-content: left;
      flex-direction: column;
    margin-top: 20px;
    width: 100%;
    margin-left: 70px;
  }

  .artist-details-info {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 90%;
      text-align: center;
      
  }

  .artist-details-info > p {
    font-size: 18px;
    color: rgb(204, 204, 204);
  }

.artist-details-info > hr {
    width: 1px;
    height: 20px;
    border: 0;
    background-color: rgb(105, 105, 105);
    margin: 0px 20px 0 20px;

}
  
  .artist-name {
    font-size: 28px;
    font-weight: bold;
    margin: 0px 0;
  }
  .artist-real-name {
    font-size: 18px;
    font-weight: bold;
    margin: 0px 0;
    font-weight: 400;
    margin-top: 10px;
    color: rgb(204, 204, 204);
  }
  
  .artist-genre {
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  .artist-bio-1 {
    font-size: 16px;
    margin-bottom: 20px;
    max-width: 500px;
    margin: 0 30px;
    width: 100%;
  }
  
  .artist-awards {
    font-size: 16px;
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  .modal-overlay-1 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content-1 {
    background-color: var(--black);
    padding: 20px;
    border-radius: 25px;
    font-size: 24px;
    font-family: 'Wide', 'Jost', 'Josefin Sans', sans-serif;
    text-align: center;
    max-width: 500px;
  }

  .modal-content-1 > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-content-1 > img {
    width: 200px;
  }
  
  .signup-link-1 {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 15px;
    padding: 10px;
    background-color: var(--red);
    color: white;
    font-family: 'Jost';
    font-size: 16px;
    border-radius: 100px;
    width: 250px;
    text-decoration: none;
    border: none !important;
  }

  .signup-link-2 {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 15px;
    padding: 10px;
    background-color: var(--grey);
    color: white;
    font-family: 'Jost';
    font-size: 16px;
    border-radius: 100px;
    width: 200px;
    text-decoration: none;
    border: 1px solid grey !important;
    margin-right: 20px;
  }
  
  .signup-link-2:hover {
    cursor: pointer;
  }

  .signup-link-1:hover {
    transform: scale(1.02);
    transition: ease-in 50ms;
  }





  @media (max-width: 700px) {

   .artist-top {
     margin-top: 30px;
   } 

   .award-profile > h2 {
     font-size: 20px;
   }

   .artist-name {
    font-size: 22px;
  }

   .award-profile {
     margin-top: -140px;
     padding-top: 80px;
     margin-bottom: 40px;
   }


  .artist-social-medias {
    margin-top: -10px;
    position: absolute;
    right: 20px;
  }

  .artist-audience-size {
    display: none !important;
  }

  .artist-social-medias > div {
    display: none;
  }

   .artist-image-2 {
    margin: -100px 0 0 25px;  
    width: 150px;
    height: 150px;
   }

   .send-profile-message-button {
     display: none;
   }

   .artist-info {
     margin-left: 30px;
   }


   .mobile-socials-artist > div {
    display: flex;
    width: 150px;
    justify-content: flex-end;
  }

  .mobile-socials-artist {
    position: absolute;
    right: 20px;
    align-items: flex-end;
    top: -170px;
    display: block;
    display: flex;
    flex-direction: column-reverse;
  }
  
  .mobile-socials-artist > div > a {
    margin: 0px 0px 0 18px;
  }

    
  .mobile-socials-artist > p {
    margin: 0px;
    font-size: 13px;
    margin-top: 3px;
    color: rgb(172, 172, 172);
  }
  

  }


  @media (max-width: 400px) {
    .award-label {
      font-size: 12px;
    }
  
    
  }
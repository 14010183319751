.all-pages-benefits {
    width: 550px;
    border-radius: 15px;
    padding: 5px 15px;
    border: 1px solid rgba(112, 112, 112, 0.445);
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;    
    position: relative;
    backdrop-filter: blur(50px);
    z-index: 1000;
    animation: fade-in 0.2s;
    background-color: rgba(0, 0, 0, 0.534);
  } 

  .skeleton-loader-benefits {
    width: 550px; /* Gleiche Breite wie all-pages-benefits */
    border-radius: 15px;
    height: 260px;
    background-color: #222;
    color: #999;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    background-repeat: no-repeat;
    animation: shimmer2 1s infinite;
    background-size: 200% 100%;
}


  .all-pages-benefit > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    width: 100%;
    padding: 15px 0 5px 0;
  }

  .all-pages-benefit > div > img {
    width: 30px;
    margin-right: 20px;
    filter: invert(1);
    margin-left: 10px;
  }

  .all-pages-benefit > div > div > h4 {
    margin-bottom: 0px;
    font-size: 18px;
    margin-top: 0px;
  }

  .all-pages-benefit > div > div > p {
    margin-top: 0px;
    margin-bottom: 0px;
    color: rgb(161, 161, 161);
  }

  .plus-button-all-pages {
      position: absolute;
      right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      border-radius: 100px;
      border: none; 
      transition: 0.3s ease-in-out;
      background-color: transparent;
  }

  .plus-button-all-pages:hover {
      cursor: pointer;
  }

  .plus-button-all-pages.open {
      rotate: 180deg;
  }

  .plus-button-all-pages > img {
      width: 30px;
  }

  .all-pages-info-points {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: -12px;
    margin-bottom: 0px;
    overflow: hidden; /* verhindert das Anzeigen von Inhalten außerhalb des Containers */
    transition: max-height 0.25s ease-in-out; /* sanfter Übergang der Höhe */
    max-height: 0; 
    margin-bottom: 20px;
}

.all-pages-info-points > p {
    text-align: left;
    margin-top: 2px;
    margin-left: 60px;
}

.all-pages-benefit > div.open {
    max-height: 600px; /* angenommene maximale Höhe, kann je nach Inhalt angepasst werden */
}

.all-pages-info-point {
    display: flex;
    text-align: left;
    width: 100%;
    margin-left: 25px;
    margin-top: 10px;

}

.all-pages-info-point > img {
    width: 22px;
    margin-left: 8%;
    margin-right: 10px;
}


.all-pages-benefit {
    margin-top: 5px;
}



@media (max-width: 700px) {
  .all-pages-benefits {
    width: 95%;
    padding: 5px 5px; 
  } 

  .all-pages-benefit > div > div > h4 {
    margin-right: 50px !important;
  }

  .plus-button-all-pages {
    right: 5px;
  }

  .empty-page-discount-banner {
    width: 90%;
    right: 5%;
  }
}

  .rss-container {
    padding: 20px;
    margin: 0 auto;
  }
  
  .rss-header {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    color: #f5f5f5;
  }
  
  .rss-toggle-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .rss-toggle-buttons button {
    background-color: #333;
    color: #f5f5f5;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .rss-toggle-buttons button:hover {
    background-color: #555;
  }
  
  .rss-input-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .rss-input-container input[type="text"],
  .rss-input-container select {
    background-color: #2b2b2b;
    color: #f5f5f5;
    border: 1px solid #444;
    padding: 10px;
    border-radius: 5px;
  }
  
  .rss-input-container input[type="text"]::placeholder {
    color: #888;
  }
  
  .rss-save-button {
    background-color: #4a90e2;
    color: #f5f5f5;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .rss-save-button:hover {
    background-color: #357abd;
  }
  
  .rss-target-list {
    list-style-type: none;
    padding: 0;
  }
  
  .rss-target-item {
    display: flex;
    justify-content: space-between;
    background-color: #2b2b2b;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    color: #f5f5f5;
  }
  
  .rss-target-item span {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .rss-delete-button {
    background-color: #d9534f;
    color: #f5f5f5;
    border: none;
    padding: 5px 10px;
    border-radius: 100px !important;
    margin-left: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .rss-delete-button:hover {
    background-color: #c9302c;
  }
  
  .rss-fetch-button {
    background-color: #5bc0de;
    color: #f5f5f5;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 20px;
  }
  
  .rss-fetch-button:hover {
    background-color: #31b0d5;
  }
  
  .rss-csv-container {
    margin-top: 20px;
    padding: 15px;
    background-color: #2b2b2b;
    border-radius: 5px;
  }
  
  .rss-csv-title {
    font-size: 18px;
    color: #f5f5f5;
    margin-bottom: 10px;
  }
  
  .rss-csv-delete-button {
    background-color: #d9534f;
    color: #f5f5f5;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .rss-csv-delete-button:hover {
    background-color: #c9302c;
  }
  
  /* Dark mode CSS for SaveRSS */
.rss-save-container {
    padding: 20px;
    background-color: #1e1e1e;
    color: #f5f5f5;
    border-radius: 8px;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .rss-input-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .rss-input-field {
    background-color: #2b2b2b;
    color: #f5f5f5;
    border: 1px solid #444;
    padding: 10px;
    border-radius: 5px;
  }
  
  .rss-input-field::placeholder {
    color: #888;
  }
  
  .rss-select-group {
    background-color: #2b2b2b;
    color: #f5f5f5;
    border: 1px solid #444;
    padding: 10px;
    border-radius: 5px;
  }
  
  .rss-save-button {
    background-color: #4a90e2;
    color: #f5f5f5;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .rss-save-button:hover {
    background-color: #357abd;
  }
  
  .rss-targets-header {
    font-size: 18px;
    margin-bottom: 10px;
    color: #f5f5f5;
  }
  
  .rss-target-list {
    list-style-type: none;
    padding: 0;
  }
  
  .rss-target-item {
    display: flex;
    justify-content: space-between;
    background-color: #2b2b2b;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    color: #f5f5f5;
  }
  
  .rss-target-item span {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .rss-delete-button {
    background-color: #d9534f;
    color: #f5f5f5;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .rss-delete-button:hover {
    background-color: #c9302c;
  }
  

  .rss-watch-button {
    background-color: #4a90e2;
    color: #f5f5f5;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
    display: inline-block;
    transition: background-color 0.3s ease;
  }
  
  .rss-watch-button:hover {
    background-color: #357abd;
  }
  
.home-nominees, .home-nominees {
    text-align: left;
    background-color: var(--black);
    padding-bottom: 30px;
    overflow: hidden;
    z-index: 100;
    margin-top: -40px;
  }

  .home-nominees-2 {
    backdrop-filter: blur(10px);
    background-color: rgba(39, 39, 39, 0.322);
    padding-top: 10px;
    border-bottom-left-radius: 45px;
    border-bottom-right-radius: 45px;
  }

  
  .home-nominees >  h2, .home-nominees-2 > h2 {
    margin-bottom: 10px;
    color: var(--white);
    font-family: 'Wide', 'Jost', 'Josefin Sans', sans-serif;
    font-size: 68px;
    text-transform: uppercase;
    margin-left: var(--edge);
    text-align: left;
  }




  .award-ribbon {
    position: absolute;
    top: 10px;
    right: 25px;
    width: 30px;
    z-index: 999;
    height: 150px;
    background-color: rgb(24, 24, 24);
  }
  

  .award-ribbon > h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    width: 20px;
    height: 20px;
    padding: 10px;
    border-radius: 100px;
    background-color: rgb(12, 12, 12);
    margin-left: -7px;
    color: rgb(223, 223, 223);
    margin-top: 10px;
    border: 1px solid rgb(97, 97, 97);
  }


  .award-ribbon::before,
  .award-ribbon::after {
    content: '';
    position: absolute;
    bottom: -14px;
    width: 15px;
    height: 15px;
    background-color: rgb(24, 24, 24);
  }
  
  .award-ribbon::before {
    right: 50%;
    clip-path: polygon(0% 0%, 100% 0%, 0% 100%); /* Linke Spitze */
  }
  
  .award-ribbon::after {
    left: 50%;
    clip-path: polygon(0% 0%, 100% 100%, 100% 0%); /* Rechte Spitze */
  }
  
  .vertical-text {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgb(196, 196, 196);
    top: 65px; /* Abstand von der oberen Kante des Ribbons, anpassen nach Bedarf */
    right: 10px; /* Platziert den Text am rechten Rand des Ribbons */
    font-size: 0.6em; /* Anpassen nach Bedarf */
    font-weight: 600;
  }
  
  .vertical-letter {
    display: block; /* Jeder Buchstabe wird als eigener Block angezeigt */
    line-height: 1; /* Passt den Zeilenabstand an, anpassen nach Bedarf */
    /* Weitere Stile nach Bedarf */
  }
  

  .award-ribbon > img {
    width: 20px;
    padding: 10px;
    border-radius: 100px;
    background-color: rgb(12, 12, 12);
    margin-left: -5px;
    margin-top: 10px;
    border: 1px solid rgb(97, 97, 97);
  }

  .home-nominees > h3 {
    color: white;
    width: 100%;
    text-align: left;
    margin-left: var(--edge);
    font-size: 22px;
    font-weight: 300;
  }

  .home-nominees-2 > h3 {
    text-align: left;
    font-weight: 300;
    font-family: 'Wide', 'Jost';
    margin-left: 70px;
    font-size: 14px;
    margin-bottom: 40px;
    color: grey;
  }
  
  .home-nominees > p  {
    font-size: 16px;
    color: var(--white);
    margin-bottom: 40px;
    margin-left: var(--edge);
    font-weight: 300;
    letter-spacing: 1px;

  }

  .home-nominees-2 > p  {
    font-size: 16px;
    color: var(--white);
    margin-bottom: 40px;
    margin-left: var(--edge);
    font-weight: 300;
    letter-spacing: 1px;

  }

  .home-nominee-song-bottom > p {
    font-size: 16px;
    color: var(--white);
    text-align: left;
    margin-top: 0px !important;
  }


  .home-nominee-song-bottom > a > p {
    font-size: 16px;
    color: var(--white);
    text-align: left;
    font-weight:400;
  }


  .home-nominee-song-bottom > p > img {
    margin-bottom: -5px;
  }

  .home-nominee-song-bottom > a > p > img {
    margin-bottom: -5px;
  }




  .home-nominee-song-bottom > a > h4 {
    font-size: 16px;
    color: var(--white);
    text-align: left;
    margin-bottom: 5px;
  }




  .home-nominee-song-bottom > h4 {
    font-size: 16px;
    color: var(--white);
    text-align: left;
  }


  .home-nominee-song-bottom  > a > h4:hover {
    text-decoration: underline;
  }
  
  .home-nominee-song-bottom  > a > p:hover {
    text-decoration: underline;
  }
  
  .home-nominees-body {
    display: flex;
    justify-content: center;
  }
  
  .home-nominees-left {
    flex: 1;
    position: relative;

  }





.home-nominees-grid::-webkit-scrollbar {
    display: none;  /* Chrome, Safari, Opera*/
}

.home-nominees-grid-2::-webkit-scrollbar {
  display: none;  /* Chrome, Safari, Opera*/
}


.home-international-grid::-webkit-scrollbar {
  display: none;  /* Chrome, Safari, Opera*/
}




  .home-nominees-grid {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden; /* Verhindert vertikales Scrollen */
    width: 100vw; /* Setzt die Breite auf die Breite des Viewports */
    box-sizing: border-box; /* Berücksichtigt Padding im Box-Modell */
    -webkit-overflow-scrolling: touch; /* Ermöglicht flüssiges Scrollen auf Touch-Geräten */
    gap: 0px; /* Abstand zwischen den Elementen */
    scroll-snap-type: x mandatory;
    padding: 0 var(--edge); /* Passt den Innenabstand an */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  
}


.home-nominees-grid-3 {
  display: flex;
  flex-wrap: wrap; /* Verhindert vertikales Scrollen */
  width: 90vw; /* Setzt die Breite auf die Breite des Viewports */
  box-sizing: border-box; /* Berücksichtigt Padding im Box-Modell */
  -webkit-overflow-scrolling: touch; /* Ermöglicht flüssiges Scrollen auf Touch-Geräten */
  gap: 20px; /* Abstand zwischen den Elementen */
  scroll-snap-type: x mandatory;
  padding: 0 80px; /* Passt den Innenabstand an */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  
  margin: 0 0 0 50px;
}

.home-nominees-grid-2{
  display: flex;
  overflow-x: auto;
  overflow-y: hidden; /* Verhindert vertikales Scrollen */
  width: 100vw; /* Setzt die Breite auf die Breite des Viewports */
  box-sizing: border-box; /* Berücksichtigt Padding im Box-Modell */
  -webkit-overflow-scrolling: touch; /* Ermöglicht flüssiges Scrollen auf Touch-Geräten */
  gap: 0px; /* Abstand zwischen den Elementen */
  scroll-snap-type: x mandatory;
  padding: 0 var(--edge); /* Passt den Innenabstand an */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  
}


.home-international-grid {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden; /* Verhindert vertikales Scrollen */
  width: 100vw; /* Setzt die Breite auf die Breite des Viewports */
  box-sizing: border-box; /* Berücksichtigt Padding im Box-Modell */
  -webkit-overflow-scrolling: touch; /* Ermöglicht flüssiges Scrollen auf Touch-Geräten */
  gap: 0px; /* Abstand zwischen den Elementen */
  scroll-snap-type: x mandatory;
  padding: 0 var(--edge); /* Passt den Innenabstand an */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  
}

.home-vocals-grid {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden; /* Verhindert vertikales Scrollen */
  width: 100vw; /* Setzt die Breite auf die Breite des Viewports */
  box-sizing: border-box; /* Berücksichtigt Padding im Box-Modell */
  -webkit-overflow-scrolling: touch; /* Ermöglicht flüssiges Scrollen auf Touch-Geräten */
  gap: 0px; /* Abstand zwischen den Elementen */
  scroll-snap-type: x mandatory;
  padding: 0 var(--edge); /* Passt den Innenabstand an */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  
}


.home-production-grid {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden; /* Verhindert vertikales Scrollen */
  width: 100vw; /* Setzt die Breite auf die Breite des Viewports */
  box-sizing: border-box; /* Berücksichtigt Padding im Box-Modell */
  -webkit-overflow-scrolling: touch; /* Ermöglicht flüssiges Scrollen auf Touch-Geräten */
  gap: 0px; /* Abstand zwischen den Elementen */
  scroll-snap-type: x mandatory;
  padding: 0 var(--edge); /* Passt den Innenabstand an */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  
}


.home-nominee-song {
  scroll-snap-align: start;
    flex: 0 0 auto; /* Verhindert, dass die Elemente gestreckt werden */
    width: 350px; /* Setzt eine feste Breite für jedes Element */
}

.home-nominee-song-4 {
  scroll-snap-align: start;
    flex: 0 0 auto; /* Verhindert, dass die Elemente gestreckt werden */
    width: 350px; 
    margin: 0 0 0 -60px;
    position: relative;
}

  
  .home-nominee-song {
    padding: 10px 0px 10px 30px;
    border-radius: 5px;
    position: relative;
    width: 350px;
    transition: 50ms ease-in-out;
  }

.home-nominee-song-4 > div {
  transition: 120ms ease-in-out;
}
.home-nominee-song-4 > div > img {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
  .home-nominee-song-4:hover > div {
    cursor: pointer;
    transform: scale(1.008);
  }
  .home-nominee-song-top > a > img {
    width: 350px;
    height: auto;
    aspect-ratio: 1 / 1; /* Setzt das Seitenverhältnis auf 1:1 */
    object-fit: cover;
    border-radius: 25px;
    border: 1px solid var(--black);
  }

  .home-nominee-song-top > a > img:hover {
    border: 1px solid white;
  }

  .home-nominee-song-top {
    position: relative;
  }



  .home-nominee-song-top > img {
    width: 100%;
    height: 340px;
    object-fit: cover;
    border-radius: 25px;
  }
  
  
  .home-nominee-song h4,
  .home-nominee-song p {
    margin: 5px 0;
  }
  

  .navigation-buttons {
      display: flex;
    position: absolute;
    width: 100%; /* Stellen Sie sicher, dass die Buttons innerhalb des Containers zentriert sind */
    top: 42%; /* Zentriert die Buttons vertikal */
    transform: translateY(-50%); /* Stellt sicher, dass die Buttons genau in der Mitte zentriert sind */
    display: flex;
    justify-content: space-between; 
    pointer-events: none;
  }

  .editor-picks-ribbon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    color: rgb(255, 255, 255);
    font-family: 'Jost';
    height:20px;
    font-weight: 600;
    border-radius: 100px;
    padding: 0px 8px 0px 28px;
    left: 50px;
    top: 30px;
    backdrop-filter: blur(3px);
  }

  .editor-picks-ribbon > img {
    width: 35px;
    margin-right: 5px;
    position: absolute;
    left: -10px;
    top: -9px;
  }
  
  
  .navigation-buttons > button {
    background-color: var(--white);
    color: #fff;
    border: none;
    border-radius: 100px;
    height: 40px;
    width: 40px;
    margin-right: 20px !important;
    margin-left: 20px !important;
    cursor: pointer;
    pointer-events: auto;
  }

  .nav-arrow {
      width: 20px;
      margin-top: 3px;
  }
  
  .navigation-buttons button:last-child {
    margin-right: 0;
  }
  
  


  @media (max-width: 800px) {
      
  .home-nominees >  h2 {
    font-size: 42px;
    margin-top: 0px !important;
    z-index: 100 !important;
  }

  .home-nominees {
    padding-top: 80px;
  }
  .home-nominees-2 {
    padding-top: 0px;
  }

  }

  @media (max-width: 800px) {
    .home-nominees-grid-3 {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap; /* Verhindert vertikales Scrollen */
      gap: 10px; /* Abstand zwischen den Elementen */
      padding: 0 0 0; /* Passt den Innenabstand an */
      margin: 0 0 0 0px !important;
    }
    .home-nominee-song-top {
      width: 100%;
      height: auto;
    }
    .home-nominee-song-top > img {
      width: 100% !important;
      height: auto;
      aspect-ratio: 1/1;
      object-fit: cover;
      border-radius: 25px;
    }
    .home-nominee-song-4 {
      width: 90%;
      margin: 0;
    }
    .home-nominees-2 > h3 {
      margin: 0 0 30px 30px;
    }
  }
  

 


  @media (max-width: 480px) {
      
    .home-nominees >  h2 {
      font-size: 28px;
      margin-top: 0px !important;
      z-index: 100 !important;
    }

    .home-nominee-song-top > a > img {
      width: 100%;
      aspect-ratio: 1/1;
      height: auto;
    }

    .home-nominee-song {
      width: 90%;
    }
    
  
    }
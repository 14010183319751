.artist-detail-page-container {
    position: relative;
    min-height: 100vh;
    overflow: hidden;
  }
  
  .artist-background-blur {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    filter: blur(50px) brightness(0.25);
    z-index: 1; /* Hintergrund bleibt hinter dem Inhalt */
  }
  
  .artist-detail-content {
    position: relative;
    z-index: 1; /* Inhalt bleibt über dem Hintergrund */
  }

  .artist-mobile-action-buttons {
      z-index: 1000;
      display: none;
      position: fixed;
      bottom: 10px;
      width: 90%;
      margin-left: 5%;
      background-color: rgba(0, 0, 0, 0.158);
      backdrop-filter: blur(40px);
      align-items: center;
      padding: 10px 0;
      justify-content: center;
      border-radius: 25px;
  }

  .artist-mobile-action-buttons > button {
    background: linear-gradient(90deg, var(--blue), rgba(0, 0, 255, 0.5), rgba(14, 14, 24, 0.5), rgba(0, 0, 255, 0.74), var(--blue));
    background-size: 200% 200%;
    animation: moveSpotlight 8s linear infinite;
      width: 90%;
      border-radius: 18px;
      border: none;
      height: 55px;
      color: white;
      font-family: 'Jost';
      display: flex;
      font-size: 16px;
      text-transform: uppercase;
      align-items: center;
      font-weight: 600;
      justify-content: center;
  }

  .artist-mobile-action-buttons > button > img {
      width: 25px;
      filter: invert(1);
      margin-left: 10px;
  }
  .artist-detail-wrapper {
      position: relative;
  }

  .artist-page-back-button {
      position: absolute;
      top: -160px;
      z-index: 1000;
      left: 60px;
      height: 45px;
      width: 45px;
      border-radius: 100px;
      border: none;
      background-color: rgba(39, 39, 39, 0.705); 
  }

  .artist-page-back-button:hover {
      cursor: pointer;
  }

  .artist-page-back-button > img {
      width: 20px;
      filter: invert(1);
      rotate: 90deg;
      height: 20px;
  }

  .mobile-header-2-wrapper {
    display: none;
}


  @media(max-width: 600px) {
    .mobile-header-2-wrapper {
        display: block;
    }
  }
  
@media (max-width: 700px) {
    .collab-songs-wrappe {
        margin-left: 2% !important;
        margin-right: 2% !important;
    }
    .artist-mobile-action-buttons {
       display: flex;
    }

    .artist-page-back-button {
        left: 30px;
    }

    .collab-songs-wrappe > h3 {
        margin-left: 30px !important;
        font-size: 14px;
    }
}
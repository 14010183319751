.page-settings-experience {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 90%;
}

@media (max-width: 800px) {
    .page-settings-experience {
        height: unset;
        min-height: 86vh;
    }
}
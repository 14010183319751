.myFavorites {
    margin-left: 220px;
    color: white;
}

.my-favorites-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr)); /* Grid-Spalten, die sich anpassen */
    gap: 20px;
    padding: 20px;
    background-color: #121212;
    color: #fff;

}

.myFavorites > h1 {
    text-align: left;
    margin-left: 25px;
    font-family: 'Wide', 'Jost';
    font-size: 26px;
    margin-top: 30px;
}

/* Styling für einzelne Favoritenelemente */
.my-favorite-item {
    border: 1px solid #333;
    border-radius: 25px;
    background-color: #1e1e1e;
    box-shadow: 0 4px 8px rgba(0,0,0,0.5);
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
    overflow: hidden; /* Verhindert Überlaufen des Inhalts */
}

.my-favorite-item:hover {
    transform: scale(1.05);
}

.my-favorite-details > img {
    width: 100%;
    height: auto; /* Automatische Höhe für Bilder */
    object-fit: cover;
    border-radius: 10px 10px 0 0;
}

.my-favorite-details p {
    margin: 5px 0;
    font-size: 12px;
    text-align: left;
}

/* Styling für den Ladeindikator */
.loading-indicator {
    text-align: center;
    font-size: 18px;
    color: #fff;
}

/* Styling für den Fall, dass keine Favoriten verfügbar sind */
.no-favorites-available {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    margin-top: -100px;
}

.my-favorite-details > div > hr {
    height: 10px;
    border: none;
    width: 1px;
    background-color: rgb(97, 97, 97);
    margin: 0px 20px;
}

.my-fav-artist-name {
    font-size: 18px;
    font-weight: 600;
}

.my-fav-artist-type {
    color: rgb(192, 192, 192);
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
}

.my-fav-artist-country {
    margin-right: 20px;
}

.fav-gig-route {
    position: absolute;
    top: 0;
    left: 0;
}

.fav-route-button {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
}

.fav-route-button:hover {
    background-color: #0056b3;
}

.fav-gig-title {
    color: rgb(175, 175, 175);
    font-size: 14px;
    margin-top: 10px;
    font-weight: 300;
    letter-spacing: 0.3px;
    height: 95px;
}

.fav-gig-title > span {
    font-weight: bold;
    color: white;
    font-size: 20px;
}

.fav-gig-details {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
}

.fav-gig-detail {
    display: flex;
    margin: 0 10px 0 0px;
    color: lightgray;
    align-items: center;
    justify-content: center;
}

.fav-gig-detail > p {
    font-size: 14px;
    margin-top: 0px;
    color: rgb(151, 151, 151);
    font-weight: 400;
}

.gig-time {
    font-size: 15px;
    margin-bottom: 18px;
}

.fav-gig-detail > img {
    width: 15px;
    margin-top: 5px;
    filter: invert(1);
    margin-right: 10px;
    margin-bottom: 22px;
}

.gig-earn-amount {
    font-size: 22px !important;
    margin: 0px 0px 20px 0px;
    font-weight: 500 !important;
    background-color: white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 600% 600%;
    animation: gradient-animation 5s ease infinite;
}

.earn-amount-free {
    font-size: 24px !important;
    margin: 0px 0px 20px 0px;
    font-weight: 500 !important;
    background: linear-gradient(270deg, #c9c9c9, #b1b1b1, #c5c5c5, #aaaaaa, #f5f5f5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 600% 600%;
    animation: gradient-animation 5s ease infinite;
}



@keyframes gradient-animation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}


.gig-info-details {
    text-align: left;
    padding: 0 10px;
}

.my-fav-artist-img-small {
    width: 30px;
    height: 30px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 50%;
}

.favorites-switch {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-left: 25px;
}

.favorites-switch > button {
    border: 1px solid rgba(255, 255, 255, 0.301);
    background-color: black;
    color: white;
    height: 40px;
    padding: 0 20px;
    font-family: 'Jost';
    font-size: 16px;
    margin-right: 10px;
    border-radius: 100px;
}

.favorites-switch > button:hover {
    cursor: pointer;
}

.favorites-switch > button.active {
    background-color: var(--blue);
    border: 1px solid var(--blue);
}

@media (max-width: 800px) {
    .fav-gig-title {
        height: 90px;
    }
    .gig-time {
        font-size: 14px !important; 
        margin-bottom: 18px;
    }

.gig-earn-amount {
    font-size: 20px !important;
}
}

/* Media Queries für spezifische Spaltenanpassungen */
@media (min-width: 1300px) {
    .my-favorites-grid {
        grid-template-columns: repeat(3, 1fr); /* 3 Spalten auf großen Bildschirmen */
    }
}
/* Media Queries für spezifische Spaltenanpassungen */
@media (min-width: 1200px) and (max-width: 1599px) {
    .my-favorites-grid {
        grid-template-columns: repeat(2, 1fr); /* 3 Spalten auf großen Bildschirmen */
    }
}

@media (min-width: 900px) and (max-width: 1199px) {
    .my-favorites-grid {
        grid-template-columns: repeat(1, 1fr); /* 2 Spalten auf mittleren Bildschirmen */
    }
}

@media (min-width: 600px) and (max-width: 899px) {
    .my-favorites-grid {
        grid-template-columns: repeat(1, 1fr); /* 1 Spalte auf kleinen Bildschirmen */
    }
}

@media (max-width: 750px) {
    .myFavorites {
        margin-left: 0px;
    }
}

@media (max-width: 599px) {
    .my-favorites-grid {
        grid-template-columns: 1fr; /* 1 Spalte auf sehr kleinen Bildschirmen */
    }

}

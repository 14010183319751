/* Container für den Ladebalken innerhalb des confirmation-step */
.completed-step5-progress-container {
    width: 100%;
    position: absolute;
    top: 1px;
    height: 60px;
    z-index: 1;
    background-color: #4e4e4e; /* Hintergrundfarbe für den leeren Ladebalken */
    border-radius: 100px;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.2); /* Innerer Schatten für 3D-Effekt */
    overflow: hidden;
    margin-bottom: 0px; /* Abstände zum Inhalt darunter */
  }
  
  /* Ladebalken selbst mit Wellenmuster */
  .completed-step5-progress-bar {
      width: 0;
      height: 100%;
      background: linear-gradient(135deg, var(--blue) 25%, rgb(37, 116, 207) 75%, var(--blue) 50%, var(--blue) 50%, var(--blue) 75%, rgb(57, 73, 221) 75%, var(--blue));
      background-size: 20px 2px;
      border-radius: 100px;
      animation: loadCompletedStep5 2s forwards, wave 1s linear infinite;
    }
    
  .start-over-button {
      position: relative;
  }
  
  /* Animation, die den Ladebalken von links nach rechts füllt */
  @keyframes loadCompletedStep5 {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  
  /* Animation für das bewegende Wellenmuster */
  @keyframes wave {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 40px 40px;
    }
  }
  
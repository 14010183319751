.viral-buy-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0);
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.2s ease-in; /* Background opacity animation */
}

.viral-buy-modal-background {
height: 100vh;
width: 100%;
z-index: 2000 !important;
display: flex;
justify-content: center;
align-items: center;
opacity: 1;
}

.viral-buy-back-button > img {
  width: 22px;
  filter: invert(1);
  rotate: 90deg;
}

.viral-buy-back-button {
  position: absolute;
  left: 30px;
  top: 30px;
  background-color: rgb(34, 34, 34);
  width: 45px;
  border: 1px solid white;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
}

.viral-buy-back-button:hover {
  cursor: pointer;
}

.viral-buy-modal-overlay.show {
    background-color: rgba(0, 0, 0, 0.822);
    opacity: 1; /* Apply when the modal is shown */
    backdrop-filter: blur(5px);
    z-index: 100000;
}

/* Main container for the modal */
.viral-buy-modal-container {
    background-color: #141414b6;
    backdrop-filter: blur(40px);
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
    padding: 40px 0px;
    border-radius: 60px;
    text-align: center;
    width: 680px;
    flex-direction: column;
    border: 1px solid rgb(59, 59, 59);
    position: relative;
    transform: translateY(-50px); /* Start off the screen */
    opacity: 0;
    transition: transform 0.4s ease-out, opacity 0.4s ease-out; /* Smooth transition */
}

.viral-buy-modal-container-2 {
  backdrop-filter: blur(40px);
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  padding: 40px 0px;
  border-radius: 60px;
  text-align: center;
  width: 680px;
  flex-direction: column;
  border: 1px solid rgb(59, 59, 59);
  position: relative;
  transform: translateY(-50px); /* Start off the screen */
  opacity: 1;
  transition: transform 0.4s ease-out, opacity 0.4s ease-out; /* Smooth transition */
}

.viral-buy-modal-container.show {
    transform: translateY(0); /* Bring the modal into view */
    opacity: 1; /* Fade in the modal */
}
  
.viral-buy-modal-close-button {
    position: absolute;
    top: 30px;
    right: 30px;
    background: none;
    border: none;
    color: #ffffff;
    font-size: 24px;
    cursor: pointer;
    transition: opacity 0.3s ease;
}
  .viral-buy-modal-vip-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }


  .viral-buy-modal-vip-header > p {
      margin: 0 30px;
  }
  
  
  .viral-buy-modal-vip-icon {
    height: 70px;
    object-fit: contain;
    margin-top: -70px;
    margin-bottom: 20px;
  }
  
  .viral-buy-modal-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: white;
    margin-top: 10px;
    margin-bottom: 0px;
    font-family: 'Wide';
  }
  
  .viral-buy-modal-features-carousel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .carousel-button {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    z-index: 100;
    padding: 10px;
    background-color: rgb(31, 31, 31);
    border-radius: 100px;
    width: 45px;
    height: 45px;
    display: flex;
    border: 1px solid rgb(131, 131, 131);
    align-items: center;
    justify-content: center;
  }
  
  .prev-button img,
  .nextolo-button img {
    width: 24px;
    transform: rotate(90deg);
    filter: invert(1);
  }

  .nextolo-button img {
      rotate: 180deg;
  }

  .nextolo-button {
      right: 10px;

  }

  .prev-button {
      left: 10px;
  }
  
  .carousel-feature-container {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .grid-feature-wrapper {
    display: flex;
    margin-left: 20px;
    transition: transform 0.5s ease-in-out;
    width: max-content; /* Ensures the width is based on the content */
  }
  
  .viral-buy-modal-feature {
    width: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: top;
    margin: 10px;
    background: linear-gradient(to bottom, rgb(19, 19, 19), rgb(0, 0, 0));
    border-radius: 35px;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;     border: 1px solid rgb(41, 41, 41);
  }

  .stripe-secure-checkout {
      width: 150px;
      margin-left: 30px;

  }


  .stripe-secure-checkout-2 {
    width: 120px;

}

  .viral-botttom-ckeckout-container {
      display: flex;
    bottom: -60px; 
    width: 100%;
    align-items: center;
    justify-content: center;
    position: absolute;
    flex-direction: row-reverse;

}

.loadinge {
    border: 4px solid rgba(255, 255, 255, 0.3); /* Light border */
    border-left-color: #ffffff; /* White color for the spinner */
    border-radius: 50%;
    width: 16px !important;
    height: 16px !important;
    animation: spin 0.5s linear infinite;
}

  .viral-buy-modal-feature:hover {
      cursor: pointer;
  }
  
  .viral-buy-modal-feature > img {
    width: 60px;
    object-fit: contain;
    height: 60px;
    margin-bottom: 10px;
    margin-top: 30px;
  }
  
  .viral-buy-modal-feature p {
    font-size: 0.9rem;
    margin-bottom: 5px;
    color: white;
    font-weight: 600;
  }

  .viral-boy-modal-text {
      color: rgb(105, 105, 105) !important;
      font-size: 12px !important;
      font-weight: 400 !important;
      margin: 0px 10px 20px 10px !important;
  }
  
  .viral-buy-circle-check {
    width: 25px !important;
    height: 25px !important;
    margin-top: 0px !important;
    position: absolute;
    top: 15px;
    right: 15px;
    opacity: 0.7;
  }

  .viral-buy-circle-check-2 {
    margin-top: 0px !important;
    position: absolute;
    top: 10px;
    left: 10px;
    opacity: 0.7;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 8px 10px;
    justify-content: center;
    border: 1px solid rgb(44, 44, 44);
    border-radius: 15px;
    background-color: black;
    
  }

  
.viral-buy-modal-button-texts {
    margin: 0 0 0 30px;
}


.viral-buy-modal-button-texts > h3 {
    margin: 0px 0 3px 0;
    font-size: 14px;
    color: rgb(172, 172, 172);
    width: 100%;
    text-align: left;
    font-weight: 400;
}

.viral-buy-modal-button-texts > p {
    margin: 0;
    color: white;
}





  .viral-buy-circle-check-2 > p {
      font-size: 9px;
      font-weight: 400;
      text-align: center;
      margin: 0;
      line-height: 110%;
      color: rgb(114, 114, 114);
  }

  .viral-buy-circle-check-2 > p > span {
    font-weight: 700;
    font-size: 11px;
    color: rgb(175, 175, 175);

  }

  .viral-buy-circle-check-2 > h5 {
      margin: 0;
      font-size: 10px;
      font-weight: 500;
  }
  
  
  /* Price Section */
  .viral-buy-modal-price-section {
    margin: 20px 20px;
  
  }
  
  .viral-buy-modal-start-text {
    font-size: 1rem;
    font-family: 'Wide', sans-serif;
    margin-left: 30px;
  }
  
  .viral-buy-modal-price-button {
    display: flex;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    align-items: center;
    display: flex;
    height: 85px;
    border: 1px solid rgba(31, 31, 31, 0);
    margin-bottom: -15px !important;
    margin-right: 30px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    margin-left: 30px;
    border-radius: 100px;
    background: linear-gradient(270deg, #000000, var(--blue)); /* Black to Dark Grey gradient */
    background-size: 400% 400%; /* Allows for smooth animation */
    animation: backgroundGradient 8s ease infinite;
  }

  .viral-buy-modal-price-button:hover {
    background: linear-gradient(270deg, #000000, var(--blue)); /* Black to Dark Grey gradient */
cursor: pointer;
transform: scale(1.01);
border: 1px solid rgb(165, 165, 165);

  }

  @keyframes backgroundGradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .viral-buy-modal-price {
    font-size: 16px;
    font-weight: 600;
    margin-top: 3px;
    color: white;
    margin-right: 10px;
    text-align: right;
    font-family: 'Jost', sans-serif;
  }

  .viral-buy-modal-price-2 {
    font-size: 13px;
    font-weight: 400;
    color: rgb(168, 168, 168);
    margin-bottom: 1px;
    display: flex;
    font-family: 'Jost', sans-serif;
    text-align: right;
  }

  .viral-buy-modal-price-2 > p {
      margin: 0;
      text-decoration: line-through;

  }

  .viral-buy-modal-price-2 > h6 {
    margin: 0 0 0 0;
    padding: 3px 7px;
    margin: 0 0 0px 10px;
    color: rgb(214, 214, 214);
    border-radius: 100px;
    font-size: 12px;
    font-weight: 600;
    background-color: var(--blue);
}


  .viral-buy-modal-price-button > div {
      display: flex;
      align-items: center;
      justify-content: center;
  }
  
  .viral-buy-modal-arrow-button {
    background: none;
    border: none;
    cursor: pointer;
    background-color: rgba(77, 77, 77, 0.103);
    width: 40px;
    margin-right: 20px;
    margin-left: 10px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 200ms ease-in-out;
    border: 1px solid rgb(59, 59, 59);
    border-radius: 100px;
  }

  .viral-buy-modal-arrow-button-2 {
    background: none;
    border: none;
    cursor: pointer;
    background-color: rgb(0, 0, 0);
    width: 35px;
    margin-right: 10px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(102, 102, 102);
    border-radius: 100px;
  }
  
  
  .viral-buy-modal-arrow-button img {
    width: 24px;
    transform: rotate(270deg);
    filter: invert();
  }

  .viral-buy-modal-arrow-button-2 img {
    width: 20px;
    transform: rotate(270deg);
    filter: invert();
  }
  
  /* Cheat Code Section */
  .viral-buy-modal-cheat-code-section {
    margin-top: 20px;
    background-color: rgba(48, 48, 48, 0.24);
    position: absolute;
    bottom: -130px;
    backdrop-filter: blur(10px);
    width: 100%;
    z-index: -1;
    height: 150px;
    padding-top: 40px;
    display: flex;
    border-bottom-right-radius: 60px;
    border-bottom-left-radius: 60px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .viral-buy-modal-cheat-code-text {
    font-size: 1rem;
    margin-bottom: 20px;
    color: grey;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .viral-buy-modal-cheat-code-input {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .viral-buy-modal-cheat-input-field {
    width: 100px;
    padding: 5px 10px;
    border-radius: 15px;
    border: 1px solid #444;
    background-color: #000000;
    color: white;
    padding: 0 0 0 20px;
    height: 40px;
    outline: none;
    font-size: 15px !important;
    font-family: 'Wide';
    width: 150px;
    font-size: 0.9rem;
  }

  .viral-buy-modal-cheat-input-field::placeholder {
      color: rgb(80, 80, 80) !important;
  }
  
  .viral-buy-modal-cheat-input-field::placeholder {
    color: #aaa;
  }
  
  .viral-buy-mobile-price {
        display: none;
  }



  @media (max-width: 800px) {

.viral-buy-modal-overlay {
    justify-content: flex-start;
    align-items: flex-start;
}

.viral-buy-mobile-price {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.viral-buy-modal-button-texts {
    margin: 0;
}

.viral-buy-modal-price-button > div {
    width: 100%;
    display: none;
}

.viral-buy-modal-button-texts > h3 {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 5px;
}

.viral-buy-modal-button-texts > p {
    margin-bottom: 20px;
}

.viral-buy-modal-price-button {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.viral-buy-modal-container {
    background-color: transparent;
    backdrop-filter: blur(40px);
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
    padding: 120px 0px 40px 0;
    border-radius: 0px;
    text-align: center;
    border: none;
    width: 100%;
    overflow: auto;
    flex-direction: column;
    height: 100%;
}

.viral-buy-modal-price-button {
    height: 80px;
}



  }
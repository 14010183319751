
.gig-save-button:hover {
    transform: scale(1.05);
}

.gig-save-button.active {
    background-color: var(--blue); /* Pink Farbe für aktive Favoriten */
    color: white;
    
}

.gig-save-button.active img {
    filter: invert(0) !important;
}

.gig-save-button img {
    margin-left: 10px;
    width: 24px;
    filter: var(--color-invert);
    height: 24px;
}

.gig-save-button.active > img {
    filter: invert(1)
}

/* Grundlegendes Overlay für das Modal */
.edit-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Schwarzer Hintergrund mit leichter Transparenz */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Stellt sicher, dass das Overlay über anderen Inhalten liegt */
  }
  
  /* Stil des Modal-Inhalts */
  .edit-modal-content {
    position: relative;
    width: 50%; /* Oder eine spezifische Breite nach Bedarf */
    padding: 20px;
    background-color: #000; /* Schwarzer Hintergrund */
    color: #fff; /* Weiße Schrift */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: column; /* Elemente werden vertikal untereinander angeordnet */
  }
  
  /* Stil für Inputs und Textarea */
  .edit-modal-content input,
  .edit-modal-content textarea {
    margin-bottom: 20px; /* Abstand zwischen den Feldern */
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #555; /* Dunkle Grenze für bessere Sichtbarkeit */
    background-color: #222; /* Dunkler Hintergrund für die Eingabefelder */
    color: #fff; /* Weiße Schrift */
  }
  
  /* Stil für Buttons */
  .edit-modal-content button {
    padding: 10px 20px;
    margin-right: 10px; /* Abstand zwischen den Buttons */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #333; /* Dunkler Hintergrund für Buttons */
    color: #fff; /* Weiße Schrift */
  }
  
  /* Stil für den Schließen-Button */
  .loco-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #f00; /* Roter Hintergrund für den Schließen-Button */
  }
  
  /* Optional: Hover-Effekt für Buttons */
  .edit-modal-content button:hover {
    background-color: #444;
  }
  
  .editor-img-preview {
      width:100px;
      height: 100px;
      border-radius: 100px;
  }
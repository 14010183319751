/* Modal Background */
.delete-reviews-modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.7); /* Darken background */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Modal Content */
.delete-reviews-modal-content {
    background-color: #2c2c2c; /* Dark background for the modal */
    color: #fff; /* Light text for contrast */
    padding: 20px;
    border-radius: 10px;
    width: 600px;
    max-width: 90%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    position: relative;
}

/* Close Button */
.delete-reviews-close {
    position: absolute;
    top: 15px;
    right: 20px;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
}

/* Heading */
.delete-reviews-modal-content h2 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 24px;
    text-align: center;
}

/* Reviews List */
.delete-reviews-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 300px; /* Set a maximum height for the list */
    overflow-y: auto; /* Enable vertical scrolling */
}

/* Individual Review Item */
.delete-review-item {
    background-color: #383838; /* Slightly lighter background for each review */
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

/* Review Details */
.delete-review-details {
    max-width: 80%;
}

/* Review Writer */
.delete-review-writer {
    font-weight: bold;
    margin-bottom: 5px;
}

/* Delete Review Button */
.delete-review-button {
    background-color: transparent;
    border: none;
    color: #ff4d4d;
    font-size: 18px;
    cursor: pointer;
    padding: 5px;
    transition: color 0.3s ease;
}

.delete-review-button:hover {
    color: #ff1a1a;
}

/* No Reviews Message */
.delete-reviews-list p {
    text-align: center;
    margin: 20px 0;
    font-size: 18px;
}

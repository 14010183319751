.artist-connects-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px; /* Abstand zwischen Text und Bildern */
    position: absolute;
    top: -5px;
    width: 230px;
    left: -255px;
  }
  
  .artist-connects-text {
    font-size: 18px;
    font-weight: 400;
    color: #cfcfcf; /* Ähnliche Textfarbe wie im Beispiel */
  }

  .artist-connects-container > p {
      color: white;
      font-size: 12px;
      font-family: 'Wide', 'Jost';
  }
  
  .connects-profiles {
    display: flex;
    align-items: center;
    gap: -10px; /* Die Bilder überlappen leicht */
  }
  
  .connect-profile-img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: -10px;
    opacity: 0.8;
    object-fit: cover;
    border: 1px solid #7c7c7c; /* Dünner Rahmen um die Bilder */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Leichter Schatten für 3D-Effekt */
  }
  

  @media(max-width: 700px) {
    .artist-connects-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 5px; /* Abstand zwischen Text und Bildern */
        position: absolute;
        flex-direction: column;
        top: 10px;
        width: 100px;
        left: -100px;
      }
      .artist-connects-container > p {
          font-size: 10px;
          margin: 0;
      }
  }
.gig-review-item-single {
    display: flex;
    width: 100%;
    
}

.gig-review-item-header {
    display: flex;
    align-items: top;
    justify-content: left;
    padding: 25px 15px 15px 15px;
    margin-bottom: 10px;
    background-color: rgb(24, 24, 24);
    width: 100%;
    border-bottom: 1px solid #2b2b2b;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    margin-top: -30px;
    border: 1px solid rgb(49, 49, 49);
    box-shadow: rgba(0, 0, 0, 0.3) 10px -19px 38px, rgba(0, 0, 0, 0.12) 0px -15px 12px;
}

.gig-review-item-wraps:first-child .gig-review-item-header {
    box-shadow: rgba(0, 0, 0, 0.1) 10px -19px 38px, rgba(0, 0, 0, 0.02) 0px -15px 12px;
    margin-top: 0px;
}   


.gig-review-item-wraps {
    width: 100%;
    transition: padding-bottom 0.3s ease-in-out; /* Transition für das Padding */

}

.gig-review-item-wraps:hover {
    padding-bottom: 15px; /* Padding-Bottom beim Hover erhöhen */
}


.review-writer-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 15px;
    margin-top: 15px;
}


.review-rating-mini {
    display: flex;
}

.review-stars-mini > img {
 width: 12px !important;
}

.review-score-text-mini {
    margin: 0;
    font-size: 12px;
    margin-top: 6px;
    margin-left: 5px;
}


.review-writer-info {
    display: flex;
    flex-direction: column;
    align-items: top;
    text-align: left;
    width: 130px;
    overflow: hidden;
    margin-right: 10px;
}

.review-writer-name {
    font-size: 12px;
    font-weight: bold;
    color: rgb(211, 211, 211);
    margin: 0 !important;
}

.review-writer-type {
    font-size: 14px;
    color: rgb(155, 155, 155);
    margin: 5px 0 !important;
}

.review-timestamp {
    font-size: 12px;
    color: #999;
    margin: 0px;
}

.review-feedback {
    font-size: 16px;
    max-width: 50%;
    text-align: left;
    color: rgb(224, 224, 224);
    margin: 0px 0px 15px 0px;
   
}
.country-counter-text {
    background-color: rgb(49, 49, 49);
    padding: 7px 15px 7px 5px;
    color: white !important;
    border-radius: 100px;
    
}

.country-counter {
    margin-top: 10px;
    margin-left: 2%;
}

.country-counter::-webkit-scrollbar {
    display: none;
}

.country-counter-text > p {
    margin: 0;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 500;
    color: rgb(206, 206, 206);
    letter-spacing: 1px;
}

.country-counter-text:hover p {
    color: white;
}


@media (max-width: 800px) {
    .country-counter-text {
        background-color: rgb(49, 49, 49);
        padding: 7px 15px 7px 5px;
        color: white !important;
        border-radius: 100px;
        margin-top: 10px;
        margin-left: 10px;
        
    }

    .country-counter-text > p {
        margin: 0;
        margin-left: 10px;
        font-size: 18px;
        font-weight: 500;
        color: rgb(206, 206, 206);
        letter-spacing: 1px;
    }
}
.page-equipment {
    margin-top: 0px;
    margin-bottom: 20px;
    border-radius: 15px;
    border: 1px solid rgba(44, 44, 44, 0);
    transition: 140ms ease-in-out;
    padding: 10px;
}

.equipment-grid-2 > h2 {
    font-family: 'Wide', 'Jost', 'Helvetica', 'Josefin Sans';
    margin-bottom: 20px;
    color: var(--color-oposite-1);
}


.page-equipment.is-active {
    padding: 10px;
    border: 1px solid rgb(133, 133, 133);
    border-color: rgb(196, 196, 196); /* Blue border when active */
    color: white; /* Text color changes when active */
  }
  
  .page-equipment.settings-route:hover {
    cursor: pointer;
    transform: scale(1.03);
    box-shadow: var(--color-shadow-2);
  }
  
  .page-equipment.settings-route:hover .edit-overlay {
    display: flex;
  }



.page-equipment:hover .gig-upload-song-button > h5 {
    color: var(--color-oposite-1);
}

.page-equipment:hover .gig-upload-song-button > img {
    opacity: 1;
}

.equipment-type-2 {
    font-weight: 600;
    margin: 5px;
}

.equipment-name-2 {
    font-weight: 300;
    margin: 5px;
}

.equipment-grid-2 {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.equipment-item-2 {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid var(--color-3);
    border-radius: 20px;
    color: var(--color-oposite-1);
}

.equipment-image-2 {
    width: 40px;  
    height: 40px; 
    filter: var(--color-invert);
    opacity: 0.5;
}

.equipment-image-container {
    width: 100px;  
    height: 100px; 
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    border: 1px solid var(--color-3);
    border-radius: 100px;
}


@media (max-width: 950px) {
    .equipment-item-2 {
        width: 150px;
    }
}


@media (max-width: 350px) {
    .equipment-item-2 {
        width: 100%;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 1px solid var(--color-3);
        border-radius: 20px;
        color: var(--color-oposite-1);
    }
}

.create-route {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    height: 90%;
}


.create-route-input {
    background-color: rgba(0, 0, 0, 0.479);
    width: 390px;
    display: flex;
    align-items: center;
    height: 52px;
    margin-top: 10px;
    border: 1px solid rgb(61, 61, 61); 
    padding: 0px 0px 0px 15px;
    border-radius: 8px;
    font-size: 18px;
    color: white;
    font-family: 'Jost';
    outline: none; /* Removes the default outline */
}

.create-route-input > input {
    height: 84%;
    width: 66%;
    background: none;
    font-size: 18px;
    font-weight: 500;
    border: none;
    outline: none;
    color: white;
    font-family: 'Jost', 'Josefin Sans';
}

.create-route-input > label {
    width: 32%;
    color: rgb(161, 161, 161);
}

.create-route-input > input::placeholder {
    color: rgba(255, 255, 255, 0.822);
}

.create-route-error-page {
    color: red;
    position: absolute;
    bottom: 20px;
    right: 120px;
}


@media (max-width: 800px) {
    .create-route {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        height: 90%;
    }

    .create-route-input > input {
            width: 60%;
            margin-left: 20px;
    }

    .create-route-input {
        width: 320px;
    }
    
}




@media (max-width: 500px) {
    .create-route-input {
        width: 320px;
    }

   
}


@media (max-width: 380px) {
    .create-route-input {
        width: 280px;
    }

    .create-route-input > input {
        width: 60%;
        margin-left: 35px;
}

   
}

@media (max-width: 300px) {
    .create-route-input {
        width: 240px;
    }

    .create-route-input > input {
        width: 60%;
        margin-left: 45px;
}

   
}
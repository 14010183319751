.artist-more-wrapper {
    padding: 0px 70px 40px 70px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #fff;
    flex-wrap: wrap;
    display: flex;
    background-color: rgba(39, 39, 39, 0.322);
  }
  
  /* Location Styling */
  .artist-more-location {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 40px;
    margin-top: 30px;

  }
  
  .artist-more-location-title {
    font-size: 0.8rem;
    font-weight: 600;
    color: #999;
    margin-bottom: 5px;
    text-align: left;
    font-family: 'Wide';
  }

  .artist-more-location-title > p {
      margin: 0px;
  }
  
  .artist-location-content {
    display: flex;
    align-items: center;
    gap: 10px;
    text-align: left;
  }
  
  .artist-country-name {
    font-size: 1.1rem;
    color: rgb(212, 212, 212);
    margin: 0px;
  }
  

  
  .artist-genres-horizontal {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 0px;
    margin-top: 30px;
    margin-right: 40px;
  }
  
  .artist-genres-title {
    font-size: 0.8rem;
    font-weight: bold;
    color: #999;
    margin: 0px;
    font-family: 'Wide';
  }
  
  .artist-genres-item {
    color: #fff;
    text-align: right;
    margin-top: 0px;
    font-size: 1.1rem;
    color: rgb(212, 212, 212);
    margin-bottom: 0px;
    margin-left: 0px; /* Abstand zwischen Titel und Genres */
  }
  
  .artist-genres-item > p {
    margin: 0px;
  }


  @media (max-width: 700px) {
    .artist-more-wrapper {
        padding: 0px 15px 40px 30px;
        margin-top: 0px;
        margin-bottom: 0px;
        color: #fff;
        flex-wrap: wrap;
        display: flex;
        background-color: rgba(39, 39, 39, 0.322);
      }
  }
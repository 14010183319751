.song-upload-alerts {
    position: fixed;
    bottom: -30px;
    left: 20px;
    z-index: 1000;
  }
  
  .song-upload-alert {
    width: 220px;
    background-color: #141414c9;
    border-radius: 20px;
    backdrop-filter: blur(30px);
    border: 1px solid rgba(255, 255, 255, 0.37);
    padding: 15px 15px 30px 15px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: left;
    flex-direction: column;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  }
  
  .song-upload-alert-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .song-upload-alert-text {
    color: white;
    text-align: left;
  }
  
  .song-upload-alert-text h4 {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    font-family: 'Wide';
  }
  
  .song-upload-alert-text p {
    margin: 5px 0;
    font-size: 14px;
  }
  
  .song-upload-alert-image > img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;

  }

  .song-upload-alert-image {
      position: relative;
  }

  .song-upload-alert-plus {
 width: 25px !important;
 height: 25px !important; 
 position: absolute;
 right: -5px;
 top: -13px;
  }
  
  .progressorio-bar {
    width: 100%;
    height: 4px;
    background-color: #333;
    margin-top: 10px;
    border-radius: 2px;
    overflow: hidden;
    position: relative;
  }
  
  .progressorio {
    height: 100%;
    background-color: var(--red);
    transition: width 0.1s linear;
  }
  

  @media (max-width: 800px) {
    .song-upload-alerts {
      display: none; 
    }
    .song-upload-alert {
      display: none; 
    }
  }
.gig-settings-save-button {
    width: 320px;
    left: 0px;
    bottom: 0px;
    height: 60px;
    background: linear-gradient(270deg, #10367e, var(--blue));
    background-size: 400% 400%;
    border: none;
    color: white;
    font-family: 'Jost';
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 500;
    border-top: 1px solid rgb(141, 141, 141);
    position: relative;
    animation: gradientAnimation 10s ease infinite;
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}



.gig-settings-save-button:hover {
    cursor: pointer;

}


@media (max-width: 1300px) {
    .gig-settings-save-button {
        width: 270px;

    }
}


@media (max-width: 900px) {
    .gig-settings-save-button {
        width: 100%;

    }
}
.service-based-content-selector > h3 {
color: white;
font-family: 'Jost';
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
font-size: 24px;
color: white;
font-family: 'Wide', 'Jost';
margin-bottom: 0px;
}

.service-based-content-selector > h3 > span {
    margin-bottom: 40px;
    margin-top: 20px;
    color: lightgrey;
    font-family: 'Jost';
    font-size: 16px;
    font-family: 'Jost';
    font-weight: 300;
    }

.service-based-content-selector {
    margin-top: 40px;

}
/* Container für das Song-Cover */
.song-cover-teamdashboard {
    width: 200px !important;
    height: 200px;
    border-radius: 10px;
    object-fit: cover;
    z-index: 0;
    opacity: 0;
    transform: translateX(100px);
    animation: slide-in-cover 1s ease forwards;  /* Die Animation startet beim Laden */
  }
  
  /* Animation zum Einfaden und Verschieben */
  @keyframes slide-in-cover {
    from {
      opacity: 0;
      transform: translateX(50px);  /* Startet 100px rechts */
    }
    to {
      opacity: 1;
      transform: translateX(0);  /* Bewegt sich nach links auf die Originalposition */
    }
  }
  

.review-generator-song {
    display: flex;
    flex-direction: column;
    margin: 220px -10px 0 20px;
    background: none;
}

/* Container für den Audio-Player */
.generator-audio-player-container {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: -72px;
    margin-left: 5px;

  }
  
  /* Runder Play/Pause-Button */
  .generator-play-pause-button {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: rgb(32, 32, 32) !important;
    color: white;
    font-size: 24px;
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    z-index: 100;
    outline: none;
    transition: background-color 0.3s ease;
  }
  
  .generator-play-pause-button.play:hover,
  .generator-play-pause-button.pause:hover {
    background-color: #666;
  }
  
  .generator-play-pause-button.play {
    content: '►';
  }
  
  .generator-play-pause-button.pause {
    content: '❚❚';
  }
  
  /* Optionale Styles für das Song-Cover */
  .song-cover-teamdashboard {
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  
  /* General container styling for better appearance */
  .audio-container {
    padding: 10px;
    border: none;
    background-color: #111; /* Dark background for the container */
    border-radius: 10px;
    display: inline-block;
  }
  

  .spotify-embeddo-container {
    margin-top: 0px;
    width: 00px !important;
    height: 80px;
    margin-left: 60px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    position: unset !important;
    animation: slide-in-cover 1s ease forwards;  /* Die Animation startet beim Laden */
  }
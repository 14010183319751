/* Dunkelmodus-Stile für React Quill Toolbar */
.text-editor-container {
    color: white;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.react-quill-container {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: rgb(32, 32, 32); /* Dunkler Hintergrund für die Toolbar */
}

.ql-toolbar .ql-picker-label,
.ql-toolbar .ql-picker-item {
    color: white; /* Weiße Textfarbe für Dropdown-Labels */
}

.ql-toolbar button {
    color: white; /* Weiße Icon-Farbe */
}

.ql-toolbar button:hover {
    background-color: #555; /* Dunklere Hintergrundfarbe beim Hover */
}

.ql-snow .ql-stroke {
    stroke: white !important; /* Weiße Farbe für SVG-Icons */
}

.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
    fill: white !important; /* Weiße Füllung für Teile der SVG-Icons */
}

.ql-toolbar {
    border-top-left-radius: 10px;  /* Rundet die obere linke Ecke */
    border-top-right-radius: 10px; /* Rundet die obere rechte Ecke */
    border-color: grey !important;

}


/* Dunkler Stil für Dropdown-Optionen */
.ql-toolbar .ql-picker .ql-picker-label,
.ql-toolbar .ql-picker .ql-picker-options {
    color: white; /* Weiße Schriftfarbe */
    background-color: rgb(26, 26, 26); /* Dunkler Hintergrund */
    border-radius: 10px;
}

.text-editor-container .ql-toolbar .ql-picker .ql-picker-options {
    border-top: 1px solid rgb(32, 32, 32); /* Dunklere obere Grenze für Kontrast */
}

.ql-container {
    border-bottom-left-radius: 10px; /* Rundung des Editors */
    border-bottom-right-radius: 10px; /* Rundung des Editors */
    border-color: grey !important;
}

.ql-container:hover {
    cursor: pointer;
}

.ql-toolbar .ql-formats {
    margin-right: 2px; /* Verringere den rechten Margin zwischen den Gruppen */
    padding: 0; /* Entferne das Padding innerhalb jeder Gruppe */
}

.ql-toolbar .ql-picker-label,
.ql-toolbar .ql-picker-item {
    padding: 4px 8px; /* Angepasstes Padding für Dropdown-Labels */
    margin: 0; /* Entferne Margin */
}
.ql-toolbar.ql-snow .ql-formats {
    margin-right: 0px;
}

.ql-picker-label {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ql-snow .ql-picker {
    height: unset;
}

.ql-color-picker {
    margin-top: 5px;
}

.span.ql-picker-label {
    padding: 3px !important;
}


.text-editor-container-save {
    width: 90%;
    height: 50px;
    background-color: var(--blue);
    border: none;
    border-radius: 10px;
    color: white;
    margin-top: 15px;
}
.text-editor-container-save:hover {
    background-color: var(--blue);
    cursor: pointer;

}

.ql-bubble {
    pointer-events: none;
    user-select: none;
}
.tier-system {
  color: white;
  padding: 20px;
  font-family: Arial, sans-serif;
  width: 90%;
  max-width: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.skeleton-loader-tiers {
  width: 100%;
  height: 230px;
border-radius: 25px;
  background-color: #222;
  color: #999;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-repeat: no-repeat;
  animation: shimmer2 1s infinite;
  background-size: 200% 100%;
}

  
  .tier-system > div > h2 {
      font-family: 'Wide';
      width: 50px;
  }

    
  .tier-system > div > p {
    font-family: 'Jost';
    margin-bottom: 60px;
}


  .tiers {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--black);
    border-radius: 25px;
    padding: 40px;
    border: 1px solid rgb(53, 53, 53);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;  
}
  
  .tier {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .tier img {
    width: 100px;
    margin-right: 30px;
    margin-left: 10px;
  }
  
  .tier-info {
    border-left: 1px solid #525252;
    padding-left: 20px;
    text-align: left;
    font-family: 'Jost';
  }

  .tier-info > h2 {
      font-size: 18px;
      margin: 0;
  }

  .tier-info > h5 {
      margin: 0;
      font-weight: 500;
      font-size: 14px;
      color: var(--red);
  }

 

  .tier-header {
      margin-top: 30px;
      letter-spacing: 0.3px;
      line-height: 160%;
      color: rgb(179, 179, 179);
  }


  @media (max-width: 500px) {
    .tiers {
      padding: 40px 20px;
      width: 68%;
      flex-direction: column;
  }
  .tier {
    flex-direction: column;
  }

  .tier > img {
    margin-bottom: 40px;
    width: 60%;
    margin-right: 0px;
    margin-left: 0px;
  }

  .tier-system > div {
    width: unset;
  }

  .tier-system {
    color: white;
    padding: 0px;
    font-family: Arial, sans-serif;
    width: 80%;
    max-width: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  

  }

  @media (max-width: 400px) {
    .tier-system > div {
      width:70% !important;
    }
  
  }


  @media (max-width: 340px) {
    .tier-system > div {
      width:70% !important;
    }
  
  }
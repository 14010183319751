.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Abdunkelung des Hintergrunds */
  z-index: 999; /* Stellt sicher, dass die Überlagerung unter dem Modal, aber über anderen Inhalten liegt */
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-publication-container {
  position: relative;
  padding: 20px;
  background-color: #000; /* Schwarzer Hintergrund für Dark Mode */
  color: #fff; /* Weiße Schrift */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  width: 90%; /* Breite des Modals */
  max-width: 500px; /* Maximale Breite */
}

.publications-display {
  border-top: 1px solid rgb(97, 97, 97);
  margin-top: 10px;

}

.publications-display > div > img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 5px;
}


.publications-display > div {
  display: flex;
  background-color: rgb(44, 44, 44);
  margin: 10px;
  border: 1px solid grey;
  height: 100px;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px;
  padding: 10px;

}



.input-group-2 {
  margin-bottom: 20px; /* Abstand zwischen den Input-Gruppen */
}

.input-group-2 label {
  display: block;
  margin-bottom: 8px;
}

.input-group-2 input,
.input-group-2 textarea {
  width: calc(100% - 20px); /* Korrektur für Padding */
  padding: 10px;
  margin-bottom: 10px; /* Abstand zwischen den Inputs */
  border: 1px solid #333;
  border-radius: 4px;
  background-color: #222;
  color: #fff;
}

.add-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #333;
  color: #fff;
  cursor: pointer;
  margin-right: 10px;
}

.add-button:hover {
  background-color: #444;
}


.publication-item {
  position: relative;

}


.mini-button {
  background-color:red !important;
  padding: 2px !important;
  position: absolute;
  right: -5px;
  top: 5px;
  border-radius: 100px !important;
  font-family: Jost;
  width: 25px;
  height: 25px;
  font-weight: 600;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  background-color: #444; /* Leichte Abweichung für visuelle Trennung */
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.close-button:hover {
  background-color: #555;
}

.stepper-steps-container {
    position: absolute;
    top: -60px; /* Passe diesen Wert je nach Bedarf an */
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    width: 400px;
    max-width: 800px; /* Passe die maximale Breite an */
    padding: 0 20px;
    z-index: 100000;
  }
  
  .stepper-steps-container > hr {
 width: 1px;
 height: 1px;
 border: none;
 background-color: rgba(255, 255, 255, 0.39);
  }

  .stepper-step {
    text-align: center;
    color: gray;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 400;
    transition: color 0.3s ease;
  }
  
  .stepper-step.active {
    color: white;
  }
  
  .stepper-step:not(:last-child)::after {
    color: gray;
    margin-left: 10px;
  }
  

  @media (max-width: 500px) {
    .stepper-steps-container {
     display: none;
    }
  }


  @media (max-height: 800px) {
    .stepper-steps-container {
     top: -30px;
    }
  }


  @media (max-height: 670px) {
    .stepper-steps-container {
     display: none;
    }
  }
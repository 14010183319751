.audio-player {
    display: flex;
    align-items: center;
    gap: 0px;
    width: 100%;
  }
  
  .play-pause-btn-2 {
    background-color: var(--gray);
    border: none;
    border-radius: 20px; /* Anpassen für noch rundere Ecken */
    width: 40px !important;
    height: 40px !important;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
  

  .progress-bar {
    flex-grow: 1;
    height: 5px; /* Dünnere Fortschrittsleiste */
    background-color: #fff;
    cursor: pointer;
    position: relative;
    width: 50%;
  }
  
  .progress {
    background-color: var(--red);
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  
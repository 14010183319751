/* General Container Styling */
.coin-info-container {
    margin: 20px auto;
    padding: 20px;
    max-width: 900px;
    background-color: var(--black);
    overflow-y: auto;
    border-radius: 10px;
    max-height: 600px;
    font-family: 'Bit';
    color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }

  .coin-info-container::-webkit-scrollbar {
    display: none;
  }
  
  /* Section Headings */
  .coin-info-container h2 {
    font-size: 24px;
    margin-bottom: 15px;
    text-align: left;
    padding-bottom: 5px;
    font-weight: 600;
  }
  
  /* Table Styling */
  .coin-info-container table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    font-size: 18px;
    color: white;
    font-family: 'Bit';
  }
  
  .coin-info-container th,
  .coin-info-container td {
    padding: 10px;
    text-align: center;
  }

  .buy-sell-volume-summary {
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    align-items: center;
  }
  
  .coin-info-container th {
    background-color: rgb(34, 34, 34);
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 24px;
  }
  
  .coin-info-container tr:nth-child(even) {
    background-color: #181818;
    color: white;
  }
  
  /* Wallets Section */
  .coin-info-container ul {
    list-style: none;
    padding: 0;
    margin-top: 20px;
  }
  
  .coin-info-container li {
    margin: 10px 0;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    color: white;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .coin-info-container li:hover {
    transform: translateY(-3px);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  .coin-info-container li strong {
    color: #007bff;
    font-weight: 600;
    color: white;
  }
  
  /* Badge Styling for Top Wallets */
  .coin-info-container li .badge {
    display: inline-block;
    background-color: #007bff;
    color: #fff;
    font-size: 12px;
    padding: 2px 8px;
    border-radius: 12px;
    margin-left: 10px;
    font-weight: bold;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .coin-info-container {
      padding: 15px;
    }
  
    .coin-info-container table {
      font-size: 12px;
    }
  
    .coin-info-container th,
    .coin-info-container td {
      padding: 8px;
    }
  
    .coin-info-container li {
      font-size: 12px;
    }
  }
  
/* OpositeInfo.css */
.oposite-info {
    width: 230px;  /* oder eine andere Breite, die Sie bevorzugen */
    padding: 0 10px;
    border-left: 1px solid rgb(59, 59, 59);  /* eine Trennlinie zum Chat-Fenster */
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;  /* Höhe auf 100% des Viewports setzen */
    overflow-y: auto;  /* Erlaubt vertikales Scrollen, wenn der Inhalt die Höhe überschreitet */
    scrollbar-width: none;  /* Für Firefox */
}

.oposite-info::-webkit-scrollbar {
    display: none;
}

.opposite-info {
    display: flex; 
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.opposite-info-pic > img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 100px;
}

.opposite-info-pic {
    border-radius: 100px;
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 125px;
    margin-top: 20px;
    border: 1px solid rgb(88, 88, 88);
}


.oposite-info > div > img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}



.oposite-info-pic {
    background-color: rgb(36, 36, 36);
    border-radius: 100px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(92, 92, 92)
}

.inbox-message-service {
    background-color: rgb(26, 26, 26);
    border-radius: 15px;
    margin-top: 10px;
}

.oposite-info h3 {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 16px;
}

.inchat-gigs > h5 {
    text-align: left;
    margin-left: 15px;
}

.inchat-gigs > hr {
    border: none;
    height: 1px;
    background-color: rgb(58, 58, 58);
    width: 90%;
    margin-top: 15px;
}

.oposite-info h2 {
    margin-top: 20px;
    margin-bottom: 5px;
    font-family: 'Wide', 'Jost';
    font-size: 14px;
}

.oposite-info p {
    margin: 5px 0;
    font-size: 14px;
}

.oposite-info-about-text {
    margin-top: 0px !important;
    margin-bottom: 20px !important;
}

.awarded-nominated-div {
    width: 100%;
    display: flex;
    background-color: rgb(29, 29, 29);
    padding: 7px 0px;
    border-radius: 10px;
    font-family: 'Wide';
    font-size: 12px;
    align-items: center;
    margin-top: 5px;
}

.awarded-nominated-div > p {
     margin: 0px !important;
     font-size: 11px;
}


.awarded-nominated-div > img {
    width: 30px !important;
    border-radius: 0px !important;
    height: unset !important;
    margin-left: 15px;
    margin-right: 15px;
}

.inpage-chat-info-box {
 background-color: rgb(27, 27, 27);
 border-radius: 15px;
 padding: 10px;
 position: relative;
 margin: 20px 5px ;
}

.inpage-chat-info-box > a > img {
    width: 210px !important;
    height: 170px !important;
    border-radius: 10px !important;
    object-fit: cover;
}


.inpage-chat-info-box > a > h5 {
    font-size: 14px;
    margin: 10px 10px;
}


.inchat-earn-amount {
    position: absolute;
    top: 0px; /* Anpassen, basierend auf dem Layout */
    right: 0px; /* Anpassen, basierend auf dem Layout */
    font-weight: 500; /* Optional: Text fett machen */
    background-color: rgb(36, 36, 36);
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
}



@media (max-width: 1100px) {
    .oposite-info {
        display: none;
    }
}
/* Grundlayout und Darkmode */
.team-analytics-dashboard {
    background-color: var(--black);
    color: #ffffff;
    padding: 20px;
    min-height: 100vh;
  }
  
  .team-analytics-dashboard-title {
    text-align: center;
    font-size: 28px;
    margin-bottom: 20px;
    color: white;
    font-weight: bold;
  }
  
  /* Layout für die Liste der Analytics */
  .team-analytics-dashboard-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  /* Styling für jedes Analytics-Item */
  .team-analytics-dashboard-item {
    background-color: rgb(19, 19, 19);
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.2s;
    position: relative;
  }
  
  .team-analytics-dashboard-item:hover {
    transform: translateY(-5px);
  }
  
  /* Header des Items: Klickanzahl und Titel */
  .team-analytics-dashboard-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  .team-analytics-dashboard-item-count {
    font-size: 16px;
    background-color: var(--blue);
    color: white;
    padding: 5px 10px;
    position: absolute;
    top: 0;
    left: 0;
    font-weight: 600;
    border-radius: 10px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  
  .team-analytics-dashboard-item-title {
    font-size: 20px;
    margin: 20px 0 20px 0;
  }
  
  /* Variantenliste */
  .team-analytics-dashboard-variants {
    list-style: none;
    padding-left: 0;
  }

  .team-analytics-dashboard-item-image {
      max-width: 100%;
      object-fit: contain;
      height: 300px;
  }
  
  .team-analytics-dashboard-variant {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .team-analytics-dashboard-variant-name {
    color: rgb(172, 172, 172);
  }
  
  .team-analytics-dashboard-variant-count {
    font-weight: bold;
    color: #ffffff;
  }
  
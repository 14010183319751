.home-awards {
    display: flex;
    padding-left: var(--edge);
    padding-right: var(--edge);
    background-color: var(--black);
    flex-direction: column;
    margin-top: -60px;
    padding-bottom: 70px;
  }

  .home-awards-grid {
    width: 100%;
    display: grid;
    margin-top: 20px;
    grid-template-columns: repeat(2, 1fr); /* Erstellt vier Spalten */
  }

  .home-awards-grid-mobile {
    width: 100%;
    display: none;
    margin-top: 20px;
  }

  .scroll-video-mobile {
    display: none;
  }

  .home-awards-body {
      display: flex;
      flex-direction: row;
      

  }

  .border-bottom-white {
    border-bottom: 1px solid rgb(117, 117, 117);
  }
  

  .home-awards-left {
      width: 60% !important;
      display: flex;
      align-items: center;
  }
  
 .home-awards-right {
      width: 40%;
  }
  
  
  .home-awards > h2 {
    font-size: 68px;
    margin-bottom: 0px;
    color: var(--white);
    width: 100%;
    text-align: left;
    font-family: 'Wide', 'Jost', 'Josefin Sans', sans-serif;
    z-index: 10;
    margin-top: 80px;
  }


  
  .home-awards > p {
    font-size: 16px;
    color: var(--white);
    width: 100%;
    text-align: left;
    font-weight: 300;
    letter-spacing: 1px;
    margin-bottom: 30px;

  }


  .home-award-song {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;
    padding: 15px 10px 15px 10px; 
    color: white;
    border-top: 1px rgb(112, 112, 112) solid;
  }

  .home-awards-right > img {
      width: 80%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
  }

  .home-award-song-left > img {
width: 50px;
height: 50px;
object-fit: cover;
border-radius: 10px;
  }
  
.home-award-song > div {
    display: flex;
    align-items: left;
    justify-content: center;
}

.song-name-awards {
  margin: 0px;
}

.song-name-awards-2 {
  margin: 0px;
  font-weight: 300;
  color: rgb(211, 211, 211) !important;
}


.song-name-awards:hover {
  text-decoration: underline;
}


.home-award-song-right {
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-award-song-middle  > h4{
    margin-bottom: -20px;
}

.home-award-song-middle {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
}

.award-ribbon-2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  background-color: rgb(24, 24, 24);
}

 


.award-ribbon-2::before,
.award-ribbon-2::after {
  content: '';
  position: absolute;
  bottom: -14px;
  width: 15px;
  height: 15px;
  background-color: rgb(24, 24, 24);
}

.award-ribbon-2::before {
  right: 50%;
  clip-path: polygon(0% 0%, 100% 0%, 0% 100%); /* Linke Spitze */
}

.award-ribbon-2::after {
  left: 50%;
  clip-path: polygon(0% 0%, 100% 100%, 100% 0%); /* Rechte Spitze */
}

.vertical-text {
  position: absolute;
  color: rgb(196, 196, 196);
  top: 65px; /* Abstand von der oberen Kante des Ribbons, anpassen nach Bedarf */
  right: 10px; /* Platziert den Text am rechten Rand des Ribbons */
  font-size: 0.6em; /* Anpassen nach Bedarf */
  font-weight: 600;
}

.vertical-letter {
  display: block; /* Jeder Buchstabe wird als eigener Block angezeigt */
  line-height: 1; /* Passt den Zeilenabstand an, anpassen nach Bedarf */
  /* Weitere Stile nach Bedarf */
}


.award-ribbon-2 > img {
  width: 20px;
  padding: 10px;
  border-radius: 100px;
  background-color: rgb(12, 12, 12);
  margin-left: -5px;
  margin-top: 10px;
  border: 1px solid rgb(97, 97, 97);
}

.home-awards-grid-mobile {
  display: none;
}


@media (max-width: 800px) {
    
  .home-awards > h2 {
    font-size: 42px;
    margin-bottom: 0px;

  }

  .home-awards-body {
    flex-direction: column-reverse;
  }

  .home-awards-grid {
    display: none;
  }
  .home-awards-grid-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .home-awards-left {
    width: 100% !important;

}

.scroll-video-desktop {
  display: none;
}

.scroll-video-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-video-mobile > video {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}


.scroll-video-mobile > img {
  width: 90%;
  margin-top: 10px;
  margin-bottom: 30px;
  transform: scale(0.8);

}



.home-awards-right {
    width: 100%;
    min-height: 300px;
}

}
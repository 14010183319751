.team-content-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
    gap: 20px;
}

.content-video-item {
    padding: 15px;
    border: 1px solid rgb(63, 63, 63);
    border-radius: 25px;
    width: 300px;
    background-color: #181818;
    transition: background-color 0.3s;
    position: relative;
    margin-top: 10px;

}

.content-top-selected-button-2 > img {
    width: 10px;
    height: 10px;
    filter: invert(1);
    padding: 5px;
}

.content-top-selected-button-2 {
    height: 30px;
    width: 30px;
    padding: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: center;
    border-radius: 100px;
    background-color: #181818;
    border: 1px solid rgba(255, 255, 255, 0.466);
}

.content-top-selected-button {
    background-color: black;
    border: 1px solid rgba(255, 255, 255, 0.288);
    color: white;
    border-radius: 100px;
    padding: 10px 20px;
    margin-bottom: 10px;
}

.content-top-selected-buttons {
    position: absolute;
    top: 10px;
    right: 10px;
}

.content-top-selected-button-edit {
    background-color: black;
    border: 1px solid rgba(255, 255, 255, 0.288);
    color: white;
    border-radius: 100px;
    padding: 10px 20px;
    margin-left: 5px;
    margin-bottom: 10px;
}


.content-video-item.selected {
    background-color: #5c9bdf69; /* Blau für ausgewählte Videos */
}

.content-video-item.edit-mode {
    background-color: #90f1909c; /* Grün für Videos im Bearbeitungsmodus */
}


.spotlight-artist-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .spotlight-artist-title {
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .spotlight-artist-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .form-label {
    font-size: 16px;
    color: #444;
  }

  .spotlight-modal-overlay {

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    margin-top: 50px;
    padding-bottom: 100px;
  }

  .image-upload-boxes {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
  }
  
  .image-upload-box {
    width: 100px;
    height: 150px;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
  }

  #image-box-2 {
    width: 250px;
  }
  
#image-box-3 {
  width: 150px;
}

  .image-upload-box input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  
  .image-upload-box span {
    display: block;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    text-align: center;
    padding: 5px 0;
    visibility: hidden;
  }
  
  .image-upload-box:hover span {
    visibility: visible;
  }
  
  
  .spotlight-modal {
    background-color: #333;
    color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
  }
  
  .spotlight-modal-title {
    color: #fff;
  }
  
  .spotlight-modal-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .spotlight-modal-form input[type="text"],
  .spotlight-modal-form textarea {
    background-color: #222;
    color: #fff;
    border: 1px solid #444;
    border-radius: 4px;
    padding: 8px;
  }
  
  .spotlight-modal-form button {
    background-color: #0066cc;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .spotlight-modal-form button:disabled {
    background-color: #555;
  }
  
  .spotlight-modal-uploading {
    color: #4CAF50;
  }
  
  .spotlight-modal-existing-data {
    margin-top: 20px;
  }
  
  
  .form-input, .form-textarea, .form-submit-button {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    color: black;
  }
  
  .form-input:focus, .form-textarea:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .form-submit-button {
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border: none;
  }
  
  .form-submit-button:disabled {
    background-color: #ccc;
  }
  
  .upload-status {
    text-align: center;
    margin-top: 15px;
  }
  
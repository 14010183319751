/* HomeCounter.css */
.home-counter-container {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(to bottom, rgb(0, 0, 0), var(--black)); /* Gradient von Rot zu Schwarz */
    color: white;
    padding: 0px 0px 0px 0px;
    height: 60px;
    margin-top: -11px;
    width: 100%;
    position: fixed;
    z-index: 1000;
    border-bottom: 1px solid rgb(54, 54, 54);
    animation: fadeIn 0.4s ease-out forwards; /* Füge die Animation hinzu */
  }

  /* HomeCounter.css */
.home-counter-container-2 {
    display: flex;
    justify-content: space-between;
    background: var(--black);
    color: white;
    padding: 20px 0px 40px 0px;
    height: 200px;
    z-index: 1000;
  }

  .home-counter-section_1 > a > p {
      margin: 20px;
      color: rgb(199, 199, 199);
  }
  

  .home-counter-section_3  > p {
    margin: 20px;
    color: rgb(199, 199, 199);
  }


  .home-counter-section_1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    width: 15%;
    font-size: 12px;
    text-transform: uppercase;
  }

  .home-counter-section_2 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 70%;
  }

  .home-counter-section_3 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    width: 15%;
    font-size: 12px;
    text-transform: uppercase;
  }

  .home-counter-middle {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 70px;
  }


  .home-counter-section_2 > div > img {
      width: 110px;
      margin-bottom: 0px;
  }

  .home-counter-section_2 > div > h5 {
      font-size: 15px;
      text-align: center;
      margin-top: 17px;
      margin-bottom: 20px;
      font-weight: 300;
      letter-spacing: 2px;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    50% {
        opacity: 0;
      }
    100% {
      opacity: 1;
    }
  }
  
  .home-counter-counter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 14px;
    animation: fadeIn 0.2s ease-out forwards; /* Füge die Animation hinzu */
  }
  
  .counter-value {
    font-size: 1.5em; /* Größere Schrift für den Counter */
    padding: 0 10px;
    font-family: 'Wide', 'Jost', 'Josefin Sans', sans-serif;
    color: rgb(255, 255, 255);

  }

  .counter-value-2 {
    font-size: 4.5em; /* Größere Schrift für den Counter */
    padding: 0 10px;
    font-family: 'Wide', 'Jost', 'Josefin Sans', sans-serif;

  }

  
  .counter-unit {
    font-family: 'Jost';
    text-transform: uppercase;
    color: rgb(245, 245, 245);
    font-size: 0.3em; /* Schriftgröße für die Einheiten */
    display: block; /* Neue Zeile */
  }


  .counter-unit-2 {
    font-family: 'Jost';
    text-transform: uppercase;
    color: rgb(250, 250, 250);
  font-size: 0.3em; /* Schriftgröße für die Einheiten */
  display: block; /* Neue Zeile */
}


  @media (max-width: 700px) {
    .home-counter-section_1 {
        display: none;
    }
    .home-counter-section_3 {
        display: none;
    }
    .home-counter-container {
        display: flex;
        justify-content: center;
        top: 0;
        margin-top: 0;
      }
      .home-counter-container-2 {
        display: flex;
        justify-content: center;
        padding-bottom: 40px;
        margin-bottom: -1px;
        height: 220px;
      }
      .home-counter-section_2 > div > img {
        width: 130px;
        margin-bottom: 0px;
    }

    .counter-value-2 {
        font-size: 2.2em; /* Größere Schrift für den Counter */
        padding: 0 10px;
        font-family: 'Wide', 'Jost', 'Josefin Sans', sans-serif;
    
      }

      .counter-value {
        padding: 0 10px;
        font-family: 'Wide', 'Jost', 'Josefin Sans', sans-serif;
    
      }
  

  }
  
.slide-stepper-steps-container {
    width: 90%;
    height: 0px; /* Höhe der Komponente */
    background-color: var(--black); /* Hintergrundfarbe, optional */
    border-radius: 100px;
  }
  
  .slide-stepper-progress {
    position: absolute;
    top: -2px;
    left: 30px;
    z-index: 100;
    border-radius: 100px;
    height: 8px; /* Höhe der Fortschrittslinie */
    background: linear-gradient(270deg, #ff0000, #ff7f00, #ff0000);
    background-size: 200% 200%;
    transition: width 0.3s ease; /* Glatter Übergang beim Fortschritt */
    animation: moveGradient 3s ease infinite;
  }


  @keyframes moveGradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  

  @media (max-width: 500px) {
    .slide-stepper-progress {
      position: absolute;
      top: -2px;
      left: -2px;
      z-index: 100;
      border-radius: 100px;
      height: 8px; /* Höhe der Fortschrittslinie */
      background: linear-gradient(270deg, #ff0000, #ff7f00, #ff0000);
      background-size: 200% 200%;
      transition: width 0.3s ease; /* Glatter Übergang beim Fortschritt */
      animation: moveGradient 3s ease infinite;
    }
  }
  
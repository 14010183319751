.hide-equipment {
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center; /* Zentriert Text und Button */
    background-color: var(--black);
    padding: 40px 0 80px 0;
    margin-top: -40px;
  }

  .hide-equipment-container > img {
      width: 130px;
      margin-top: 15px;
  }

  .hide-equipment-container {
      width: 90%;
      padding: 20px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 25px;
      border: 1px solid rgb(80, 80, 80);
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  }
  
  .equipment-image {
    max-width: 100%; /* Sorgt dafür, dass das Bild responsiv ist */
    border-radius: 20px; /* Abgerundete Ecken für das Bild */
    margin-bottom: 20px; /* Abstand zwischen Bild und Text */
  }
  
  .equipment-text-1 {
    font-size: 28px;
    font-weight: 600;
    margin: 0px 20px 20px 20px;
    color: white;

  }

  .equipment-text {
    font-size: 18px;
    font-weight: 300;
    margin: 0px 20px 20px 20px;
    color: white;

  }
  
  .equipment-button {
    padding: 10px 20px;
    border: 1px solid white;
    margin-top: 20px;
    border-radius: 100px;
    background-color: transparent; /* Ersetzen Sie dies durch Ihre bevorzugte Farbe */
    color: white;
    cursor: pointer;
    font-size: 1em;
    margin-bottom: 30px;
    width: fit-content;
  }
  
  .equipment-button:hover {
    background-color: var(--dark-blue); /* Dunklere Farbe für den Hover-Effekt */
  }
  

  @media (max-width: 810px) {
    .hide-equipment {
      padding: 20px 0 20px 0;
    }
  }
.review-stars-container {
    display: flex;
    align-items: center;
    background-color: rgba(59, 59, 59, 0.13);
    border-radius: 100px;
    padding: 0px 10px;
    position: absolute;
    bottom: 40px;
    right: 10px;
    z-index: 100;
    justify-content: center;
    height: 28px;
    backdrop-filter: blur(10px);
}

.review-stars > img {
    width: 11px;
    margin-right: 2px;
    z-index: 1000;
    margin-top: 0px;
}

.review-score-text {
    color: #fff;
    margin-right: 7px !important;
    font-size: 12px !important;
    font-weight: 500;
    margin-top: 7px !important;
}

@media (max-width: 650px) {
    .review-stars-container {
        bottom: 60px;
    }
}

/* Hauptcontainer */
.viral-vault-container {
    text-align: center;
    background-color: var(--black);
    padding: 0px;
    color: #fff;
  }
  
  /* Header und Logo */
  .viral-vault-header {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

  }

  .viral-vault-header > h5 {
      width: fit-content;
      margin-bottom: 10px;
      margin-top: 80px;
      padding: 10px 20px;
      border-radius: 100px;
      background-color: var(--blue);
      position: relative;
      z-index: 10;

  }

  .viral-vault-header > h2 {
      font-family: 'Wide';
      font-size: 68px;
      z-index: 100;
      margin-top: 0px;
      margin-left: 15%;
      margin-right: 15%;
      margin-bottom: 0px;
      position: relative;
  }



  .viral-vault-header > h2:hover {
      cursor: pointer;
  }
  .viral-vault-header > h2:hover > span {
    text-decoration: underline;
}

  .viral-vault-header > p {
      margin-top: 10px;
      color: grey;
      margin-bottom: 60px;
  }
  
  .viral-vault-logo {
    width: 200px;
    height: auto;
    margin: 50px 0 0 0;
    z-index: 100;
    position: relative;
  }
  
  /* Filter-Typ-Selektor */
  .viral-vault-type-selector {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;
    flex-wrap: wrap;
    margin-right: 10%;
    margin-left: 10%;

  }
  
  .viral-filter-button {
    background-color: rgb(29, 29, 29);
    border: none;
    border-radius: 25px;
    font-size: 20px;
    font-family: 'Wide';
    padding: 10px 15px;
    z-index: 100;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    transition: 140ms ease-in-out;
  }

  .viral-filter-button.active {
    background-color: #ff4757; /* Highlight-Farbe für den aktiven Button */
    color: #fff;
    transform: scale(1.1);
  }
  
 

  .viral-filter-button-text {
      display: flex;
      flex-direction: column;
      text-align: left;
  }

  .viral-filter-button-text > p {
      font-size: 12px;
      margin: 0;
      font-family: 'Jost';
      color: grey;
      max-width: 200px;
      margin-top: 5px;
  }
  
  .viral-filter-icon {
    width: 85px;
    height: 85px;
    border-radius: 20px;
    object-fit: cover;
    margin-right: 20px;
    margin-bottom: 0px;
  }
  
  .viral-filter-button span {
    color: #fff;
    font-weight: bold;
    font-size: 14px;
  }
  
/* Einzigartige Präfix-Klassen für ListArtistActions (laa-) */

.laa-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .laa-modal-content {
    background-color: rgb(0, 0, 0);
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .laa-modal-content h2 {
    margin-top: 0;
  }
  
  .laa-modal-label {
    display: block;
    margin-bottom: 10px;
  }
  
  .laa-modal-input[type="number"] {
    width: 100%;
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .laa-modal-button {
    margin-right: 10px;
    padding: 10px 20px;
    border-radius: 4px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .laa-modal-button.laa-modal-close {
    background-color: #6c757d;
  }
  
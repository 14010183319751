.gig-text-header > div {
    display: flex;
    align-items: center;
    flex-direction: left;
    width: 100%;
}

.gig-text-header > div > h2 {
    font-family: 'Wide';
    color: var(--color-oposite-1);
}

.page-text-header {
    margin-top: 0px;
    margin-bottom: 20px;
    border-radius: 15px;
    border: 1px solid rgba(128, 128, 128, 0);
    transition: 140ms ease-in-out;
    padding: 0px;
    margin-left: 0px;
    padding: 10px;

}


.page-text-header:hover .edit-overlay {
    display: flex; /* Make visible on hover */
}

.page-text-header.settings-route {
    border: 1px solid rgba(255, 255, 255, 0); /* Solid border for clarity */
}

.page-text-header.is-active {
    padding: 10px;
    border-color: rgb(196, 196, 196); /* Blue border when active */
}

.page-text-header.settings-route:hover {
    cursor: pointer;
    transform: scale(1.03);
    box-shadow: var(--color-shadow-2);
}

.page-text-header:hover .gig-upload-song-button > h5 {
    color: var(--color-oposite-1);
}

.page-text-header:hover .gig-upload-song-button > img {
    opacity: 1;
}
.react-quill {
    color: var(--color-1);  /* Nutze eine Variable, die je nach Modus umgeschaltet wird */
  }

  .ql-editor {
      padding: 0px 20% 0 0;
      font-family: 'Jost', 'Helvetica', 'Josefin Sans', 'Sans Serif';
    font-size: 15px;
        
    }

    .ql-tooltip {
        height: 10px;
    }

    .ql-snow > .ql-editor {
        min-height: 200px;
        padding: 12px !important;
    }

    .ql-editor:hover {
        cursor: pointer;
    }
.premium-sub-button-1 {
    height: 42px;
    border: 0px;
    border-radius: 100px;
    padding: 0 18px;
    font-family: 'Jost';
    font-weight: 500;
    font-size: 14px;
    background-color: var(--blue);
    color: white;
}

.premium-sub-button-2 {
    height: 42px;
    border: 0px;
    border-radius: 100px;
    padding: 0 18px;
    border: 1px solid rgb(92, 92, 92);
    font-family: 'Jost';
    font-weight: 500;
    font-size: 14px;
    background-color: rgb(27, 27, 27);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.premium-sub-button-2:hover, .premium-sub-button-1:hover {
    cursor: pointer;
}

.premium-sub-button-2-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    margin-right: 5px;
}

.premium-sub-button-2-text > h4 {
    margin: 0;
    font-size: 14px;
    text-align: left;
    letter-spacing: 0.5px;
    font-weight: 500;
}

.premium-sub-button-2-prices {
    display: flex;
    margin: -2px 0 0 0;
    font-size: 11px;
    color: rgb(216, 216, 216);
}

.premium-sub-button-2-prices > p {
    margin: 0;
    color: white;
    font-weight: 500;
}

.premium-sub-button-2-prices > span > p {
    margin: 0 10px 0 5px;
    text-decoration: line-through;
}

.premium-sub-button-2 > img {
    width: 17px;
    margin-left: 5px;
}

.premium-sub-button-3 {
    height: 42px;
    border: 0px;
    border-radius: 100px;
    padding: 0 18px;
    font-family: 'Jost';
    font-weight: 500;
    font-size: 14px;
    background-color: var(--red);
    color: white;
}

@media (max-width: 900px) {

.premium-sub-button-2 {
   display: none;
}


.premium-sub-button-1 {
    display: none;
 }
 .premium-sub-button-3 {
    display: none;
 }

}
.chat-list-container {
    display: flex;
    height: 100vh;
    background-color: #121212; /* Dunkler Hintergrund */
    color: white;
}

.chat-sidebar-toggle-wrapper {
    position: relative;
}

.dashboard-chat-sidebar {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), #181818);
    padding:  0 10px;
    transition: width 0.3s ease-in-out; /* Glatter Übergang für die Breite */
    margin-left: 200px;
    transition: width 0.10s ease-in-out; /* Glatter Übergang für die Breite */
    transform: translateX(0);
    min-width: 230px;
    width: 230px; /* Ausgangsbreite */
    height: 100vh; /* Setzt die Höhe auf 100% der Viewport-Höhe */
    scrollbar-width: none; /* Für Firefox */
    overflow-y: auto;
}

/* Verstecken der Scrollbar für Webkit-Browser wie Chrome, Safari */
.dashboard-chat-sidebar::-webkit-scrollbar {
    display: none;
}


.dashboard-chat-sidebar > h2 {
    font-family: 'Wide', 'Jost';
    font-size: 22px;
}

.chat-sidebar-info {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
}

.typing-indicator {
    margin-top: 0px !important;
    position: absolute;
    bottom: 3px;
    left: 6px;
    font-size: 13px !important;
    background-color: var(--black);
    width: fit-content !important;
    padding: 2px 7px !important;
    border-radius: 100px;
}


@keyframes pulse {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    50% {
      opacity: 0.5;
      transform: scale(0.99);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .chat-sidebar-info > span > p {
    margin: -5px 0 0 0;
    width: 100%;
    text-align: left;
    font-size: 14px;
    font-weight: 300;
    padding: 0;
    animation: pulse 1.5s infinite; /* Hier kannst du die Dauer und andere Eigenschaften anpassen */
  }
  
.chat-sidebar-info > p {
    margin: 0;
    width: 100%;
    font-size: 13px;
    font-weight: 500;
}

.chat-prev-upladed-img {
    position: absolute;
    max-height: 60px !important;
    object-fit: contain;
    right: 160px;
    bottom: 5px;
    border-radius: 5px;
    border: 1px solid rgba(128, 128, 128, 0.5);
}


.chat-entry {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;
    margin: 5px 0;
    position: relative;
    border-bottom: 1px solid rgb(46, 46, 46);
}

.chat-entry:hover {
    background-color: #282828;
    border-radius: 10px;
}

.active-chat-entry {
    border-radius: 10px;
    background-color: #2c2c2c; /* Hellgrauer Hintergrund für den aktiven Chat */
}


.chat-profile-image-2 {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
}

.chat-profile-image-3 {
    width: 40px !important;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
    min-width: 40px;
}

.chat-info .chat-sidebar-name {
    font-size: 16px;
    font-weight: bold;
}

.mobile-chat-back-button {
    background-color: rgba(70, 70, 70, 0.288);
    border-radius: 100px !important;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 10px 35px 10px;
    display: none;

}

.mobile-chat-back-button:hover {
    cursor: pointer;
}

.mobile-chat-back-button > img {
    width: 40px;
    rotate: 90deg;
}

.chat-sidebar-name {
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 13px;
}

.chat-messages {
    flex-grow: 1;
    padding: 0px 20px;
}

.chat-sidebar-info > div {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    position: relative;
}

.dashboard-chat-sidebar.closed .chat-entry:first-of-type {
    margin-top: 15px;
}



.chat-sidebar-info > div > span {
    background-color: var(--red);
    width: 20px;
    height: 20px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    right: 0;
}


.no-chats-message {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    margin: -100px 0px 0px 200px;
}

.no-chats-message > img {
 width: 150px;
}

.no-chats-message > p {
    font-family: 'Jost';
    margin-top: 0px;
    margin-bottom: 30px;
    color: rgb(199, 199, 199);
}

.no-chats-message > h2 {
    font-family: 'Wide', 'Jost';
}

.no-chats-message > a {
    font-family: 'Wide', 'Jost';
    padding: 0px 35px;
    border-radius: 13px;
    color: white;
    background-color: #282828;
    border: 1px solid rgb(66, 66, 66);
    font-family: 'Jost';
    text-decoration: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.no-chats-message > a > p {
    font-size: 16px;
    letter-spacing: 0.5px;
}

.no-chats-message > a:hover {
    cursor: pointer;
}


.chat-sidebar-toggle-button {
    top: 40%;
    position: absolute;
    right: -20px;
    z-index: 1000;
    margin-left: -30px;
    border: none;
    border-radius: 4px;
    padding: 5px;
    cursor: pointer;
    background: none;
    font-size: 16px;
}

.chat-sidebar-toggle-button > img {
    width: 35px;
}

.sidebar-toggle-button:hover {
    background-color: #0056b3;
}

.dashboard-chat-sidebar.closed .chat-sidebar-info > div > span {
    top: -25px !important;
}


@media (max-width: 750px)  {

    .no-chats-message {
        margin: -100px 20px 0px 20px;
    }

.dashboard-chat-sidebar {
    margin-left: 0px; 
}

.mobile-chat-back-button {
    display: flex;
}

}
.dashboard-chat-sidebar.closed .chat-entry {
    width: 40px; /* Verringere die Breite auf 50px */
    border-radius: 20px;
}




.dashboard-chat-sidebar.closed {
    min-width: unset !important;
    width: 60px;

}

.dashboard-chat-sidebar.closed > h2 {
    display: none;
}


@media (max-width:750px) {
    .dashboard-chat-sidebar > h2 {
        margin-top: 50px !important;
    }
    .chat-sidebar-toggle-button {
        display: none;
    }
}


@media (max-width: 400px) {
  
}

@media (max-width: 320px) {
    .chat-messages {
        padding: 0;
    }
}
.tiktok-video-pagination {
    color: white;
    margin-bottom: 60px;
    margin-top: 30px;
}

.tiktok-video-pagination-back {
    width: 20px;
    filter: invert(1);
    rotate: 90deg;
}

.saveToggliContainer {
    position: absolute;
    top: -55px;
    right: 10px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    background-color: rgb(36, 36, 36);
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    font-size: 24px;
    transition: background-color 0.3s ease;
  }
  
  .saveToggliContainer:hover {
    background-color: pink;
  }
  
  .saveToggliContainer.saved {
    background-color: red;
    border: 1px solid white;
  }

.finding-trends-text {
    opacity: 1;
    margin-top: 2px;
    font-family: 'Wide';
    margin-top: 20px;
    animation: opacity-swirl 1s infinite;
}



@keyframes opacity-swirl {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    0% 
    {
        opacity: 1;
    }
}

.tiktok-video-pagination-next {
    width: 20px;
    filter: invert(1);
    rotate: 270deg;
}

.tiktok-video-pagination > button {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    height: 60px;
    background-color: black;
    border: 1px solid rgb(121, 121, 121);
}

.tiktok-video-pagination > button:hover {
    cursor: pointer;
    transform: scale(1.02);
}

.tiktok-video-pagination > button:disabled {
    background-color: rgba(73, 73, 73, 0.411);
    cursor: auto;
}

.tiktok-video-pagination > button:disabled .tiktok-video-pagination-back {
    opacity: 0.2;;
}

.tiktok-video-pagination > button:disabled .tiktok-video-pagination-next {
    opacity: 0.2;;
}
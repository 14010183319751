.all-pages-wrapper {
    color: #fff;
    min-height: 100vh;
    margin-left: 200px;
  }
  
  .all-pages-title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .all-pages-title-bar h1 {
    margin: 0;
    color: #1db954; /* Spotify green for branding */
  }

  .all-pages-route {
    margin-bottom: 0px;
  }
  


  .all-pages-create-link {
    text-decoration: none;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .all-pages-create-link:hover,
  .all-pages-create-link:focus {
    background-color: #1db954;
    color: #121212;
  }

  .setup-reminder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


.setup-reminder > p {
  margin-top: -10px;
  font-weight: 600;
  margin-bottom: 30px;
  font-size: 18px;
}
  
  .setup-reminder > a {
    color: white;
    width: 100%;
    height: 55px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Jost';
    border: 1px solid var(--red);
    border-radius: 100px;
    font-size: 18px;
    font-weight: 600;
    background-image: linear-gradient(270deg, #ffffff, silver, #ffffff);
    background-size: 600% 600%;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent; /* Für Safari */
    animation: GradientText 7s ease infinite;
  }
  
  @keyframes GradientText {
    0% {background-position: 0% 50%}
    50% {background-position: 100% 50%}
    100% {background-position: 0% 50%}
  }
  
  
  
  .all-pages-gig-title {
    margin-top: -5px;
    margin-bottom: 0px;
    font-size: 22px;
    
  }
  
  .all-pages-gig-text {
    font-size: 0.9rem;
    line-height: 1.4;
    color: #a1a1a1;
  }
  
  .all-pages-empty-state {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    min-height: 92vh;
    background-image: var(--black);
  }
  
  .all-pages-empty-state-inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0);
    width: 700px;
    padding: 0px 20px 80px 20px;
    border-radius: 15px;
    z-index: 100;
  }

  .all-pages-empty-state-inner > h2 {
    font-size: 32px;
    font-family: 'Wide', 'Jost';
    margin-bottom: 0px;
    margin-top: -30px;
    z-index: 100;
  }

  .all-pages-empty-state-inner > p {
    max-width: 500px;
  }



  .all-pages-empty-state-inner > img {
    width: 170px;
    margin-bottom: 50px;
    z-index: 100;
    
  }

  .all-pages-empty-state-inner > a {
    color: white;
    text-decoration: none;
    background: linear-gradient(215deg, #9dc1ccb4 49.9%, #274aaa 80%),
                linear-gradient(304deg, rgba(6, 101, 117, 0.4) 24.9%, #aa2743 91%),
                linear-gradient(10deg, #b1c9d8 39.9%, #2776aa 50%),
                linear-gradient(145deg, #1e0852 15.9%, #672188 40%);
    background-size: 250% 250%;
    background-repeat: no-repeat;
    background-blend-mode: hard-light;
    animation: moveGradient 20s ease infinite;
    height: 65px;
    padding: 5px 0;
    width: 60%;
    display: flex;
    align-items: center;
    font-size: 16px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    text-transform: uppercase;
    justify-content: center;
    border-radius: 100px;
    margin-top: 15px;
    transition: 130ms ease-in-out;
    backdrop-filter: blur(30px);
}

.all-pages-empty-state-inner > a:hover {
    transform: scale(1.01);
}

@keyframes moveGradient {
    0% {
        background-position: 0% 100%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 0% 100%;
    }
}


  .all-pages-empty-state-inner > p {
    margin-bottom: 0px;
    z-index: 100;
  }

  .all-pages-empty-state-inner > a > img {
    width: 35px;
    margin-left: 10px;
    position: absolute;
    right: 20px;
  }
  
  .all-pages-empty-state-text {
    font-size: 1.1rem;
    color: #a1a1a1;
  }


  .all-pages-empty-state-link {
    width: 82% !important;
  }

  .all-pages-empty-state-link > p {
    font-weight: 500;
    font-size: 17px;
  }


  .all-pages-empty-state-link:hover,
  .all-pages-empty-state-link:focus {
    transform: scale(1.02);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
  

  .empty-page-discount-banner {
    width: 57%;
    border-radius: 15px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    position: fixed;
    top: -47px;
    border: 1px solid rgb(59, 59, 59);
    right: 125px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .empty-page-discount-banner > img {
    width: 30px;
    margin-right: 15px;
  }

  .empty-page-discount-banner > p {
    color: rgb(204, 204, 204);
    font-size: 14px;
  }


  @media (max-width: 750px) {
    .all-pages-wrapper {
      margin: 0px;
    }
  }


  @media (max-width: 600px) {
    .all-pages-wrapper {
      margin: 0px;
    }



    .all-pages-empty-state-inner > img {
      margin-top: 30px;
    }

    .all-pages-empty-state-link {
      width: 95% !important;
    }

    .all-pages-grid > ul {
      padding: 0px;
    }
  }
.superchat-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.664);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.superchat-modal-content::-webkit-scrollbar {
    display: none; 
}

.superchat-modal-content {
    width: 90%;
    max-width: 600px;
    height: 400px;
    scrollbar-width: none; /* Für Firefox: Scrollbar ausblenden */
    background-color: rgba(19, 19, 19, 0.301);
    border-radius: 35px;
    backdrop-filter: blur(25px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: 1px solid rgba(116, 116, 116, 0.185);
    align-items: center;
    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.superchat-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.superchat-modal-header > img {
    width: 150px;
}



@keyframes shimmerText {
    0% {
      text-shadow:
        1px 1px 2px orange,
        0px 0px 25px silver,
        -1px -1px 2px orange;
    }
    50% {
      text-shadow:
        1px 1px 2px silver,
        0px 0px 35px orange,
        -1px -1px 2px silver;
    }
    100% {
      text-shadow:
        1px 1px 2px orange,
        0px 0px 25px silver,
        -1px -1px 2px orange;
    }
  }
  
  .superchat-modal-title {
    margin: 0;
    font-size: 16px;
    color: white; /* Basisfarbe des Textes */
    position: absolute;
    top: 25px;
    left: 25px;
  }
  
  .superchat-modal-header > span {
    animation: shimmerText 3s ease infinite;
    display: inline-block; /* Notwendig, damit die Animation angewendet wird */
    color: transparent; /* Macht den Text selbst unsichtbar, zeigt nur den Schatten */
  }
  
  
  .superchat-modal-header > span {
    background: linear-gradient(45deg, orange, silver, orange);
    background-size: 200% 200%;
    animation: gradientBackground 3s ease infinite;
    color: white; /* Damit der Text sichtbar bleibt */
    padding: 10px;  
}
  

.superchat-close-button {
    border: none;
    background: none;
    font-size: 1.5rem;
    cursor: pointer;
    position: absolute;
    color: white;
    top: -10px;
    right: -10px;
    padding: 10px;
    border-radius: 100px;
    background-color:var(--black);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(255, 255, 255, 0.274);
    height: 45px;
    width: 45px;
}

.superchat-modal-body {
    overflow-y: auto;
    width: 100%;
    margin-top: 10px;
}

.superchat-modal-body::-webkit-scrollbar {
    display: none;
}

.superchat-modal-body > p {
    margin-bottom: 0px;
    margin-top: 0px;
}


.superchat-top-buttons > button {
    height: 40px;
    border: none;
    margin: 0 5px;
    padding: 7px 15px;
    font-weight: 500;
    color: white;
    font-size: 14px;
    border-radius: 100px;
    font-family: 'Jost';
    background-color: var(--black);
}

.superchat-top-buttons > button:hover {
    cursor: pointer;
}

.superchat-top-buttons {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-top: 10px;
    margin-left: 15px;
}

.superchat-modal-footer {
    padding: 10px;
    text-align: right;
}

.superchat-top-buttons button.active {
    border: 1px solid white;
    color: white;
    background-color: var(--blue); /* Optional: Button-Hintergrund für besseren Kontrast */
}


.superchat-modal-footer > button {
    height: 40px;
    width: 100px;
    border-radius: 100px;
    border: 1px solid white;
    bottom: 0;
    left: 50%;
    margin: 0px;
}

.superchat-open-button {
    position: absolute;
    right: 60px;
    color: white;
    bottom: 18px;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    border: none;
    background-color: var(--black);
    display: flex;
    align-items: center;
    justify-content: center;
}

.superchat-open-button > img {
    filter: invert(1);
    width: 25px;
    height: 25px;
    opacity: 0.8;
}

.superchat-open-button:hover > img {
    opacity: 1;
    cursor: pointer;
}

.review-request-button {
    background-color: var(--blue); /* Grüne Hintergrundfarbe */
    color: white;
    margin-top: 25px;
    height: 50px;
    margin-bottom: 0px;
    width: 210px;
    border: none;
    font-family: 'Jost';
    cursor: pointer;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

}


.superchat-gig-selector {
    display: flex;
    width: calc(100% - 40px) !important;
    align-items: center;
    justify-content: space-between;
    padding: 25px 15px;
    margin: 0px;
    border-radius: 15px;
}



.superchat-gig-selector > div > select {
    width: 250px !important;
    color: white;
    height: 50px;
    border: 1px solid rgba(226, 226, 226, 0.233);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--blue);
    padding: 10px;
    font-size: 15px;
    font-family: 'Jost';
    color: white;
    border-radius: 7px;
    outline: none;
    margin-left: 15px;

}

.request-review-top {
    background-color: var(--black);
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 15px;
    padding-top: 1px;

}

.review-request-button > img {
    width: 20px;
    filter: invert(1);
    margin-left: 8px;
}

.request-review-top > h2 {
    font-size: 18px !important;
    text-align: left;
    margin-left: 30px;
    margin-bottom: -10px;
}

.superchat-gig-selector >  div {
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: column;
    width: 100%;
}

.superchat-gig-selector >  div > label {
    text-align: left;
    margin-bottom: 10px;
    margin-left: 15px;
    font-size: 13px;
    color: grey;
}





@media (max-width: 1000px) {
    .superchat-open-button {
        right: 55px !important;
    }

    .superchat-modal-backdrop {
        z-index: 10000;
    }
    
}

  .login {
    font-family: 'Jost', sans-serif;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    background-color: var(--black);
    position: relative;
    font-family: 'Jost';
  }

  .references {
    display: flex;
    position: fixed;
    bottom: 50px;
  }

  .references > p {
    margin: 0 30px;
    color: rgb(77, 77, 77);
    text-transform: uppercase;
    font-size: 13px;
  }

  .login {
    position: relative;
    width: 100%;
    min-height: 100vh;  /* Stellen Sie sicher, dass der Container die volle Höhe des Viewports einnimmt */
    overflow: hidden;
}



/* In Ihrer SignUp.css */
.login-message.success {
  color: green;
}

.login-message.error {
  color: rgb(236, 73, 73);
}

.verify_mail_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.verify_mail_container > h1 {
  margin-top: 50px;
  color: rgb(255, 255, 255);
  margin-bottom: 20px;
  font-size: 42px;
  margin: 50px 30px 20px 30px;
}

.verify_mail_container > div > img {
  width: 50px;
  margin-bottom: 10px;
  filter: invert(1);
  opacity: 0.9;
}

.verify_mail_container > div {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  padding: 0px;
  border-radius: 100px;
  height: 120px;
  width: 120px;
  margin-bottom: -30px;
  margin-top: 30px;
}

.login-input:focus {
  outline: none; /* Entfernt den blauen Rahmen */
}


.verify_mail_container > p {
  line-height: 200%;
  color: rgb(255, 255, 255);
  margin: 0px 30px 20px 30px;

}


.mail-icon {
  opacity: 0.6;
  filter: invert(1);
}

.lock-icon {
  opacity: 0.6;
  filter: invert(1);
}

.about-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(53, 53, 53);
  text-decoration: none;
  border-radius: 100px;
  position: absolute;
  top: 30px;
  right: 30px;
  width: 140px;
  height: 35px;
  font-size: 14px;
}

.header-about-icon {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 150px;
}

@keyframes slideIn {
  0% {
      transform: translateY(0%);
      opacity: 0;
  }
  5% {
    transform: translateY(20px);
    opacity: 0.0;
}

  100% {
      transform: translateY(0);
      opacity: 1;
  }
}


.login-container {
    padding: 20px;
    border-radius: 28px;
    max-width: 400px;
    width: 100%;
    min-height: 500px;
    border: 1px solid rgba(255, 255, 255, 0.116); 
    backdrop-filter: blur(30px);  /* Hier wird der Effekt angewendet */
    transform: translateY(100%); /* This moves the element off the screen at the bottom */
    transition: transform 0.5s ease-out; /* This adds a transition to the transform property */
    opacity: 0; /* This makes the element invisible */
    transition: opacity 0.5s ease-out, transform 0.5s ease-out; /* This adds a transition to the opacity and transform properties */
    animation: slideIn 0.2s forwards;
    color: rgb(104, 104, 104);
    background-color: rgba(32, 32, 32, 0.356);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; }
  

  .login-btn:hover {
background-color: rgb(247, 247, 247) !important; 
  }
  
.login-header {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 15px;
    margin-top: 20px;
    font-size: 36px;
    font-family: 'Wide', 'Jost', 'Josefin Sans', sans-serif;
    color: white;
    
}

.login-header-fonts {
  margin: 0 20px;
}

.signup-screen > p {
  color: var(--white) !important;
  margin: 0 15px;
}

.sign-in-mini-font {
  font-size: 12px;
  margin-bottom: 10px !important;
  color: rgb(206, 205, 205);
}

.toggle-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  position: absolute;
  top: -85px;
  right: 150px;
  
}
.toggle-buttons > div {
  background-color: var(--blue);
  padding: 5px 10px;
  border-radius: 100px; 
  border: 1px solid rgb(78, 78, 78); 
  backdrop-filter: blur(70px);  /* Hier wird der Effekt angewendet */
  transform: translateY(100%); /* This moves the element off the screen at the bottom */
  transition: transform 0.5s ease-out; /* This adds a transition to the transform property */
  opacity: 0; /* This makes the element invisible */
  transition: opacity 0.5s ease-out, transform 0.5s ease-out; /* This adds a transition to the opacity and transform properties */
  animation: slideIn 0.4s forwards;
  color: white;
  background-color: rgba(0, 0, 0, 0.096);
}

.toggle_button_1.active {
  font-weight: 700;
  color: white; /* oder eine andere Hervorhebungsfarbe */
}

.toggle_button_1 {
  color: white;
  background-color: transparent;
  border: none;
  font-family: 'Jost';
  margin-bottom: 2px;

}

.toggle_button_1:hover {
  cursor: pointer;
}

  .signup-screen > p {
      text-align: center;
      margin-bottom: 30px;
  }
  
  .login-label {
    display: block;
    margin-bottom: 6px;
    font-weight: 500;
    font-size: 11px;
    margin-left: 10px;
    color: rgb(255, 255, 255) !important;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    color: var(--blue);
    text-align: left;
  }
  
  .login-input {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: transparent;
    font-size: 14px;
    letter-spacing: 0.1px;
    margin-left: 5px;
    font-weight: 500;
    font-family: 'Jost';
    color: rgb(255, 255, 255) !important;
  }

  .login-input::placeholder {
 color: rgb(255, 255, 255);
  }

  
  .login-btn-1 {
    width: 100%;
    background-color: rgba(7, 7, 7, 0.192);
    color: rgb(233, 233, 233);
    font-family: 'Jost';
    border: none;
    height: 50px;
    padding: 12px 15px;
    border-radius: 100px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s;
    margin-bottom: 10px;
    margin-top: 20px;
    transition: ease-in-out 100ms;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @keyframes shine {
    0% {
      left: -50%;
    }
    50% {
      left: 100%;
    }
    100% {
      left: -50%;
    }
  }
  
  .active-button-3 {
    background-color: var(--red); /* Ersetze --red durch einen spezifischen Hex-Farbcode, falls var(--red) nicht definiert ist */
    position: relative;
    overflow: hidden; /* Wichtig, um sicherzustellen, dass der "Shine"-Effekt innerhalb des Buttons bleibt */
    color: #fff; /* Textfarbe, falls benötigt */
    border: none; /* Entferne Rahmen, falls vorhanden */
    cursor: pointer; /* Handcursor als Zeichen der Interaktivität */
    transition:  ease-in-out 100ms;
  }
  
  .active-button-3 > p {
    z-index: 1000;
  }


  
  

    
  .login-btn-2 > img {
    width: 30px;
    z-index: 1000;
  }

  .login-btn-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: rgb(54, 54, 54);
    border: 1px solid rgb(255, 255, 255);
    padding: 12px 15px;
    border-radius: 100px;
    cursor: pointer;
    height: 50px;
    font-size: 14px;
    transition: background-color 0.2s;
    margin-bottom: 10px;
    transition: ease-in-out 100ms;
    background-color: rgb(248, 248, 248) !important;
  }

  .login-btn-3 {
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    color: white;
    border: none; 
    padding: 12px 15px;
    border-radius: 10px;
    height: 50px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s;
    text-decoration: underline;
    margin-top: 20px;
    transition: ease-in-out 100ms;
    ;
  }



  .login-btn-4 {
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    color: white;
    border: none; 
    padding: 12px 15px;
    height: 50px;
    border-radius: 10px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.2s;
    text-decoration: none;
    transition: ease-in-out 100ms;
    text-align: center;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -12px; 
    right: 22px;
  }

  .login-btn-5 {
    background-color: rgba(255, 255, 255, 0);
    color: rgb(255, 255, 255);
    border: none; 
    padding: 12px 15px;
    border-radius: 10px;
    padding: 10px 15px;
    cursor: pointer;
    text-decoration: underline !important;
    font-size: 14px;
    transition: background-color 0.2s;
    text-decoration: none;
    margin-top: 0px;
    height:30px;
    transition: ease-in-out 100ms;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

 

  .active-button-3:hover {
    transform: scale(1.01);
  }

  .login-btn-2:hover {
    transform: scale(1.01);
  }

  .login-btn-3:hover {
    transform: scale(1.01);
    background-color: rgba(255, 255, 255, 0) !important;
    color: white !important;
  }

  #forgot-input-relative {
    position: relative;
  }

  .login-input-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgb(180, 180, 180);
      height: 45px;
      position: relative;
      margin-bottom: 12px;
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 0) !important;
      transition: ease-irgba(255, 255, 255, 0.863)
  }

  
  .minispinner {
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-top: 2px solid #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: minispin 1s linear infinite;
  }
  
  @keyframes minispin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  


  .login-input-wrapper.error {
    border: 1px solid red;
    background-color: rgba(128, 0, 0, 0.158) !important;

  }
  
  .login-input-wrapper.valid {
    border: 1px solid white;
    background-color: rgba(0, 128, 0, 0.082) !important;
  }
  
  .check-icon-1 {
    filter: brightness(0) saturate(100%) invert(61%) sepia(25%) saturate(5098%) hue-rotate(71deg) brightness(96%) contrast(78%);    width: 20px !important;
  }


  .login-input::placeholder {
    color: rgb(228, 228, 228);
    font-weight: 400;
  }


  .login-input-wrapper-passive {
    background-color: rgba(255, 255, 255, 0);
}

  

  .login-input-wrapper:hover {
    background-color: rgba(255, 255, 255, 0.144);

  }
  
  .login-input-wrapper > img {
      width: 15px;
      margin-right: 15px;
      filter: invert(1);
  }

  .login-btn:hover {
    background-color: #0056b3;
  }
  
  .login-btn:disabled {
    background-color: #d1d1d1;
    cursor: not-allowed;
  }
  
  .login-message {
    color: rgb(236, 73, 73);
    text-align: center;
    margin-top: 20px;
    font-weight: 500;
  }

  .login-header-fonts > p {
    color: white;
  }

  
  .login-link {
    text-decoration: none;
    color: #007bff;
    display: inline-block;
    margin: 10px 0;
  }
  
  .login-link:hover {
    text-decoration: underline;
  }
  

  @media (max-width: 1180px) {
    .references {
      display: none;
    }
  } 

  @media (max-height: 700px) {
    .references {
      display: none;
    }
  } 
  

  @media (max-width: 780px) {

    .references {
      display: none;
    }

    .header-about-icon {
      z-index: 100;
      width: 100px;
      top: 15px;
      left: 15px;
    }

    .login-header {
      margin-top: 60px;
    }

 

    .toggle-buttons {
      right: 0px;
      top: -20px;
      z-index: 100;
      position: absolute;
    }


    .login-form {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .login-form > div {
      width: 92%;
    }

    .toggle_button_1 {
      font-size: 14px;
    }

   

    .toggle-buttons > div {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      background-color: var(--black);
      padding: 5px 30px 5px 10px;
      border: none;
    }

    .login-btn-4 {
display: none;

    }

    .login-btn-1 {
      width: 92%;
    }

    .login-btn-2 {
      width: 92%;
    }
 
 

.login-container {
    border-radius: 0px;
    width: 100%;
    background-color: transparent;
    min-height: 100vh;
    padding: none;
    border: none; 
    box-shadow: unset;
    max-width: none;
}

  }
/* SongAwardInfo.css */
.song-award-info-wrapper {
    height: 70px;
    background-color: var(--black);
    overscroll-behavior-y: none !important; /* Verhindert vertical rubber banding */

}

.song-award-info > img {
    width: 40px;
    height: 40px;
    z-index: 1005;
    margin-right: 10px;
}

.song-award-info > div {
    text-align: left;
}

.song-award-info > div > h3 {
    margin-bottom: 0px;
    font-family: 'Wide', 'Jost', 'Josefin Sans', sans-serif;
    letter-spacing: 0.5px;
}

.song-award-info > div > p {
    margin-top: 0px;
    font-weight: 200;
    letter-spacing: 1px;
    font-size: 13px;
}


.song-award-info {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--black);
    border-bottom: 1px solid rgb(68, 68, 68);
    top: 55px;
    z-index: 1000;
    width: 100%;
    color: white;
    position: fixed;
  }
  
  @media (max-width: 750px) {
    .song-award-info {
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--black);
        border-bottom: 1px solid rgb(68, 68, 68);
        top: 0px;
        z-index: 1000;
        width: 100%;
        color: white;
        position: fixed;
      }
      
  }
.settings-service-selection-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .service-settings-item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 110px;
    margin: 10px 0;
    border: 1px solid rgba(204, 204, 204, 0);
    border-radius: 15px;
    background-color: #ffffff05;
    cursor: pointer;
    opacity: 1;
  }

  

  .service-settings-item.selected {
    background-color: #0000004d;
    animation: fade-in-3 0.15s;
    border-color: rgba(136, 136, 136, 0.87);
  }

  @keyframes fade-in-3  {
      0% {
          opacity: 0;
          transform: scale(1.02);
      }
      100% {
        opacity: 1;
        transform: scale(1.0);
    }
  }

  .service-settings-item.selected >  .service-settings-image {
      opacity: 1;
  }
  

  
  .service-settings-image {
    width: 50px;
    height: 50px;
    margin-right: 20px;
    margin-left: 10px;
    filter: invert(1);
    opacity: 0.5;
  }
  
  .service-settings-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: white;
    font-family: 'Jost';
    text-align: left;
  }
  
  .service-settings-name {
    font-size: 18px;
    margin-bottom: 0px;
    margin-top: 10px;
  }
  
  .service-settings-description {
    font-size: 14px;
    color: rgb(182, 182, 182);
    margin-top: 5px;

  }
  
  .service-type-toggle {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .service-type-toggle-button {
    padding: 10px 20px;
    margin: 0 10px;
    font-size: 13px;
    border: none;
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid rgb(59, 59, 59);
    border-radius: 100px;
    cursor: pointer;
    font-weight: 500;
    font-family: 'Jost';
    animation: fade-in-2 1s;
  }
  
  .service-type-toggle-button.active {
    background-color: var(--blue);
    color: white;
    border: 1px solid var(--blue);
  }
  
.dashboardNav {
  width: 200px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 4000;
  height: 100vh;
  background-image: linear-gradient(
    to left, 
    #242424 0%, /* Beginnt am rechten Rand */
    #242424 3%, /* #242424 endet bei 33% */
    var(--black) 97% /* var(--black) beginnt bei 66% und füllt den Rest */
  ); 
  color: white;
  padding: 0px;
  box-sizing: border-box;
  transition: transform 0.2s ease; /* Optional, falls du auch eine Rücktransformation möchtest */
}

.total-unread-messages-menu-1 {
  position: absolute;
  bottom: 20px;
  color: white;
  font-family: 'Jost';
  background: var(--red);
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 130px;
}


.profileSection {
  text-align: left;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: 26px;
  width: 174px;
  margin-left: 12px;
  padding: 10px 0;
}

.profile_back_logo_icon {
  width: 12px;
  filter: invert(1);
  padding: 7px;
  border-radius: 100px;
  background-color: rgba(255, 255, 255, 0.541);
  border: 1px solid rgb(185, 185, 185);
  margin-right: 10px;
}

.profile_logo_img {
  width: 100px;
}

.profileSection-2 {
  text-align: center;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
  border: 1px solid rgba(78, 78, 78, 0.555);
  width: 174px;
  height: 160px;
  margin-left: 12px;
  border-radius: 25px;
  position: absolute;
  bottom: 0;
}

.userAvatar {
  width: 55px !important;
  height: 55px !important;
  border-radius: 50%;
  object-fit: cover; /* Stellt sicher, dass das Bild das div vollständig ausfüllt */
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 0px;
  border: 1px solid rgb(255, 255, 255);
  transition: 100ms ease-in-out;
}

.userAvatar:hover {
  transform: scale(1.02);
}

.profile-nav-label {
  width: 100%;
  text-align: left;
  margin-top: 30px;
  margin-bottom: -15px;
  margin-left: 10px;
}



.profile-nav-item.hasRoute {
  color: white;
}

.profile-nav-label > h4 {
  font-weight: 400;
  letter-spacing: 1px;
  color: grey;
}

.profile-nav-item-link-box {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  text-decoration: none !important;
  margin-top: 10px;
  z-index: 100;
  min-height: 60px;
  transition: transform 0.1s ease; /* Optional, falls du auch eine Rücktransformation möchtest */
}

@keyframes activateRoute {
  from {
    transform: translateX(4px);
  }
  to {
    transform: translateX(0);
  }
}

.profile-nav-item-link-box.hasRoute {
  background-color: var(--black);
  position: relative;
  animation: activateRoute 0.1s forwards; /* Nutzt die definierte Animation */
}

.profile-nav-item-image-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px !important;
  border-radius: 100px;
  overflow: hidden;
  margin-right: 10px;
  margin-left: 10px;
}

.dashboardNav-curve {
  position: absolute;
  right: 0; /* Ausrichtung am rechten Rand */
  width: 20px; /* 15cm in Pixel, oder passen Sie die Größe nach Bedarf an */
  height: auto; /* Erhält das Seitenverhältnis */
  filter: brightness(0) saturate(100%) invert(8%) sepia(0%) saturate(1348%)
    hue-rotate(170deg) brightness(96%) contrast(84%);
}

.dashboardNav-curve-2 {
  position: fixed;
  top: 59.5px;
  filter: brightness(0) saturate(100%) invert(11%) sepia(94%) saturate(0%)
    hue-rotate(218deg) brightness(99%) contrast(97%);
  z-index: 1000;
  transform: rotate(180deg);
  left: 319px; /* Ausrichtung am rechten Rand */
  width: 30px !important; /* 15cm in Pixel, oder passen Sie die Größe nach Bedarf an */
  height: 30px !important; /* Erhält das Seitenverhältnis */
}

.dashboardNav-curve.top {
  top: 20px;
  right: -20px;
  transform: translateY(-100%) rotate(180deg); /* Optional, wenn das SVG außerhalb des Containers sein soll */
}

.dashboardNav-curve.bottom {
  bottom: 20px;
  right: -20px;
  transform: translateY(100%) rotate(90deg); /* Flippt das untere SVG */
}

.menu-triangle {
  display: none; /* Standardmäßig versteckt */
  position: absolute;
  width: 17px; /* 15cm in Pixel */
  height: auto; /* Erhält das Seitenverhältnis */
  right: 0;
  filter: brightness(0) saturate(100%) invert(8%) sepia(1%) saturate(49%)
    hue-rotate(314deg) brightness(98%) contrast(101%);
}

.menu-triangle.top {
  top: 0.5px;
  transform: translateY(
    -100%
  ); /* Positioniert das SVG außerhalb des Containers */
}

.trophy-nav-icon {
  filter: invert(1) !important;
  width: 16px !important;
}

.settings-nav-icon {
  filter: invert(1) !important;
  width: 18px !important;
}

.menu-triangle.bottom {
  bottom: 0.5px;
  transform: translateY(100%) rotate(270deg); /* Flippt und verschiebt das SVG */
}

.profile-nav-item-link-box.hasRoute .menu-triangle {
  display: block; /* Zeigt das SVG nur an, wenn der Container aktiv ist */
}

.profile-nav-item-image-box > img {
  width: 25px;
}

.profile-nav-item-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  text-align: left;
}

.profile-nav-item {
  text-align: left;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.3px;
  margin: 5px 0;
  display: flex;
  align-items: center;
  height: 50px;
  color: rgb(185, 185, 185);
  text-decoration: none !important;
}

.profile-nav-item:hover {
  color: white;
}

.userName {
  color: white;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: -5px;
  margin-top: 0px;
}

.profile-nav-item-image-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes blueGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.profile-nav-item > span {
  background: linear-gradient(270deg, var(--blue), var(--black));
  background-size: 400% 400%;
  color: white;
  animation: blueGradient 5s ease infinite;
  padding: 3px 6px;
  font-size: 8px;
  border-radius: 100px;
  letter-spacing: 1px;
  margin-left: 7px;
  border: 1px solid rgba(124, 124, 124, 0.158);
}




.userSettings {
  color: white;
  font-size: 12px;
  display: flex;
  font-weight: 400;
}

.profile-section-header-text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 0px;
  margin-bottom: 5px;
}

.profile-section-header-text:hover {
  text-decoration: underline;
  text-decoration-color: white;
}

.navLinks {
  list-style-type: none;
  padding: 0;
}

.navLinks li a {
  color: white;
  text-decoration: none;
  display: block;
  padding: 10px;
  transition: background-color 0.3s;
}

.navLinks li a:hover {
  background-color: #333; /* Hover-Effekt */
}

@media (max-width: 1300px) {
  .dashboardNav-curve-2 {
    left: 269px; /* Ausrichtung am rechten Rand */
  }
}

@media (max-width: 900px) {
  .dashboardNav-curve-2 {
    display: none;
  }
}

@media (max-width: 750px) {
  .dashboardNav {
    display: none;
  }
}

@media (max-height: 580px) {
  .profileSection-2 {
    display: none;
  }
}

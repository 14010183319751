
.sell-art {
    padding-top: 10px;
    color: white;
    font-size: 22px;
    text-align: left;
    overflow: hidden;
    padding-bottom: 10px;
    background-color: rgba(39, 39, 39, 0.322);


}

.sell-art > h3 {
    font-weight: 300;
    margin-left: 70px;
    font-family: 'Wide';
    font-size: 14px;
    margin-bottom: 30px;
    color: grey;
}


.sell-art-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px; /* Abstand zwischen den Boxen */
    padding: 0px 0px 40px 0px;
    color: white;
    text-align: left;
    align-items: center;
  }

  .forward_icon_sell {
    position: absolute;
    width: 30px;
    top: 10px;
    right: 10px;
    filter: invert(1);
  }
  
  .sell-art-item {
    width: calc(90% - 40px); /* Breite der Box */
    height: 120px; /* Höhe der Box */
    display: flex;
    align-items: center;
    backdrop-filter: blur(50px);
    position: relative;
    padding: 20px;
    gap: 20px; /* Abstand zwischen Icon und Text */
    background-color: rgba(24, 24, 24, 0.445);
    z-index: 10;
    border-bottom: 0px !important;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    margin-top: -30px;
    border: 1px solid rgb(49, 49, 49);
    box-shadow: rgba(0, 0, 0, 0.3) 10px -19px 38px, rgba(0, 0, 0, 0.12) 0px -15px 12px;
  }

  .sell-art-item:hover {
    background-color: rgb(12, 12, 12);
    cursor: pointer;
  }

  .service-title-link {
    color: inherit; /* Verwendet die Textfarbe des Elternelements */
  }

  .sell-art-item:first-child {
    box-shadow: rgba(0, 0, 0, 0.05) 10px -19px 38px, rgba(0, 0, 0, 0.12) 0px -15px 12px;
    margin-top: 0px;
  }

  .sell-art-item:nth-child(2) {
    margin-top: -40px;
  }

  .sell-art-item:nth-child(3) {
    margin-top: -40px;
  }

  .sell-art-item:nth-child(4) {
    margin-top: -40px;
  }
  
  

.red-dot-countainer {
    background-color: var(--red);
    width: 150px;
    height: 150px;
    border-radius: 100px;
    position: absolute;
    z-index: 0;
    top: 0%;
    backdrop-filter: blur(100px); /* Weichzeichnen des Hintergrunds */
    filter: blur(35px);

}

.service-title-link {
    color: inherit; /* Verwendet die Textfarbe des Elternelements */
    text-decoration: none; /* Entfernt die Unterstreichung von Links */
  }

.service-title-link:hover {
    text-decoration: underline; /* Fügt eine Unterstreichung beim Hover hinzu */
  }
  

@keyframes moveHorizontally {
    0%, 100% {
        transform: translateY(-30%); 
        transform: translateX(-30%); /* Beginnt in der Mitte */
    /* Beginnt in der Mitte */
    }
    50% {
        transform: translateY(30%); /* Bewegt sich nach links oder rechts */
    }
}

  .service-icon {
    width: 60px; /* Größe des Icons */
    height: 60px;
    margin-right: 10px;
    margin-left: 20px;
    filter: invert(1);
  }
  
  .service-text {
    border-left: 1px solid rgb(141, 141, 141); /* Trennlinie */
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100px;
  }
  
  .service-text h4 {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
  }
  
  .service-text p {
    margin: 5px 10px 0 0;
    font-size: 16px;
    font-weight: 200;
  }


  @media(max-width: 700px) {
    .sell-art > h3 {
      font-weight: 300;
      margin-left: 30px;
      font-family: 'Wide';
      font-size: 14px;
      margin-bottom: 30px;
      color: grey;
  }
  }
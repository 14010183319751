.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050; /* Stellen Sie sicher, dass dies über anderen Elementen liegt */
  }

  .pupu-checkbox {
      background-color: aqua;
  }

  .cancel-button {
      height: 50px;
      border: 0;
      margin-top: -10px;
      border-radius: 50px;
  }
  
  .edit-artist-modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 500px; /* An Ihre Bedürfnisse anpassen */
  }
  
  .edit-artist-modal .add-song-form {
    display: grid;
    grid-gap: 20px;
  }
  
  .edit-artist-modal .awarded-boxes {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    padding: 10px;
    font-size: 12px;
  }
  
  .edit-artist-modal .checkbox-label {
    display: flex;
    align-items: center;
  }
  
  .edit-artist-modal .checkbox-label input {
    margin-left: 10px;
  }


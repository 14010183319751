.tier-header-top {
    width: 500px;
    height: 100px; /* Erhöhte Höhe für Text unterhalb des Containers */
    color: #fff; /* Weiße Schrift */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
    justify-content: center;
  }
  
  .steps-container {
    display: flex;
    justify-content: space-between;
    width: 80%;
    padding: 10px;
    border: 1px solid rgb(97, 97, 97);
    border-radius: 100px;
    margin-bottom: 50px;
    background-color: var(--gray);
  }
  
  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .number-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgb(10, 10, 10); /* Dunklere Farbe für inaktive Kugeln */
    color: #ccc; /* Hellerer Text für inaktive Kugeln */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: 'Jost';
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }
  
  .labels-container {
    display: flex;
    justify-content: space-between;
    width: 84%;
    margin-bottom: 15px;
    font-family: 'Jost';
  }
  
  .step-label {
    font-size: 12px;
  }
  
  .active .number-circle {
    background-color: var(--blue); /* Helle Farbe für die aktive Kugel */
    color: #fff;
  }
  


  @media (max-width: 800px) {
    .steps-container {
      width: 60%;
    }
    .labels-container {
      width: 65%;
    }
  }

  @media (max-width: 380px) {
    .steps-container {
      width: 50%;
    }
    .labels-container {
      width: 55%;
    }
  }


  @media (max-width: 300px) {
    .steps-container {
      width: 40%;
    }
    .labels-container {
      width: 45%;
    }
  }
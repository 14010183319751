/* Container für die gesamte Promo-Text-Komponente */
.promo-text-container {
    padding: 10px 15px;
    border-radius: 8px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Add New Button */
.promo-add-new-button {
    color: rgb(179, 179, 179);
    padding: 10px 10px;
    height: 60px;
    font-family: 'Jost';
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    background: none;
    border: 1px solid rgba(94, 94, 94, 0.418);
    border-radius: 100px;
    font-size: 16px;
    margin-bottom: 15px;
    cursor: pointer;
    transition: background-color 0.05s ease;
}

.promo-add-new-button:hover {
    color: white;
    background-color: black;
    border: 1px solid rgba(175, 175, 175, 0.418);
}

.promo-add-new-button > img {
    width: 24px;
    margin-left: 10px;
    filter: invert(1);
    opacity: 0.6;
}
.promo-title-input {
    background-color: var(--black) !important;
    border: 1px solid rgba(92, 92, 92, 0.404) !important;
    height: 50px;
    color: white;
    border-radius: 12px;
    font-family: 'Jost';
    padding: 0 0px 0 15px !important;
    font-size: 16px;
    width: 550px !important;
}

.promo-add-new-button:hover > img {
    width: 24px;
    margin-left: 10px;
    filter: invert(1);
    opacity: 1;
}

.promo-text-title {
    margin: 0 0 10px 0;
    font-size: 20px;
}

/* Editor für neuen oder bearbeiteten Promo-Text */
.promo-text-editor {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.promo-text-input {
    width: 400px !important;
    height: 80px;
    padding: 10px;
    border-radius: 15px;
    font-size: 14px;
    resize: none;
    outline: none;
}

/* Vorschau-Bild und PDF-Symbol */
.promo-preview-image, .promo-preview-pdf {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    margin-right: 10px;
}

.promo-preview-pdf {
    width: 40px;
    height: 40px;
}

/* Liste der gespeicherten Promo-Texte */
.promo-text-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.promo-text-item {
    display: flex;
    min-height: 85px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 15px;
    background-color: rgba(73, 73, 73, 0.226);
    border: 1px solid rgba(102, 102, 102, 0.24);
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.promo-text-preview {
    align-items: center;
    text-align: left;
    width: 80%;
}

.promo-text-content {
    font-size: 14px;
    margin: 0;
    margin-right: 10px;
    color: rgb(163, 163, 163);
    margin-top: 5px;
}

.promo-action-buttons {
    width: 150px;
}



/* Edit-Button */
.promo-edit-button {
    position: absolute;
    color: white;
    background: none;
    padding: 6px 12px;
    border-radius: 100px;
    right: 10px;
    top: 10px;
    font-size: 14px;
    border: 1px solid rgba(255, 255, 255, 0.226);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.promo-edit-button:hover {
    border: 1px solid rgba(255, 255, 255, 0.555);
}

.promo-send-button > img {
    width: 23px;
    rotate: 45deg;
    margin-left: 10px;
    margin-bottom: 2px;
}



/* Save/Update Button */
.promo-save-button {
    background-color: var(--blue);
    color: white;
    padding: 10px 20px;
    width: 100%;
    height: 50px;
    font-family: 'Jost';
    border: none;
    border-radius: 12px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.promo-send-button:hover {
    border: 1px solid white;

}

.promo-send-button {
    background-color: var(--black);
    color: white;
    border: 1px solid var(--black);
    height: 50px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 10px 15px;
    margin-left: 10px;
    width: 110px;
    display: flex;
    align-items: center;
    font-family: 'Jost';
    justify-content: center;
    border-radius: 100px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.promo-delete-button > img {
    width: 35px;
}

.promo-delete-button {
    background: none;
    border: none;
}

.promo-delete-button:hover {
    cursor: pointer;
}

.promo-text-editor-buttons {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.promo-cancel-button {
    background-color: var(--black);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius:14px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.promo-cancel-button:hover {
    transform: scale(1.03);
}




/* Responsive Anpassungen */
@media (max-width: 600px) {
    .promo-text-container {
        padding: 15px;
    }

    .promo-add-new-button, .promo-save-button, .promo-edit-button {
        font-size: 14px;
        padding: 8px 16px;
    }

    .promo-text-input {
        height: 60px;
    }

    .promo-preview-image, .promo-preview-pdf {
        width: 30px;
        height: 30px;
    }
}

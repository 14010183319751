.watch-video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0px 20px 60px 20px;
    margin-bottom: 20px;
    background-color: var(--black);
  }

  .watch-video-container > h3 {
      color: white;
      text-align: left;
      width: 100%;
      margin-left: var(--edge);
      font-weight: 300;
      font-size: 22px;
  }
  
  .watch-video-container iframe {
    width: 98%;
    max-width: 100%; /* Stellt sicher, dass der Iframe auf kleinen Geräten nicht über die Bildschirmbreite hinausgeht */
    height: 650px; /* Oder jede andere feste Höhe, oder verwenden Sie 'auto' für eine automatische Höhe */
    border-radius: 20px; /* Abgerundete Ecken für den Iframe */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Etwas Schatten für Tiefe */
    border: none; /* Entfernen der Standardrahmen */
    aspect-ratio: 16 / 9; /* Moderner Weg, das Seitenverhältnis beizubehalten */
  }
  
  @media (max-width: 800px) {
    .watch-video-container iframe {
      width: 98%;
      max-width: 100%; /* Stellt sicher, dass der Iframe auf kleinen Geräten nicht über die Bildschirmbreite hinausgeht */
      height: 300px; /* Oder jede andere feste Höhe, oder verwenden Sie 'auto' für eine automatische Höhe */
      border-radius: 20px; /* Abgerundete Ecken für den Iframe */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Etwas Schatten für Tiefe */
      border: none; /* Entfernen der Standardrahmen */
      aspect-ratio: 16 / 9; /* Moderner Weg, das Seitenverhältnis beizubehalten */
    }
  }
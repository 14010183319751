.gig-equipment-selector-wrapper > select {
    width: 93%;
    height: 54px;
    background-color: var(--black);
    color: white;
    border-radius: 7px;
    padding-left: 10px;
    font-family: 'Jost';
    font-size: 16px;
    margin-bottom: 20px;
    outline: none;
}



.gig-equipment-selector-wrapper {
    width: 90%;
    margin-left: 5%;
    margin-top: 30px;
    margin-right: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--black);
    border-radius: 15px;
    flex-direction: column;
    padding: 20px 0;
}

.page-add-equipment-button {
    width: 94%;
    height: 50px;
    background-color: var(--blue);
    border: none;
    border-radius: 10px;
    color: white;
    font-family:'Jost';
}

.page-add-equipment-button:hover {
    cursor: pointer;
}

.equipment-preview-settings {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    width: 90%;
    position: relative;
    margin-left: 5%;
    margin-right: 5%;
    border-bottom: 1px solid rgb(102, 102, 102);
    padding-bottom: 5px;
    padding-top: 5px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    margin-top: 10px;
}

.equipment-preview-settings > p {
    padding: 0px;
    margin: 5px;
    max-width: 200px;
}

.equipment-preview-settings > button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: none;
    background-color: var(--red);
    border-radius: 100px;
    top: 5px;
    right: 5px;

}

.equipment-preview-settings > button:hover {
    cursor: pointer;
}

.equipment-preview-settings > button > img {
    width: 10px;
    filter: invert(1);
}
/* Hauptcontainer für die Publikationen */
.read-more {
    background-color: var(--black);
    padding-top: 10px;
    padding-bottom:10px;
}

.read-more > h3 {
    text-align: left;
    margin-left: var(--edge);
    color: white;
    font-size: 22px;
    font-weight: 400;
}

.publication-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    color: white;
}

.publication-text > p {
    text-align: left;
    width: 90%;
    color: white;
}

.publication-text > h5 {
    text-align: left;
    width: 90%;
    color: rgb(182, 182, 182);
    margin-bottom: 20px;
    margin-top: 0px;
}

.publication-text > a{
    text-align: left !important;
    width: 90%;
    color: white;
    text-decoration: none;
    font-size: 12px;
}

.forward_icon {
    position: absolute;
    top: 10px;
    width: 30px !important;
    height: 30px !important;
    right: -10px;
    filter: invert(1);
}


.publication-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Abstand zwischen den Publikationsblöcken */
    justify-content: flex-start;
    margin: var(--edge);
    background-color: var(--black);
    position: relative;
    overflow: hidden;
  }
  
  /* Einzelner Publikationsblock */
  .publication {
    display: flex;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    align-items: center;
    border: 1px solid rgb(82, 82, 82);
    padding: 20px;
    border-radius: 20px;
    position: relative; /* Für das absolute positionierte Icon */
    max-width: 500px;
    width: 40%;
    overflow: hidden;
    /* Max. Breite des Publikationsblocks */
  }
  
  /* Stil für das Bild */
  .pub-header-img {
    width: 150px;
    opacity: 0.8;
    height: 150px;
    border-radius: 15px;
    object-fit: cover; /* Damit das Bild seine Proportionen beibehält */
    margin-right: 20px; /* Abstand zwischen Bild und Text */
  }

  .publication-text > span {
    text-align: left !important;
    width: 90%;
  }

  
  /* Stil für den Textcontainer (Link und Text darunter) */

  
  /* Stil für das Icon in der oberen rechten Ecke */
  .forward_icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 24px; /* Größe des Icons */
    height: 24px;
  }
  
  /* Hier können Sie Media Queries hinzufügen, um die Darstellung auf kleineren Bildschirmen anzupassen */
  @media (max-width: 800px) {
    .publication {
      flex-direction: column;
      max-width: none;
      width: 100%;
    }

    .read-more > h3 {
      margin-top: 50px;

  }

.publication-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: var(--edge);
  background-color: var(--black);
  position: relative;

}

    .read-more {
      width: 100%;
  }


  .publication-text > h5 {
    text-align: left !important;
    width: 100%;
  }

  .publication-text > p {
    text-align: left !important;
    width: 100%;
  }

  .publication-text > span {
    text-align: left !important;
    width: 100%;
  }


    .pub-header-img {
      margin-right: 0;
      width: 100%;
      min-height: 220px;
      max-height: 400px;
      margin-bottom: 20px; /* Abstand zwischen Bild und Texten, wenn sie untereinander sind */
    }
  
    .forward_icon {
      top: 10px;
      right: 10px;
    }
  }
  
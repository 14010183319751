.category-label {
    display: flex;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 20px;

}

.category-label > span {
    margin-right: 10px;
    font-weight: 400;
    font-size: 15px;
}

.category-label > hr {
    flex-grow: 1;
    border: none;
    height: 1px;
    background-color: rgb(61, 61, 61);
}
/* General Dashboard Layout */
.coin-analysis-dashboard {
    font-family: "Inter", sans-serif;
    background-color: #121212;
    color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    max-width: 1200px;
    margin: 40px auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
}

/* Header Section */
.coin-analysis-dashboard-header {
    text-align: center;
    margin-bottom: 20px;
}

.coin-analysis-dashboard-header h1 {
    font-size: 28px;
    color: #ffffff;
    margin-bottom: 10px;
}

.coin-analysis-dashboard-header p {
    font-size: 16px;
    color: #bbbbbb;
}

/* Card Style */
.coin-analysis-dashboard-card {
    background-color: #1e1e1e;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
    margin-bottom: 30px;
}

.coin-analysis-dashboard-card h2 {
    font-size: 22px;
    margin-bottom: 20px;
    color: #64b5f6;
    border-bottom: 1px solid #333;
    padding-bottom: 8px;
}

/* Token Stats Section */
.coin-analysis-data-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
    font-size: 16px;
}

.coin-analysis-data-grid div {
    color: #ffffff;
    background-color: #2a2a2a;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}

.coin-analysis-data-grid div a {
    color: #64b5f6;
    text-decoration: none;
    font-weight: bold;
}

.coin-analysis-data-grid div a:hover {
    text-decoration: underline;
}

/* Analyze Button */
.coin-analysis-analyze-button-wrapper {
    text-align: center;
    margin-bottom: 30px;
}

.coin-analysis-analyze-button {
    background-color: #64b5f6;
    color: #121212;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.coin-analysis-analyze-button:hover {
    background-color: #42a5f5;
    transform: translateY(-2px);
}

.coin-analysis-analyze-button:disabled {
    background-color: #444;
    color: #aaa;
    cursor: not-allowed;
}

/* Chart Section */
.coin-analysis-dashboard-card canvas {
    max-width: 100%;
    background-color: #2a2a2a;
    border-radius: 8px;
}

/* Data Display Section */
.coin-analysis-data-grid div p {
    margin: 5px 0;
}

.coin-analysis-entry {
    background-color: #2a2a2a;
    color: #ffffff;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 15px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.4);
}

.coin-analysis-entry p {
    margin: 8px 0;
}

/* Responsive Design */
@media (max-width: 768px) {
    .coin-analysis-data-grid {
        grid-template-columns: 1fr;
    }

    .coin-analysis-dashboard-header h1 {
        font-size: 24px;
    }

    .coin-analysis-dashboard-card h2 {
        font-size: 18px;
    }

    .coin-analysis-analyze-button {
        font-size: 14px;
    }
}

.invest-holders-container {
  padding: 20px;
  background-color: #121212;
  color: #fff;
  font-family: 'Roboto', Arial, sans-serif;
  max-width: 1000px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
}

.invest-holders-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #333;
}

.invest-holders-title {
  font-size: 26px;
  font-weight: bold;
}

.invest-holders-add-button {
  background-color: #007bff;
  color: white;
  font-size: 24px;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.invest-holders-add-button:hover {
  background-color: #0056b3;
  transform: scale(1.1);
}

.invest-holders-list-container {
  background-color: #1f1f1f;
  border-radius: 10px;
  padding: 15px;
}

.invest-holders-item-container {
  margin-bottom: 15px;
  background-color: #252525;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease-in-out;
}

.invest-holders-item-container:hover {
  transform: translateY(-3px);
}

.invest-holders-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.invest-holders-info {
  display: flex;
  flex-direction: column;
}

.invest-holders-name {
  font-size: 20px;
  font-weight: bold;
}

.invest-holders-wallet {
  font-size: 14px;
  color: #aaa;
  word-break: break-all;
}

.invest-holders-actions {
  display: flex;
  gap: 10px;
}

.invest-holders-action-button,
.invest-holders-edit-button {
  width: 30px;
  height: 30px;
  background-color: #007bff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s, transform 0.2s;
}

.invest-holders-action-button:hover,
.invest-holders-edit-button:hover {
  background-color: #0056b3;
  transform: scale(1.1);
}

.invest-holders-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.invest-holders-modal-content {
  background-color: #1f1f1f;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.invest-holders-modal-input {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #333;
  border-radius: 5px;
  background-color: #2b2b2b;
  color: #fff;
}

.invest-holders-modal-actions {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.invest-holders-modal-button {
  flex: 1;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.invest-holders-modal-button.cancel {
  background-color: #555;
}

.invest-holders-modal-button:hover {
  background-color: #0056b3;
}

.invest-holders-modal-button.cancel:hover {
  background-color: #333;
}

/* Coins Section */
.invest-holders-coins-container {
  margin-top: 15px;
  background-color: #1a1a1a;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.invest-holders-coins-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.invest-holders-coin {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #333;
}

.coin-details {
  display: flex;
  align-items: center;
  gap: 15px;
}

.coin-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #444;
}

.coin-name {
  font-size: 16px;
  font-weight: bold;
  color: #f5f5f5;
}

.coin-symbol {
  font-size: 14px;
  color: #aaa;
}

.coin-amount {
  font-size: 14px;
  font-weight: bold;
  color: #00cc88;
}

.no-coins-message {
  color: #777;
  font-style: italic;
  margin: 10px 0;
  text-align: center;
}

/* Dark mode styling for PlayTreasure1 */
.play-treasure-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.play-treasure-overlay > div > div > img {
  width: 260px;
  margin-bottom: 10px;
  margin-top: -20px;
  transition: 140ms ease-in-out;
}

.spinneritto {
  border: 4px solid rgba(0, 0, 0, 0.1); 
  border-left-color: #000; 
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  display: inline-block;
  vertical-align: middle;
}

.play-treasure-overlay > div  > img {
  width: 270px;
  margin-bottom: -30px;
  margin-top: -20px;
  transition: 140ms ease-in-out;
}

.play-treasure-modal {
  background-color: var(--black); /* Dark background for the modal */
  padding: 40px;
  border-radius: 35px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  text-align: center;
  position: relative;
  width: 400px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.play-treasure-modal h1 {
  font-size: 32px;
  color: #f0f0f0; /* Light text color */
  margin-bottom: 0px;
  font-family: 'Wide';
  margin-top: 0px;
}

.play-treasure-modal p {
  font-size: 16px;
  color: #b0b0b0; /* Slightly dimmer text color */
  margin-bottom: 30px;
}

.play-treasure-modalo-button {
  background-color: var(--blue); /* Green for primary button */
  color: white;
  padding: 10px 10px;
  font-family: 'Jost';
  font-size: 18px;
  width: 100%;
  height: 60px;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  margin-bottom: 10px;
}

.play-treasure-modal button:hover {
  opacity: 0.9;
}

.play-treasure-modal button:disabled {
  background-color: #555;
  cursor: not-allowed;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.close-button:hover {
  opacity: 0.7;
}


/* Grundlegendes Dark Mode Styling */
.select-contact-container {
    color: #fff;
    border-radius: 8px;
    margin: auto;
    min-height: 85%;
}

.select-contact-header {
    text-align: center;
    margin-bottom: 20px;

}

.contact-toggle-div {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.158);
    margin: 10px 0;
    padding: 10px 15px;
    border-radius: 15px;
    margin-top: 10px;
}


.contact-toggle-div > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.contact-toggle-div > div > div {
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: flex-start !important;
}


.select-contact-label {
    display: block;
    margin: 10px 0;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 18px;
}

/* Styling für den Toggle-Slider */
.toggle-slider {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 40px;
    background-color: rgb(46, 46, 46);
    border-radius: 11px;
    transition: .4s;
}

.toggle-slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

.contact-toggle-img {
    width: 40px;
    margin-left: 10px;
    margin-right: 20px;
}

.contact-toggle-message-div > h3 {
    margin: 10px 0 0 0;
    padding: 0;
    text-align: left;
}

.contact-toggle-message-div > h5 {
    margin: 5px 0 10px 0;
    font-weight: 400; 
    font-size: 15px;
    padding: 0;
    text-align: left;
    color: grey;
}

input[type="checkbox"].toggle-input:checked + .toggle-slider {
    background-color: var(--blue);
}

input[type="checkbox"].toggle-input:checked + .toggle-slider:before {
    transform: translateX(18px);
}

input[type="checkbox"].toggle-input {
    opacity: 0;
    width: 0;
    height: 0;
}

.select-contact-input {
    width: 95%;
    height: 45px;
    padding: 0 10px;
    font-family: 'Jost';
    margin-top: 5px;
    border: none;
    border-radius: 7px;
    background-color: rgba(255, 255, 255, 0.062);
    color: white;
    font-size: 16px;
    outline: none;
    margin-bottom: 10px;
}


.select-contact-button {
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
}

.error-message {
    color: #f44336;
    text-align: center;
    margin-top: 15px;
}



@media (max-width: 800px) {
.select-contact-container {
    color: #fff;
    border-radius: 8px;
    margin: 0;
    min-height: 82vh;
    margin-top: 80px !important;
}
}
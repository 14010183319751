.audio-bar {
    position: fixed;
    bottom: 10px;
    border-radius: 25px;
    left: 15px;
    right: 15px;
    background-color: rgba(34, 34, 34, 0.678); /* oder jede andere gewünschte Farbe */
    display: flex;
    backdrop-filter: blur(40px);
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    z-index: 1000; /* Stellen Sie sicher, dass dies über anderen Elementen liegt */
  }
  
  .play-pause-btn {
    margin-right: 10px;
    /* Weitere Stilisierung für den Button */
  }
  
  .song-cover {
    width: 50px; /* Oder jede andere gewünschte Größe */
    height: 50px; /* Oder jede andere gewünschte Größe */
    margin-right: 10px;
  }
  
  .song-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 25%;
    color: white;
  }

.song-player-middle {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.time-display {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.time-display > p {
    font-size: 11px;
    color: lightgray;
}

.song-player-end {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

  .play-pause-button {
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 35px;
      border-radius: 100px;
      border: none;
      background-color: var(--black);
      margin-right: 10px;
      transition: 200ms ease-in-out;
      cursor: pointer;

  }

  .play-pause-button-4 {
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 45px;
    bottom: 10px !important;
    left: 5px !important;
    z-index: 100;
    border-radius: 100px;
    border: none;
    background-color: var(--black);
    margin-right: 10px;
    transition: 120ms ease-in-out;
    cursor: pointer;

}


.play-pause-button-4:hover {
  transform: scale(1.1);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

  .audio-bar-enter {
    transform: translateY(100%);
  }
  .audio-bar-enter-active {
    transform: translateY(0);
    transition: transform 500ms;
  }
  .audio-bar-exit {
    transform: translateY(0);
  }
  .audio-bar-exit-active {
    transform: translateY(100%);
    transition: transform 500ms;
  }


  .play-pause-button:hover {
      transform: scale(1.1);
  }

  .pause-icon , .play-icon {
      width: 12px;
      filter: invert(1);
      margin-left: 2px;
  }
  

  
  .song-img {
      width: 50px;
      height: 50px;
      border-radius: 5px;
      margin-right: 10px;
      object-fit: contain;

  }


.progress-bar {
    -webkit-appearance: none;
    width: 70%;
    height: 4px;
    border-radius: 2px;
    background: var(--gray);
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
    margin: 0px 10px;
  }

  
  .progress-bar::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: var(--red);
    cursor: pointer;
  }
  
  .progress-bar::-moz-range-thumb {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #1db954;
    cursor: pointer;
  }
  .volume-control {
    -webkit-appearance: none; /* Entfernt die Standard-Styles für WebKit-Browser */
    width: 100px; /* Breite des Reglers */
    height: 4px; /* Höhe des Reglers */
    background: #c9c9c9; /* Hintergrundfarbe des Reglers */
    cursor: pointer; /* Cursor beim Hover */
    margin: 10px 0; /* Abstand oben und unten */
    border-radius: 2px; /* Rundung der Ecken */
  }

  .speakers-icon {
      width: 15px;
      filter: invert(1);
      margin-right: 20px;
      margin-left: 15px;

  }
  
  .volume-control::-webkit-slider-thumb {
    -webkit-appearance: none; /* Entfernt die Standard-Styles für das Thumb-Element in WebKit-Browsern */
    height: 9px; /* Höhe des Thumb-Elements */
    width: 10px; /* Breite des Thumb-Elements */
    border-radius: 50%; /* Rundung des Thumb-Elements */
    background: var(--red); /* Hintergrundfarbe des Thumb-Elements */
    cursor: pointer; /* Cursor beim Hover */
  }
  
  .volume-control::-moz-range-thumb {
    height: 9px; /* Höhe des Thumb-Elements */
    width: 9px; /* Breite des Thumb-Elements */
    border-radius: 50%; /* Rundung des Thumb-Elements */
    background: #1db954; /* Hintergrundfarbe des Thumb-Elements */
    cursor: pointer; /* Cursor beim Hover */
  }
  
  .volume-control::-moz-range-track {
    background: #ffffff; /* Hintergrundfarbe des Tracks */
    height: 4px; /* Höhe des Tracks */
  }
  
  .volume-control::-ms-thumb {
    height: 9px; /* Höhe des Thumb-Elements */
    width: 9px; /* Breite des Thumb-Elements */
    border-radius: 50%; /* Rundung des Thumb-Elements */
    background: var(--red); /* Hintergrundfarbe des Thumb-Elements */
  }
  
  .volume-control::-ms-track {
    background: transparent; /* Hintergrundfarbe des Tracks */
    border-color: transparent; /* Entfernt die Border */
    color: transparent; /* Entfernt zusätzliche Farben */
  }
  
  .volume-control:focus {
    outline: none; /* Entfernt den Fokus-Rand */
  }
  


  @media (max-width: 700px) {

.song-player-end {
  display: none;
}
.song-info {
  width: 25%;
}

.song-player-middle {
  width: 220px;
  margin-right: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}
  }


  @media (max-width: 400px) {
    .song-player-middle {
      width: 180px;
      margin-right: 5%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
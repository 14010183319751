.gig-seller-infos {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Erstellt ein Gitter mit zwei gleich großen Spalten */
  margin-top: 10px;
  position: relative;
  margin-bottom: 15px;
  cursor: pointer; /* Zeiger, um Interaktivität anzuzeigen */
  transition: 150ms ease-in-out;
  border: 1px solid rgba(255, 255, 255, 0);
  border-radius: 10px;
  padding: 10px;

}

.gig-seller-info-pair {
  display: flex;
  flex-direction: column; /* Stapelt den Titel über den Wert */
  align-items: flex-start; /* Links ausgerichtete Elemente */
}

.gig-seller-info-title {
  font-size: 12px; /* Schriftgröße für Titel */
  font-weight: 600; /* Fett gedruckt */
  color: var(--color-oposite-4); /* Leicht dunkle Schriftfarbe für Werte */
  margin-bottom: 0px;
  text-transform: uppercase;
  user-select: none;  /* Verhindert die Auswahl des Textes */
  -webkit-user-select: none;  /* Verhindert die Auswahl des Textes für WebKit-Browser wie Safari und Chrome */
  -ms-user-select: none;  /* Verhindert die Auswahl des Textes für Internet Explorer */
  -moz-user-select: none; /* Abstand zwischen Titel und Wert */
}

.gig-seller-info-value {
  font-size: 18px; /* Schriftgröße für Wert */
  color: var(--color-oposite-2); /* Leicht dunkle Schriftfarbe für Werte */
  margin-top: 5px;
  font-weight: 300;
  margin-right: 10px;
  text-align: left;
  letter-spacing: 0.2px;
  user-select: none;  /* Verhindert die Auswahl des Textes */
  -webkit-user-select: none;  /* Verhindert die Auswahl des Textes für WebKit-Browser wie Safari und Chrome */
  -ms-user-select: none;  /* Verhindert die Auswahl des Textes für Internet Explorer */
  -moz-user-select: none;
}


.gig-seller-infos.is-active {
  padding: 10px;
  border: 1px solid rgb(133, 133, 133);
  border-color: rgb(196, 196, 196); /* Blue border when active */
  color: white; /* Text color changes when active */
}

.gig-seller-infos.settings-route:hover {
  cursor: pointer;
  transform: scale(1.03);
  box-shadow: var(--color-shadow-2);
}

.gig-seller-infos.settings-route:hover .edit-overlay {
  display: block;
}


@media (max-width: 800px) {
  .gig-seller-infos {
    padding: 0 20px;
  }
} 
.admin-add-song {
    max-width: 1000px;
    margin: auto;
    padding: 20px;
    background-color: var(--black);
    border: 1px solid white;
    border-radius: 8px;
    margin-top: 25px;
    color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .add-song-title {
    text-align: center;
    color: white;
  }
  
  .add-song-form {
    display: grid;
    grid-gap: 20px;
  }
  
  .file-upload-section {
    display: flex;
    justify-content: center;
    align-items: cneter;
    margin-bottom: 0px;
    background-color: rgb(0, 0, 0);
    padding: 20px 0;
    border: 1px solid grey;
  }
  
  .file-upload-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: 'Wide', 'Jost', 'Josefin Sans', sans-serif;
    text-align: center;
  }
  
  .checkbox-label {
      font-weight: 500;
  }
  .file-upload-input {
    margin-top: 10px;
  }
  
  .input-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
  
  .text-input,
  .genre-select,
  .nomination-checkbox,
  .award-checkbox,
  .remove-button,
  .add-button,
  .uploading-status {
    width: 90%;
    padding: 10px;
    border-radius: 4px;
    background-color: #000000;
    border: 1px solid grey;
    color: white;
  }
  
  .text-input::placeholder,
  .genre-select::placeholder {
    color: rgb(255, 255, 255);
  }
  
  .publication-input-group,
  .equipment-input-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    padding: 10px;
    width: 90%;
    flex-direction: column;
    border-radius: 10px;
    background-color: rgb(19, 19, 19);
    color: white;
    position: relative;

  }

.remove-button-pub:hover {
  cursor: pointer;
  scale: 1.05;
}

 .remove-button-pub > img {
   width: 12px;
   filter: invert(1);
 }

  .remove-button-pub {
      display: flex;
      align-items: center;
      border: 0px;
      background-color: var(--red);
      color: white;
      font-weight: 700;
      justify-content: center;
      height: 25px;
      width: 25px;
      border-radius: 100px;
      position: absolute;
      right: 10px;
      top: 5px;
      font-family: 'Jost';
      font-size: 10px;
  }

  .awarded-boxes {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgb(0, 0, 0);
      border: 1px solid grey;
      padding: 10px 0;
      flex-direction: column;
  }

  .awarded-boxes > div {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid rgb(100, 100, 100);
    padding: 10px;
  }

  .add-song-input {
    color: rebeccapurple;
  }

  .input-group-wrapper-pub {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: rgb(41, 41, 41);
      border-radius: 10px;
      padding: 10px;
      border: 1px solid white;

  }
  
  .remove-button,
  .add-button {
    padding: 5px 5px;
    background-color: #000000;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
    width: fit-content;
    width: 100%;
    color: white;
  }
  
  .remove-button:hover,
  .add-button:hover {
    background-color: #111111;
  }
  
  .submit-button {
    background-color: var(--blue);
    color: white;
    border: none;
    cursor: pointer;
    height: 55px;
    border-radius: 15px;
    width: 100%;
  }

  .video-gallery-submit-button {
    background-color: var(--blue);
    color: white;
    border: none;
    cursor: pointer;
    height: 55px;
    border-radius: 15px;
    width: 100%;
  }

  
  .uploading-status {
    text-align: center;
    color: #666;
  }
  


.gig-song {
    display: flex;
    color: white;
    flex-direction: column;
    width: 100%;
    height: 95px;
    margin-left: 0px;
    margin-top: 0px;

}

.gig-song > div {
    display: flex;

}




.play-pause-icon-gig {
    filter: invert(1);
    width: 15px;
    margin-top: 15px;
    margin-left: 25px;
    margin-right: 10px;
}

.gig-song > h3 {
    font-size: 13px;
    width: 100%;
    font-weight: 300;
    text-align: left;
    color: white !important;
    margin-top: 0px;
    margin-bottom: 7px;
    margin-left: 1px;
}

.inner-gig-play {
    display: flex;
    justify-content: left;
    border: 1px solid rgb(66, 66, 66);
    border-radius: 15px;
    padding-top: 7px;
}

.wave-placeholder {
    margin-top: -3.5px;
    width: 83% !important;
    padding-bottom: 3.5px;
}


.gig-song-2 > div  {
    display: flex;
    background-color: var(--color-2);
    margin: 10px 0;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    height: 60px;
    padding: 0 10px;
    box-shadow: var(--color-shadow-3);
}
.gig-song-2 > h3 {
    text-align: left;
    font-size: 12px;
    color: var(--color-oposite-4);
    font-weight: 400;
    margin-bottom: 0px;
    color: grey;
}

.gig-song-2 > div > button > img {
    width: 12px;
    margin-left: 2px;
    filter: var(--color-invert);
}

.gig-song-2 > div > button {
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: var(--color-2);
    border-radius: 100px;
    margin-right: 10px;
    margin-left: 5px;
}

.gig-song-2 > div > button:hover {
    cursor: pointer;
    transform: scale(1.02);
    box-shadow: var(--color-shadow-2);
}


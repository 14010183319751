.home-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    height: 80px;
    background-color: var(--black);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;   
     position: sticky;
    top: 0;
    z-index: 1000;
    border-bottom: 1px solid rgb(71, 71, 71);

  }
  
  .header-logo {
    width: 120px;
    margin-top: 5px;
  }

  
  .header-content {
    display: flex;
    align-items: center;
  }
  
  .text-for-experts {
    margin-right: 20px;
    font-size: 14px;
    color: white;
  }
  

  .submit-song-btn:hover {
    background-color: #0056b3;
  }
  
  .avatar-container .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }

  .header-content > a:hover {
    text-decoration: underline  !important;
  }

  .header-content > button > a:hover {
    text-decoration: underline  !important;
  }
  
  .header-avatar {
    border-radius: 100px;
    width: 30px;
    height: 30px;
    object-fit: cover;
    transition: 200 ease-in-out;
  }


.home-editor-picks-hosted-wrapper {
  display: flex;
  align-items: center;
  margin-top: 0px;
  color: white;
  margin-left: 3%;
}

.home-editor-picks-hosted-left > img {
  width: 50px;
  border-radius: 100px;
}

.home-editor-picks-hosted-left > img:first-child {
  margin-right: -10px;
}

.home-editor-picks-hosted-right > p {
  margin: 0;
  color: grey;
}

.home-editor-picks-hosted-right {
  display: flex;
  align-items: left;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: 15px;

}

.home-editor-picks-hosted-right > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.home-editor-picks-hosted-right > div > p {
  margin: 0;
}

.home-editor-picks-hosted-right > div > a {
  color: white;
  font-family: 'Jost';
}

.home-editor-picks-hosted-right > div > hr {
  height: 10px;
  width: 1px;
  margin: 0 10px;
  border: none;
  background-color: rgb(146, 146, 146);
}



  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    90% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 800px) {
    .text-for-experts {
      display: none;
    }

    .text-for-experts {
      display: none;
    }

    .outline-button {
      padding: 7px 13px;
      font-size: 16px;
      margin-right: 0px;
    }
  }
.fav-save-button {
    display: flex;
    width: 35px;
    height: 35px;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background-color: var(--black);
    border: none;
    position: absolute;
    right: 10px;
    z-index: 100;
    top: 10px;
}

.fav-save-button:hover {
    transform: scale(1.03);
}

.fav-save-button.active {
    background-color: var(--red);
}

.fav-save-button:hover {
    cursor: pointer;
}

.fav-save-button-img {
    width: 20px !important;
    height: 20px !important;
    filter: invert(1);
}
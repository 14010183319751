.gig-review-widget {
    padding: 0px 15px 15px 15px;
    border-radius: 8px;
    margin-bottom: 20px;
    color: white;
    display: flex;
    align-items: top;
}


.gig-reviews-header {
    display: flex;
    align-items: center;
}

.gig-review-breiter {
    width: 200px
}

.gig-review-list-wraper {
    width: 75%;
    margin-top: 30px;
    max-height: 500px;
    overflow-y: auto; /* Ermöglicht vertikales Scrollen */
    scrollbar-width: none; 

}
.gig-review-list-wraper::-webkit-scrollbar {
    display: none; /* Scrollleiste ausblenden */
}

.gig-reviews-header > h3 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
}

.review-score-wrapper {
    display: flex;
    align-items: center;
    height: 25px;
}

.total-reviews-text {
    margin-left: 10px;
    font-size: 14px;
    color: rgb(211, 211, 211);
}

.review-summary {
    margin-top: 15px;
}

.review-item {
    margin-bottom: 15px;
}

.review-item > h4 {
    margin: 0 0 0px 0;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
}

.review-stars {
    display: flex;
    align-items: center;
}

.review-stars img.star {
    width: 15px;
    margin-right: 2px;
}

.review-score-text {
    color: rgb(204, 204, 204);
    margin-left: 5px;
    margin-top: 20px;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
}



@media (max-width: 1000px) {
    .gig-review-widget {
        display: flex;
        flex-direction: column;
    }
    .gig-review-breiter {
        width: 100%;
    }

    .gig-review-list-wraper {
        width: 100%;
    }

    .review-writer-image {
        width: 40px !important;
        height: 40px !important;
    }
    
}
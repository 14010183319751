.artist-editor-quote-container {
    background-color: rgba(39, 39, 39, 0.322);
    margin: 0px 0px 0px 0px;
    color: white;
    text-align: left;

}

.bold-first-word {
    font-weight: bold;
  }

  .artist-editor-quote-container > div {
      padding: 40px 0;
  }
  

.artist-editor-quote-container > div > h3 {
    margin: 0 20px 0 70px;
    font-weight: 300;
    font-family: 'Wide';
    color: grey;
    font-size: 14px;
    margin-bottom: 20px;
}

.artist-editor-quote-container > div > p  {
    margin: 0 40px 0 65px;
    font-size: 34px;
    font-style: italic;
    color: white;
    max-width: 1200px;
    font-weight: 200;
}


@media (max-width: 700px) {
    .artist-editor-quote-container > div > p  {
        margin: 0 40px 0 30px;
        font-size: 28px;
        font-style: italic;
        color: white;
        max-width: 1200px;
        font-weight: 200;
    }

    .artist-editor-quote-container > div > h3 {
        margin: 0 20px 20px 30px;
    }   
}
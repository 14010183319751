/* RetargetingModal.css */
.rtt-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .rtt-modal-content {
    background-color: #1e1e1e;
    color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    max-width: 400px;
    max-height: 500px;
    overflow-y: auto;
    width: 90%;
    text-align: center;
  }
  
  .rtt-modal-content button {
    background-color: var(--blue);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    margin: 10px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s ease;
  }
  
  .rtt-modal-content button:hover {
    background-color: #45a049;
  }
  
  .rtt-modal-content button:focus {
    outline: none;
  }
  
.welcome-stepper-container {
    background-color: var(--black);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.welcome-stepper-wrapper {
    background-color: rgba(2, 2, 2, 0);
    min-height: 600px;
    max-width: 500px;
    width: 500px;
    border-radius: 25px;
    position: relative;
    backdrop-filter: blur(50px);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

}

.left-welcome-side {
  font-family: 'Wide', 'Jost', 'Josefin Sans', sans-serif;
  font-size: 48px;
    margin: 0 20px;
}

.left-welcome-side > h2 {
    font-size: 24px;
    margin-top: 60px;
    margin-bottom: 10px;
}


.left-welcome-side > p {
    font-size: 16px;
    font-weight: 300;
    font-family: 'Jost';
    margin-top: 0px;
    color: rgb(207, 207, 207);
    margin: 0 10% 10px 10%;
}

.upload-area-inside > img {
    width: 100px;
}

.upload-area-inside > p {
    margin: 0px 30px 0 30px;
    margin-top: 20px;
    color: gray;
}

.upload-area-inside > img {
    filter: invert(1);
    width: 70px;
    opacity: 0.5;
}

.image-preview-upload {
    width: 250px;
    height: 250px;
    border-radius: 10px;
    object-fit: cover;
}



.toggle-switch-3  {
  min-width: 40px;
  height: 20px;
  background-color: rgb(0, 0, 0);
  border-radius: 25px;
  border: 1px solid rgb(78, 78, 78);
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin-left: 55px;
}

.terms-privacy-checkboxes {
  margin-top: 25px;
}

.tos-wrapper {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: 3px;
}

.tos-wrapper > p {
  margin: 0;
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  margin-right: 50px;
  margin-left: 10px;
  color: rgb(173, 173, 173);
}

.tos-wrapper > p > span {
  color: rgb(252, 107, 107);
}

.tos-wrapper > p > a {
  color: rgb(185, 185, 185);
}

.service-option {
    width: 190px;
    height: 100px;
    border: 1px solid rgb(85, 85, 85);
    margin: 10px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 25px;
    opacity: 0.7;
  }


  .service-options-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Zentriert die Items und verteilt den verfügbaren Platz */
    max-width: 470px; /* Stelle sicher, dass der Container breit genug für zwei Items ist */
    margin: auto; /* Zentriert den Container */
  }
  
  
  
  .service-option img {
    width: 35px;
    margin-bottom: 10px;
    margin-top: 15px;
    filter: invert(1);
    user-select: none; /* Diese Zeile hinzufügen, um Textauswahl zu verhindern */
  }
  
  .service-option p {
    margin-top: 0px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    pointer-events: none;
    user-select: none; /* Diese Zeile hinzufügen, um Textauswahl zu verhindern */
  }
  
  .service-option input[type="checkbox"] {
    display: none; /* Hide the actual checkbox */
  }
  
  .service-option.checked {
    border-color: var(--blue);
    opacity: 1;
    transition: ease-in 50ms;
  }

  #service-artists-checked.checked {
    border-color: var(--red) !important;
  }
  



.upload-area {
    height: 300px;
    width: 300px;
    background-color: rgb(12, 12, 12);
    border-radius: 25px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
  
  }

.upload-area-2 {
  height: 300px;
  width: 300px;
  background-color: rgb(44, 44, 44);
  border-radius: 1000px !important;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.upload-area-frame {
    height: 280px;
    width: 280px;
    top: 10px;
    right: 10px;
    border: 1px dashed rgb(126, 126, 126);
    border-radius: 20px;
    position: absolute;
    background-color: transparent;
    
}

.upload-area-frame-2 {
  height: 280px;
  width: 280px;
  top: 10px;
  right: 10px;
  border: 1px dashed rgb(126, 126, 126);
  border-radius: 1000px;
  position: absolute;
  background-color: transparent;
  
}


.upload-area:hover {
    background-color: rgb(34, 34, 34) !important;
    cursor: pointer;

}

.upload-area-2:hover {
  background-color: rgb(94, 94, 94) !important;
  cursor: pointer;

}

.select-genre-div {
  width: 80%;
}


.stepper-wrapper > div > input {
  background-color: rgba(0, 0, 0, 0.479);
    width: 390px;
    height: 52px;
    margin-top: 10px;
    border: 1px solid rgb(61, 61, 61); 
    padding: 0px 0px 0px 15px;
    border-radius: 8px;
    font-size: 16px;
    color: white;
    font-family: 'Jost';
    outline: none; /* Removes the default outline */
}

.select-genre-div > label {
text-align: left;
margin-top: 15px;
margin-bottom: 0px;
color: rgb(255, 255, 255);
font-size: 13px;
font-weight: 400;
letter-spacing: 1px;
width: 100%;
}

/* Adding a focused state with a different border or box-shadow for accessibility */
.stepper-wrapper > div > input:focus {
    border: 1px solid var(--blue); /* Replace '--highlight-color' with your chosen color */
}


.stepper-wrapper > div {
    display: flex;
    flex-direction: column;
}



@keyframes gradientAnimation {
    0%, 100% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
  }
  
  .welcome-next-button {
    width: fit-content;
    margin-top: 20px;
    padding: 15px 25px;
    border-radius: 10px;
    color: var(--white);
    background: linear-gradient(270deg, #4449AD, #7479DD);
    background-size: 400% 400%;
    border: none;
    align-self: flex-end;
    position: absolute;
    bottom: 20px;
    right: 20px;
    outline: none;
    animation: gradientAnimation 2s ease infinite;
    transition: transform 0.2s;
    font-family: 'Jost';
    font-size: 15px;

  }

  .welcome-next-button:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
  
  .welcome-next-button:disabled {
    background-image: none;
    background-color: rgb(24, 24, 24); /* Grau */
    cursor: not-allowed;
  }

  .welcome-back-button {
    width: fit-content;
    margin-top: 20px;
    padding: 15px 25px;
    border-radius: 10px;
    color: var(--white);
    border: none;
    font-family: 'Jost';
    background-color: transparent;
    align-self: flex-end;
    position: absolute;
    bottom: 20px;
    left: 20px;
    outline: none;
    animation: gradientAnimation 2s ease infinite;
    transition: transform 0.2s;
  }

  .welcome-back-button:hover {
      background-color: black;
      cursor: pointer;
  }
  


  @media only screen and (max-width: 500px) and (max-height: 620px) {

    .service-options-containers {
      position: relative;
    }


    .scrollable-content-wrapper{
      position: relative;

    }
    
  }

  @media only screen and (max-width: 500px) and (max-height: 450px) {


  }


  @media (max-width: 500px) {
    .stepper-wrapper > div > input {
        width: 96% !important;
        font-size: 16px;
    }

    .welcome-stepper-container {
        justify-content: flex-start;
        position:  relative;
    }

    #first-jury-stepper {
      width: 95%;
    }

    .left-welcome-side > p {
      margin: 0 5%;
  }

.select-genre-div {
  width: 95%;
  display: flex;
  align-items: center;
}

#image-wrapper-style {
  min-height: 550px;
}

    .upload-area {
      width: 90vw;
  }
  
  .upload-area-frame {
      height: 280px;
      width: 85vw;
      top: 10px;
      right: 10px;
      border: 1px dashed rgb(126, 126, 126);
      border-radius: 20px;
      position: absolute;
      background-color: transparent;
      
  }

      
  .welcome-next-button {
    bottom: 20px;
    right: 20px;

  }

  .welcome-back-button {
    bottom: 20px;
    left: 20px;
    background-color: var(--black);

  }



  .stepper-wrapper > div > input {
    width: 85vw;
    height: 52px;
    font-size: 16px;
    margin-top: 10px;
    border: 1px solid rgb(61, 61, 61); 
    padding: 0px 0px 0px 15px;
    border-radius: 8px;
    color: white;
    font-family: 'Jost';
    outline: none; /* Removes the default outline */
}

.service-options-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Zentriert die Items und verteilt den verfügbaren Platz */
  max-width: unset; /* Stelle sicher, dass der Container breit genug für zwei Items ist */
  margin: unset;
  width: 100vw; /* Zentriert den Container */
}

.service-options-containers {
  height: 490px;
}



.service-option {
  width: 40%;
}
   

    .welcome-stepper-wrapper {
        width: 100%;
        background-color: transparent;
        padding-top: 30px;
        height: calc(100vh - 30px);
        min-height: 500px;
      }

      .toggle-switch-3  {
        margin-left: 25px;
      }
      

    

      
      .tos-wrapper > p {
        margin: 0;
        font-size: 12px;
        text-align: left;
        margin-right: 20px;
        margin-left: 10px;
        color: rgb(182, 182, 182);
      }

      #online-links-div {
        width: 92% !important;
      }
    
  }

  @media (max-width: 450px) {

    #image-wrapper-style {
      min-height: 600px;
    }
  }
 

  @media (max-width: 400px) {
    .welcome-stepper-wrapper {
      min-height: 650px;
    }

    #online-links-div {
      width: 90% !important;
    }

    .upload-area-2 {
      width: 250px;
      height: 250px;
    }



    .upload-area-frame-2 {
      width: 230px;
      height: 230px;
    }

    .upload-area-inside > p {
      font-size: 14px;
    }
     
  .upload-area-frame {
    height: 280px;
    width: 84vw;
    top: 10px;
    right: 10px;
    border: 1px dashed rgb(126, 126, 126);
    border-radius: 20px;
    position: absolute;
    background-color: transparent;
    

}

.left-welcome-side > p {
  margin: 0 2%;
}

  }



  @media (max-width: 300px) {
    .welcome-stepper-wrapper {
      min-height: 700px;
    }


    #online-links-div {
      width: 84% !important;
    }

    .service-option > p {
      font-size: 12px;
    }
  }

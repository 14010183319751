.artist-songs {
    display: flex;
    flex-wrap: nowrap; /* Verhindert, dass die Karten umgebrochen werden */
    overflow-x: auto; /* Ermöglicht horizontales Scrollen, falls nötig */
    justify-content: center; /* Zentriert die Karten */
    gap: 20px; /* Fügt einen Abstand zwischen den Karten hinzu */
  }
  
  .song-card-artist {
    flex: 0 0 auto; /* Verhindert, dass die Karten gestreckt oder geschrumpft werden */
    margin-bottom: 20px; /* Fügt am unteren Rand der Karten einen Abstand hinzu */
    background-color: var(--card-bg);
    overflow: hidden;
    text-align: left;
  }



  
  .song-card-artist > div > img {
    height: 300px;
    width: 300px; /* Sorgt dafür, dass das Bild die Breite der Karte ausfüllt */
    object-fit: cover;
    border-radius: 25px; /* Sorgt dafür, dass das Bild den Container ohne Verzerrung ausfüllt */
  }
  
  .song-card-details {
    padding: 10px;
  }
  
  .song-card-details h4,
  .song-card-details p {
    color: var(--white);
    text-align: left;
    margin: 5px 0;
  }
  
  .navigation-buttons {
    display: flex;
    justify-content: space-between; /* Zentriert die Buttons horizontal */
  }
  
  .navigation-buttons button {
    background-color: var(--white);
    color: var(--black);
    border: none;
    opacity: 0.8;
    border-radius: 20px;
    cursor: pointer;
    transition: 200ms ease-in-out;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  }

  .navigation-buttons button:hover {
    opacity: 1;

  }
  
  .navigation-buttons button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
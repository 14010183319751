.gig-intro-wrapper {
    text-align: left;
    border-radius: 10px;
    transition: 150ms ease-in-out;
    position: relative;
    padding: 0px 10px 10px 10px;
    margin-top: -20px;
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;
}


.gig-intro-wrapper > h2 {
    font-size: 24px;
    font-weight: 600;
    color: var(--color-oposite-2); /* Text color changes when active */
    user-select: none;  /* Verhindert die Auswahl des Textes */
    -webkit-user-select: none;  /* Verhindert die Auswahl des Textes für WebKit-Browser wie Safari und Chrome */
    -ms-user-select: none;  /* Verhindert die Auswahl des Textes für Internet Explorer */
    -moz-user-select: none; 
    text-align: left;
    max-width: 800px;
}

.gig-intro-wrapper > p {
    color:  grey; /* Text color changes when active */
    user-select: none;  /* Verhindert die Auswahl des Textes */
    -webkit-user-select: none;  /* Verhindert die Auswahl des Textes für WebKit-Browser wie Safari und Chrome */
    -ms-user-select: none;  /* Verhindert die Auswahl des Textes für Internet Explorer */
    -moz-user-select: none; 
    text-align: left;
}

.gig-intro-wrapper.settings-route {
    border: 1px solid rgba(255, 255, 255, 0); /* Solid border for clarity */
}

.gig-intro-wrapper.is-active {
    padding: 10px;
    border-color: rgb(196, 196, 196); /* Blue border when active */
}

.gig-intro-wrapper.settings-route:hover {
    cursor: pointer;
    transform: scale(1.03);
    box-shadow: var(--color-shadow-2);
}

.gig-intro-wrapper:hover .edit-overlay {
    display: flex; /* Make visible on hover */
}



.edit-overlay {
    display: none; /* Default state is not visible */
    position: absolute;
    top: -10px; /* Adjust as needed */
    right: -7px; /* Adjust as needed */
    background-color: var(--black); /* Semi-transparent background */
    padding: 5px 10px;
    border-radius: 100px;
    align-items: center;
    width: 40px;
    font-weight: 400;
    justify-content: center;
    text-align: center;
    border: 1px solid rgb(99, 99, 99);
}


.edit-overlay > img {
    display: none;
}


@media (max-width: 500px) {
    .gig-intro-wrapper > p {
        text-align: center;
        margin: 10px 10px 10px 10px;
        color: grey;
    }

    .gig-intro-wrapper {
        margin-top: -10px;
    }
}


/* Dark mode CSS for ShowDailyContent component */

.rss-daily-content-container {
    padding: 20px;
    color: #f5f5f5;
    border-radius: 8px;
    width: 100%;
    margin: 0 0;
  }
  
  .rss-daily-content-header {
    font-size: 24px;
    margin-bottom: 20px;
    color: #f5f5f5;
    text-align: center;
  }
  
  .rss-no-entries {
    text-align: center;
    font-size: 18px;
    color: #888;
  }
  
  .rss-video-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Adjusted minmax value */
    gap: 20px;
  }
  
  .rss-video-item {
    background-color: #2b2b2b;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
    color: #f5f5f5;
    max-width: 390px;
  }
  
  .rss-video-item h4 {
    font-size: 22px;
    margin-bottom: 10px;
    color: #f5f5f5;
  }
  
  .rss-video-item p {
    font-size: 14px;
    color: #aaa;
    margin-bottom: 10px;
  }
  
  .rss-video-iframe {
    width: 100%;
    height: 300px;
    border: none;
    border-radius: 5px;
  }
  
.gig-page-header {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 240px;
    position: relative;
    z-index: 10;
  }

  .gig-page-header:hover {
    cursor: pointer;
  }


.online-status-background {
  width: 132px !important;
  border-radius: 100px;
  height: 132px !important;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  top: 4px;
  left:9px;
  background-color: rgb(97, 206, 97) !important;
  animation: pulsatori 2s infinite ease-in-out;
}

.online-profile-banner {
  position: absolute;
  bottom: 10px;
  color: white;
  z-index: 100;
  border-radius: 100px;
  border: 1px solid rgb(94, 241, 94);
  margin: 0px !important;
  font-size: 10px !important;
  background-color: var(--black);
  padding: 2px 10px;
  background: linear-gradient(135deg, rgb(87, 87, 87), rgb(59, 114, 59), black) !important;
  background-size: 200% 200%;
  background-position: 0% 50%;
}


  .page-header-banner.settings-route {
   border-radius: 25px;
    border: 2px solid rgba(255, 255, 255, 0); /* Solid border for clarity */
}

.page-header-banner.is-active {
    border-color: rgb(196, 196, 196); /* Blue border when active */
    color: white; /* Text color changes when active */
}

.page-header-banner.settings-route:hover {
    cursor: pointer;
    transform: scale(1.01);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(49, 49, 49, 0.35) 0px -2px 6px 0px inset;
}

.page-header-banner:hover .edit-overlay {
    display: flex; /* Make visible on hover */
}

  .profile-section > div > h3 {
 margin-bottom: 0px;
 margin-top: 5px;
 color: var(--color-oposite-1);
 
  }

  .gig-gallery-image-prev {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

.swiper {
  border-radius: 15px; /* Wie vorher für das Bild */
  overflow: hidden; /* Verhindert, dass Inhalte über die Ränder hinaus angezeigt werden */
}

/* Stil für die Bilder innerhalb der Swiper Slides */
.swiper-slide > img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Dies stellt sicher, dass das Bild den Slide bedeckt */
}

.page-header-banner {
  width: 100%;
  object-fit: cover;
}
.swiper-wrapper {
  max-width: 100%;
}


.page-header-banner {
  width: 1000px; /* oder 100% wenn es responsiv sein soll */
  position: relative;
  margin-top: 30px; /* Abstand von oben */
  margin-left: auto; /* Zentriert das Element */
  margin-right: auto;
  border-radius: 15px;
  overflow: hidden; /* Verhindert das Herausragen von Kinderelementen */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 140ms ease-in-out;
}

.swiper-button-prev, .swiper-button-next {
  position: absolute;
  top: 50%; /* Zentriert die Buttons vertikal */
  transform: translateY(-50%);
  z-index: 10; /* Stellt sicher, dass die Buttons über den Slides liegen */
}


.swiper-button-prev {
  margin-top: 10px;
  left: -27px; /* Abstand vom linken Rand */
  height: 50px;
  width: 50px;
  border-radius: 100px;
  border: var(--color-oposite-1);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-2);
  box-shadow: var(--color-shadow);
  transition: 100ms ease-in-out;

}

.swiper-button-next {
  margin-top: 10px;
  right: -27px; /* Abstand vom linken Rand */
  height: 50px;
  z-index: 10000;
  width: 50px;
  border-radius: 100px;
  border: var(--color-oposite-1);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-2);
  box-shadow: var(--color-shadow);
}

.swiper-button-next:hover {
  cursor: pointer;
}


.swiper-button-prev:hover {
  cursor: pointer;
}

.swiper-button-prev:hover > img{
  opacity: 1;
}

.swiper-button-next:hover > img{
  opacity: 1;
}



.swiper-button-next > img {
  width: 20px;
  filter: var(--color-invert);
  opacity: 0.8;
}

.swiper-button-prev.disabled,
.swiper-button-next.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
}


.swiper-button-prev > img {
  width: 20px;
  filter: var(--color-invert);
  opacity: 0.8;
}

.video-page-container {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
}

.video-thumbnail-3 {
  width: 100%;
  object-fit: cover;
  min-height: 100%;
}

.slide-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.play-pause-btn-3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #5364d4bb;
  width: 55px;
  height: 55px;
  border-radius: 100px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 0px;
  border: 1px solid rgba(255, 255, 255, 0.582);
  justify-content: center;

}

.slide-thumbnail-container {
  height: 100%;
}

.thumbnail-container > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.play-pause-btn-4 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #2b2b2bbb;
  width: 52px;
  height: 52px;
  border-radius: 100px;
  backdrop-filter: blur(10px);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: 150;
  margin-top: 0px;
  border: 1px solid rgba(255, 255, 255, 0.582);
  justify-content: center;

}

.page-header-banner .play-pause-btn-3.is-playing {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.page-header-banner:hover .play-pause-btn-3.is-playing {
  opacity: 1;
}


.play-pause-btn-3.is-playing {
  position: absolute;
  top: 50%;
  display: flex;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 55px;
  height: 55px;
  border-radius: 100px;
  border: none;
  cursor: pointer;
  align-items: center;
  margin-top: 0px;
  border: 1px solid rgba(255, 255, 255, 0.582);
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}


.play-pause-btn-3 img {
  width: 15px !important;
  object-fit: contain;
  filter: invert(1);
  margin-left: 1px;

}


/* Pseudo-Element für die Beibehaltung des Seitenverhältnisses */
.page-header-banner:before {
  content: "";
  display: block;
  padding-top: 62.25%; /* 16:9 Seitenverhältnis */
}

/* Die Swiper-Komponente muss absolut positioniert werden, um über das pseudo-Element zu liegen */
.page-header-banner .swiper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


  
  .profile-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    position: absolute;
    bottom: -200px;
    z-index: 10;

  }

  
  .profile-image-3 {
    width: 120px; /* Anpassen je nach Bedarf */
    height: 120px; /* Anpassen je nach Bedarf */
    border-radius: 50%;
    display: none; /* Wird durch JavaScript geändert, sobald das Bild geladen ist */
    object-fit: cover;
    margin-bottom: 10px;
    z-index: 100;
    box-shadow: var(--color-shadow);
    border: 3px solid rgb(226, 226, 226); /* Optional, je nach Design */
    background-color: white; /* Falls das Bild einen transparenten Hintergrund hat */
  }
  
  .profile-info {
    display: flex;
    justify-content: center;
    gap: 30px; /* Anpassen je nach Bedarf */
    padding: 10px; /* Anpassen je nach Bedarf */
    margin-top: 0px;
  }
  
  .profile-info > span {
    color: var(--color-oposite-3) ;
    margin-top: 0px;
    font-size: 16px;
    user-select: none;  
    -webkit-user-select: none;
    -ms-user-select: none; 
    -moz-user-select: none;  

 }
  



 @media (max-width: 500px) {
  .profile-info {
    display: flex;
    flex-direction: column;
    height: 30px;
    gap: 0;
    margin-top: 20px;
  }

  .profile-info > hr {
    display: none;
  }


  .swiper-button-prev {
    margin-top: 10px;
    left: -5px; /* Abstand vom linken Rand */
    height: 50px;

  
  }
  
  .swiper-button-next {
    margin-top: 10px;
    right: -5px; /* Abstand vom linken Rand */
    height: 50px;
  }

  .gig-page-header {
    margin-bottom: 310px;
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }

  .gig-intro-wrapper > h2 {
    text-align: center;
    margin: 10px 20px;
  }
    
  .profile-section {
    bottom: -280px;
    z-index: 10;

  }

  .page-header-banner:before {
    padding-top: 72.25%;
  }

  
 }
/* Allgemeiner Container für den gesamten GigFilterComponent */
.gig-filter-component-container {
    width: calc(100% - 40px);
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}


.democharts_boys {
    width: 350px;
    position: absolute;
    bottom: 0;
    right: -12px;
}

.gig-filter-buttons-container > p {
    margin: 0;
    color: rgb(179, 179, 179);
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 20px;
}

/* Stil für die Filter-Buttons */
.gig-filter-buttons-container {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    padding-bottom: 10px;
    margin-top: 30px;
    margin-bottom: -20px;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.gig-filter-buttons-container::-webkit-scrollbar {
    display: none; /* Chrome, Safari und Opera */
}




.gig-filter-button {
    flex: 0 0 auto;
    padding: 10px 20px;
    margin-right: 10px;
    font-weight: 500;
    font-family: 'Jost';
    letter-spacing: 0.5px;
    background-color: #181818;
    color: #fff;
    border-radius: 100px;
    border: 1px solid #2e2e2e;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.gig-filter-button:hover {
    background-color: #202020;
}

/* Scrollbar-Styling */
.gig-filter-buttons-container::-webkit-scrollbar {
    height: 8px;
}

.gig-filter-buttons-container::-webkit-scrollbar-thumb {
    background-color: #4a4a4c;
    border-radius: 10px;
}


/* Container unterhalb der Filter */
.gig-filter-content-area {
    display: flex;
    margin-top: 20px;
    height: 250px; /* Höhe des Bereichs anpassen */
}


/* Linke Seite mit dem Suchfeld */
.gig-filter-search-container {
    width: 50%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    background: linear-gradient(135deg, #181622, #7b2cff);
    border-radius: 25px;
}

.marketplace-gig-filter-text {
    color: white !important;
    margin-left: 25px;
    position: absolute;
    top: 10px;
    background-color: var(--blue);
    width: fit-content;
    padding: 5px 15px;
    font-size: 14px !important;
    border-radius: 100px;
}

.search-input-icon {
    position: absolute;
    bottom: 16px;
    width: 24px;
    right: 20px;
    z-index: 101;
    filter: invert(1);
    opacity: 0.7;
}

.gig-filter-search-container > div {
    display: flex;
    justify-content: left;
    position: absolute;
    align-items: left;
    flex-direction: column;
    border-radius: 25px;
    text-align: left;
    margin-left: 0px;
    bottom: 20px;
    width: 80%;

} 
.gig-filter-search-container > div > h2 {
    text-align: left;
    color: white;
    font-size: 24px;
    margin-bottom: 10px;
    font-family: 'Wide';
    margin-left: 24px;
    z-index: 100;
    
}


.gig-filter-button.selected {
    color: #fff;
    border: 1px solid var(--red); /* Blau leuchtende Umrandung */
}


.gig-filter-search-box {
    width: 100%;
    padding: 15px 15px 15px 25px;
    margin-left: 20px;
    z-index: 100;
    border-radius: 100px;
    border: 1px solid #ffffff1e;
    color: #fff;
    font-size: 16px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    font-family: 'Jost';
    outline: none;
    backdrop-filter: blur(20px);
    background-color: rgba(19, 19, 19, 0.692);

}

.gig-filter-search-box:focus {
    border: 1px solid #ffffff;

}

.gig-filter-search-box::placeholder {
    color: transparent;
    background: linear-gradient(90deg, rgb(122, 122, 122), white);
    -webkit-background-clip: text;
    background-clip: text;
    animation: gradientAnimation5 1s infinite;
}

@keyframes gradientAnimation5 {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}

.gig-filter-right-side {
    display: flex;
    width: 50%;
    justify-content: space-around;
}

/* Rechte Seite mit zwei Containern übereinander */
.gig-filter-additional-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.gig-filter-upper-container,
.gig-filter-lower-container {
    height: 50%;
    background-color: #2c2c2e;
    border-radius: 20px;
    margin-left: 20px;
    display: flex;
    border-bottom: none !important;
    border: 1px solid rgba(100, 100, 100, 0.39);
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 15px 15px 30px 15px;
    margin-bottom: 10px;
    color: #fff;
    font-size: 12px;
    font-family: 'Jost';
    transition: 100ms ease-in-out;
    margin-top: -15px;
    box-shadow: 
    rgba(0, 0, 0, 0.19) 0px -10px 20px, 
    rgba(0, 0, 0, 0.23) 0px -6px 6px;


}

.gig-filter-upper-container {
    margin-top: 0px;
    padding-bottom: 25px;
}


.gig-filter-upper-container:hover,
.gig-filter-lower-container:hover {
    cursor: pointer;
    transform: translateY(-5px);
    transition: transform 0.3s ease;
}


/* Hintergrundbilder */
.gig-filter-upper-container {
    background-image: url('../../public/img/triple_a_banner_1.jpg');
    background-size: cover;
    background-position: center;
}

.gig-filter-lower-container {
    background-image: url('../../public/img/aaa_influencer_3_1.webp');
    background-size: cover;
    background-position: center;
    margin-top: -25px;
}

#lowest-container {
    background-image: url('../../public/img/aaa_top_cards_2.webp');
    background-size: cover;
    background-position: center;
    margin-top: -25px;
    z-index: 11;
}

.gig-filter-side-container {
    background-size: cover;
    background-position: center;
}

.gig-filter-side-container {
    position: relative;
    width: varl(50% - 10px) !important;
    background-color: #0a0a0a; /* Dunkler Hintergrund */
    border-radius: 20px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #ffffff;
}

.gig-filter-side-container > h2 {
    font-size: 20px;
    margin-bottom: -5px !important; 
    margin-top: 5px !important;
}

.gig-cash-icon {
    width: 40px;
    margin-top: -10px;
    margin-bottom: 10px;
}

.gig-filter-side-container p {
    font-size: 14px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.gig-filter-side-container .check-icon {
    width: 15px;
    height: 15px;
    filter: invert(1);
    margin-right: 8px;
    margin-right: 10px;
}

.gig-join-button {
    color: white;
    border: none;
    background-color: transparent; 
    border-radius: 30px;
    padding: 5px 15px;
    text-transform: uppercase;
    font-family: 'Jost';
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 16px;
    height: 45px;
    border: 1px solid rgba(255, 255, 255, 0.336);
    cursor: pointer;
    margin-bottom: -20px;
    transition: background-color 0.3s ease;
    width: 150px; /* Passe die Breite an, um den Button in der Mitte zu platzieren */
    background-size: 200% 200%;
    animation: moveSpotlight 8s linear infinite;

}

.gig-join-button:hover {
    border: 1px solid rgba(255, 255, 255, 0.952);

}

.join-button:hover {
    background-color: #3b32c6; /* Dunklerer Blauvioletter Ton bei Hover */
}


/* Text innerhalb der Container zentrieren */
.gig-filter-upper-container h2,
.gig-filter-lower-container h2,
.gig-filter-side-container h2 {
    position: relative;
    z-index: 2; /* Text über das Bild legen */
    margin: 0;
}

/* Schwarzer Hintergrund unter dem Bild */


.gig-filter-side-container {
    width: 50%;
    margin-left: 20px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Anpassungen für das letzte Element, damit es keinen unteren Rand hat */
.gig-filter-lower-container {
    margin-bottom: 0;
    z-index: 10;
}




@media (max-width: 850px) {
    .gig-filter-side-container {
        display: none;
    }
    .gig-filter-additional-container {
        width: 100%;
    }
    .gig-filter-search-container {
        min-width: 410px;
    }

   
}

@media (max-width: 700px) {
    .gig-filter-content-area {
        height: unset;
        flex-direction: column;
        margin-top: 300px;
    }
    .gig-filter-right-side {
        width: 100%;
    }

    .gig-filter-search-container {
        min-width: 100%;
        width: 100%;
    }

    .gig-filter-upper-container, .gig-filter-lower-container {
        margin-left: 0px;
        border-radius: 15px;
        height: 200px;
        margin-bottom: -24px;
    }
    .marketplace-gig-filter-text {
        display: none;
    }
    .gig-filter-buttons-container {
        margin-top: 60px;
    }
    .gig-filter-search-container > div {
        width: 100%;
    }

    .gig-filter-search-box {
        margin-left: 0px;
    }
}




.verified-badge-container {
    display: flex;
    align-items: center;
    background-color: rgba(65, 65, 65, 0.329);
    border-radius: 100px;
    padding: 0px 4px;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 100;
    justify-content: center;
    height: 28px;
    backdrop-filter: blur(20px);
}

.verified-icon {
    width: 22px;
    margin-right: 5px;
    z-index: 1000;
    margin-top: 0px;
}

.verified-text {
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    margin-right: 8px;
    text-transform: uppercase;
}

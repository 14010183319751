.live-payment-options {
    text-align: center;
    color: white;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.payment-icons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom:10px;
    
}

.payment-icon-wrapper {
    width: 52px;
    height: 52px;
    background-color: rgb(240, 240, 240);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    margin-left: -10px; /* To create the overlap effect */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}


.payment-icon-wrapper-2 {
    width: 60px;
    height: 60px;
    background-color: rgb(24, 24, 24);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    margin-bottom: 15px;
    margin-left: -10px; /* To create the overlap effect */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}


.payment-icon-wrapper:first-child {
    margin-left: 0; /* Prevent overlap on the first icon */
}

.payment-option-icon {
    width: 28px;
    height: 30px;
    object-fit: contain;
}

.platform-fees {
    font-weight: bold;
    font-size: 16px;
    margin: 10px 0 5px 0;
    color: rgb(214, 214, 214);
}


.platform-description {
    font-size: 13px;
    margin-bottom: 30px;
    margin-top: 0px;
    color: #9b9b9b;
    max-width: 300px;

}

.money-back-guaranteed {
    border-radius: 50%;
    max-width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 0px;
    margin-bottom: 10px;
}

.guarantee-title {
    font-weight: bold;
    font-size: 16px;
    margin-top: 0px;
    color: rgb(214, 214, 214);

}

.guarantee-description {
    font-size: 13px;
    margin-top: -10px;
    color: #9b9b9b;
}




/* ReviewCreator.css */

.review-creator-container {
    position: relative;
}

.review-creator-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 600px;
    max-height: 90vh; /* Begrenzung der Höhe auf 90% der Viewport-Höhe */
    background-color: #1e1e1e;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    padding: 20px;
    color: #f0f0f0;
    display: flex;
    flex-direction: column;
}

.review-creator-modal-content {
    max-height: 70vh; /* Maximale Höhe des Inhalts */
    overflow-y: auto; /* Scrollbar hinzufügen, wenn der Inhalt zu groß ist */
    padding-right: 10px; /* Platz für die Scrollbar schaffen */
}

.review-creator-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    color: #f0f0f0;
}

.review-creator-form-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}

.review-creator-form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #f0f0f0;
}

.review-creator-form-group input[type="text"],
.review-creator-form-group select,
.review-creator-feedback-textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #444;
    border-radius: 5px;
    background-color: #2e2e2e; /* Darker background for inputs */
    color: #f0f0f0;
    font-size: 14px;
    outline: none;
}

.review-creator-form-group input[type="text"]:focus,
.review-creator-form-group select:focus,
.review-creator-feedback-textarea:focus {
    border-color: #007bff; /* Highlight color on focus */
}

.review-creator-form-group input[type="file"] {
    color: #f0f0f0;
}

.review-creator-preview-image {
    margin-top: 10px;
    max-width: 100px;
    border-radius: 100px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.review-creator-spinner {
    width: 50px;
    height: 50px;
    border: 6px solid #444;
    border-top: 6px solid #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 20px auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.review-category-2 {
    margin-bottom: 15px;
}

.review-category-2 p {
    margin-bottom: 5px;
    font-weight: bold;
    color: #f0f0f0;
}

.review-creator-star {
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-right: 5px;
}

.review-creator-feedback-textarea {
    height: 100px;
    resize: none;
}

.review-creator-modal button {
    padding: 10px 15px;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    color: #f0f0f0;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s ease;
}

.review-creator-modal button:hover {
    background-color: #0056b3;
}

.review-creator-modal button:disabled {
    background-color: #444;
    cursor: not-allowed;
}

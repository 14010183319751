.loadding-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 65px;
    left: 0;
    width: 100%;
    height: 100%;
    flex-direction: column;
    background-color: var(--black);
    z-index: 1000; /* Stellen Sie sicher, dass der Loading Screen über anderen Inhalten liegt */
  }

  .loddri-loading-screen-full {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    padding-top: 65px;
    left: 0;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: var(--black);
    z-index: 1000; /* Stellen Sie sicher, dass der Loading Screen über anderen Inhalten liegt */
  }
  
  .loddri-spinner {
    border: 4px solid lightgray; /* Hintergrundfarbe des Spinners */
    border-top: 4px solid var(--red); /* Hauptfarbe des Spinners */
    border-radius: 50%;
    width: 40px;
    margin-top: -200px;
    height: 40px;
    margin-left: 20px;
    animation: spin 0.45s linear infinite;
  }


  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loddri-loading-logo {
    max-width: 200px;
    margin-top: 20px;
  }
  
  
.page-qa {
    margin-top: 0px;
    margin-bottom: 20px;
    border-radius: 15px;
    border: 1px solid rgba(128, 128, 128, 0);
    transition: 140ms ease-in-out;
    padding: 10px;
}


.page-qa.is-active {
    padding: 10px;
    border: 1px solid rgb(133, 133, 133);
    border-color: rgb(196, 196, 196); /* Blue border when active */
    color: white; /* Text color changes when active */
  }
  
  .page-qa.settings-route:hover {
    cursor: pointer;
    transform: scale(1.03);
    box-shadow: var(--color-shadow-2);
  }
  
  .page-qa.settings-route:hover .edit-overlay {
    display: block;
  }



.page-qa:hover .gig-upload-song-button > h5 {
    color: var(--color-oposite-1);
}

.page-qa:hover .gig-upload-song-button > img {
    opacity: 1;
}

.qa-list > h2 {
    font-family: 'Wide', 'Jost', 'Josefin Sans', 'Sans Serif';
    margin-bottom: 40px;
}

.qa-list {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    gap: 10px;
    color: var(--color-oposite-3);
}

.qa-item {
    cursor: pointer;
    padding: 10px 10px 10px 20px;
    border: 1px solid var(--color-3);
    border-radius: 10px;
}

.qa-question {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    padding: 5px 0;
    font-weight: 500;
}

.qa-answer {
    margin-top: 10px;
    animation: slideDown 0.3s ease-out forwards;
    display: flex;
    text-align: left;
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.add-qa-button {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.add-qa-button img {
    margin-left: 10px;
}

.arrow-icon {
    transition: transform 0.3s ease;
    margin-right: 10px;
}

.arrow-icon.up {
    transform: rotate(-180deg);
}

.arrow-icon.down {
    transform: rotate(0deg);
}


.qa-question > img {
    width: 20px;
    filter: var(--color-invert);
}
.collab-login-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .collab-login-popup {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  }
  
  .collab-login-popup-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .collab-login-popup-content {
    text-align: center;
  }
  
  .login-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .login-button:hover {
    background-color: #45a049;
  }
  
.home-promo-banner {
    color: #ffffff; /* Weißer Text */
    border-radius: 20px; /* Abgerundete Ecken */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 1040px;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 40px;
    overflow: hidden;
  }
  
  .home-promo-banner-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius:0;
    background: linear-gradient(to bottom, var(--black), black); /* Lila zu Schwarz Farbverlauf */
    justify-content: space-between;
    align-items: center;
  }
  
  .home-promo-banner-wrapper-left {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 0%;
    margin-top: 00px;
    width: 100%;
  }

  .home-promo-banner-wrapper-left > h2 {
    font-size: 48px;
    text-align: center;
    font-weight: bold;
    font-family: 'Wide', 'Jost';
    z-index: 100;
    margin: 10px 0px 0px 0px;
    max-width: 700px;
  }

    
  .home-promo-banner-wrapper-left > p {
    text-align: center !important; 
    width: 90%;
  }
  
  .home-promo-banner-wrapper-left > h2 > span {
    font-size: 52px;
    text-align: left;
    font-weight: bold;
    font-family: 'Wide';
    z-index: 100;
    margin: 10px 0px 0px 0px;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.829), 
    0 0 20px rgba(255, 255, 255, 0.011), 
    0 0 30px rgba(255, 255, 255, 0.02); /* Mehrere Schichten für ein realistisches Leuchten */

animation: floatingEffect 4s ease-in-out infinite;
  }
  .home-promo-banner-wrapper-left h2::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.103), rgba(255, 255, 255, 0)); /* Himmlische Wolke */
    filter: blur(30px); /* Weichzeichner für den "Nebel"-Effekt */
    opacity: 0.1; /* Halbdurchsichtig, damit der Effekt subtil bleibt */
    transform: translate(-50%, -50%);
    animation: cloudEffect 4s ease-in-out infinite; /* Langsame Bewegung der Wolke */
    pointer-events: none; /* Verhindert, dass die Wolke Interaktionen blockiert */
  }
  
  /* Wellenartiges Schweben des Textes */
  @keyframes floatingEffect {
    0%, 100% {
      transform: translateY(-10);
    }
    50% {
      transform: translateY(-5px);
    }
  }
  
  /* Langsame Bewegung der "Wolke" */
  @keyframes cloudEffect {
    0%, 100% {
      transform: translate(-50%, -50%) scale(1);
    }
    50% {
      transform: translate(-50%, -50%) scale(1.01); /* Leichte Skalierung für den Bewegungseffekt */
    }
  }

  /* Gradient über den Text */
/* Gradient über den Text */
.home-promo-banner-wrapper-left > h2 > span {
  background: linear-gradient(90deg, #0004ff, #dddddd, #1900ff, #b6b6b6, #5e08ff); /* Silbriger Gradient mit einer neutralen Farbe für einen edlen Effekt */
  background-size: 400%; /* Größere Hintergrundgröße für langsame Bewegung */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: refinedGradientAnimation 8s ease-in-out infinite; /* Sanfte und endlose Bewegung */
}

/* Keyframes für die Animation */
@keyframes refinedGradientAnimation {
  0% {
    background-position: 0% 50%; /* Gradient beginnt am linken Rand */
  }
  50% {
    background-position: 100% 50%; /* Gradient bewegt sich langsam nach rechts */
  }
  100% {
    background-position: 0% 50%; /* Kehrt nahtlos zur linken Seite zurück */
  }
}


  
  .home-promo-banner-wrapper-left > p {
    font-size: 18px;
    color: rgb(204, 204, 204);
    z-index: 100;
    text-align: left;
    margin: 10px 0px 10px 0px;
    max-width: 500px;
  }


  
  .home-promo-banner-wrapper-right {
    position: relative;
    flex: 0%;
    display: none;
    justify-content: flex-end;
    height: 100%;
  }
  
  .home-promo-banner-wrapper-right > img {
    width: 670px;
    border-radius: 10px;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  #promo-blur-banner {
    right: unset;
    left: 0%;
    z-index: 0 !important;
    opacity: 0.4;
    height: 140%;
    position: absolute;
  }

  .home-promo-banner-wrapper-left > a {
    color: white;
    padding: 15px 30px;
    border: 1px solid white;
    text-decoration: none;
    border-radius: 100px;
    font-family: 'Jost';
    cursor: pointer;
    margin-top: 20px;
    font-size: 16px;
    transition: background-color 0.3s ease;
    display: flex;
    z-index: 100;
  }

  .home-promo-connect-boxen {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 100;
    margin-top: 10px;
    margin-bottom: 0px;
  }
  
  .home-promo-connect-boxen > img {
    width: 480px;
    margin-bottom: 30px;
  }
  .home-promo-banner-wrapper-left > a > p {
      margin: 0;
      text-decoration: none;
      font-size: 18px !important; 
  }

  .home-promo-banner-wrapper-left > a > img {
    width: 20px;
    margin-left: 10px;
    filter: invert(1);
}
  
  .home-promo-banner .button:hover {
    background-color: #45a049;
  }

.home-promo-info-box-1 {
  background-color: var(--black);
  padding: 15px 15px;
  width: 90px;
  margin: 0 15px 20px 0;
  height: 140px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;  border-radius: 30px;
  margin-top: 20px;
  border: 1px solid rgb(90, 90, 90);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.cta-promo-link > p {
  font-size: 18px !important;
  text-transform: uppercase;
  text-decoration: underline;
}

.home-promo-info-box-1 > p {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}

.home-promo-info-box-1 > p > span {
  font-size: 10px !important;
}

.home-promo-info-boxen {
  display: flex;
  z-index: 100;
}

.home-promo-info-box-1 > img {
  width: 40px;
  margin-top: 15px;
}






  @media (max-width: 800px) {

.home-promo-banner {
    height: 1000px;
}

.home-promo-banner-wrapper-left {
    margin-top: 50px;
    margin-left: 0px;
    text-align: center;
    align-items: center;
    height: 100%;
}


#promo-blur-banner {
  left:-20%;
}

    .home-promo-banner-wrapper {
        flex-direction: column;
    }
    .home-promo-banner-wrapper-right {
        position: relative;
        flex: 1;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        align-items: center;
        justify-content: center;
      }
      
      .home-promo-banner-wrapper-right > img {
        width: 420px;
        border-radius: 10px;
        position: relative;
        display: none;
      }

      .home-promo-banner-wrapper-left > h2 {
        text-align: center;
        margin: 10px 15px 0px 15px;
      }
      
      .home-promo-banner-wrapper-left > p {
        text-align: center;
        margin: 10px 15px 0px 15px;
      }
      .home-promo-info-boxen {
        display: flex;
        z-index: 100;
        margin-bottom: -10px !important;
      }
  }

  @media (max-width: 500px) {
    .home-promo-banner-wrapper-right > img {
        width: 320px;
      }
      .home-promo-connect-boxen > img {
        width: 320px;
        margin-top: 20px;
      }
      .home-promo-banner-wrapper-left > h2 {
        font-size: 38px;
        margin: 10px 10px 0px 10px;
      }

      .home-promo-info-boxen {
        display: flex;
        z-index: 100;
        margin-bottom: 10px !important;
      }
      
      .home-promo-banner-wrapper-left > h2 > span {
        font-size: 38px;
      }
      .home-promo-banner-wrapper-left {
        margin: 0 !important;
      }
      .home-promo-info-box-1 {
        background-color: var(--black);
        padding: 15px 15px;
        width: 75px;
        margin: 0 5px 0 5px;
        height: 140px;
        border-radius: 30px;
        margin-top: 20px;
        border: 1px solid rgb(90, 90, 90);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
      }
  }

  @media (max-width: 380px) {
    .home-promo-info-box-1 {
      width: 70px;
      padding: 15px 10px;

    }

    .home-promo-banner-wrapper-left > a > p {
      margin: 0;
      text-decoration: none;
      font-size: 15px !important; 
  }
  }
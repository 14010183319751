.marketplace-page > h1 {
    color: white !important;
    text-align: left;
    margin-left: 20px;
    font-family: 'Wide', 'Jost';
    margin-bottom: 10px;
    font-size: 54px;
}



.marketplace-page > p {
    color: white;
    text-align: left;
    margin-left: 20px;
    margin-top: 0px;
    color: rgb(199, 199, 199);
    margin-left: 20px;
    max-width: 700px;
}

.marketplace-page-blurred {
    position: relative; /* Wichtig, damit das absolute Positionieren der Kinder relativ zu diesem Element erfolgt */
    width: 100%;
    min-height: 100vh; /* Stelle sicher, dass der Container die volle Bildschirmhöhe einnimmt */
}

.marketplace-page-2 {
    filter: blur(4px);
    -webkit-filter: blur(4px);
    position: absolute;
    top: 0;
    pointer-events: none;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.marketplace-page-2 > h1 {
    color: white !important;
    text-align: left;
    margin-left: 20px;
    font-family: 'Wide', 'Jost';
    margin-bottom: 10px;
    font-size: 54px;
}

.market-blurry-layer {
    animation: fade-in 2s ease-in-out;
    width: 100%;
}

.marketplace-page-2 > p {
    color: white;
    text-align: left;
    margin-left: 20px;
    margin-top: 0px;
    color: rgb(199, 199, 199);
}



.not-eligible-background-blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  filter: blur(8px);
}

.not-eligible-overlay {
  position: absolute;
  color: white;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: top;
  background: rgba(0, 0, 0, 0.5);
}
  
.all-pages-empty-state-inner-2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 700px;
    padding: 20px 20px 50px 20px;
    border-radius: 15px;
    overflow-y: auto;
    z-index: 100;
    margin-top: 40px;
  }

    
.all-pages-empty-state-inner-2::-webkit-scrollbar {
    display: none;
}

  .all-pages-empty-state-inner-2 > h2 {
    font-size: 32px;
    font-family: 'Wide', 'Jost';
    margin-bottom: 0px;
    margin-top: -30px;
  }

  .all-pages-empty-state-inner-2 > p {
    max-width: 500px;
  }

  .all-pages-empty-state-inner-2 > img {
    width: 170px;
    margin-bottom: 50px;
    margin-top: 40px;
    
  }

  .all-pages-empty-state-inner-2 > a {
    color: white;
    text-decoration: none;
    background: linear-gradient(270deg, var(--blue), rgba(0, 0, 0, 0.438));
    background-size: 400% 400%;
    height: 60px;
    padding: 5px 0;
    width: 98%;
    display: flex;
    align-items: center;
    font-size: 16px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    text-transform: uppercase;
    justify-content: center;
    border-radius: 15px;
    margin-top: 30px;
    transition: 130ms ease-in-out;
    backdrop-filter: blur(30px);
    animation: GradientShift 8s ease infinite;
}

@keyframes GradientShift {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}


  .all-pages-empty-state-inner-2 > p {
    margin-bottom: 60px;
  }

  .all-pages-empty-state-inner-2 > a > img {
    width: 20px;
    filter: invert(1);
    margin-left: 10px;
  }


  @media (max-height: 1200px) {

  .all-pages-empty-state-inner-2 > a {
    width: 78%;
}
  }


  @media (max-height: 850px) {
    .all-pages-empty-state-inner-2 > img {
        margin-bottom: 50px;
      }
     
  }


  @media (max-height: 700px) {


      .marketplace-page-blurred {
        overflow: unset; /* Verhindert Scrollbalken aufgrund von Überlappung oder Unschärfe */
    }


    .all-pages-empty-state-link {
        margin-bottom: 100px;
    }

    .no-market-overlay {
        padding: 0;
    }

 

    
  }


  @media (max-width: 700px) {
    .all-pages-empty-state-inner-2 > a {
        width: 98%;
    }
    .marketplace-page > h1 {
        font-size: 34px;
    }
    .all-pages-empty-state-inner-2 > img {
      margin-top: 40px;
    }

  }

  @media (max-width: 500px) {
    .marketplace-page > h1 {
        font-size: 32px;
        margin-top: 30px;
    }



  }


  @media (max-width: 350px) {
    .all-pages-empty-state-inner-2 > img {
      margin-top: 130px;
    }
  }
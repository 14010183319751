.collab-songs-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 20px;
  padding: 20px;
  color: #fff;
}

.collab-song-bottom {
  position: relative;
  padding: 23px 10px 15px 10px;
  margin-top: -15px;
  text-align: left;
  background-color: var(--black);
  overflow: hidden;
  height: 70px;
}

.collab-song-bottom-bg {
  position: absolute;
  top: -30px; /* Move it higher to let the blur overlap */
  left: 0;
  width: 100%;
  height: 300px; /* Increase height to allow more blur */
  background-size: cover;
  background-position: bottom;
  transform: scale(-1);
  opacity: 0.7;
  z-index: 0;
  filter: blur(20px);

}







.collab-song-content {
  position: relative;
  z-index: 1; /* Keep text above the background image */
  color: var(--white);
}

.collab-song-content h4, 
.collab-song-content p {
  margin: 5px 0;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Optional: Improves text readability */
}


.collab-song {
  border-radius: 20px;
  position: relative;
  overflow: hidden; /* Verhindert, dass der Inhalt überläuft */
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: 100ms ease-in-out;
}

.collab-song:hover .collab-contact-type-info {
  opacity: 1;
}

.collab-song:hover {
  transform: scale(1.003);
}

.song-cover-circle {
  position: absolute;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  z-index: 10;
  top: 50%;
  border: 1px solid gray;
  left: 50%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  opacity: 0.9;
  background-color: black;
  transform: translate(-50%, -50%); /* Center the circle */
  transition: transform 0.5s ease-in-out; /* Smooth stopping effect */
}

.playing .song-cover-circle {
  animation: playspin 3s linear infinite;
}

@keyframes playspin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}


.collab-song-top {
  width: 100%;
  padding-top: 100%; /* Dies sorgt für ein 1:1-Seitenverhältnis */
  position: relative; /* Ermöglicht das absolute Positionieren des Bildes */
}

.collab-song-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-bottom-left-radius: 20px;
  z-index: 2;
  opacity: 0.9;
  border-bottom-right-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.collab-song:hover .collab-song-img {
  opacity: 1;
}



.play-pause-button-3 {
  width: 85px;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 80px;
  left: 16px; /* Adjusted for the circle */
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border: none;
  background-color: transparent; /* Make the button background transparent */
  z-index: 2; /* Ensure it is above the circle */
  transition: 200ms ease-in-out;
  cursor: pointer;
  z-index: 100;
}

.play-pause-button-3 > img {
  width: 14px;
  height: 14px;
  padding: 14px;
  z-index: 100;


}

.collab-contact-artist-button {
  position: absolute;
  right: 10px;
  bottom: 8px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  height: 42px;
  margin-top: -35px;
  backdrop-filter: blur(20px);
  border: none;
  background-color: rgba(117, 117, 117, 0.842);
  color: white;
  padding: 0 15px;
  font-family: 'Jost';
}

.collab-contact-artist-button:hover > p {
  color: rgb(255, 255, 255) !important;

}

.collab-contact-artist-button > p {
  margin: 0 5px 0 0 !important;
  font-size: 16px !important;
  color: rgb(245, 245, 245) !important;
  font-weight: 500 !important;
  letter-spacing: 0.5;
  text-transform: uppercase;
}

.collab-contact-artist-button > img {
  width: 20px;
  filter: invert(1);
  margin-left: 5px;
}

.collab-contact-artist-button:hover {
  transform: scale(1.03);
}

.collab-contacted-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.795);
  color: white;
  display: flex;
  align-items: center;
  z-index: 101;
  justify-content: center;
  flex-direction: column;
  font-size: 18px;
  font-weight: bold;
  border-radius: 8px;
  pointer-events: none;
  transition: 200ms ease-in-out;
}

.collab-contacted-overlay > p {
  margin: 0 !important;
  color: white !important;
  font-size: 18px !important;
}

.collab-song:hover .collab-contacted-overlay {
  opacity: 0.5;
}


.collab-contacted-overlay > img {
  width: 40px;
  margin-bottom: 10px;
}

.collab-contact-like-button {
  position: absolute;
  right: 10px;
  top: 40px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  height: 30px;
  margin-top: -35px;
  backdrop-filter: blur(20px);
  border: none;
  background-color: rgba(117, 117, 117, 0);
  color: white;
  padding: 0 10px;
  font-family: 'Jost';
}

.collab-contact-like-button:hover {
  cursor: pointer;
  transform: scale(1.03);
  background-color: rgba(117, 117, 117, 0.377);
}

.collab-contact-type-info {
  position: absolute;
  right: 0px;
  bottom: 55px;
  z-index: 100;
  margin-top: -35px;
  justify-content: center;
  flex-direction: column;
  align-items: right;
  border: none;
  color: rgb(207, 207, 207);
  font-family: 'Jost';
  display: flex;
  opacity: 0;
  transition: 50ms ease-in-out;
}

.countryflag-emoji-text {
  height: 20px;
  display: flex;
  align-items: center;
  right: 15px;
  bottom: 05px;
  position: absolute;
}

.song-genre-collab-info {
  height: 20px;
  display: flex;
  align-items: center;
  right: 15px;
  bottom: 35px;
  position: absolute;
}

.song-genre-collab-info > p {
  color: rgb(228, 228, 228) !important;
  margin-top: 4px !important;
}

.countryflag-emoji-text > p {
  margin-top: -1px !important;
  font-size: 12px !important;
}

.collab-contact-type-info > p {
  margin: 0 7px 5px 0 !important;
  color: rgb(219, 219, 219) !important; 
  font-weight: 600;
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  height: 30px;
  font-size: 13px !important;
  border-bottom-left-radius: 0px !important;
  backdrop-filter: blur(20px);
  background-color: rgba(0, 0, 0, 0.274);
  padding: 0 10px;
  border-radius: 100px;
}

.collab-contact-type-info > img {
  filter: invert(1);
  width: 17px;
}

.collab-contact-like-button > p {
  margin: 0 0px 0 7px !important;
  color: rgba(214, 214, 214, 0.897) !important; 
  font-weight: 400;
  font-size: 13px !important;
}

.collab-contact-like-button > img {
  filter: invert(1);
  width: 17px;
}

.collab-contact-artist-button:hover {
  cursor: pointer;
}

.collab-song-bottom > p {
  font-size: 16px;
  color: var(--white);
  text-align: left;
}


.collab-song-bottom > a > p {
  font-size: 16px;
  color: var(--white);
  text-align: left;
  font-weight:400;

}


.collab-song-bottom > p > img {
  margin-bottom: -5px;
}

.collab-song-bottom> a > p > img {
  margin-bottom: -5px;
}




.collab-song-bottom > a > h4 {
  font-size: 16px;
  color: var(--white);
  text-align: left;

}




.collab-song-bottom > h4 {
  font-size: 16px;
  color: var(--white);
  text-align: left;
}


.collab-song-bottom  > a > h4:hover {
  text-decoration: underline;
}

.collab-song-bottom  > a > p:hover {
  text-decoration: underline;
}


.collab-song h4, .collab-song p {
  margin: 5px 0 0px 0;
  font-size: 16px;
  color: var(--white);
  font-weight: 500;
}

.collab-song h4 {
  margin-top: 15px;
  font-weight: 400;
  font-size: 14px;
}





/* Media Queries für spezifische Spaltenanpassungen */
@media (min-width: 1200px) {
  .collab-songs-grid {
      grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 900px) and (max-width: 1199px) {
  .collab-songs-grid {
      grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .collab-songs-grid {
      grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 599px) {
  .collab-songs-grid {
      grid-template-columns: 1fr;
  }

.collab-song-content > div > a > p {
  font-size: 19px !important;
}


}



.component-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.component-wrapper-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to top, var(--blue), var(--black));

}

.orb-animation-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 1300px;
  overflow: hidden;
}

.orb {
  position: absolute;
  border-radius: 50%;
  opacity: 0.8;
  background-color: var(--red); /* Beispiel Farbe */
  filter: blur(20px);
  animation: moveOrb infinite linear;
  z-index: 0;
}

.orb2 {
  position: absolute;
  border-radius: 50%;
  opacity: 0.8;
  background-color: var(--blue); /* Beispiel Farbe */
  filter: blur(20px);
  animation: moveOrb infinite linear;
  z-index: 0;
}


.orb3 {
  position: absolute;
  border-radius: 50%;
  opacity: 0.8;
  background-color: var(--red); /* Beispiel Farbe */
  filter: blur(20px);
  animation: moveOrb infinite linear;
  z-index: 0;
}

.orb4 {
  position: absolute;
  border-radius: 50%;
  opacity: 0.8;
  background-color: var(--red); /* Beispiel Farbe */
  filter: blur(20px);
  animation: moveOrb infinite linear;
  z-index: 0;
}

.orb-1 {
  bottom: 5%;
  left: 25%;
  width: 200px;
  height: 200px;
  animation-duration: 8s;
}

.orb2-1 {
  bottom: 15%;
  left: 35%;
  width: 100px;
  height: 100px;
  animation-duration: 8s;
}

.orb4-1 {
  bottom: 15%;
  left: 35%;
  width: 100px;
  height: 100px;
  animation-duration: 8s;
  background-color: var(--blue);
}

.orb-2 {
  bottom: 15%;
  left: 55%;
  width: 300px;
  height: 300px;
  animation-duration: 10s;
}

.orb2-2 {
  bottom: 25%;
  left: 64%;
  width: 120px;
  height: 120px;
  animation-duration: 10s;
}

.orb4-2 {
  bottom: 25%;
  left: 64%;
  width: 120px;
  height: 120px;
  animation-duration: 10s;
  background-color: var(--blue);
}




.orb-3 {
  top: 25%;
  left: 22%;
  width:350px;
  height: 350px;
  animation-duration: 15s;
}

.orb2-3 {
  top: 25%;
  left: 26%;
  width:160px;
  height: 160px;
  animation-duration: 15s;
  opacity: 1;

}




.orb-4 {
  top: 5%;
  right: 15%;
  width: 400px;
  height: 400px;
  animation-duration: 20s;
}

.orb2-4 {
  top: 10%;
  right: 25%;
  width: 180px;
  height: 180px;
  animation-duration: 20s;
  opacity: 0.3;
}


/* Weitere spezifische Styles für ".orb" Elemente */

@keyframes moveOrb {
  0% {
    transform: translateX(0) translateY(0);
  }
  50% {
    transform: translateX(-50px) translateY(50px);
  }
  100% {
    transform: translateX(0) translateY(0);
  }
}


@media (max-width: 1200px) {
  .orb {
    position: absolute;
    border-radius: 50%;
    opacity: 0.8;
    background-color: var(--red); /* Beispiel Farbe */
    filter: blur(20px);
    animation: moveOrb infinite linear;
    z-index: 0;
  }
  
  .orb-1 {
    bottom: 10%;
    left: 27%;
    width: 200px;
    height: 200px;
    animation-duration: 8x;
  }
  
  .orb-2 {
    bottom: 22%;
    left: 60%;
    width: 250px;
    height: 250px;
    animation-duration: 10s;
  }
  
  .orb-3 {
    top: 25%;
    left: 22%;
    width:300px;
    height: 300px;
    animation-duration: 15s;
  }
  
  .orb-4 {
    top: 2%;
    right: 12%;
    width: 350px;
    height: 350px;
    animation-duration: 20s;
  }
}


@media (max-width: 1000px) {
  .orb {
    position: absolute;
    border-radius: 50%;
    opacity: 0.8;
    background-color: var(--red); /* Beispiel Farbe */
    filter: blur(20px);
    animation: moveOrb infinite linear;
    z-index: 0;
  }
  
  .orb-1 {
    bottom: 20%;
    left: 30%;
    width: 150px;
    height: 150px;
    animation-duration: 8s;
  }
  
  .orb-2 {
    bottom: 30%;
    left: 60%;
    width: 200px;
    height: 200px;
    animation-duration: 10s;
  }
  
  .orb-3 {
    top: 25%;
    left: 20%;
    width:250px;
    height: 250px;
    animation-duration: 15s;
  }
  
  .orb-4 {
    top: 5%;
    right: 10%;
    width: 300px;
    height: 300px;
    animation-duration: 20s;
  }
}



@media (max-width: 800px) {
  .orb {
    position: absolute;
    border-radius: 50%;
    opacity: 1;
    background-color: var(--red); /* Beispiel Farbe */
    filter: blur(20px);
    animation: moveOrb infinite linear;
    z-index: 0;
  }
  
  .orb-1 {
    bottom: 30%;
    left: 30%;
    width: 100px;
    height: 100px;
    animation-duration: 8s;
  }
  
  .orb-2 {
    bottom: 40%;
    left: 60%;
    width: 150px;
    height: 150px;
    animation-duration: 10s;
  }
  
  .orb-3 {
    top: 25%;
    left: 20%;
    width:200px;
    height: 200px;
    animation-duration: 15s;
  }
  
  .orb-4 {
    top: 5%;
    right: 10%;
    width: 250px;
    height: 250px;
    animation-duration: 20s;
  }
}


@media (max-width: 450px) {
  .orb {
    position: absolute;
    border-radius: 50%;
    opacity: 1;
    background-color: var(--red); /* Beispiel Farbe */
    filter: blur(20px);
    animation: moveOrb infinite linear;
    z-index: 0;
  }
  
  .orb-1 {
    bottom: 32%;
    left: 25%;
    width: 100px;
    height: 100px;
    animation-duration: 8s;
  }
  
  .orb-2 {
    bottom: 45%;
    left: 70%;
    width: 150px;
    height: 150px;
    animation-duration: 10s;
  }
  
  .orb-3 {
    top: 30%;
    left: 0%;
    width:150px;
    height: 150px;
    animation-duration: 15s;
  }
  
  .orb-4 {
    top: 5%;
    right: 5%;
    width: 200px;
    height: 200px;
    animation-duration: 20s;
  }
}
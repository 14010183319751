

.collab-chat-artist-info {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 10px;
}

.collab-chat-artist-image {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
}

.collab-chat-artist-name {
    font-size: 14px;
    color: rgb(255, 255, 255);
    font-weight: 600;
}

.collab-start-container {
    background-color: #00000052;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 15px;
    border: 1px solid rgba(204, 204, 204, 0.349);
}


.collab-start-container > div > h3 {
    margin: 0 10px 5px 0;
    font-size: 14px;
    color: white;
}

.collab-start-container > div {
    display: flex;
    flex-direction: column;
    align-items: left;
}

.collab-start-container > ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.collab-start-container > ul > li {
    font-size: 12px;
    color: white;
    margin-bottom: 3px;
}

.collab-start-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    margin-top: 5px;
    background-color: rgba(92, 92, 92, 0.171);
    height: 32px;
    border-radius: 4px;
    border-top-right-radius: 100px;
    border-top-left-radius: 100px;
    border-bottom-right-radius: 100px;
    border-bottom-left-radius: 0px;
}

.collab-start-item-image {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    object-fit: contain;
    filter: invert(1);
    margin-left: 10px;
}

.mobile-new-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    height: 60px;
    margin-top: 10px;
    background-color: var(--black);
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: rgba(0, 128, 0, 0);
  }

  .mobile-new-header-wrapper {
      display: none;
      flex-direction: column;
  }

  .mobile-new-header-wrapper-2 {
    display: none;
    flex-direction: column;
}

  .mobile-new-header-add {
      display: flex;
      margin-top: 12px;
      align-items: center;
      justify-content: center;
      color: white;
  }


  .mobile-new-header-add > a {
      color: white;
      margin: 0 10px;
      text-decoration: none;
      font-size: 14px;
      text-transform: uppercase;
      padding: 5px 10px;
      border-radius: 100px;
      letter-spacing: 0.5px;
      font-weight: 400;
      color: lightgray;
  }

  .mobile-new-header-add > a.active {
    border: 1px solid grey;
    font-weight: 500;
    color: white;
}


@media (max-width: 750px) {
  .mobile-new-header-wrapper-2 {
      display: flex;
  }
}

  @media (max-width: 600px) {
    .mobile-new-header-wrapper {
        display: flex;
    }

    .mobile-new-header-add > a {
      font-size: 16px;
    }
  }

  @media (max-width: 360px) {
    .mobile-new-header-add > a {
      color: white;
      margin: 0 10px;
      text-decoration: none;
      font-size: 14px;
      text-transform: uppercase;
      padding: 5px 10px;
      border-radius: 100px;
      letter-spacing: 0.5px;
      font-weight: 400;
      color: lightgray;
  }
  }
.create-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Stellt sicher, dass der Container die volle Höhe des Viewports einnimmt */

  }
  
  .create-page-window {
    background-color: rgba(2, 2, 2, 0);
    height: 650px;
    max-width: 500px;
    width: 500px;
    border-radius: 25px;
    position: relative;
    backdrop-filter: blur(50px);
    box-shadow: rgba(0, 0, 0, 0.507) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px;
    opacity: 1; /* Anfangs unsichtbar machen */
    animation: fade-in-2 0.5s;
  }
  .create-page-window::-webkit-scrollbar {
    display: none; /* Verstecke die Scrollleiste */
}


  @keyframes fade-in-2 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }



.create-bottom-gradient {
  height: 70px;
  width: 100%;
  padding-left: 50px;
  margin-left: -25px;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.432), transparent); /* Gradient from black to transparent */
  position: absolute;
  bottom: -20px;
  z-index: 9;
}


  .create-page-form {
    width: 100%;
    max-width: 400px; /* Begrenzt die Breite des Formulars innerhalb des Fensters */
  }
  
  .form-input, .form-button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    border: none;
  }
  
  .form-input {
    background-color: #555; /* Dunklerer Hintergrund für Input-Felder */
    color: #fff; /* Weiße Schrift */
  }
  
  .form-button {
    background-color: #007bff; /* Blauer Button */
    color: #fff;
    cursor: pointer;
  }
  
  .form-button:hover {
    background-color: #0056b3; /* Dunkleres Blau beim Hover */
  }
  
  .error-message {
    color: #ff5555; /* Rote Schrift für Fehlermeldungen */
    margin-top: 10px;
  }
  

  .create-page-header-fonts {
    color: white;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .create-page-header-fonts > h2 {
    font-family: 'Wide', 'Jost';
  }

  .create-page-header-fonts > p {
    color: lightgray;
  }

  .next-button-container {
    position: sticky;
    bottom: 0px; /* Hält den Button am unteren Rand des `content-container` */
    width: 100%; /* Breite anpassen, um Padding des Elternelements zu berücksichtigen */
    right: 0px;
    height: 70px;
    z-index: 10;
    pointer-events: none;
    }

.gig-settings-back-button {
  width: fit-content;
  margin-top: 20px;
  padding: 15px 25px;
  border-radius: 10px;
  color: var(--white);
  background: none;
  background-size: 400% 400%;
  border: none;
  align-self: flex-end;
  position: absolute;
  bottom: 0px;
  left: 0px;
  outline: none;
  animation: gradientAnimation 2s ease infinite;
  transition: transform 0.2s;
  font-family: 'Jost';
  font-size: 15px;
  z-index: 10;
  pointer-events: all;

}

.gig-settings-back-button:hover {
  cursor: pointer;
}

  .create-page-next-button-2 {
      width: fit-content;
      margin-top: 20px;
      padding: 15px 25px;
      border-radius: 10px;
      color: var(--white);
      background: linear-gradient(270deg, #4449AD, #7479DD);
      background-size: 400% 400%;
      border: none;
      align-self: flex-end;
      position: absolute;
      bottom: 20px;
      right: 20px;
      outline: none;
      animation: gradientAnimation 2s ease infinite;
      transition: transform 0.2s;
      font-family: 'Jost';
      font-size: 15px;
      z-index: 100;
      pointer-events: all;
  
    }


  .create-page-next-button {
    width: fit-content;
    margin-top: 20px;
    padding: 15px 25px;
    border-radius: 10px;
    color: var(--white);
    background: linear-gradient(270deg, #4449AD, #7479DD);
    background-size: 400% 400%;
    border: none;
    align-self: flex-end;
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 10;
    outline: none;
    animation: gradientAnimation 2s ease infinite;
    transition: transform 0.2s;
    font-family: 'Jost';
    font-size: 15px;
    pointer-events: all;

  }
  
    .create-page-next-button:hover {
      cursor: pointer;
      transform: scale(1.05);
    }


    .create-page-next-button-2:hover {
      cursor: pointer;
      transform: scale(1.05);
    }
    
    .create-page-next-button:disabled {
      background-image: none;
      background-color: rgb(24, 24, 24); /* Grau */
      cursor: not-allowed;
    }
    
  

    @media (max-width: 800px) {
      .create-page-container {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh; /* Stellt sicher, dass der Container die volle Höhe des Viewports einnimmt */
    
      }

      .create-page-header-fonts > h2 {
        margin-top: 50px;
      }
      
      .create-page-window {
        height: unset;
        box-shadow: none;
        width: unset;
        border-radius: 0px;
        width: 100%;
      }
      .page-mood-selection {
        min-height: 86vh;
      }

      .next-button-container {
        position: sticky;
        bottom: 0px; /* Hält den Button am unteren Rand des `content-container` */
        width: 100%; /* Breite anpassen, um Padding des Elternelements zu berücksichtigen */
        right: 0px;
        height: 70px;
        z-index: 10;
        pointer-events: none;
        }

    }


    
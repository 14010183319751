.dashboard-page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--black);
}

.dashboard-page > img {
    width: 250px;
    position: absolute;
    top: 30px
}

.dashboard-page > h2 {
    font-size: 52px;
    color: white;
    margin-bottom: -20px;
    margin-top: 0px;
    z-index: 10;
}

@keyframes pulsate {
    0% {
      transform: scale(0.9);
      filter: blur(50px);
      opacity: 1;
    }
    50% {
      transform: scale(1);
      filter: blur(60px);
      opacity: 0.7;
      background-color: black;
    }
    100% {
      transform: scale(0.9);
      filter: blur(50px);
      opacity: 1;
    }
  }
  
  .pulsator {
    width: 340px;
    position: absolute;
    height: 340px;
    border-radius: 1000px;
    background-color: var(--red);
    margin-bottom: 30px;
    top: 30%;
    filter: blur(50px);
    animation: pulsate 4s infinite ease-in-out;
  }
  
.dashboard-page > p {
    font-size: 20px;
    color: white;
    z-index: 10;
    margin: 30px 15px 0px 15px;
}

.user-image-top {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 100px;
  margin-top: 10px;
  margin-right: 20px;
  margin-left: -10px;
}


@media (max-width: 350px) {
  .pulsator {
    width: 240px;
    position: absolute;
    height: 240px;
    border-radius: 1000px;
    background-color: var(--red);
    margin-bottom: 30px;
    top: 30%;
    filter: blur(50px);
    animation: pulsate 4s infinite ease-in-out;
  }
}
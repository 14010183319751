/* Dark mode CSS for ShowRssCsv */
.rss-csv-container {
    padding: 20px;
    background-color: #1e1e1e;
    color: #f5f5f5;
    border-radius: 8px;
    margin: 0 auto;
  }
  
  .rss-csv-header {
    font-size: 24px;
    margin-bottom: 20px;
    color: #f5f5f5;
    text-align: center;
  }
  
  .rss-clear-button {
    background-color: #d9534f;
    color: #f5f5f5;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .rss-clear-button:hover {
    background-color: #c9302c;
  }
  
  .rss-youtube-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .rss-youtube-item {
    padding: 15px;
    border-radius: 8px;
    text-align: center;
  }
  
  .rss-youtube-item h4 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #f5f5f5;
  }
  
  .rss-youtube-item a {
    color: #4a90e2;
    text-decoration: none;
    font-weight: bold;
  }
  
  .rss-youtube-item a:hover {
    text-decoration: underline;
  }
  
  .rss-youtube-item p {
    font-size: 14px;
    color: #aaa;
    margin-top: 10px;
  }
  
.submission-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px; /* Abstand zwischen den Containern */
    color: white;
    z-index: 1000;
    padding-top: 50px !important;
    padding-bottom: 100px; 
}

.submission-screen > h1 {
z-index: 1000;
}

.submission-screen-bottom-wrapper {
    display: flex;
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px; /* Abstand zwischen Elementen innerhalb der Container */
}

.top-container {
    border: 1px solid rgba(82, 82, 82, 0.356);
    padding: 20px 10px;
    border-radius: 15px;
    width: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    z-index: 100;
    background-color: var(--black);
}

.top-container > img {
    width: 30px;
    margin-right: 10px;
}

.top-container > p {
    font-family: 'Wide', 'Jost';
    font-size: 16px;
    color: rgb(228, 228, 228);
    margin: 0px;
}

.bottom-container-2 > p {
    width: 100%;
    font-family: 'Jost';
}


.bottom-container {
    border: 1px solid rgba(114, 114, 114, 0.253);
    background-color: rgba(10, 10, 10, 0.384);
    padding: 10px;
    margin: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    border-radius: 25px;
    width: 300px;
    backdrop-filter: blur(20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: visible; /* Stellt sicher, dass Inhalte außerhalb nicht abgeschnitten werden */
    animation: slideUp-3 0.5s ease-out forwards; /* Animation anwenden */

}

@keyframes slideUp-3 {
    from {
      transform: translateY(25px); /* Starte 20px unterhalb der Endposition */
      opacity: 0; /* Beginne mit einer Transparenz von 0 für einen weichen Einstieg */
    }
    to {
      transform: translateY(0px); /* Ende an der normalen Position */
      opacity: 1; /* Volle Sichtbarkeit am Ende */
    }
  }
  

.bottom-container-2 {
    border: 1px solid rgba(255, 255, 255, 0.356);
    background-color: rgba(255, 255, 255, 0.082);
    padding: 10px;
    border-radius: 15px;
    width: 300px;
    display: flex;
    align-items: center;
    z-index: 100;
    justify-content: space-between;/* Stellt sicher, dass Inhalte außerhalb nicht abgeschnitten werden */
}

.container-bottom-text {

    margin-bottom: 0px;
    position: absolute;
    z-index: 1011;
    top: -10px; 
    right: 3px;
    background-color: rgba(0, 0, 0, 0.123);
    max-width: fit-content;
    padding: 4px 10px;
    border-radius: 100px;
    font-size: 12px;

}
/* Die anderen Stile bleiben gleich */






.sub-next-button > img {
    height: 12px;
    margin-right: 15px;
    filter: invert(1);
    padding: 10px;
    border-radius: 100px;
    border: 1px solid rgba(0, 0, 0, 0.568);
}

.sub-next-button > p {
    width: 100%;
    margin-right: -40px;

}


.sub-next-button {
    width: 100%;
    border-radius: 15px;
    display: flex;
    height: 55px;
    align-items: center;
    justify-content: space-between;
    transition: smooth 200ms;
    font-family: 'Jost';
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5;
    background-color: var(--red);
    color: white;    
    color: var(--white);
    background: linear-gradient(90deg, var(--blue), rgba(0, 0, 255, 0.5), rgba(14, 14, 24, 0.5), rgba(29, 29, 128, 0.74), var(--blue));
    background-size: 200% 200%;
    animation: moveSpotlight 8s linear infinite;
    transition: transform 0.2s;
}



.sub-next-button:hover {
    scale: 1.01;
    cursor: pointer;
}


.container-bottom-text {
    margin-bottom: 20px; /* Anpassen für den Abstand zum nächsten Element */
    width: 100%;
    text-align: center; /* Zentriert den Text, falls gewünscht */
}

.bottom-container > h3 {
    margin-top: 65px;
    margin-bottom: 0px;
    font-family: 'Wide', 'Jost', 'Josefin Sans', sans-serif;
}

.bottom-container > h4 {
    margin-top: 0px;
    color: rgb(196, 196, 196);
    font-weight: 400;
    margin: 0px 20px;
    margin-bottom: 30px;
}

.mobile-submission-logo {
    animation: none !important;
    width: 200px !important;
    margin-bottom: 20px !important;
    display: none;
}

.bottom-container > div > img {
    width: 140px;
    margin-top: 20px;
    animation: fade-in 0.3s;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.bottom-container > div {
    height: 160px;
}



.profile-image {
    /* Styling für das Bild */
    width: 35px; /* Beispielbreite */
    height: auto; 
    margin-left: 10px;
    filter: invert(1);
}

@media (max-width: 700px) {
    .submission-screen-bottom-wrapper {
        display: flex;
        flex-direction: column;
    }
}


@media (max-width: 550px) {
    .mobile-submission-logo {
        animation: none !important;
        width: 200px !important;
        margin-bottom: 20px !important;
        display: block;
    }

    
}


@media (max-width: 350px) {
    .bottom-container {
        width: 85%;
    }

    .top-container {
        width: 85%;
    }

    
}
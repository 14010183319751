.home-banner {
    text-align: center;
    padding: 0px 0px 20px 0px;
    background-color: var(--black);
  }
  

  .sub-container {
    display: flex;
    align-items: center;
    overflow-x: auto; 
    justify-content: flex-start;
    /* Ermöglicht das horizontale Scrollen */
  }
  
  .fixed-text {
    flex-shrink: 0; /* Verhindert das Schrumpfen des Textes */
    white-space: nowrap; /* Hält den Text in einer Zeile */
    color: var(--white);
    font-size: 12px;
    margin-right: 20px; 
    margin-left: var(--edge);
    margin-top: 10px;
    letter-spacing: 0.5px;
  }
  
  .artist-list {
    display: flex;
    list-style: none;
    padding: 0;
    font-family: 'Wide', 'Jost', 'Josefin Sans', sans-serif;
    font-size: 10px;
    margin: 10px 0px 0px 0px;
    overflow-x: auto; /* Ermöglicht das horizontale Scrollen innerhalb der Liste */
    max-width: calc(100% - 50px); /* Maximalbreite abzüglich der Breite des festen Textes */
    white-space: nowrap; /* Stellt sicher, dass die Listenelemente nicht umbrechen */
  }
  
  .artist-list li {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 15px 5px 7px;
    color: rgb(216, 216, 216);
    white-space: nowrap; /* Verhindert, dass die Namen umbrechen */
    margin-right: 15px;
    font-size: 10px;
    background-color: rgb(20, 20, 20);
    border-radius: 100px;
    border: 1px solid rgb(56, 56, 56);
  }
  
  /* Stil für die Scrollbar, optional */
  .artist-list::-webkit-scrollbar {
    display: none;
  }
  
  .artist-list::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  .artist-list::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  .mini-image {
    width: 35px; /* Oder eine passende Größe */
    height: 35px; /* Oder eine passende Größe */
    object-fit: cover;
    border-radius: 50%;
    opacity: 1;
    animation: fade-in 2s ease-in-out;
  }

  .artist-list li:hover .mini-image {
    opacity: 1;
  }

  .artist-list li:hover {
    color: white;
    background-color: rgb(15, 15, 15);
  }


  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .artist-list-image-container {
    background-color: rgb(0, 0, 0);
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px; /* Abstand zwischen Bild und Name */
    animation: pulseBackground 3s infinite; /* Pulsierungsanimation für 5 Sekunden, unendlich wiederholen */
  }
  
  @keyframes pulseBackground {
    0% {
      background-color: rgb(0, 0, 0); /* Schwarz */
    }
    50% {
      background-color: rgb(128, 128, 128); /* Grau */
    }
    100% {
      background-color: rgb(0, 0, 0); /* Schwarz */
    }
  }
  

  .red-dot {
    display: inline-block; /* oder `display: inline;`, je nach Bedarf */
    /* Andere Stildefinitionen wie Breite, Höhe und Hintergrundfarbe hier einfügen */
    /* Zum Beispiel: */
    width: 5.8vw;
    height: 5.8vw;
    background-color: #da3933;
    border-radius: 50%; /* macht es zu einem Kreis */
    margin: 0 20px 20px 5px; /* Fügt etwas Platz hinzu, ändern Sie es nach Bedarf */
    vertical-align: middle; /* Ausrichtung mit dem Text */
    animation: pulse 4s infinite ease-in-out;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.9); /* Vergrößern auf 110% der ursprünglichen Größe */
    }
    100% {
      transform: scale(1);
    }
  }

  .top-banner-font {
    width: 99%;
    margin: 0 auto;
    font-size: 6.0vw;
    line-height: 1.2;
    font-family: 'Wide', 'Jost', 'Josefin Sans', sans-serif;
    color: var(--white);
    padding-top: 15px;
  }
  
  @media (max-width: 1300px) {
    .top-banner-font {
      font-size: 6.0vw;
    }
    .red-dot {
      margin: 0px 20px 10px 5px;
    }
  }

  @media (max-width: 1000px) {
    .top-banner-font {
      padding-top: 10px;
      font-size: 6.0vw;
    }
    .red-dot {
      margin: 0px 15px 10px 5px;
    }
  }
  
  @media (max-width: 800px) {
    .red-dot {
     margin: 0 5px 10px 5px
    }
    .top-banner-font {
      font-size: 5.8vw;
      text-align: left;
      width: 95%;
      margin-left: var(--edge);
      padding-top: 10px;
    }
    .red-dot {
      margin: 0px 15px 10px 5px;
    }
  }
  
  @media (max-width: 500px) {
    .red-dot {
     margin: 0 5px 10px 5px
    }
    .top-banner-font {
      font-size: 50px;
      text-align: left;
      width: 95%;
      margin-left: var(--edge);
      padding-top: 10px;
      margin-top: 15px;
    }
    .red-dot {
      margin: 0px 15px 10px 5px;
    }

    .artist-list {
      max-width: calc(100% - 50px)
    }

    .artist-list li {
      margin-right: 10px;
    }

    .fixed-text {
      margin-right: 10px;
    }
  }


  @media (max-width: 340px) {
    .top-banner-font {
      font-size: 40px;
      text-align: left;
      width: 95%;
      margin-left: var(--edge);
      padding-top: 10px;
    }
  }

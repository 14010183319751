/* AwardCreator.css */
.award-creator {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: var(--black);
    color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    margin: auto;
  }
  
  .award-creator label {
    font-weight: bold;
  }
  
  .award-creator input[type="text"],
  .award-creator input[type="date"],
  .award-creator textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border-radius: 4px;
    border: none;
    color: black;
  }
  
  .award-creator button {
    padding: 10px 20px;
    cursor: pointer;
    background-color: #4CAF50; /* Green */
    color: white;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .award-creator button:hover {
    background-color: #45a049;
  }
  
  /* Wenn Sie Variablen in Ihrem Projekt noch nicht definiert haben, fügen Sie diese zum :root hinzu */
  :root {
    --black: #121212; /* Oder welchen Farbwert Sie für 'schwarz' bevorzugen */
  }
  
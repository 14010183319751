.all-pages-grid-item-link {
    text-decoration: none;
    position: relative;
    color: inherit; /* Erbt die Farbe vom Elternelement */
  }
  
  .all-pages-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(90%, 1fr));
    gap: 20px;
    list-style: none;
    padding-bottom: 50px;
  }

  #page-prev-live-link {
      border: 1px solid rgb(58, 58, 58);
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
  }

  #page-prev-live-link > img {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }

  #page-prev-live-link > p {
   margin: 0;
   padding: 0;
  }

  .live-page-container-2 {
      border-radius: 15px;
      width: 500px;
      pointer-events: none;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;  }


  .page-not-live-notice {
      background-color: rgba(0, 0, 0, 0.315);
      flex-direction: column;
      border-radius: 15px;
      width: 500px;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 500px;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;  
  }

  .page-not-live-notice > img {
      width: 150px;
      margin-top: -30px;
  }

  .all-pages-bottom-top {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 50px;
  }

  .all-pages-bottom-top > a {
      background-color: var(--blue);
      margin: 10px;
      padding: 10px 18px;
      border-radius: 100px;
      font-size: 14px;
      border: 1px solid (--blue);

  }

  .all-pages-bottom {
      text-align: left;
      margin-left: 20px;
      width: 100%;
  }
  
  .all-pages-grid-item {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;    
    display: flex;
    background-color: #0000000e;
    border: 1px solid rgb(44, 44, 44);
    width: 94%;
    max-width: 1300px;
    margin-left: -10px;
    backdrop-filter: blur(50px);
    border-radius: 25px;
    position: relative;
    padding: 1rem;
    list-style: none; /* Entfernt Bulletpunkte vom li */
    transition: transform 0.2s ease, box-shadow 0.2s ease; /* Optional: Hinzufügen von Hover-Animation */
  }

  .all-pages-top-info-box {
      display: flex;
      font-size: 12px;
      height: 30px;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;
  }


  .all-pages-gig-service {
      background-color: var(--black);
      margin: 0 10px 0 0;
      padding: 5px 10px;
      border-radius: 10px;
      font-weight: 400;
  }

  .all-pages-gig-status {
    background-color: var(--black);
    margin: 0 10px 0 0;
    padding: 5px 10px;
    border-radius: 100px;
    font-weight: 400;
}

.all-pages-gig-info-boxes {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0px;
}

.all-pages-gig-info-box {
    display: flex;
    margin-right: 25px;
}

.all-pages-gig-stats-boxes {
    display: flex;  /* Verwendet Flexbox für das Layout */
    flex-wrap: wrap;  /* Erlaubt den Umbruch auf die nächste Zeile */
    justify-content: flex-start;  /* Gleichmäßiger Abstand zwischen den Elementen */
    margin: 20px 0;  /* Vertikaler Abstand für den gesamten Container */
    gap: 10px;
}

.all-pages-gig-stats-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(190, 190, 190, 0.062);
    border-radius: 15px;
    width: 46%;
    height: 60px;
    margin: 0 15px 0 0;
}

.all-pages-gig-stats-box > div {
    display: flex;
    align-items: center;
    justify-content: center;  /* Zentriert die Elemente vertikal */
}

.all-pages-gig-stats-box > div > p {
    color: white;
    font-size: 14px;
}

.all-pages-gig-stats-box > p {
    margin-right: 15px;
    font-size: 12px;
    color: grey;
}

.all-pages-gig-stats-box img {
    width: 20px;  /* Setzt eine feste Größe für das Bild */
    height: 20px;  /* Setzt eine feste Größe für das Bild */
    margin-right: 10px;
    margin-left: 15px;  /* Abstand zwischen Bild und Text */
    filter: invert(1);
    opacity: 0.5;
}

.all-pages-gig-text {
    font-size: 14px;  /* Größe des dynamischen Textes */
    color: rgb(214, 214, 214);  /* Farbe des Textes */
}

.all-pages-gig-info-box > img {
    width: 20px;
    margin-right: 5px;
    filter: invert(1);
    opacity: 0.5;
}


.all-pages-gig-status.live {
  background-color: rgb(58, 165, 58);
  animation: pulse-2 2s infinite;
}

@keyframes pulse-2 {
  0% {
      transform: scale(1);
      box-shadow: 0 0 0 rgba(58, 165, 58, 0.7);
  }
  50% {
      transform: scale(1.05);
      box-shadow: 0 0 10px rgba(58, 165, 58, 0.7);
  }
  100% {
      transform: scale(1);
      box-shadow: 0 0 0 rgba(58, 165, 58, 0.7);
  }
}


  .all-pages-route {
    margin-bottom: 0px;
    font-family: 'Jost';
    font-size: 14px;
    margin-bottom: 15px;
    font-weight: 300;
    color: rgb(228, 228, 228);
    letter-spacing: 1px;
  }

  .all-pages-route > span {
      font-weight: 600;
      color: white;
  }
  


  .all-pages-grid-item:hover .all-page-delete-button {
    display: block;
  }

  .all-page-delete-button {
    position: absolute;
    left: 5px !important;
    top: 5px;
    height: 35px;
    width: 35px;
    border-radius: 100px;
    z-index: 1000;
    background-color: rgb(58, 58, 58);
    border: none;
    display: none;
  }

  .all-page-delete-button:hover {
    transform: scale(1.04);
    cursor: pointer;
    background-color: var(--red);
  }


  .all-page-delete-button > img {
    filter: invert(1);
    width: 10px;
  }


  .all-pages-grid-item.no-gig-settings {
    background-color: #2020206c;
    border: 1px solid var(--red);
  }

  .all-pages-bottom-bottom {
      display: flex;
      align-items: center;
      flex-direction: column;
  }

  .all-pages-top-label {
      margin-bottom: 10px;

  }

  .all-pages-top-label > p {
      margin-right: 10px !important;
      color: grey;
      font-size: 12px;
  }

  .all-pages-top-label > hr {
    height: 1px;
    background-color: rgb(97, 97, 97);
    border: none;
    width: 100%;
  }

  .all-pages-bottom-bottom > div > button {
      height: 40px;
      margin-right: 10px;
      width: 110px;
      border: none;
      border-radius: 100px;
      border: 1px solid rgb(117, 117, 117);
      font-family: 'Jost';
      color: white;
      font-size: 12px;
      background-color: rgba(128, 128, 128, 0.151);
  }

  .all-pages-bottom-bottom > div {
    justify-content: flex-start;
    width: 100%;
    display: flex;
    align-items: center;

  }



  .page-prev-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .page-prev-switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider-prev {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(43, 43, 43);
    transition: .4s;
    border-radius: 34px;
  }
  
  .slider-prev:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: rgb(102, 102, 102);
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  

  .prev-page-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1000;
  }

  .prev-page-qr-container {
      border-radius: 10px;
      overflow: hidden;
      height: 370px
  }

  .page-prev-qr-button:hover {
    cursor: pointer;
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(0.7);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  .prev-page-modal-content {
    background: rgb(29, 29, 29);
    backdrop-filter: blur(50px);
    border: 1px solid rgba(124, 124, 124, 0.274);
    padding: 20px;
    border-radius: 25px;
    position: relative;
    display: flex;
    z-index: 100;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: scaleIn 0.14s ease-out forwards;
  }

  .prev-page-modal-content > h2 {
      font-family: 'Wide', 'Jost';
      margin-bottom: 0px;
      margin-bottom: 0px;
  }

  .prev-page-modal-content > p {
    margin-bottom: 10px;
    margin-top: 10px;
    max-width: 400px;
    text-align: center;
    font-size: 14px;
}

  .prev-page-modal-content > input {
    width: 95%;
    height: 45px;
    border-radius: 10px;
    outline: none;
    border: 0px;
    margin: 10px 0;
    padding-left: 15px;
    font-family: 'Jost';
    color: white;
    background: rgba(255, 255, 255, 0.137);
  }

  .prev-page-modal-content > input::placeholder {
    color: white;
  }

  .prev-page-modal-content > textarea::placeholder {
    color: white;
  }

  .import-reviews-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

.prev-upload-image-container {
  width: 100%;
  background-color: rgba(36, 36, 36, 0.37);
  height: 150px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

  .prev-upload-image-container > img {
    width: 80px;
    max-height: 80px;
    border-radius: 10px;
    object-fit: cover;
    margin-left: 10px;
    margin-top: 20px;
  }

  .prev-upload-image-container:hover {
    cursor: pointer;
  }


  .prev-default-image {
    width: 30px !important;
    filter: invert(1);
  }

  .prev-save-button.active {
    background-color: var(--blue) !important;
  }

  .import-reviews-buttons > button {
    width: 150px;
    height: 45px;
    border-radius: 100px;
    margin: 0 10px;
    font-family: 'Jost';
    border: 1px solid white;
    color: white;
    background: none;
    margin-top: 10px;
  }

  .prev-page-modal-content > textarea {
    width: 95%;
    height: 80px;
    border-radius: 10px;
    border: 0px;
    margin-bottom: 10px;
    padding-left: 15px;
    font-family: 'Jost';
    padding-top: 10px;
    font-size: 14px;
    color: white;
    outline: none;
    background: rgba(255, 255, 255, 0.137);
  }

  .prev-page-thank-you {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .prev-page-thank-you > h2 {
    margin-bottom: 0px;
  }

  .prev-page-thank-you > button {
    width: 100%;
    height: 50px;
    border: none;
    background: var(--blue);
    color: white;
    border-radius: 100px;
    margin-top: 10px;
    font-family: 'Jost';
  }
  

  .prev-page-thank-you > button:hover {
    cursor: pointer;
}
  
  .prev-page-modal-content > button {
      width: 100%;
      height: 50px;
      border: none;
      background: grey;
      color: white;
      border-radius: 100px;
      margin-top: 10px;
      font-family: 'Jost';
      
  }

  .prev-page-modal-content > button:hover {
      cursor: pointer;
  }
  

  .prev-rev-import-close-button {
    height: 35px !important;
    width: 35px !important;
    border-radius: 100px;
    border: 1px solid white;
    margin: 0px;
    position: absolute;
    top: -5px;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(39, 39, 39) !important;
}

.prev-rev-import-close-button > img {
  width: 10px;
  filter: invert(1);
}



.skeleton-loader-grid {
  width: 97%;
  height: 530px;
border-radius: 25px;
  background-color: #222;
  border: 1px solid grey;
  color: #999;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(126, 126, 126, 0.8) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-repeat: no-repeat;
  animation: shimmer2 0.8s infinite;
  background-size: 200% 100%;
}


@media (max-width: 1200px) {
  .all-pages-gig-stats-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(190, 190, 190, 0.062);
    border-radius: 15px;
    width: 100%;
    height: 60px;
    margin: 0 0px 0 0;
  }


  .all-pages-bottom-bottom > div {
    margin-top: -10px;

  }

}

@media (max-width: 1000px) {

  .all-pages-grid-item {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;    
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0000000e;
    border: 1px solid rgb(44, 44, 44);
    width: 90%;
    margin-left: 0px;
    max-width: 1300px;
    backdrop-filter: blur(50px);
    border-radius: 25px;
    position: relative;
    padding: 1rem;
    list-style: none; /* Entfernt Bulletpunkte vom li */
    transition: transform 0.2s ease, box-shadow 0.2s ease; /* Optional: Hinzufügen von Hover-Animation */
  }

  .all-pages-bottom {
    margin-top: 30px;
  }

  .all-pages-gig-stats-box {
    width: 97%;
  }


  .live-page-container-2 {
    width: 99%;
   }

}

@media (max-width: 900px) {

   .all-pages-grid-item {
    margin-left: -10px;

  }

  .live-page-container-2 {
    width: 99%;
   }

}


@media (max-width: 800px) {
  .live-page-container-2 {
    width: 99%;
   }

   .all-pages-bottom-top {
     justify-content: center;
     margin-bottom: 15px;
   }

   .all-pages-grid-item {
    margin-left: -10px;

  }

}


@media (max-width: 600px) {

  .all-pages-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  ul {
    padding: 0px !important;
  }

  .all-pages-grid-item {
    width: 90%;
    margin-left: 10px;

  }

  .page-not-live-notice {
    background-color: var(--black);
    flex-direction: column;
    border-radius: 15px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;  
}


  .all-pages-bottom-bottom > div > button {
    height: 50px;
    margin-right: 10px;
    width: 130px;
    border: none;
    border-radius: 100px;
    border: 1px solid rgb(117, 117, 117);
    font-family: 'Jost';
    color: white;
    font-size: 14px;
    background-color: rgba(128, 128, 128, 0.151);
}

  .all-pages-bottom {
    margin-left: 0px;
}

  .live-page-container-2 {
    height: 600px;
   }
}


@media (max-width: 500px) {
  .all-pages-grid-item {
    margin-left: 5px;
  }
}
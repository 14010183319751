

.add-project {
    margin-top: 0px;
    margin-bottom: 20px;
    border-radius: 15px;
    border: 1px solid rgba(128, 128, 128, 0);
    transition: 140ms ease-in-out;
    padding: 10px;
}



.add-project:hover .edit-overlay {
    display: flex; /* Make visible on hover */
}

.add-project.settings-route {
    border: 1px solid rgba(255, 255, 255, 0); /* Solid border for clarity */
}

.add-project.is-active {
    padding: 10px;
    border-color: rgb(196, 196, 196); /* Blue border when active */
}

.add-project.settings-route:hover {
    cursor: pointer;
    transform: scale(1.03);
    box-shadow: var(--color-shadow-2);
}

.add-project:hover .gig-upload-song-button > h5 {
    color: var(--color-oposite-1);
}

.add-project:hover .gig-upload-song-button > img {
    opacity: 1;
}

.project-list-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px;
    overflow: hidden;
}

.project-list-inner > h2 {
    width: 100%;
    text-align: left;
    font-family: 'Wide';
    color: var(--color-oposite-1);
}

.project-item-inner {
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    text-align: left;
    margin-top: 20px;
    padding: 10px;
}

.project-item-inner > div > h3 {
    text-align: left;
    width: 100%;
    margin-bottom: 0px;
    font-size: 18px;
    color: var(--color-oposite-2);
}

.project-item-inner > div > p {
    font-size: 15px;
    font-weight: 300;
    color: var(--color-oposite-3);
}

.project-item-inner > div {
    margin-left: 20px;
}


.project-image {
    width: 100%;
    aspect-ratio: 1/1;
    height: auto;
    object-fit: cover;
    margin-bottom: 10px;
    max-width: 350px;
    max-height: 350px;
    border-radius: 15px;
}

.project-item-inner-bottom > p > span {
    font-weight: 500;
}


@media (max-width: 800px) {

    .project-image {
        aspect-ratio: 1/1;
        max-height: unset;
        max-width: 100%;

    }

    .project-item-inner {
        flex-direction: column;
        align-items: flex-start;
    }

    .project-item-inner > div {
        margin-left: 0px;
    }
    
    
}




.caam-chartlist-award-container {
    background: var(--black);
    color: white;
    padding: 30px;
    border-radius: 40px;
    width: 100%;
    width: 450px;
    border: 1px solid rgba(192, 192, 192, 0.13);
    margin: 0;
    z-index: 100;
    height: 600px;  /* Maximalhöhe */
    overflow-y: auto;   /* Scrollen bei Überlauf */
    box-shadow: rgba(50, 50, 93, 1.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    opacity: 0;
    transform: translateY(100px);
    animation: fadeInUp 0.3s ease-in-out forwards;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(50px); /* Startet 100px tiefer */
    }
    100% {
        opacity: 1;
        transform: translateY(0); /* Endet auf ursprünglicher Position */
    }
}


.caam-chartlist-award-container::-webkit-scrollbar {
    display: none;
}


.award-toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px 15px;
    background-color: rgb(32, 32, 32);
    border-radius: 100px;
    margin: 10px 10px 30px 10px;
    transition: background-color 0.3s ease;
  }
  
  .award-toggle.active {
    background-color: var(--blue); /* Hintergrundfarbe, wenn aktiv */
  
}

  .award-toggle > img {
      width: 20px;
      filter: invert(1);
      margin: 0 10px 0 0px;
  }
  
 
  
  .award-toggle:hover {
    cursor: pointer; /* Hintergrundfarbe bei Hover */
  }

.caam-award-info {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.caam-award-info > label {
    background-color: rgb(41, 40, 40);
    margin: 3px 10px;
    padding: 5px 10px;
    border-radius: 100px;
}

.caam-chartlist-award-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    margin: 40px 0 60px 0;
}

.caam-chartlist-award-grid-item {
    background: rgb(39, 39, 39);
    padding: 0px;
    border-radius: 15px;
    display: flex;
    font-size: 14px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.caam-chartlist-award-grid-item.selected {
    background: var(--blue);
}

.caam-chartlist-award-grid-item img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    filter: invert(1);
    margin-top: 10px;
}

.caam-award-options-container {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 30px;
}

.caam-award-option-item {
    background: rgb(31, 31, 31);
    border-radius: 100px;
    height: 120px;
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.caam-award-option-item.selected {
    background: var(--blue);
    transform: scale(1.01);
}

.caam-award-option-item img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    filter: invert(1);
    margin-top: 10px;
}

.caam-chartlist-award-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

/* Modal-Styling */
.caam-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    backdrop-filter: blur(5px);
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.caam-modal-content {
    background: transparent;
    color: white;
    padding: 0px;
    border-radius: 8px;
    z-index: 1001;
    margin-left: -200px;
}

/* Styling für Compliment Grid */
.caam-compliment-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    margin-top: 20px;
}

.caam-compliment-item {
    background: rgb(31, 31, 31);
    padding: 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.caam-compliment-item.selected {
    background: var(--blue); /* Farbe bei ausgewähltem Zustand */
}

.caam-compliment-img {
    width: 25px;
    height: 25px;
    margin-right: 15px;
    opacity: 0.5;
    filter: invert();
}

.caam-compliment-item h4 {
    margin: 0;
    font-size: 1rem;
    color: white;
}

.caam-compliment-item p {
    margin: 5px 0 0;
    font-size: 0.85rem;
    color: #bbb;
}

.generate-review-section > button {
    color: white;
    text-decoration: none;
    background: linear-gradient(215deg, #9dc1ccb4 49.9%, #274aaa 80%),
                linear-gradient(304deg, rgba(6, 101, 117, 0.4) 24.9%, #aa2743 91%),
                linear-gradient(10deg, #b1c9d8 39.9%, #2776aa 50%),
                linear-gradient(145deg, #1e0852 15.9%, #672188 40%);
    background-size: 250% 250%;
    background-repeat: no-repeat;
    background-blend-mode: hard-light;
    animation: moveGradient 20s ease infinite;
    height: 50;
    border: none;
    font-family: 'Jost';
    font-weight: 500;
    padding: 5px 0;
    width: 140px;
    display: flex;
    align-items: center;
    font-size: 16px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    text-transform: uppercase;
    justify-content: center;
    border-radius: 100px;
    margin-top: 25px;
    transition: 130ms ease-in-out;
    backdrop-filter: blur(30px);
    transition: 100ms ease-in-out;
}

.caam-disabled-button {
    display: none !important;
}



.caam-save-button {
    width: 100%;
    height: 65px;
    border-radius: 100px !important;
    background-color: var(--blue);
    font-size: 16px;
    text-transform: uppercase;
    font-family: 'Jost';
    font-weight: 500;
    margin-top: 20px;
    border: none;
    color: white;
    transition: 100ms ease-in-out;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.caam-save-button:hover {
    cursor: pointer;
    transform: scale(1.01);
}

.generate-review-section > button:hover {
    cursor: pointer;
    transform: scale(1.01);
}

.generated-review {
    margin-bottom: -10px;
}

.generated-review > h4 {
    margin: 30px 0 -10px 0;

}

.generated-review > p {
    color: rgb(161, 161, 161);
}

.quote-editor-details > img {
width: 50px;
border-radius: 100px;
object-fit: cover;
}

.editor-quote-section {
    margin-top: 30px;
}
.invest-container {
    padding: 20px;
    background-color: #121212;
    color: #fff;
    font-family: Arial, sans-serif;
    width: 100%;
    margin: 20px auto;
    border-radius: 10px;
  }
  
  .invest-title {
    font-size: 34px;
    font-weight: bold;
    font-family: 'Wide';
    margin-bottom: 40px;
  }
  
  .invest-toggle-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .invest-toggle-label {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
  }
  
  .invest-toggle-input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .invest-tab:hover {
    cursor: pointer;
  }

  .invest-tab {
    margin: 5px 10px;
    color: white;
    padding: 10px 20px;
    border-radius: 100px;
  }
  
  .invest-tab.active {
    color: white;
    border: 1px solid var(--blue);
    padding: 10px 20px;
    border-radius: 100px;
  }
  
  .invest-content {
    margin-top: 20px;
  }
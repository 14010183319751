.gig_component {
  background: var(--color-gradient-1);
  width: 400px;
  gap: 16px; /* Füge einen Abstand zwischen den Gigs ein */
  flex-wrap: wrap; /* Erlaube das Umbruch der Elemente */
  position: relative;
  border-radius: 25px;
  box-shadow: var(--color-shadow);
  transition: 120ms ease-in-out;
  margin-bottom: 20px;
  margin-top: 25px;
  border: 1px solid var(--color-3);
  z-index: 100;


}


.gig_component.is-active {
  border-color: var(--color-oposite-4); /* Blue border when active */
}

.gig_component.settings-route:hover {
  cursor: pointer;
  transform: scale(1.03);
}


.gig_component:hover .edit-overlay {
  display: flex; /* Make visible on hover */
}

.gig_header_1 {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 25px 30px 0 30px;
    text-align: left;
}

.gig_header_1 > h2 {
  font-size: 20px;
  color: var(--color-oposite-1);
  margin-top: 0px;
  user-select: none;  
-webkit-user-select: none;
-ms-user-select: none; 
-moz-user-select: none;  
}

.gig_header_1 >  div h4 {
  font-size: 12px;
  margin-top: 0px;
  color: grey;
  user-select: none;  
-webkit-user-select: none;
-ms-user-select: none; 
-moz-user-select: none;  
}


.gig_header_1 > div > p {
  font-size: 24px;
  color: var(--color-oposite-1);
  margin-bottom: -3px !important;
  margin-top: 0px;
  user-select: none;  
-webkit-user-select: none;
-ms-user-select: none; 
-moz-user-select: none;  
}

@keyframes gradient-shift {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.free-text {
  font-size: 20px; /* Anpassung nach Bedarf */
  font-weight: 500;
  background: linear-gradient(45deg, rgb(119, 119, 119), rgb(255, 255, 255), rgb(85, 85, 85));
  background-size: 200% 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* Spezifisch für Webkit-Browser */
  background-clip: text;
  color: transparent; /* Diese Eigenschaft wird ignoriert, wenn background-clip: text funktioniert */
  animation: gradient-shift 3s linear infinite;
}


.gig_header_1 > div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  text-align: right;
  height: fit-content;
  padding: 0 0 0 10px;
  width: 80px;
}

.feature-checkmark {
  width: 15px !important;
  position: absolute;
  top: -10px;
  display: none;
  right: 7px;
  opacity: 0.5 !important;
  user-select: none;  
-webkit-user-select: none;
-ms-user-select: none; 
-moz-user-select: none;  
}

.feature-item:hover > .feature-checkmark {
  display: block;
}

.gig_desc > p {
margin-top: -10px;
font-size: 16px;
color: var(--color-oposite-2);
margin: 0 30px 20px 30px;
text-align: left;
font-weight: 300;
user-select: none;  
-webkit-user-select: none;
-ms-user-select: none; 
-moz-user-select: none;  
}

.gig_feature  {
  display: flex;
  margin-left: 25px;
  margin-top: 0px;
  margin-bottom: 0px;
  align-items: center;
}

.gig_feature > p {
  display: flex;
  margin-top: 0px;
  font-weight: 500;
  margin-bottom: 0px;
  color: var(--color-oposite-1);
  user-select: none;  
-webkit-user-select: none;
-ms-user-select: none; 
-moz-user-select: none;  
}


.gig_feature > img {
  width: 25px;
  margin-right: 10px;
  filter: var(--color-invert);
  user-select: none;  
-webkit-user-select: none;
-ms-user-select: none; 
-moz-user-select: none;  
}




.gig-contact-button {
    display: inline-block;
    margin: 5px;
    border: none;
    background-color: var(--blue); /* WhatsApp grün */
    color: white;
    text-decoration: none;
    border-radius: 10px;
    font-size: 15px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 500;
    padding: 1px 20px;
  font-family: 'Jost';
    margin-bottom: 20px !important;
    box-shadow: var(--color-shadow-3)
  }

  .whats-contact-button {
    display: inline-block;
    margin: -5px 5px 5px 5px;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border: 1px solid #747474; /* WhatsApp grün */
    color: var(--color-oposite-2);
    text-decoration: none;
    border-radius: 10px;
    font-size: 15px;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 500;
    margin-bottom: 20px !important;
    box-shadow: var(--color-shadow-3)
  }

  .whats-contact-button-2 {
    display: inline-block;
    margin: -5px 5px 5px 5px;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border: 1px solid #747474; /* WhatsApp grün */
    color: var(--color-oposite-2);
    text-decoration: none;
    border-radius: 10px;
    font-size: 15px;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 500;
    margin-bottom: 20px !important;
    box-shadow: var(--color-shadow-3)
  }

  .whats-contact-button > img {
    width: 22px;
    filter: var(--color-invert);
    margin-left: 10px;
  }


  .whats-contact-button-2 > img {
    width: 22px;
    filter: var(--color-invert);
    margin-left: 10px;
  }
  
  
  .gig-contact-button:hover {
    cursor: pointer;
    transform: scale(1.01);
  }
  
.disabled-buttoni {
  background-color: rgba(128, 128, 128, 0.548) !important;
}
    
  .whats-contact-button:hover {
    border: 1px solid #25D366; /* WhatsApp grün */
  }

  .whats-contact-button:hover {
    border: 1px solid var(--blue); /* WhatsApp grün */
  }



  .features-container {
    display: flex; /* Ermöglicht flexibles Layout der Kinder */
    gap: 10px; /* Abstand zwischen den Feature-Items */
    margin-top: 20px; /* Abstand vom oberen Inhalt */
    align-items: center;
    justify-content: left;
    margin-left: 20px;
    flex-wrap: wrap; /* Erlaubt den Umbruch der Items */
}

.feature-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 15px;
    border: 1px solid rgba(138, 138, 138, 0.274);
    text-align: center;
    width: 110px;
    height: 105px;
    font-size: 12px;
    position: relative;
}


.feature-item > div > p {
    margin: 0 10px;
    color: var(--color-oposite-2);
    text-align: center;
}

.feature-item > div {
  height: 60px;
  display: flex;
  align-items: center;
}

.feature-item img {
    width: 32px; /* Die Breite des Bildes */
    max-width: 150px; /* Maximale Breite des Bildes */
    height: auto; /* Stellt sicher, dass das Bildverhältnis erhalten bleibt */
    margin-top: 18px; /* Abstand vom Bild zum Text */
    filter: var(--color-invert);
    opacity: 0.8;
}


.gig-contact-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
}

.gig_header_royalty_split {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.feature-modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feature-modal-content {
  background: var(--color-2);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--color-oposite-2);
  padding: 20px 10px;
  max-width: 250px;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;}

.feature-modal-content > h4 {
  margin-bottom: 0px;
  margin-top: 0px;
}

.feature-modal-content > p  {
  margin-top: 10px;
  color: var(--color-oposite-1);
}


.order-modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-modal-content {
  background: var(--color-2);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  color: var(--color-oposite-2);
  height: 100%;
  width: 100%;
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}

.order-textarea-wrapper {
  width: 100%;
  height: 60%;
  align-items: center;
  justify-content: center;
  position: relative;
}


.order-modal-content > div > textarea {
  width: 80%;
  min-height: 100%;
  background-color: var(--color-3);
  border: none;
  border-radius: 15px;
  padding: 15px;
  resize: none;
  font-family: 'Jost', 'Josefin Sans';
  font-size: 16px;
  outline: none;
  color: var(--color-oposite-1);
}

.order-start-chat-button {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  position: absolute;
  bottom: 20px;

}

.order-start-chat-button > button {
  height: 45px;
  width: 43%;
  border-radius: 10px !important;
  margin: 5px;
  border: 1px solid var(--color-oposite-4);
  background: none;
  color: var(--color-oposite-1);
  font-family: 'Jost';
  font-size: 16px;

}

.order-start-chat-button > button:hover {
  cursor: pointer;
}


.chat-preview-uploaded-img {
  position: absolute;
  border-radius: 10px;
  bottom: 0;
  margin-bottom: 90px;
  left: 35px;
  width: 60px !important;
  max-height: 100px;
  object-fit: cover;
}

.order-back-button {
  position: absolute;
  left: 25px;
  margin-top: 20px;
  padding: 5;
  border-radius: 100px;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  border: 1px solid var(--color-oposite-4);
  justify-content: center;
}

.order-back-button:hover {
  cursor: pointer;
}

.order-back-button > img {
  filter: var(--color-invert);
  width: 17px;
}

.message-sent-div {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: 'Wide';
  height: 70%;
  font-size: 24px;
}

.message-sent-div > img {
  width: 100px;
  animation: fade-in 300ms ease-in-out;
}

#order-start-second-button {
  background-color: var(--blue);
  color: white;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;

}
#order-start-second-button:hover {
  cursor: pointer;

}

.order-start-second-button > p {
  margin: 0px;
  padding: 0px;
}

.chat-image-upload-2 {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 35px;
  bottom: -20px;
  border: 1px solid var(--color-oposite-4) !important;
  border-radius: 100px;
  display: flex;
  background: none;
  align-items: center;
  justify-content: center;
  border: none;
}

.chat-image-upload-2 > img {
  filter: var(--color-invert);
  width: 25px;
  opacity: 0.5;
}

.chat-image-upload-2:hover > img {
  opacity: 1;
  cursor: pointer;
}
.order-modal-content > h4 {
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 24px;
}

.order-modal-content > p  {
  margin-top: 10px;
  color: lightgray;
}


.feature-modal-content > button {
  border: 1px solid var(--color-oposite-1);
  color: var(--color-oposite-1);
  padding: 5px 10px;
  border-radius: 100px;
  background-color: rgba(255, 255, 255, 0);
}

.feature-modal-content > button:hover {
  cursor: pointer;
}



/* TheGig.css */
/* TheGig.css */

/* Standard-Gradienten für den Black Mode */
.gig-background-1 { background: linear-gradient(to bottom, var(--blue), #121212); }
.gig-background-2 { background: linear-gradient(to bottom, #5d2677be, #000000); }
.gig-background-3 { background: linear-gradient(to bottom, #eb8d53, #0f0f0f); }
.gig-background-4 { background: linear-gradient(to bottom, #ad162f, #020202); }
.gig-background-5 { background: linear-gradient(to bottom, #a88934, #000000); }

/* Angepasste Gradienten für den White Mode */
.gig-background-1-white { background: linear-gradient(to bottom, rgba(0, 110, 255, 0.11), #ffffff); }
.gig-background-2-white { background: linear-gradient(to bottom, #802fa51e, #ffffff); }
.gig-background-3-white { background: linear-gradient(to bottom, #f08d5033, #ffffff); }
.gig-background-4-white { background: linear-gradient(to bottom, #d6324d18, #ffffff); }
.gig-background-5-white { background: linear-gradient(to bottom, #ee951025, #ffffff); }

/* TheGig.css */

/* Primärfarben für die Buttons entsprechend dem ausgewählten Style */
.primary-color-1 { background-color: var(--blue); }
.primary-color-2 { background-color: #5d2677d3; }
.primary-color-3 { background-color: #d18352; }
.primary-color-4 { background-color: #7a2525; }
.primary-color-5 { background-color: #917528; }

/* Für White Mode - weniger Deckkraft, da die Farben leuchten sollen */
.primary-color-1-white { background-color: rgba(39, 70, 206, 0.678); }
.primary-color-2-white { background-color: #5d2677d3; }
.primary-color-3-white { background-color: #eb8d53 }
.primary-color-4-white { background-color: #ad162fe3; }
.primary-color-5-white { background-color: #b9973a; }




@media (max-width: 1200px) {
  .gig_component.settings-route {
    width: 100%;
  }
}


@media (max-width: 900px) {
  .gig_component {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .gig_component {
    border-radius: 0px !important;
    margin-top: -5px;
    padding-top: 30px;
    padding-bottom: 30px;
    border: none;
  }

  .features-container {
    align-items: center;
    justify-content: center;
    margin-left: 0;
}
}
.equipment-used-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Abstand zwischen den Equipment-Boxen */
    justify-content: left;
    margin-left: var(--edge);
  }

  .equipment-used {
      display: flex;
      flex-direction: column;
      padding-bottom: 40px;
      padding-top: 20px;
      background-color: var(--black);
      margin-top: -70px;
      color: white;
  }

.equipment-used > h3 {
    width: fit-content;
    text-align: left;
    margin-left: var(--edge);
    font-size: 22px;
    font-weight: 400;
}
  
  .equipment-item {
    width: 200px;
    height: 200px;
    border: 1px solid rgb(110, 110, 110); /* Grauer Border für die Box */
    border-radius: 20px; /* Leicht abgerundete Ecken */
    padding: 20px; /* Innenabstand */
    display: flex;
    flex-direction: column;
    background-color: rgb(15, 15, 15);
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }
  
  .equipment-item p {
    font-size: 16px;
    margin-bottom: 10px;
    color: rgb(119, 119, 119);
  }
  
  .equipment-item h4 {
    font-size: 18px;
    font-weight: bold;
  }
  
.song-vote {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    background-color: var(--black);
    padding: 50px 0 80px 0;
}

.song-vote-header-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 500px;
    border: 1px solid rgb(104, 104, 104);
    border-radius: 5px;
}

.song-vote-middle-div {
    display: flex;
    width: 300px;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    font-size: 14px;
    color: rgb(219, 219, 219);
    letter-spacing: 0.5px;
}

.song-vote-header-bar > p {
    width: 300px;
    text-align: center;
    font-weight: 300 !important;
    font-size: 14px;
    color: rgb(219, 219, 219);
}

.song-info-and-player {
    display: flex; 
    flex-direction: column;
    align-items: left;
    margin-top: 50px;
    width: 80%;
    position: relative;

}

.artist-box {
    display: flex;
    margin-bottom: 5px;
}

.artist-box > a > p {
    color: rgb(214, 214, 214);
    letter-spacing: 0.5px;
}



.back-button-2 {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: left;
    left: var(--edge);
    border: none;
    height: 40px;
    margin-bottom: 80px;
    margin-top: -100px;
    border-radius: 0px;
    border-bottom: 1px;
    background-color: transparent;
    color: white;
    text-align: left;
    font-family: 'Jost';
    text-transform: uppercase;
    opacity: 0.6;
}

.back-button-2:hover {
   opacity: 1;
   cursor: pointer;
}

.back-button-2 > img {
    width: 15px;
    filter: invert(1);
    margin-right: 10px;
    z-index: 100;
}



.artist-image {
    width: 40px; /* oder eine andere Größe, die du bevorzugst */
    height: 40px;
    border-radius: 50%; /* für ein rundes Bild */
    margin-right: 10px; /* Abstand zum Künstlernamen */
    margin-top: 10px;
    object-fit: cover;
  }

.song-info-and-player > p {
    text-align: left;
    font-size: 14px;
}

.song-info-and-player > h2 {
    text-align: left;
    font-size: 48px;
    margin-top: -10px;
    font-weight: 400;
    margin-bottom: 10px;
}

.vote-box {
    display: flex;
    width: 100%;
    align-items: center;
    position: absolute;
    bottom: -50px;
    font-size: 14px;
}

.vote-box > p {
    margin-right: 12px;
    font-size: 14px;
}

.vote-btn {
    padding: 0px 0px;
    font-size: 16px;
    width: 110px;
    height: 40px;
    cursor: pointer;
    border: 1px solid white;
    border-radius: 100px;
    color: white;
    position: relative;
    overflow: hidden;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

  }

.vote-btn > img {
    width: 17px;
    filter: invert(1);
    margin-left: 10px;
}


  
  .vote-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    margin: -2px; /* Erzeugt den Effekt eines "Border", der den Button umgibt */
    border-radius: inherit; /* Stellt sicher, dass die Rundung beibehalten wird */
    background: linear-gradient(90deg, var(--red) 50%, black 50%);
    background-size: 200% 200%;
    animation: slideGradient 10s linear infinite;
  }
  
  @keyframes slideGradient {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .thank-you-message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    font-size: 20px;
    color: black;
  }


.artist-image {
    width: 30px; /* oder eine andere Größe, die du bevorzugst */
    height: 30px;
    border-radius: 50%; /* für ein rundes Bild */
    margin-right: 10px; /* Abstand zum Künstlernamen */
    margin-top: 13px;
  }
  

.info-spacer {
    width: 1px;
    border: 0px;
    height: 20px;
    background-color: white;
}

.song-vote-left  {
    width: 50%; 
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    margin-left: var(--edge);
}


.song-vote-right  {
    width: 50%; 
}





.song-vote-right > img {
    width: 500px;
    height: 500px;
    object-fit: cover;
    border-radius: 15px;
}

.song-vote-right-mobile > img {
    width: 500px;
    height: 500px;
    object-fit: cover;
    border-radius: 15px;
}

.song-vote-right-mobile {
    display: none;
    width: 100%;
}

.artist-linker:hover {
    text-decoration: underline !important;
}

.artist-linker > p {
    display: flex;
}

@media (max-width: 1100px) {

    .song-info-and-player {
        display: flex; 
        flex-direction: column;
        align-items: left;
        margin-top: 50px;
        width: 96%;
        position: relative;
    
    }

    .song-vote-right > img {
        width: 400px;
        height: 400px;
        object-fit: cover;
        border-radius: 15px;
    }

.song-vote-header-bar {
    width: 400px;
}
.song-vote-header-bar {
    font-size: 12px;
}

.back-button-2 {

    margin-bottom: 50px;
    margin-top: -50px;

}

}


@media (max-width: 850px) {

.song-vote {
 flex-direction: column;
}

.back-button-2 {

    margin-bottom: 40px;
    margin-top: -20px;
    width: 96%;

}


.vote-box {
    bottom: -50px !important;
    font-size: 12px;
}

.song-vote-right > img {
    object-fit: cover;
    border-radius: 15px;
}


.song-vote-right {
    display: none;
} 

.song-info-and-player > h2 {
    text-align: left;
    font-size: 28px;
    margin-top: -10px;
    font-weight: 400;
    margin-bottom: 20px;
}




.song-vote-right-mobile > img {
    display: block;
    width: 100% !important;
    height: 700px;
} 

.song-vote-left  {
    width: 96%;
    align-items: center;
    margin-left: 0px;

}

.song-vote-header-bar > p {
    width: 100%;
    text-align: center;
    font-weight: 300 !important;
    font-size: 14px;
    color: rgb(219, 219, 219);
}


    .song-vote-header-bar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 96%;
        border: 1px solid rgb(104, 104, 104);
        border-radius: 5px;
    }

    .song-vote-right-mobile {
display: flex;
align-items: center;
justify-content: center;
    }
}



@media (max-width: 700px) {

.song-vote-right-mobile > img {
    height: 600px;
} 
}

@media (max-width: 600px) {

    .song-vote-right-mobile > img {
        height: 500px;
    } 
    .song-detail-song-name {
        font-size: 26px !important;
        margin-top: 0px !important;
    }

    }

@media (max-width: 500px) {
    
.song-vote-right-mobile > img {
    height: 440px;
    object-fit: cover;
    border-radius: 15px;
}


}

@media (max-width: 450px) {
    
    .song-vote-right-mobile > img {
        height: 400px;
        object-fit: cover;
        border-radius: 15px;
    }
    }



    @media (max-width: 400px) {
    
        .song-vote-right-mobile > img {
            height: 350px;
            object-fit: cover;
            border-radius: 15px;
        }
        }

        @media (max-width: 380px) {
    
            .song-vote-right-mobile > img {
                height: 330px;
                object-fit: cover;
                border-radius: 15px;
            }
            }
    
            @media (max-width: 360px) {
    
                .song-vote-right-mobile > img {
                    height: 320px;
                    object-fit: cover;
                    border-radius: 15px;
                }
                }

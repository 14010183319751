.song-stepper-container {
    background-color: var(--black);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    
}


.song-stepper-header >  img {
    width: 120px;
    margin-left: 15px;
}

.song-stepper-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    color: white;
    position: absolute;
    top: 5px;
    z-index: 1000;
}

.song-stepper-header > div {
    display: flex;
}

.song-stepper-input > img {
    display: flex;
    width: 30px;
    position: absolute;
    right: 10px;
    top: 8px;
}



.song-stepper-header > a > div {
    width: 35px;
    height: 35px;
    border-radius: 100px;
    margin-top: -30px;
    border: 0;
    background-color: black;
    margin-right: 10px;
    margin-left: 10px;
}

.song-stepper-header > a > div:hover {
    cursor: pointer;
    scale: 1.02;
}

.song-stepper-header > a > div > img {
    width: 12px;
    filter: invert(1);
    margin-top: 11px;
}

.song-stepper-header > a > img {
    width: 120px;
    margin-left: 15px;
}

.submission-screen> h1 {
    font-family: 'Wide', 'Jost', 'Josefin Sans', sans-serif;
}

.song-stepper-wrapper-wrapper {
  position: relative;
}


.song-stepper-wrapper {
    background-color: rgba(36, 36, 36, 0.473);
    min-height: 600px;
    max-width: 500px;
    backdrop-filter: blur(5px);
    width: 500px;
    border-radius: 25px;
    position: relative;
    z-index: 100;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.left-song-side {
    font-family: 'Wide', 'Jost', 'Josefin Sans', sans-serif;
    font-size: 48px;
    margin: 0 20px;
}



.song-stepper-input {
    background-color: rgba(0, 0, 0, 0.445);
    height: 50px;
    border: 1px solid rgb(54, 54, 54) !important; 
    padding: 0px 35px 0px 0px;
    border-radius: 10px;
    color: white;
    font-family: 'Jost';
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    width: 355px !important;

}

.equipment-input-group-3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    padding: 12px;
    width: 93%;
    flex-direction: column;
    border-radius: 10px;
    background-color: rgb(19, 19, 19);
    color: white;
    position: relative;

  }

.song-text-input-2 {
    width: 94%;
    height: 52px;
    font-family: 'Jost';
    font-size: 14px;
    border-radius: 8px;
    border: 0px;
    background-color: black;
    padding-left: 15px;
    color: white;
    outline: none;

}


.equipment-input-group-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    padding: 15px;
    width: 100%;
    flex-direction: column;
    border-radius: 10px;
    background-color: rgb(19, 19, 19);
    color: white;
    position: relative;
    outline: none;
  }

  .equipment-input-group-2 > label {
      text-align: left;
      width: 100%;
      font-size: 12px;
      color: rgb(201, 201, 201);
      margin-top: -6px;

  }

.equipment-select {
    width: 98%;
    margin-top: 10px;
    color: white;
    background-color: var(--black);
    height: 52px;
    padding-left: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    outline: none;



}

.song-stepper-input > input {
    background-color: none !important;
    border: none !important;
    background: none !important;
    margin: 0px 0px 0px 5px !important;
    outline: none;
    color: white;
    font-family: 'Jost';
    font-size: 16px;
    outline: none;

}


.left-song-side > h2 {
    font-size: 24px;
    margin-top: 80px;
    margin-bottom: 10px;
}

.upload-spacer {
    margin-bottom: 0px;
}

.mood-selection-container {
    width: 390px;
}

.mood-selection-container-2 {
  width: 100%;
}

/* Keyframes for the bounce effect */
@keyframes bounce {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }
  
  /* Keyframes for the box-shadow effect */

  
  .mood-button.selected {
    background-color: var(--blue); /* Ausgewählter Zustand */
    color: white;
    transition: ease-in 150ms;
  }

  .mood-button-2.selected {
    background-color: var(--blue); /* Ausgewählter Zustand */
    color: white;
    transition: ease-in 150ms;
  }
  
  @keyframes click-pulse {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(149, 157, 165, 0.2);
    }
    50% {
      transform: scale(1.05);
      box-shadow: 0 0 0 10px rgba(149, 157, 165, 0);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(149, 157, 165, 0);
    }
  }
  
  .mood-button {
    margin: 5px;
    padding: 8px 13px;
    font-weight: 400;
    border: none;
    border-radius: 20px;
    letter-spacing: 0.5px;
    font-size: 14px;
    font-family: 'Jost';
    cursor: pointer;
    background-color: var(--black); /* Nicht ausgewählter Zustand */
    color: rgb(207, 207, 207);
    transition: transform 0.2s, box-shadow 0.2s; /* Smooth transition for transform and shadow */
    outline: none;
  }
  
  .mood-button:focus { /* Use :focus to apply the animation when clicked and maintain focus */
    animation: click-pulse 0.5s forwards;
  }

  .mood-button-2 {
    margin: 5px;
    padding: 8px 13px;
    font-weight: 400;
    border: none;
    border-radius: 20px;
    letter-spacing: 0.5px;
    font-size: 14px;
    font-family: 'Jost';
    cursor: pointer;
    background-color: #ffffff05; /* Nicht ausgewählter Zustand */
    color: rgb(207, 207, 207);
    transition: transform 0.2s, box-shadow 0.2s; /* Smooth transition for transform and shadow */
    outline: none;
  }
  
  .mood-button-2:focus { /* Use :focus to apply the animation when clicked and maintain focus */
    animation: click-pulse 0.5s forwards;
  }
  
  


  .uploading-loader {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; /* Anpassen nach Bedarf */
  }
  
  .uploading-image {
    width: 35px; /* Anpassen nach Bedarf */
    height: auto;
    z-index: 2; /* Stellt sicher, dass das Bild über dem Spinner liegt */
  }
  
  .spinner-3 {
    position: absolute;
    width: 60px; /* Sollte größer als das Bild sein */
    height: 60px;
    border: 4px solid rgba(0,0,0,0.1); /* Äußerer transparenter Rand des Spinners */
    border-radius: 50%;
    border-top-color: var(--red); /* Farbe des Spinners */
    animation: spin 0.5s linear infinite;
    z-index: 1;
  }
  
    
  .spinner-4 {
    position: absolute;
    width: 60px; /* Sollte größer als das Bild sein */
    height: 60px;
    border: 4px solid rgba(0,0,0,0.1); /* Äußerer transparenter Rand des Spinners */
    border-radius: 50%;
    border-top-color: var(--red); /* Farbe des Spinners */
    animation: spin 0.5s linear infinite;
    z-index: 1;
  }
  

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  

.left-song-side > p {
    font-size: 16px;
    font-family: 'Jost';
    margin-top: 0px;
    margin-left: 16px;
    margin-right: 16px;
    max-width: 350px;
}

.song-preview {
    text-align: center;
    display: flex;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 70%;
  }
  
  .progress-barro {
    width: 100%;
    background-color: rgb(112, 112, 112);
    height: 5px;
    border-radius: 5px;
    margin-top: 10px;
  }

  .confirmation-step {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      height: 500px;
      width: 100%;
  }


  .start-over-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90%;
      height: 64px;
      border-radius: 10px;
      background-color: transparent;
      color: white;
      font-family: 'Jost';
      font-size: 15px;
      font-family: 'Jost';
      z-index: 100;
  }

  .start-over-button > button {
    font-family: 'Jost';
    font-size: 15px;

  }

  .start-over-button > button:hover {
      cursor: pointer;
  }

  .confirmation-step > button {
    background-color: aqua;
  }

  .confirmation-step > div > h2 {
    font-size: 34px;
    margin-bottom: 10px;
    margin-top: 40px;
    font-family: 'Wide', 'Jost';
  }

  .confirmation-step > div > p {
      margin: 0 15px;
      max-width: 350px;
      color: lightgray;
  }

  .confirmation-step > div > img {
      width: 140px;
      margin-bottom: -20px;
      animation: fade-in 150ms ease-in-out;
  }

  
  
  .progresso {
    height: 5px;
    background-color: var(--red); /* Schwarzer Fortschrittsbalken */
    width: 0%; /* Initial keine Breite */
    border-radius: 5px;
  }

  .song-details-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 390px;
  }


  .song-details-container-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 390px;
}

  .song-details-container > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 390px;
}

.song-details-container-2 > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 390px;
}
  
  .scrollable-content-wrapper {
      display: flex;
      justify-content: center;
      width: 100%;
    max-height: calc(100% - 60px); /* oder eine andere Höhe, die zu deinem Layout passt */
    overflow-y: auto;
    height: 330px;
  }

  .scrollable-content-wrapper-2 {
    display: flex;
    justify-content: center;
    width: 100%;
  max-height: calc(100% - 60px); /* oder eine andere Höhe, die zu deinem Layout passt */
  overflow-y: auto;
  height: 330px;
}

  .equip-header-text {
    margin-bottom: 0px;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 600; 
    margin-bottom: 0px;
    text-align: left;
  }

  .image-upload-container-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

  .add-buttone {
      width: 150px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      font-family: 'Jost';
      color: white;
      background-color: var(--gray);
      border-radius: 100px;
      border: 0px;
      margin-top: 10px;
  }

  .add-buttone > img {
      width: 18px;
      margin-right:5px;
      filter: invert(1);
  }

  .add-buttone:hover {
    cursor: pointer;
  }


  .collaborators-section {
    width: 390px;
}


  .add-buttone > p {
      margin-left: 10px;
  }

  .song-details-container > h4 {
      margin-bottom: 0px;
      font-size: 16px;
      font-weight: 600;
      width: 100%;
      text-align: left;
    margin-top: 0px;
  }

  .song-details-container-2 > h4 {
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    text-align: left;
  margin-top: 0px;
}

  .song-youtube-box {
      width: 100%;
  }


  .scrollable-content-wrapper::-webkit-scrollbar {
    display: none; /* Für WebKit-Browser wie Chrome und Safari */
  }

  .scrollable-content-wrapper-2::-webkit-scrollbar {
    display: none; /* Für WebKit-Browser wie Chrome und Safari */
  }
  
  @media only screen and (max-width: 500px) and (max-height: 650px) {
    .image-upload-container-2 {
      position: relative;
    }

    .song-details-container {
        position: relative;
    }

    .song-details-container > div {
        position: relative;
    }


  }

  @media only screen and (max-width: 500px) and (max-height: 550px) {


  }

  @media(max-width: 800px) {
    .spinner-4 {
      right: 110px !important;
    }
  }

  @media (max-width: 500px) {
    .song-stepper-wrapper {
        background-color: var(--black);
        min-height: unset;
        height: calc(100vh - 30px);
        margin-top: 30px;
        max-width: 500px;
        min-height: 520px;
        width: 100%;
        flex-direction: flex-start;
    }

    #song-name-input {
        width: 90% !important;
    }

  

    .image-upload-container-2 {
        min-height: 410px;
        background-color: var(--black);
        width: 96vw;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        padding: 1vw;
    }

    .collaborators-section {
        width: 94vw;
    }

    .equip-header-text {
        margin-left: 4vw;
    }


    .song-stepper-wrapper {
        position: relative;
    }

    .song-stepper-input {
        width: 77vw;
    }


.submission-screen > h1 {
    font-family: 'Wide', 'Jost', 'Josefin Sans', sans-serif;
    margin: 0px 40px;
}

.song-stepper-input > img {
    top: 14px;
    z-index: 100;
}

.submission-screen > img {
    margin-top: 50px;
    width: 200px;
    margin-bottom: 20px;
}

    .scrollable-content-wrapper {
        display: flex;
        justify-content: center;
        width: 100vw;
      min-height: 400px;
      overflow: unset;
      background-color: var(--black);
    }


    .scrollable-content-wrapper-2 {
        display: flex;
        overflow: unset;
        justify-content: center;
        align-items: flex-start;
        width: 100vw;
        height: 100%;
        position: relative;

    }


    .song-stepper-input {
        height: 53px;

    }

    .song-details-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 86vw;
        padding: 0 7vw;
        background-color: var(--black);
        min-height: 460px;
    }



    .song-details-container-2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 86vw;
        overflow-y: auto;
        padding: 0 7vw 0vh 7vw;
        height: 100%;
        background-color: var(--black);
    }

    .song-details-container > div {
        width: 100%;
    }
  

    .song-details-container-2 > div {
        width: 100%;
        background-color: var(--black);

    }

    .song-stepper-input > input {
        margin-top:0px !important;
        font-size: 16px;
        font-family: 'Jost';
    }

    .mood-button {
        margin: 5px;
        padding: 8px 13px;
        font-weight: 400;
        border: none;
        border-radius: 20px;
        letter-spacing: 0.5px;
        border: 1px solid rgb(56, 56, 56);
        font-size: 15px;
        font-family: 'Jost';
        cursor: pointer;
        background-color: var(--black); /* Nicht ausgewählter Zustand */
        color: rgb(207, 207, 207);
      }
      

  .add-buttone {
    width: 160px;
    height: 48px;
    margin-left: 3vw;
}



.add-buttone > p {
    font-weight: 500;
}
.song-stepper-input {
    width: 86% !important;
}
  
  
    
  }

  @media (max-width: 395px) {
    .mood-button {
        font-size: 14px;
    }

    .mood-selection-container {
        width: 340px;
    }
    .song-details-container > div {
        width: 103%;
    }

    .song-stepper-input {
        width: 86% !important;
    }
  
  }

  @media (max-width: 340px) {
    .mood-button {
        font-size: 12px;
    }

    #song-name-input {
        width: 87% !important;
    }

    .mood-selection-container {
        width: 300px;
    }
  }

  @media (max-width: 340px) {
    .mood-selection-container {
        width: 260px;
    } 
  }
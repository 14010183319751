.home-info-container {
    display: flex;
    background-color: var(--black);
    justify-content: center;
    align-items: center;
    margin:  0;
    color: white;
    padding-bottom: 40px;
    
  }

  .home-info-content > h1 {
      font-size: 40px;
      margin-bottom: 0px;
      font-family: 'Wide', 'Jost', 'Josefin Sans', sans-serif;
    }

  .home-info-content > h2 {
      font-size: 17px;
      max-width: 600px;

  }

  .home-info-link {
      width: 100%;
  }

  .outline-button-2 {
      max-width: 350px;
      font-weight: 500;
      font-size: 16px;
      width: 90%;
      font-family: 'Jost';
      height: 60px;
      border: 1px solid white;
      background-color: transparent;
      color: white;
      border-radius: 100px;
      margin-top: 20px;
      margin-bottom: 40px;
      text-transform: uppercase;
  }

  .outline-button-2:hover {
      cursor: pointer;
      background-color: rgb(0, 0, 0);
  }

  .home-info-content > h3 {
    font-size: 18px;
    max-width: 700px;
    font-weight: 300;
    font-size: 16px;

}
  
  .home-info-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    border: 1px solid rgb(77, 77, 77);
    border-radius: 25px; /* Gerundete Ecken */
    width: 90%;
    padding: 20px;
    text-align: center;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  }
  
  .home-info-image {
    width: 95%;
    object-fit: contain;
    margin-top: 40px;
    max-width: 300px; /* Stellt sicher, dass das Bild die Breite des Containers nicht überschreitet */
    border-radius: 15px 15px 0 0; /* Rundet die oberen Ecken des Bildes ab */
  }
  

  
  @media (max-width: 600px) {
    .home-info-content > h1 {
        font-size: 52px;
        margin-bottom: 0px;
        font-family: 'Wide', 'Jost', 'Josefin Sans', sans-serif;
      }
      
  .home-info-content {
  width: 85%;
  padding: 0px 15px;
  text-align: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}

  
.home-info-image {
    width: 70%;
    object-fit: contain;
    margin-top: 40px;
    max-width: 300px; /* Stellt sicher, dass das Bild die Breite des Containers nicht überschreitet */
    border-radius: 15px 15px 0 0; /* Rundet die oberen Ecken des Bildes ab */
  }
  .home-info-container {
    padding: 10px 0;
    
  }

  
  }


  @media (max-width: 340px) {
    .home-info-content > h1 {
        font-size: 42px;
        margin-bottom: 0px;
        font-family: 'Wide', 'Jost', 'Josefin Sans', sans-serif;
      }
      
    }
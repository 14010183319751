/* PlaySpy.css */
.play-spy-overlay {
    position: fixed; /* Fixed overlay that covers the entire screen */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.774); /* Abgedunkelter Overlay-Hintergrund */
    z-index: 1000; /* z-index of 1000 */
    cursor: none; /* Hide the default cursor */
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .play-spy-h2 {
      position: absolute;
      font-weight: 600;
      bottom: 0px;
      padding: 40px 40px 20px 40px;
      font-size: 24px;
      margin: 0;
      width: fit-content;
      font-family: 'Jost';
      background-color: rgba(22, 22, 22, 0.5);
      border-radius: 100px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      backdrop-filter: blur(10px);
  }
  
  .play-spy-spotlight {
    position: absolute; /* Spotlight moves with the mouse */
    width: 150px;
    height: 150px;
    margin-left: 25px;
    border-radius: 50%;
    background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 80%);
    pointer-events: none;
    transition: top 0.05s ease-out, left 0.05s ease-out; /* Smoother transition */
  }
  
  .play-spy-cube {
    position: absolute; /* Absolute position to keep it in place */
    width: 50px;
    height: 50px;
    border-radius: 5px;
    cursor: pointer;
    transition: opacity 0.15s ease; /* Smooth Transition for Cube Visibility */
    opacity: 0; /* Initially hidden */
    z-index: 1001; /* Higher than the overlay */
  }
  
  .play-spy-cube-img {
    width: 180%;
    height: 180%;
    object-fit: cover;
  }
  
  .play-spy-cursor {
    position: absolute; /* Custom cursor follows the mouse */
    width: 40px;
    height: 40px;
    z-index: 1000; /* Ensure cursor is on top */
  }
  
  .play-spy-cursor-img {
    width: 140%;
    height: 140%;
    object-fit: cover;
    rotate: 52deg;
    margin-top: 80px;
    z-index: 100;

  }
  
  .play-spy-close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 10px 20px;
    background-color: #fff;
    border: none;
    cursor: pointer;
  }
  
.wallet-trades-container {
  padding: 20px;
  border-radius: 8px;
  max-width: 700px;
  color: white;
  margin: 0 auto;
}

.wallet-trades-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.wallet-trades-form img {
  width: 500px;
  max-height: 400px;
  object-fit: contain;
}

.wallet-trades-form label {
  font-size: 14px;
}

.wallet-trades-form input {
  padding: 8px;
  font-size: 14px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.wallet-trades-form button {
  padding: 10px 15px;
  font-size: 14px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.wallet-trades-form button:hover {
  background-color: #0056b3;
}

.wallet-trades-list ul {
  list-style: none;
  padding: 0;
}

.wallet-trades-list img {
  width: 500px;
  max-height: 400px;
}

.wallet-trades-list li {
  padding: 10px;
  color: white;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

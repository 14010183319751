.chat-window {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.messages-list {
    flex-grow: 1;
    overflow-y: auto;
    padding: 0px 0;
    height: 100%;
}

.messages-list::-webkit-scrollbar {
    display: none; /* Für WebKit-Browser wie Chrome und Safari */
}

.messages-list .message:first-child {
    margin-top: 15px;
}



.message {
    display: flex;
    margin-bottom: 10px;
}

.message.right {
    justify-content: flex-end;
}

.message.left {
    justify-content: flex-start;
}

.chat-profile-image{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
}




.bubble {
    max-width: 400px;
    background-color: #222222;
    padding: 5px 10px;
    border-radius: 20px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    position: relative;
}

.message.right .bubble {
    background-color: var(--blue);
    color: white;
    border-bottom-right-radius: 0px !important;
    text-align: left;

}

.message.left .bubble {
    border-bottom-left-radius: 0px !important;
    text-align: left;

}

.chat-image-container > img {
    width: 400px !important;
    max-height: 500px;
    object-fit: cover;
    border-radius: 15px;
}

.chat-image-container {
    position: relative;
}


.chat-image-container:hover > .chat-download-icon {
    display: flex;
}

.chat-download-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 40px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    height: 40px;
    display: none;
    align-items: center;
    border: none;
    border-radius: 100px;
    border: 1px solid rgba(0, 0, 0, 0.295);
    justify-content: center;
}

.chat-download-icon:hover {
    cursor: pointer;
}

.chat-download-icon > img {
    width: 20px;
}


.bubble > p {
    margin-top: 7px;
    margin-bottom: 7px;
    margin-left: 7px;
    margin-right: 7px;
}



.chat-message-input {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 10px;
    margin-bottom: 15px;
}


.chat-message-input textarea {
    width: 100%;
    border: 1px solid rgb(49, 49, 49);
    border-radius: 15px;
    background-color: var(--black);
    padding: 15px 150px 15px 15px;
    color: white !important;
    outline: none;
    font-family: 'Jost';
    font-size: 16px;
    resize: none; /* or vertical, both, none */

}

.chat-message-input input {
    flex-grow: 1;
    padding: 8px;
    margin-right: 10px;
    width: 100%;
    background: grey;
    outline: none;
}

.chat-image-upload {
    position: absolute;
    right: 115px;
    bottom: 18px;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    border: none;
    background-color: var(--black);
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-image-upload-4 {
    right: 10px;
    bottom: 10px;
    margin-left: 2px;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    border: none;
    background-color: var(--black);
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-image-upload-4 > img {
    filter: invert(1);
    opacity: 0.8;
    user-select: none;
    width: 20px;
}

.chat-image-upload:hover > img {
    opacity: 1;
    cursor: pointer;
}

.chat-image-upload > img {
    filter: invert(1);
    opacity: 0.8;
    user-select: none;
    width: 20px;
}

.chat-profile-image.right {
    margin-left: 10px;
    margin-right: 0px;
}

.chat-profile-image.left {
    margin-left: 10px;
    margin-right: 0px;
}


.chat-image-send-button {
    width: 40px !important;
    margin-left: 10px;
    height: 40px !important;
    border-radius: 100px;
    border: none;
    outline: none;
    background-color: var(--black);
    display: flex !important;
    align-items: center;
    rotate: 45deg;
    user-select: none;
    justify-content: center;
    transition: background-color 0.3s ease; /* Übergang für die Hintergrundfarbe */
}

.chat-image-send-button:hover {
    cursor: pointer;
    background-color: var(--black-hover); /* Hintergrundfarbe ändert sich beim Hovern */
}

.chat-image-send-button:active {
    transform: scale(0.9); /* Skalierungseffekt beim Klicken */
}

.chat-image-send-button > img {
    filter: invert(1);
    width: 25px;
}


.load-more-btn {
    width: 100%;
    margin-bottom: 20px;
    height: 40px;
    background-color: var(--black);
    color: white;
    border: 1px solid grey;
    border-radius: 5px;
    font-family: 'Jost';
    text-transform: uppercase;
    color: rgb(187, 187, 187);
}

.read-status {
    position: absolute;
    bottom: -22px;
    font-size: 13px;
    right: 0;
}

.pdf-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
}

.pdf-modal {
    position: relative;
    background: black;
    border: 1px solid rgb(56, 56, 56);
    width: 80%;
    max-width: 600px;
    padding: 20px;
    border-radius: 25px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.pdf-modal > button > img {
    width: 12px;
}

.pdf-modal > button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pdf-modal-close {
    position: absolute;
    top: -10px;
    right: -10px;
    background: white;
    border-radius: 100px;
    border: none;
    font-size: 18px;
    height: 30px;
    width: 30px;
    cursor: pointer;
}

.pdf-chat-button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    color: white;
    border: 2px dotted #6981c57c;
    border-radius: 15px;
    margin: 10px 5px 10px 5px;
    padding: 20px 20px;
    font-size: 14px;
    display: flex;
    flex-direction: column;    
    font-family: 'Jost';
    font-weight: 400;
}

.pdf-chat-button > img {
    width: 40px;
    margin-bottom: 10px;
    margin-top: 5px;

}

.pdf-button .view-text {
    margin-left: 5px;
}





@media (max-width: 1000px) {
    .chat-image-upload {
        position: absolute;
        right: 95px !important;
        bottom: 18px;
        width: 40px;
        height: 40px;
        border-radius: 100px;
        border: none;
        background-color: var(--black);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 700px) {
    .chat-message-input textarea {
        padding: 15px 15px 15px 15px;
    }

    .superchat-open-button {
        display: none !important;
    }

    .chat-image-upload {
        right: 60px !important; 
    }

    .chat-prev-upladed-img {
        right: 60px;
        bottom: 8px;
    }

    .chat-image-upload {
        display: none;
    }

    .chat-image-container > img {
        width: 150px !important;
        max-height: 200px;
        object-fit: cover;
        border-radius: 10px;
    }
    
    
}

@media (max-width: 550px) {

    .dashboard-chat-sidebar {
        padding: 0px 5px;
    }
}
.gig-default-settings {
    width: 100%
}

.gig-default-settings {
    width: 100%
}

.default-settings > div > h2 {
    width: 80%;
    line-height: 150%;
    font-family: 'Wide';
    margin-bottom: 0px;
    font-size: 22px;
}

.default-settings > div > p {
    width: 80%;
    font-size: 14px;
    color: grey;
}

.gig-default-banner {
    display: flex;
}

.gig-default-banner > img {
    width: 15px;
    margin-left: 5px;
}

.default-settings > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.gig-pro-text {
    color: rgb(196, 196, 196);
}

.gig-pro-text > span {
    font-weight: 700;
    background: linear-gradient(270deg, var(--blue), #d4d4d4, #a8a8be, var(--blue));
    background-size: 400% 400%;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: GradientAnimation 15s ease infinite;
}

@keyframes GradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}

.thank-you-step {
    height: 90%;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.thank-you-step > h2 {
    font-family: 'Wide';
}

.thank-you-step > h3 {
    font-family: 'Wide';
}
.myAwards {
    color: white;
    margin-left: 0px;
    min-height: 100vh;
    overflow: hidden;
    margin-top: 10px;
  }

  .myAwards2 {
    color: white;
    margin-left: 200px;
    min-height: 100vh;
    overflow: hidden;
    margin-top: 30px;

  }

  .artistName_2 {
      margin-top: 0px;
  }

  .mySongsHeader_2 > h1 {
    font-size: 26px;
    font-family: 'Wide';
  }
    
  .mySongsHeader_2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding-right: 40px;
    padding-left: 40px;
    padding-top: 15px;
    margin-bottom: 15px;
  }
  
  .songsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(490px, 1fr));
    gap: 20px;
    justify-items: center;
    padding: 20px 0;
  }
  .songsGrid > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .songDetails_2 > img {
      width: 150px;
      margin-top: 25px;
      margin-bottom: 15px;
  }

  .songDetails_2 {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
  }



  .songDetails_2 > div > h4 {
    font-size: 13px;
    font-weight: 300;
    margin-bottom: 0px;
    color: rgb(190, 190, 190);
  }

  .songDetails_2 > div > h3 {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px;
  }

  .songDetails_2 > div > h6 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    margin-top: -15px;
    font-weight: 300;
    text-align: center;
  }

 
  

  .songDetails_2 > div > h2 {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 600;
    margin-top: 30px;
    font-family: 'Wide', 'Jost', 'Josefin Sans';
    color: transparent;
    background: linear-gradient(100deg, #c7c7c7 30%, #ffffff 50%, #c7c7c7 70%);
    background-size: 200% 200%;
    -webkit-background-clip: text;
    background-clip: text;
    animation: shine2 4s ease-in-out infinite;
  }
  
  @keyframes shine2 {
    0%, 100% {
      background-position: 100% 0;
    }
    50% {
      background-position: -100% 0;
    }
  }
  
  


  .songAwardItem {
    background-color: var(--black);
    border: 1px solid rgb(92, 92, 92);
    padding: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;    
    width: 480px;
    min-height: 730px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 25px;
    position: relative;
    overflow: hidden;
    margin-bottom: 45px;
  }


  .songAwardItem::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), var(--background-url) center center / cover no-repeat;
    filter: blur(40px);
    z-index: 0;
  }
  
  
  .songDetails_2, .songAwardItem img {
    position: relative;
    z-index: 1;
  }

  .awardWinnerSeal {
      width: 80px !important;
      height: 80px !important;
      position: absolute !important;
      right: -20px;
      opacity: 0.5;
      animation: rotateSeal 10s linear infinite;
  }

  .nominatedSeal {
    width: 80px !important;
    height: 80px !important;
    position: absolute !important;
    right: 0px;
    opacity: 0.5;
    animation: rotateSeal 10s linear infinite;
}

@keyframes rotateSeal {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  

  .certificate-signature {
      bottom: 70px;
      filter: invert(1);
      width: 120px !important;
  }
  
  .artist-certificate-image-container > img {
    width: 250px;
    height: 250px;
    object-fit: cover;
    border-radius: 15px;
  }

  .artist-certificate-image-container {
      width: 265px;
      height: 265px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 100;
      margin-top: 15px;
      border-radius: 20px;
      border: 1px solid rgba(255, 255, 255, 0.212);
  }
  















  @media (max-width: 750px) {
    /* MySongs.css */
 .myAwards {
   margin-left: 0px;
 }

 .myAwards2 {
  margin-left: 0px;
}
 .mySongsHeader_2 {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column-reverse;
   height: unset;
   margin-bottom: 0px;
    padding-right: 20px;
    padding-left: 20px;
 }
 .mySongsHeader_2 > h1 {
  margin-top: 30px;
}

 
 .subHeader > p {
   font-size: 14px;
 }
}


@media (max-width: 550px) {
  .songAwardItem {
    width: 98%;
    margin-bottom: 0px;
  }

  .songsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(98%, 1fr));
    gap: 20px;
    justify-items: center;
    padding: 20px;
  }

 
}

@media (max-width: 400px) {
  .songAwardItem {
    width: 100%;
    border: none;
    border-radius: 0px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .songsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    gap: 20px;
    justify-items: center;
    padding: 0px;
    margin-top: 20px;
  }
  .songAwardItem {
    width: 94%;
  }
  .songDetails_2 > img {
    margin-bottom: 0px;
  }
  .artist-certificate-image-container {
    margin-top: 5px;
  }
}

@media (max-width: 370px){
  .songDetails_2 > div > h2 {
    font-size: 20px;
  }


} 

@media (max-width: 340px){


 

  .songDetails_2 > div > h4 {
    font-size: 12px;
  }
} 
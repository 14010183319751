

.artist-dashboard {
  width: 100%;
  height: 100%;
  background-color: var(--black);
}


.submission-screen > img {
    width: 250px;
    z-index: 1000;
    animation: pulseAnimation 4s infinite ease-in-out;
}

.submission-screen {
    padding-bottom: 50px; /* or a specific pixel value */
    /* This allows vertical scrolling if content overflows */
  }

  .submission-screen::-webkit-scrollbar {
    display: none;
}
  

.submission-screen > p {
    max-width: 500px;
    margin-top: 0px;
    z-index: 100;
    margin-bottom: 30px;
    padding-left: 40px;
    padding-right: 40px;

}





.submission-screen > h1 {
    margin-bottom: 0px;
}


@keyframes pulseAnimation {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
  }
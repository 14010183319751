.gig-highlights-container {
    width: calc(100% - 20px);
    border-top: 1px solid var(--color-3);
    border-bottom:  1px solid var(--color-3);
    padding-top: 20px;
    margin-left: 10px;
  }

  .gig-highlights-wrapper {
    margin-top: -40px;
    padding: 20px 0 30px 0px;
  }
  
  .highlight-verified,
  .highlight-award,
  .highlight-nomination {
    display: flex;
    border-radius: 10px;
    align-items: center;
    text-align: left;
    justify-content: flex-start;
    margin-bottom: 20px; /* Abstand zwischen den Highlight-Bereichen */
  }
  
  .highlight-icon {
    width: 30px; /* Größe der Icons anpassen */
    margin-right: 10px; /* Abstand zwischen Icon und Text */

}
  
  .highlight-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: calc(100% - 60px); /* Breite abzüglich des Icon-Bereichs */
  }
  
  .highlight-title {
    font-weight: 600;
    font-size: 11px; /* Anpassen nach Bedarf */
    color: var(--color-oposite-1);
    text-transform: uppercase;
    margin-bottom: 0px;
    margin-top: 0px;
    font-family: 'Wide';
  }
  
  .highlight-description {
    font-size: 14px; /* Anpassen nach Bedarf */
    color: var(--color-oposite-4); /* Farbe der Beschreibung */
  }
  
  @media (max-width: 599px) {
    .gig-highlights-wrapper {
      padding: 40px 0 30px 0px;
    }
    
  }
body {
  margin: 0;
  font-family: 'Jost', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--black);
}


body::-webkit-scrollbar {
  background-color: transparent; 
  width: 0px;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html {
  scroll-behavior: smooth;
}

:root {
  --black: #121212; 
  --gray: rgb(54, 54, 54);  
  --red: #D54842;
  --white: #ffffff; 
  --edge: 2.5vw; 
  --blue: #4449AD;
  --color-1: #000000; /* Dunkelstes Schwarz */
  --color-2: #121212; /* Dunkelgraue Variante */
  --color-3: #2c2c2c;    /* Schwächere Dunkelgraue Variante */
  --color-4: #666;  
  --color-gradient-1: linear-gradient(to bottom, rgb(32, 32, 32), rgb(8, 8, 8));
  --color-invert: invert(1); /* Keine Invertierung */
  --color-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  --color-shadow-2:  rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(49, 49, 49, 0.35) 0px -2px 6px 0px inset;
  --color-shadow-3: rgba(0, 0, 0, 0.3) 0px 10px 10px;
  --color-oposite-1: #ffffff; /* Reines Weiß */
  --color-oposite-2: #f0f0f0; /* Helles Grau */
  --color-oposite-3: #e0e0e0; /* Schwächeres Helles Grau */
  --color-oposite-4: #cfcfcf; /* Noch schwächeres Weiß */
}
:root[data-theme="white"] {
    /* Grundfarben im Hellmodus */
    --color-1: #ffffff; /* Reines Weiß */
    --color-2: #f0f0f0; /* Helles Grau */
    --color-3: #e0e0e0; /* Schwächeres Helles Grau */
    --color-4: #cfcfcf; /* Noch schwächeres Weiß */
    --color-oposite-1: #000000; /* Dunkelstes Schwarz */
    --color-oposite-2: #121212; /* Dunkelgraue Variante */
    --color-oposite-3: #2c2c2c;    /* Schwächere Dunkelgraue Variante */
    --color-oposite-4: #666;  
    --color-gradient-1: linear-gradient(to bottom, #ffffff, #e0e0e0); /* Helles Weiß zu einem schwachen Grau */
    --color-invert: invert(0); /* Keine Invertierung */
    --color-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    --color-shadow-2: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    --color-shadow-3: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

}

@font-face {
  font-family: 'Wide'; 
  src: url('./Fonts/wide.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bit'; 
  src: url('./Fonts/bit.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


.outline-button {
  padding: 10px 20px;
  margin-right: 20px;
  color: white;
  background: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid white;
  font-family: 'Jost';
}
/* src/pages/FAQPage.css */
.bc-faq-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    width: 100%;
    margin-bottom: 130px;
  }
  
  .bc-faq-title {
    font-family: 'Jost';
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 40px;
    color: rgb(255, 255, 255);
    font-family: 'Wide';
  }
  
  .bc-faq-list {
    width: 90%;
  }
  
  .bc-faq-item {
    border-top: 1px solid rgb(29, 29, 29);
    color: rgb(187, 187, 187);
  }

  .bc-faq-item:hover {
    border-top: 1px solid rgb(29, 29, 29);
    color: rgb(255, 255, 255);
  }
  
  .bc-faq-item:last-child {
    border-bottom: 1px solid rgb(29, 29, 29);
  }


  
  .bc-faq-question-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px;
    cursor: pointer;
  }
  
  .bc-faq-question {
    font-family: var(--font-secondary);
    font-size: 18px;
    text-align: left;
  }
  
  .bc-faq-icon {
    font-size: 28px;
    color: gray;
  }
  
  .bc-faq-answer {
    max-height: 0;
    width: 100%;    
    max-width: 600px;
    text-align: left;
    overflow: hidden;
    transition: max-height 0.3s ease, padding 0.3s ease;
  }
  
  .bc-faq-answer p {
    margin: 0 30px 0 0;
    padding: 0px 0 20px 0;
  }
  
  .bc-faq-answer.active {
    max-height: 200px; /* Adjust based on the content height */
    padding: 0px 10px;
  }
  

  
  .bc-faq-item:first-child::before {
    top: 0;
  }
  
  .bc-faq-item:last-child::after {
    bottom: 0;
  }
  

  @media (max-width: 1000px) {
    .footer-links {
        margin-left: 0px;
        margin-right: 0px;
    }
  }

  @media (max-width: 800px) {
    .bc-faq-page-container {
      padding: 40px 0;
      width: 100%;
    }
    .footer-links {
        display: flex;
        flex-direction: column;
        margin-left: 0px;
        margin-right: 0px;
        align-items: center;
    }
    .outline-button-3 {
        width: 200px;
        height: 55px !important;
    }
  }
.artist-bio-container {
    display: flex;
    color: white;
    background-color: var(--black);
  }

  .artist-bio-2 {
    display: flex;
    flex-direction: column;
    background-color: var(--black);
    color: white;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 40px;
  }


  .artist-bio > h3 {
      width: 100%;
      text-align: left;
      margin-left: var(--edge);
      font-size: 24px;
      font-weight: 300;
  }


  
  .artist-bio-text {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content:center;
    text-align: left;
    margin-left: var(--edge);
    margin-right: 20px;
  }
  
  .artist-bio-title {
      font-size: 42px;
      margin-bottom: 0px;
  }

  .artist-bio-description {
    font-size: 18px;
}

  .artist-bio-image {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
    border-radius: 25px;
  }
  

  .artist-bio-image > img {
    width: 90%;
    max-height: 500px;
    border-radius: 20px;
    height: auto;
    object-fit: cover; /* Damit das Bild nicht verzerrt wird */
  }
  
  .artist-bio-2 > h3 {
      text-align: left;
      margin-left: var(--edge);
      font-size: 22px;
      font-weight: 300;
  }


  @media (max-width: 800px) {
    .artist-bio-container {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }
    .artist-bio-image {
      width: 100%; 
    }

    .artist-bio-text {
      width: 90%
    }

    .artist-bio-2 {
      display: flex;
      flex-direction: column;
      background-color: var(--black);
      color: white;
      padding: 0;
      width: 100%;
      margin-top: -30px;
    }
  
  
  }
.auto-messages-wrapper {
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
  }
  

  .auto-analytics-container {
    display: flex;
    flex-wrap: wrap;

  }

  .auto-uploaded-image-container > img {
    width: 280px;
    margin-top: 20px;
    max-height: 300px;
  }

  .auto-messages-container {
      display: flex;
      flex-wrap: wrap;
  }
  
  .auto-messages-toggle-btn {
    display: block;
    margin: 10px auto;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .auto-message-section {
      width: 350px;
      margin: 10px;
      min-height: 500px;
      background-color: rgb(19, 19, 19);
      border-radius: 25px;
      margin-top: 40px;
      border: 1px solid grey;
  }

  .auto-message-section > h2 {
      margin: 25px 0 0 0;
  }
  
  .auto-messages-toggle-btn:hover {
    background-color: #0056b3;
  }
  
  .auto-messages-form,
  .auto-messages-view {
    margin-top: 20px;
  }
  
  .auto-messages-form label {
    font-weight: bold;
  }
  
  .auto-messages-form input {
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }
  
  .auto-messages-form button {
    background-color: #28a745;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .auto-messages-form button:hover {
    background-color: #218838;
  }
  
  .auto-messages-list {
    margin-top: 20px;
  }
  
  .auto-message-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;

  }
  
  .auto-messages-delete-btn {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .auto-messages-delete-btn:hover {
    background-color: #c82333;
  }
  
  .auto-messages-view p {
    font-size: 16px;
  }
  

  .auto-img-delete-image-button {
    background-color: rgb(190, 26, 26) !important;
    top: 0 !important;
    position: absolute;
    right: 0px;
    border: 1px solid white !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    border-radius: 100px !important;
  }

  .auto-uploaded-image-container {
    margin: 10px;
    position: relative;
  }


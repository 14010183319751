.select-widget-settings {
    padding: 20px;
    font-family: Arial, sans-serif;
    text-align: center;

  }
  
  .pro-feature-header h4 {
    margin: 0;
    color: white; /* Orange für PRO */
    font-size: 12px;
    font-family: 'Wide';
    width: fit-content;
    padding: 5px 10px;
    background-color: var(--blue);
    border-radius: 100px;
  }

  .pro-feature-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .pro-feature-header p {
    margin: 5px 0 20px; /* Kleiner Abstand nach oben, größerer nach unten */
    font-size: 12px;
    color: white;
    font-family: 'Jost';
    text-transform: uppercase;
    margin-top: 10px;
  }
  
  .pro-feature-content p {
    margin: 5px 0 20px; /* Kleiner Abstand nach oben, größerer nach unten */
    font-size: 24px;
    color: white;
    font-family: 'Wide';
    margin-top: 30px;
    font-weight: 600;
  }
  
  .pro-feature-content a {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: rgba(255, 255, 255, 0)/* Blau */;
    border: 1px solid white;
    border-radius: 10px;
    cursor: pointer;
    font-family: 'Jost';
    text-decoration: none;
  }


  .widget-preview-image {
    width: 100%;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  }

  .widget-preview-image:hover {
    cursor: pointer;
    transform: scale(1.02);
  }

  .widget-preview-image.disabled-image {
    opacity: 0.1;
    transform: scale(1.0);
    cursor: unset;
  }

  .widget-preview > div {
    position: relative;
  }

 .widget-added-confirm {
   position: absolute;
   right: 10px;
   display: flex;
   background-color: rgb(19, 19, 19);
   border-radius: 100px;
   align-items: center;
   top: 10px;
   padding: 5px 7px;
   border: 1px solid rgb(80, 80, 80);
   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; 
  }

 .widget-added-text {
  position: absolute;
  top: 0%;
  height: 100%;
  left: 0%;
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.795);
  border-radius: 15px;
}

.widget-preview-image-container:hover .widget-added-text {
  display: flex; /* Zeigt das Overlay an, wenn über das Bild gehovered wird */
  cursor: pointer;
}

.widget-preview.disabled .widget-preview-image-container:hover .widget-added-text {
  display: none; /* Blockiert die Anzeige des Overlays für deaktivierte Widgets */
}


.widget-added-text > img {
  width: 30px;
  height: 30px;
}


 .widget-preview-info {
   text-align: left;
 }
 
 .widget-preview-info > p {
   margin: 0 0 10px 0;
   font-family: 'Jost';
   font-size: 14px;
   color: grey;
 }

 .widget-preview-info > h5 {
  margin: 15px 0 5px 0;
  font-family: 'Jost';
  font-size: 16px;

 }
  .widget-added-confirm > img {
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }

  .widget-added-confirm > p {
    margin: 0;
    font-family: 'Jost';
    font-size: 14px;
    margin-right: 5px;
    margin-left: 3px;
    color: rgb(219, 219, 219);
  }

  .widget-add-text {
    font-family: 'Jost';
  }
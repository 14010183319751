.page-qa-settings-stored {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--black);
    margin: 20px 0;
    border-radius: 10px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    padding: 10px 0;
}

.page-qa-settings-stored > div {
    max-width: 80%;
}

.add-qa-button {

}

.add-qa-button {
    width: 94%;
    margin-left: 3%;
    margin-right: 3%;
    height: 52px;
    background: var(--blue) !important;
    border: none;
    border-radius: 10px;
    margin-top: 15px;
    color: white;
    font-family: 'Jost';
    margin-bottom: 50px;
    font-size: 15px;
}

.add-qa-button:hover {
    cursor: pointer;
}

.page-qa-settings-stored > button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: none;
    background-color: var(--red);
    border-radius: 100px;
    top: 5px;
    right: 5px;

}

.page-qa-settings-stored > button:hover {
    cursor: pointer;
}

.page-qa-settings-stored > button > img {
    width: 10px;
    filter: invert(1);
}
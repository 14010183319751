

.collab-page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
}

.collab-page-right {
    color: white;
    display: flex;
    align-items: center;
    justify-content: right;
    margin-right: 2%;
    margin-top: 35px;
    width: 300px;
}


.collab-page-header-left {
    display: flex;
    flex-direction: column;
    color: white;
    width: 70%;
}

.collab-page-header-left > p {
    text-align: left;
    margin: -10px 0 10px 2%;
    color: rgb(139, 139, 139);
}


.collab-page-right > button {
    border-radius: 15px;
    background-color: blue;
    border: 1px solid rgba(255, 255, 255, 0.151);
    color: white;
    height: 60px;
    width: 280px;
    padding: 5px 15px;
    margin-left: 10px;
    font-weight: 600;
    font-family: 'Wide';
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: relative;
    overflow: hidden;
    background: linear-gradient(90deg, var(--blue), rgba(0, 0, 255, 0.5), rgba(14, 14, 24, 0.5), rgba(0, 0, 255, 0.74), var(--blue));
    background-size: 200% 200%;
    animation: moveSpotlight 8s linear infinite;
}

.collab-page-right > button:hover {
    cursor: pointer;
    transform: scale(1.01);
}

.collab-page-right > button > img {
    width: 20px;
    filter: invert(1);
    margin-left: 10px;
}

.collab-page-right > p {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
    color: rgb(189, 189, 189);
    margin-right: 5px;
}

.collab-page-header-left > h1 {
    margin: 15px 25px !important; 
}

.collab-page-header-left > h1 > span {
    font-size: 12px;
    margin-bottom: 20px !important;
    position: absolute;
}


@keyframes moveSpotlight {
    0% {
        background-position: -200% 0%;
    }
    100% {
        background-position: 200% 0%;
    }
}


@media (max-width: 800px) {

    .collab-page-header-left {
        width: 100%;
        align-items: center;
        justify-content: center;
        margin: 0;
    }

    .collab-page-header {
        margin-top: 30px;
    }

    .collab-page-header-left > h1 {
        font-size: 38px !important;
        text-align: center !important;
        margin: 30px 0 0 0;
    }

    .collab-page-header-left > p {
        text-align: center;
        max-width: 80%;
        margin: 0 !important;
    }

    .collab-page-header  {
        flex-direction: column;
    }

    .collab-page-right {
        color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0%;
    margin-top: 45px;
    width: 95%;
    margin-bottom: 20px;
    }
}
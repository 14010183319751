.header-label {
    text-align: center;
    color: white;           /* Dunkelgraue Textfarbe */
    font-size: 22px;       /* Angemessene Schriftgröße */
    font-weight: 400;   /* Standard-Schriftstärke */
    margin: 20px 5% 40px 5%;  
    font-family: 'Wide';
    width: 90%;
}


@media (max-width: 800px) {
    .default-settings > div > h2 {
        margin-top: -50px;
    }

    .header-label {
        margin-top: -50px;
    }
}
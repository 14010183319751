.page-projects-delete > img {
    width: 15px;
    filter: invert(1);
}

.page-projects-delete {
   border-radius: 100px;
   position: absolute;
   top: 10px;
   right: 10px;
   display: flex;
   align-items: center;
   justify-content: center;
   width: 30px;
   height: 30px;
   border: none;
   background-color: var(--red);
}

.page-projects-delete:hover {
    cursor: pointer;
}

.add-new-project-button {
    width: 90%;
    height: 50px;
    background: var(--black) !important;
    border: none;
    border-radius: 7px;
    margin-top: 15px;
    color: white;
    font-family: 'Jost';
    font-size: 15px;
    margin-bottom: 40px !important;

}

.add-new-project-button:hover {
    cursor: pointer;
}

.save-all-project-button:hover {
    cursor: pointer;
}

.save-all-project-button {
    width: 90%;
    height: 50px;
    background: var(--blue) !important;
    border: none;
    border-radius: 7px;
    margin-top: 15px;
    color: white;
    font-family: 'Jost';
    font-size: 15px;
}

.add-first-project {
    width: 90%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    flex-direction: column;
    padding-bottom: 30px;
    background-color: rgba(0, 0, 0, 0.288);
}

.add-first-project > img {
    width: 30px;
    margin-top: 50px;
}













/* Basiseinstellungen für den Datepicker */
.react-datepicker {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: #333; /* Dunkler Hintergrund */
    border: 1px solid #555; /* Dunklere Border für mehr Kontrast */
    color: white; /* Weiße Textfarbe für alle Standardtexte */
    border-radius: 8px;
    font-size: 16px;
    text-align: center;
    min-height: 40px;
}


/* Header des Datepickers */
.react-datepicker__header {
    background-color: #222; /* Noch dunklerer Hintergrund für den Kopfbereich */
    border-bottom: 1px solid #555;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    font-size: 16px;
    color: white;
}

/* Navigationssymbole */
.react-datepicker__navigation {
    top: 10px;
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: none;
}



/* Tage */
.react-datepicker__day,
.react-datepicker__day-name {
    color: white;
    outline: none;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
    background-color: #565656; /* Mittlerer Grauton für ausgewählte Tage */
    color: white;
}

.react-datepicker__day--disabled {
    color: #555;
    cursor: not-allowed;
}

.react-datepicker__day:hover {
    background-color: #444; /* Leichter Helligkeitsunterschied beim Hover */
    color: white;
}

/* Monats- und Jahres-Dropdown */
.react-datepicker__month-dropdown-container,
.react-datepicker__year-dropdown-container,
.react-datepicker__month-year-dropdown-container {
    color: white;
}

.react-datepicker__month-read-view,
.react-datepicker__year-read-view,
.react-datepicker__dropdown-select {
    background-color: #333;
    color: white;
}




.react-datepicker__month-option--selected_month,
.react-datepicker__year-option--selected_year {
    background-color: #565656;
}


.date-picker-label {
width: 90%;
text-align: left;
font-size: 14px;
color: lightgray;
margin-bottom: 10px;
}

/* Zusätzliche Anpassungen für weitere Elemente nach Bedarf */




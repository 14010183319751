.home-new-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    height: 60px;
    margin-top: 10px;
    background-color: var(--black);
    position: sticky;
    top: 0;
    z-index: 10000;
    background-color: rgba(0, 128, 0, 0);
    backdrop-filter: blur(10px);
  }

  .home-new-header-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    height: 60px;
    margin-top: 10px;
    background-color: var(--black);
    position: sticky;
    top: 0;
    z-index: 10000;
    background-color: rgba(0, 128, 0, 0);
    backdrop-filter: blur(10px);
  }

  .header-new-middle > a {
      color: rgb(221, 221, 221);
      text-decoration: none;
      margin: 0 5px;
      font-size: 14px;
      padding: 7px 15px;
      border-radius: 100px;
      text-transform: uppercase;
      letter-spacing: 1px;
  }


  .header-new-middle > a.active {
    color: white;
    font-weight: 500;
    border: 1px solid rgb(121, 121, 121);
}

  .header-new-left, .header-new-middle, .header-new-right {
    display: flex;
    color: white;
    align-items: center; /* Zentriert Inhalte vertikal */
  }
  
  .header-new-logo {
    width: 120px;
    margin-top: 5px;
  }

  
  .header-new-content {
    display: flex;
    align-items: center;
  }
  
  .text-for-experts {
    margin-right: 20px;
    font-size: 14px;
    color: white;
  }
  

  .submit-song-btn:hover {
    background-color: #0056b3;
  }
  
  .avatar-container .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }

  .header-content > a:hover {
    text-decoration: underline  !important;
  }

  .header-content > button > a:hover {
    text-decoration: underline  !important;
  }
  
  .header-avatar {
    border-radius: 100px;
    width: 35px;
    height: 35px;
    object-fit: cover;
    transition: 200 ease-in-out;
  }

  .header-avatar-2 {
    border-radius: 100px;
    width: 30px !important;
    height: 30px !important;
    object-fit: cover;
    transition: 200 ease-in-out;
  }

  .header-new-right > a {
      display: flex;
      align-items: center;
      justify-content: center;
  }

  .nav-down-settings-button {
      font-size: 12px;
      width: 80%;
      height: 35px;
      background-color: var(--gray);
      border-radius: 100px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Jost';
      font-weight: 500;
      color: rgb(204, 204, 204) !important;
  }

  .nav-down-settings-button:hover {
    color: white !important;
  }

  .nav-down-settings-button > img {
    width: 14px;
    margin-left: 5px;
    filter: invert(1);
    opacity: 0.7;
  }

  .nav-down-settings-button:hover > img {
    opacity: 1;
  }

  .apply-for-free-header-button {
    position: absolute;
    right: 142px;
  }

  .apply-for-free-header-button > button {
    border-radius: 100px;
    padding: 12px 15px;
    margin-top: 3px;
    background-color: var(--blue);
    border: none;
    color: white;
    font-family: 'Jost';
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
  }
  
  .apply-for-free-header-button > button > p {
    margin: 0;
    padding: 0;
    font-size: inherit;
    font-weight: inherit;
    background: linear-gradient(90deg, silver, white, rgb(228, 228, 228), white, silver); /* Mehrfache Wiederholung für nahtlosen Übergang */
    background-size: 200%; /* Anpassung für flüssige Bewegung */
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    animation: gradientMove 3s linear infinite;
  }
  
  @keyframes gradientMove {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%; /* Nahtlose Rückkehr zur Anfangsposition */
    }
  }

  .apply-for-free-header-button > button:hover {
    cursor: pointer;
  }

  .header-new-right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: rgb(27, 27, 27);
      border: 1px solid rgb(78, 78, 78);
      border-radius: 100px;
      padding: 5px 5px;
      width: 100px;
  }

  

  .total-unread-messages-menu-2 {
      position: absolute;
      bottom: 10px;
      right: -10px;
      width: 15px;
      height: 15px;
      font-size: 11px;
      background-color: var(--red);
      border-radius: 100px;
      color: white;
      font-family: 'Jost';
      display: flex;
      align-items: center;
      justify-content: center;

  }


  .chat-notification {
    position: relative;
    display: flex;
}

  .chat-notification-count {
    position: absolute;
    top: -10px; /* Anpassen basierend auf Ihrem Design */
    right: -10px; /* Anpassen basierend auf Ihrem Design */
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 0 6px;
    font-size: 12px;
}

.chat-notification > img:hover {
  transform: scale(1.05);
}

.header-new-right > a > img:hover {
  transform: scale(1.05);
}


.header-pro-right-pro {
  position: absolute;
  left: 155px;
  top: 2px;
  text-transform: uppercase;
  color: white;
  padding: 5px 12px;
  border-radius: 100px;
  font-size: 13px;
  font-weight: 500;
  background: linear-gradient(90deg, var(--blue), rgba(0, 0, 255, 0.5), rgba(14, 14, 24, 0.5), rgba(0, 0, 255, 0.74), var(--blue));
    background-size: 200% 200%;
    animation: moveSpotlight 8s linear infinite;
}


.toggler-button {
  display: flex;
  flex-direction: row-reverse;
  margin: 0;
  align-items: center;
  justify-content: center;
}

.toggler-button > img {
 width: 25px;
 max-height: 22px;
 filter: invert(1);
 margin-left: 5px;
 margin-right: -5px;
 object-fit: contain;
}

.premium-sub-header-wrapper {
  position: absolute;
  right: 142px;
}

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    90% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 800px) {
    .text-for-experts {
      display: none;
    }

    .text-for-experts {
      display: none;
    }

    .outline-button {
      padding: 7px 13px;
      font-size: 16px;
      margin-right: 0px;
    }
    .apply-for-free-header-button > button {
      display: none;
    }
  


  }

  @media (max-width: 750px) {
    .home-new-header-2 {
      display: none;
    }
  }

  @media (max-width: 600px) {

  #header-awards-button {
    display: none;
  }
  .home-new-header {
    display: none;
  }

  .header-new-left > a {
    margin: 0 0 !important;
    padding: 7px 0;
  }

  .header-new-left {
display: none;
  }
  
  .header-new-middle {
width: 50%;
display: flex;
align-items: center;
justify-content: flex-start;
  }
  
  .header-new-right {
max-width: 50%;
  }

  }
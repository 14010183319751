.page-settings-container {
    color: #fff;
    min-height: 100vh;
    position: relative; /* Positionierung relativ zum Container */

  }
  
  .page-settings-header {
    margin-bottom: 20px;
    height: 60px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
  }
  
  .page-settings-layout {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
  }


  .page-settings-sidebar {
    position: fixed;
    height: calc(100vh - 60px); /* Höhenanpassung, um die Kopfzeile zu berücksichtigen */
    margin-top: 60px;
    box-shadow: rgb(61, 61, 61) 0px 20px 10px -10px;
    left: 0;
    top: 0;
    width: 320px; /* Neue Breite inklusive SidebarSide */
    background-image: linear-gradient(to left, #242424, #131313);
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none; /* für Firefox */
    display: flex; /* Ermöglicht das Nebeneinanderstellen von SidebarSide und WidgetSettings */
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 1000;
  }
  
  .page-settings-sidebar::-webkit-scrollbar {
    display: none; /* für Chrome, Safari und Opera */
  }
  
  
  
  .page-settings-main {
    width: 100%;
    margin-top: 60px;
    margin-left: 320px;
  }

  
  .page-settings-loading,
  .page-settings-empty {
    text-align: center;
    margin-top: 50px;
  }
  

  .page-settings-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: rgb(61, 61, 61) 0px 5px 10px -10px;
    border-bottom: 1px solid rgb(53, 53, 53);
    z-index: 10000;

  }

  .page-settings-header-left {
    display: flex;
    width: 50%;
    align-items: center;
    height: 100%;
    background-image: linear-gradient(to left, #242424, #131313);

  }

  .page-settings-header-center {
    background-color: #242424 !important;
  }

  .page-settings-header-center > button {
    border: 1px solid rgba(255, 255, 255, 0); /* Blauer Hintergrund für aktive Buttons */
    color: rgba(255, 255, 255, 0.842);
    font-family: 'Jost';
    margin: 0 20px;
    font-size: 14px;
    padding: 5px 15px;
    border-radius: 100px;
    background: #f0f0f015; /* Grauer Hintergrund für nicht aktive Buttons */

  }
  .page-settings-header-center > button.header-active {
    border: 1px solid white; /* Blauer Hintergrund für aktive Buttons */
    color: #ffffff; /* Weiße Schriftfarbe */
  }

  .page-settings-header-center > button:hover {
    cursor: pointer;
  }


  .page-settings-header-right {
    width: 80%;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    background-color: #242424 !important;
    height: 100%;
    align-items: center;

  }

  .page-settings-header-right > button {
    height: 42px;
    width: 120px;
    border-radius: 10px;
    border: none;
    background-color: rgb(49, 49, 49);
    color: white;
    font-family: 'Jost';
    font-size: 14px;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .page-settings-header-right > a > button {
    height: 42px;
    width: 120px;
    border-radius: 10px;
    border: none;
    background-color: var(--blue);
    color: white;
    font-family: 'Jost';
    font-size: 14px;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .page-settings-header-right > a > button:hover {
    cursor: pointer;
  }

  .page-settings-header-left > a > img {
    width: 120px;
    margin-left: 20px;
  }

  .page-settings-header-left > a {
    width: 100%;
    display: flex;
    color: white;
    text-decoration: none;
    font-weight: 500;
    letter-spacing: 0.5px;
    z-index: 10000;
  }

  .page-settings-header-left > a > p > span {
    margin-right: 5px;
    margin-left: 20px;
  }

  .page-settings-header-left > button > img {
    width: 15px;
    filter: invert(1);
  }

  .page-settings-header-left > button {
    background-color: rgb(46, 46, 46);
    border: 0px;
    width: 35px;
    height: 35px;
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    margin-left: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;  }


    .page-settings-header-left > button:hover {
      cursor: pointer;
    }

    .pages-page-settings-back {
      width: 20px !important;
      filter: invert(1);
      margin-left: 30px !important;
      rotate: 90deg;
    }

    .page-settings-bottom-mobile {
      display: none;
    }


    @media (max-width: 1300px) {
      .page-settings-sidebar {
        width: 270px; /* Neue Breite inklusive SidebarSide */
      }
      
    }



    @media (max-width: 1200px) {
      .page-settings-main {
        width: calc(100% - 200px);
        margin-left: 270px;
      }
      
    }



    @media (max-width: 800px) {
      .page-settings-main {
        width: calc(100% - 200px);
        margin-left: 270px;
        
      }
      .page-settings-header-right > button {
        width: 90px;
      }
      .page-settings-header-left {
        display: flex;
        width: 100%;
    
      }

      .page-settings-header-left > a {
        font-size: 13px;
        margin-left: -10px;
      }

    }
      @media (max-width: 900px) {
        .settings-header-logo {
          display: none;
      }

      .page-settings-sidebar {
        display: none;
      }
      .page-settings-main {
        width: 100%;
        margin-left: 0px;
      }

      
      .page-settings-bottom-mobile {
        display: flex;
        flex-direction: column;
        position: fixed;
        align-items: center;
        width: 100%;
        z-index: 1000;
        bottom: 0;
        height: 60px;
        border-top: 1px solid rgb(70, 70, 70);
        overflow: hidden;
        transition: height 0.3s ease;
        background-color: var(--black);
        overflow: hidden;  /* Verhindert das Scrollen, wenn nicht erweitert */
      }

      .page-settings-bottom-mobile::-webkit-scrollbar {
        display: none;
      }
      
      .page-settings-bottom-mobile.expanded {
        height: 75%; /* Angepasste Höhe für geöffnete Widget-Einstellungen */
        overflow-y: auto;  /* Ermöglicht das Scrollen innerhalb des Containers */

      }
      
      .plus-toggle-button {
        position: absolute;
        bottom: 10px; /* Button immer 10px vom unteren Rand */
        left: 35px; /* Zentrierung von links */
        transform: translateX(-50%); /* Genauere Zentrierung, basierend auf der Breite des Elements */
        width: 35px; /* Breite des Buttons festlegen */
        height:35px; /* Höhe des Buttons festlegen */
        cursor: pointer;
      }

      .settings-widget-mobile-container {
        height: 100%; 
         /* Höhe auf 100% setzen, damit es den gesamten verfügbaren Raum einnimmt */
        overflow-y: auto;  /* Ermöglicht Scrollen innerhalb des Containers */
      }

      .bottom-toggle-plus > p  { 
        font-size: 16px;
        text-align: left;
        width: 50%;
        margin-left: 70px;
      }

      .bottom-toggle-plus {
        width: 100%;
      }
      
      .settings-widget-mobile-container::-webkit-scrollbar {
        display: none;
      }
      .plus-toggle-button img {
        filter: invert(1);
        width: 100%; /* Bild füllt den Button aus */
        transition: transform 0.3s; /* Animation für das Drehen */
      }
      
      .page-settings-bottom-mobile.expanded .plus-toggle-button img {
        transform: rotate(45deg); /* Dreht das Plus zu einem X */
      }
      

    }



    @media (max-width: 900px) {

      .settings-widget-mobile-container {
        width: 90%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
      }

      .settings-widget-mobile-container > div {
        width: 100%;
      }


    }


    @media (max-width: 500px) {
      .plus-toggle-button {
        bottom: 12px;
        left: 30px;
      }

      .bottom-toggle-plus > p {
        margin-left: 60px;
        font-weight: 500;
      }

      .page-text-header.settings-route {
        border: none !important;
      }

      .gig-seller-infos.is-active {
        padding: 0 !important;
      }

      .page-settings-main {
        width: 93%;
        margin-left: 0px;
      }

      .settings-route:hover {
         transform: unset;
}
    }
.list-artists-container {
    padding: 20px;
  }
  
  .list-artists-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .list-artists-table, .list-artists-table th, .list-artists-table td {
    border: 1px solid #ddd;
  }
  
  .list-artists-table th, .list-artists-table td {
    padding: 1px 8px;
    text-align: left;
  }
  
  .list-artists-table th {
    background-color: #313131;
  }
  
  .list-artists-table tr:nth-child(even) {
    background-color: #4b4b4b;
  }
  
  .list-artists-table tr:hover {
    background-color: #303030;
  }
  
  .expert-mini-img {
    width: 150px;
    max-height: 150px;
    object-fit: cover;
    padding: 10px;
    border-radius: 15px;
  }
.payout-option {
    display: flex;
    align-items: center;
    width: 95%;
    height: 80px;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid rgba(204, 204, 204, 0);
    border-radius: 15px;
    background-color: #ffffff05;
    cursor: pointer;
    color: white;
    opacity: 1;
    font-family: 'Jost';
  }
  .settings-payout-selection-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 90%;
  }
  
  
  .payout-option.selected {
    background-color: #00000042;
    border-color: rgba(136, 136, 136, 0.87);
    animation: fade-in-3 0.2s;
  }
  
  .payout-option:hover {
    border-color: rgba(136, 136, 136, 0.692);
  }
  
  .payout-option img {
    width: 50px;
    height: 50px;
    margin-right: 20px;
    margin-left: 10px;
    filter: invert(1);
    opacity: 0.5;
  }
  
  .payout-option.selected img {
    opacity: 1;
  }
  
  .payout-option-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .payout-option-text > h3 {
      margin-bottom: 5px;
      margin-top: 0px;
  }

  .payout-option-text > p {
    margin-bottom: 0px;
    margin-top: 0px;
    color: grey;
    text-align: left;
}
  
  .form-button {
    width: fit-content;
    padding: 15px 25px;
    border-radius: 10px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .form-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  


  @media (max-width: 800px) {
    .settings-payout-selection-container {
      min-height: 86vh;
    }
    
  }
.social-links-container {
    position: absolute;
    top: -50px;
    right:40px;
    z-index: 1;
    display: flex;
    justify-content: center;
}

.social-links-container > a {
    width: 45px;
    height: 45px;
    background-color: var(--color-2);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3px 7px;
}

.gig-save-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Jost';
    font-size: 15px;
    height: 45px;
    width: 150px;
    border-radius: 12px;
    background-color: var(--color-2);
    border: 0px;
    color: var(--color-oposite-1);
    margin-top: 7px;
    margin-left: 10px;
    margin-right: 12px;
}

.gig-save-button:hover {
    cursor: pointer;
    transform: scale(1.02);
}

.gig-save-button > p {
    font-weight: 500;
}
.gig-save-button > img {
    width: 20px;
    margin-left: 10px;
    filter: var(--color-invert);
    opacity: 0.8;
}

.social-links-container > a > img {
    width: 20px;
    height: 20px;
    filter: var(--color-invert);
    opacity: 0.8;
}


@media (max-width: 1200px) {
    .social-links-container.settings {
        position: absolute;
        top: -50px;
        right: -20px;
        z-index: 1;
        display: flex;
        justify-content: center;
    }
    
}


@media (max-width: 900px) {
    .social-links-container.live {
        position: absolute;
        top: -50px;
        right: -20px;
        z-index: 1;
        display: flex;
        justify-content: center;
    }
    .page-preview-header > button {
        margin-bottom: -70px;
        font-size: 16px;
        margin-left: -20px;
    }
    .gig-save-button {
        margin-right: 10px !important;
    }
}

@media (max-width: 600px) {
    .gig-save-button  {
        width: 50px;
        border-radius: 100px;
        height: 50px;
        padding: 0px;
        margin: 0 15px 0 0;
    }


    .gig-save-button > p {
        display: none;
        margin: 0;
    }

    .gig-save-button > img {
        margin: 0px !important;
    }

    .social-links-container.settings {
        right: 10px;
    }

}
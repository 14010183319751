/* Versteckt die Navigationsleiste standardmäßig */
.mobile-nav-container {
    display: none;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: var(--black);
    border-top: 1px solid #474747;
    height: 65px;
    z-index: 1010;
    transition: transform 0.3s ease-in-out;
}

/* Aktiviert die Anzeige der Navigationsleiste auf kleinen Bildschirmen */
@media (max-width: 750px) {
    .mobile-nav-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

.nav-visible {
    transform: translateY(0%);
}

.nav-hidden {
    transform: translateY(90%);
}

.mobile-nav-link {
    text-decoration: none;
    color: #ffffff;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mobile-nav-icon {
    width: 28px;
    height: 28px;
    object-fit: contain;
    margin-top: 15px;
    margin-bottom: 1px;
}

.mobile-nav-link-active {
    border-top: 2px solid var(--red);
}

.mobile-nav-label {
    font-size: 12px;
    margin-top: 4px;
}

.mobile-nav-icon-upload {
    /* Custom styles for Upload icon */
    width: 50px;
    height: 50px;
    margin-top: -10px;
}

.mobile-nav-label-upload {
    font-size: 22px;
    font-weight: 500;
}


}
/* MySongs.css */
.mySongs {
    color: white; /* Für den Dark Mode */
    margin-left: 200px;
  }
  
  .mySongsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding-right: 40px;
    padding-left: 40px;
    margin-top: 15px;
    margin-bottom: 0px;
  }

  .skeleton-image-2 {
    background: #ddd;
    animation: shimmer 2s infinite;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 1000px 100%;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    background-repeat: no-repeat;
    animation: shimmer2 1s infinite;
    background-size: 200% 100%;
  }

  .song-details-nominations > div {
      width: fit-content;
      position: relative;
      
  }
  .song-details-nominations > div > img {
    width: 15px;
    filter: invert(1);
    margin-right: 5px;
    
}

  .song-details-nominations {
      margin-bottom: 20px;
      margin-top: 10px;
  }

  .subHeader {
      display: flex;
      margin-left: 20px;
  }

  .subHeader > p {
      padding: 10px;
      margin: 10px 15px 0 10px;
      border-radius: 10px;
      color: gray;
      font-size: 14px;
  }
  

  .song-details-nominations > div {
      background-color: var(--black);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px 0 10px;
    font-size: 13px;
    font-weight: 500;
    margin-left: 0px;
  }

 

  .song-details-nominations > div > h5 {
      width: 100%;
      text-align: left;
      margin-top: 10px;
      color: rgb(197, 197, 197);
  }

  .song-details-nominations > p:first-child {
      margin-bottom: 5px;
    
  }

  .plus-song-icon {
    width: 30px;
    margin-left: 15px;
    opacity: 1;
    border: 1px solid rgba(255, 255, 255, 0);
    border-radius: 100px;
}

.upload-song-button:hover .plus-song-icon {
    opacity: 1;
    border: 1px solid rgba(255, 255, 255, 0.829);
}

.upload-song-button:hover {
    transform: scale(1.01);
    border: 1px solid rgba(255, 255, 255, 0.562);
    cursor: pointer;

}


  .upload-song-button {
    background: linear-gradient(215deg, #7199b0 49.9%, #1a3379 80%),
                linear-gradient(304deg, rgba(6, 101, 117, 0.4) 24.9%, #aa2743 91%),
                linear-gradient(10deg, #b1c9d8 39.9%, #2776aa 50%),
                linear-gradient(145deg, #1e0852 15.9%, #672188 40%);
    background-size: 200% 200%;
    background-repeat: no-repeat;
    background-blend-mode: hard-light;    background-size: 200% 200%;
    animation: moveGradient2 8s linear infinite;
      color: white;
      border: none;
      text-transform: uppercase;
      height: 50px;
      width: 220px;
      font-family: 'Jost';
      font-size: 13px;
      border-radius: 12px;
        display: flex;
        font-weight: 600;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        border: 1px solid rgba(255, 255, 255, 0);

  }
  
  .mySongsMain {
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 330px)); /* Passt die Spalten automatisch an, mit mindestens 200px Breite für jedes Grid-Element */
    grid-gap: 30px; /* Setzt den festen Abstand zwischen den Grid-Elementen auf 30px */
    align-items: start;
    background-color: var(--black) !important;
}


  .delete-confirm-modal-overlay {
    position: fixed; /* Fixed statt absolute, um den gesamten Bildschirm abzudecken */
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10000;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Abdunkelung des Hintergrunds */
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px); /* Blurren des Hintergrunds */
  }
  
  .delete-confirm-modal {
    background-color: var(--black);
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1050; /* Stellen Sie sicher, dass das Modal über anderen Elementen schwebt */
    width: 90%; /* Breite des Modals */
    max-width: 400px; /* Maximale Breite für größere Bildschirme */
  }
  
  .delete-confirm-modal button {
    margin-top: 20px;
    margin-right: 10px;
    padding: 10px;
    border: none;
    border-radius: 5px;
  }

  .delete-confirm-modal button:hover {
    transform: scale(1.02);
    cursor: pointer;
  }



  .delete-confirm-modal > p {
      font-size: 20px;
  }

  .mySongsMain::-webkit-scrollbar {
    display: none; /* Versteckt die Scrollbar in Webkit-Browsern */
  }
  
  
  .songItem {
    display: flex;
    width: 330px;
    align-items: center;
    margin-bottom: 20px;
    flex: 0 0 auto; /* Verhindert, dass die Items schrumpfen oder wachsen, was für eine gleichmäßige Darstellung sorgt */
    flex-direction: column;
    background-color: rgb(24, 24, 24);
    margin: 10px;
    white-space: normal; /* Stellt den normalen Textumbruch innerhalb der SongItems wieder her */
    min-height: 510px;
    border-radius: 25px;
    border: 1px solid rgb(24, 24, 24);
    position: relative;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;  
    transition: 50ms ease-in-out;
    border: 1px solid rgba(255, 255, 255, 0.116);
}

.delete-box {
    position: absolute;
    top: -5px; /* Passen Sie dies an Ihre Bedürfnisse an */
    right: -5px; /* Passen Sie dies an Ihre Bedürfnisse an */
    cursor: pointer;
    background-color: black;
    padding: 10px;
    border-radius: 100px;
    width: 12px;
    height: 12px;
    align-items: center;
    justify-content: center;
    opacity: 0;
    display: flex;
    z-index: 100;
  }

  .songItem:hover .delete-box {
    opacity: 1; /* Sichtbar machen beim Hover über songItem */
  }

  .deleteIcon {
    filter: invert(1);
    width: 10px;
    pointer-events: none;
    color: rgb(75, 75, 75);
  }



  .songCover {
    width: 300px;
    height: 300px;
    border-radius: 25px;
    padding: 15px;
    object-fit: cover;
  }


  .skeleton-loader {
    width: 300px;
    height: 300px;
    border-radius: 25px;
    padding: 15px;
    position: relative;
    overflow: hidden;
    animation: pulseAnimation 1.5s infinite ease-in-out;
  }
  



@keyframes shimmer2 {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
 
 
  @keyframes pulseAnimation {
    0%, 100% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
    }
    50% {
      box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
    }
  }
  
  
  .songCover {
    transition: opacity 0.5s ease-out;
  }
  
  
  .songDetails h3, .songDetails p {
    margin: 0;
    width: 100%;
  }
  

  .songDetails {
      text-align: left;
      width: 90%;

  }

  .profile-download-certificate > img {
      width: 22px;
      margin-right: 10px;
      filter: invert(1);
  }

  .mySongsHeader > h1 {
    font-family: 'Wide';
    font-size: 26px;
  }

  .profile-rating-number {
      position: absolute;
    top: 325px;
    right: 25px;
    display: none;
  }

  .profile-rating-number > p {
      text-align: center;
      margin-left: 3px;
      font-size: 11px;
      margin-bottom: 5px;
      color: grey;

  }

  .profile-rating-number > div {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      width: 60px;
      background-color: rgba(0, 0, 0, 0.555);
      border-radius: 100px;
  }

  .profile-rating-number > div > p {
      width: 100%;
      text-align: center;
      font-size: 24px;
      font-weight: 500;
  }

.profile-download-certificate:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;   
    animation: colorShift 5s infinite alternate ease-in-out;

}
  
  .profile-download-certificate {
    position: absolute;
    width: 330px;
    left: 0;
    bottom: 0px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    color: white;
    border-top: 1px solid rgb(75, 74, 74);
    transition: 200ms ease-in-out;
    /* Animation hinzufügen */
  }
  
  /* Keyframes für die Animation definieren */
  @keyframes colorShift {
    from {
      background-color: var(--red);
    }
    to {
      background-color: #c44343;
    }
  }
  

  .profile-download-certificate > p {
      text-decoration: none;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.4px;
  }



  .certificate-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: 'translate(-50%, -50%)';
    z-index: 1000;
    background: var(--black);
    padding: 20px;
    width: auto;
    max-width: 600px;
    max-height: 500px;
    overflow-y: auto; /* Ermöglicht das Scrollen, wenn der Inhalt höher als 500px ist */
    text-align: center;
  }

  .plus-song-icon-3 {
    width: 100px;
  }
  

  .certificate-modal-content > h2 {
      font-size: 50px;
  }

  .zero-credits-comp {
    position: absolute;
    top: 20px;
    font-size: 13px;
    padding: 5px 10px;
    border: 1px solid rgba(255, 255, 255, 0.466);
    border-radius: 100px;
  }


  .songs-get-vip-button {
    background-color: rgba(255, 255, 255, 0.055);
    position: absolute;
    display: flex;
    bottom: 0;
    height:70px;
    width: 100%;
    color: white;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    font-weight: 500;
  }

  .vip-pass-icon {
    width: 30px;
    margin-left: 20px;
  }

.upload-next-container > p {
  font-size: 18px;
  font-weight: 500;
}

  .upload-next-container {
    display: flex;
    width: 330px;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    flex: 0 0 auto;
    flex-direction: column;
    margin: 10px;
    white-space: normal;
    min-height: 510px;
    border-radius: 25px;
    position: relative;
    transition: 50ms ease-in-out;
    background: linear-gradient(215deg, #7199b0 49.9%, #1a3379 80%),
                linear-gradient(304deg, rgba(6, 101, 117, 0.4) 24.9%, #aa2743 91%),
                linear-gradient(10deg, #b1c9d8 39.9%, #2776aa 50%),
                linear-gradient(145deg, #1e0852 15.9%, #672188 40%);
    background-size: 200% 200%;
    background-repeat: no-repeat;
    background-blend-mode: hard-light;
    animation: moveGradient 15s ease infinite;
    color: white;
    font-family: 'Jost';
    border-bottom: 0px;
    border: 1px solid rgba(128, 128, 128, 0.466);
    text-decoration: none;
    transition: 100ms ease-in-out;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}

.upload-next-container:hover {
  transform: scale(1.01);
  cursor: pointer;
}

@keyframes moveGradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@keyframes moveGradient2 {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}



.mySongstoggleMenu {
  align-items: left;
  display: flex;
  margin-left: 26px;
  margin-bottom: 0px;
}


.mySongstoggleMenu > button {
 background: none;
 border: 1px solid rgba(255, 255, 255, 0.37);
 font-size: 16px;
 color: white;
 font-weight: 400;
 font-family: 'Jost';
 background-color: black;
 padding: 0px 20px;
 border-radius: 15px;
 height: 55px;
 margin: 0px 10px;
 display: flex;
 font-size: 14px;
 align-items: center;
 justify-content: center;
}

.mySongstoggleMenu > button:hover {
  cursor: pointer;
  transform: scale(1.01);
}

.mySongstoggleMenu > button > img {
  width: 20px;
  margin-right: 10px;
}


.mySongstoggleMenu > button.active {
  border: 1px solid var(--red);
  font-weight: 500;
  cursor: pointer;
}

.mySongstoggleMenu > button:nth-child(2) {
  margin-left: 0px;
}





  @media (max-width: 750px) {
   /* MySongs.css */
.mySongs {
  margin-left: 0px;
  padding-bottom: 60px;
}
.mySongsMain {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}

.mySongstoggleMenu {
  align-items: center !important;
  display: flex;
  justify-content: center;
  margin-left: 0%;
  margin-top: 25px;
}


.mySongstoggleMenu > button {
  border: 1px solid rgba(255, 255, 255, 0.37);
  font-size: 20px;
  font-weight: 500;
  border-radius: 12px;

 }
 
 .mySongstoggleMenu > button.active {
   background-color: var(--blue);
   font-weight: 500;
 }
 
 .mySongstoggleMenu > button:nth-child(2) {
   margin-left: 0px;
 }

 .songtoggleButton {
   font-size: 14px !important;
 }

 .awardtoggleButton {
  font-size: 14px !important;
 }
 

.mySongsHeader {
  margin-bottom: 0px;
}

.mySongsHeader > h1 {
  margin-bottom: 0px;
}



.subHeader > p {
  font-size: 14px;
  margin: 0 5px;

}

.mySongsHeader > h1 {
  font-size: 34px;
  z-index: 999;
}

.mySongsHeader {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column-reverse;
  height: unset;
}

.upload-song-button {
  width: 100%;
  height: 75px;
  font-size: 16px;
  border-radius: 18px;
}



.plus-song-icon {
  opacity: 1;
}

.subHeader {
  align-items: center;
  justify-content: center;
  margin: 10px 0 0 0;
}

  }

  @media(max-width: 750px) {
    .mySongstoggleMenu > button {
      width: 42%;
      padding: 0px;
      border-radius: 18px;
      height: 60px;
    }
  }



  @media (max-width: 350px) {
    .songCover {
      width: 90%;
      height: 250px;
    }

    .subHeader > p {
      font-size: 12px;
    }

    .songtoggleButton {
      font-size: 12px !important;
    }
   
    .awardtoggleButton {
     font-size: 12px !important;
    }

    .upload-next-container {
      width: 100%;
    }


    .songItem {
      width: 98%;
      
    }

    .profile-download-certificate {
      width: 100%;
    }
  }

  @media (max-width: 340px) {
    .songItem {
      min-height: 480px;
    }
  }

  @media (max-width: 320px) {
    .songItem {
      min-height: 460px;
    }
    .songCover {
      width: 90%;
      height: 215px;
    }

  }

  @media (max-width: 300px) {
    .songItem {
      min-height: 425px;
    }
  }
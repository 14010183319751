/* Modal Overlay */
.woody-modal-overlay {
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  /* Modal Content */
  .woody-modal-content {
    background-color: #333;
    color: #fff;
    border-radius: 8px;
    padding: 20px;
    max-width: 600px;
    width: 90%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.3s ease-in-out;
  }
  
  .woody-modal-content h2 {
    margin-top: 0;
  }
  
  .woody-modal-content p {
    margin-bottom: 20px;
  }
  
  .woody-modal-content ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .woody-modal-content ul li {
    margin-bottom: 10px;
  }
  
  .woody-summary-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #2b2b2b; /* Dunkler Hintergrund für die Tabelle */
    color: #e0e0e0; /* Helle Schriftfarbe */
  }

  
  .woody-summary-table th,
  .woody-summary-table td {
    padding: 12px;
    border: 1px solid #444; /* Dunkler Rand für Zellen */
  }
  
  .woody-summary-table th {
    background-color: #3a3a3a; /* Dunklerer Hintergrund für die Kopfzeilen */
    font-weight: bold;
  }
  
  .woody-summary-table tr:nth-child(even) {
    background-color: #333; /* Leicht dunklerer Hintergrund für gerade Zeilen */
  }
  
  .woody-summary-table tr:hover {
    background-color: #444; /* Hintergrundfarbe bei Hover */
  }
  

  /* Buttons */
  .woody-modal-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.3s ease;
  }
  
  .woody-modal-button:hover {
    background-color: #0056b3;
  }
  
  .cancel-button {
    background-color: #dc3545;
  }
  
  .cancel-button:hover {
    background-color: #c82333;
  }
  
.woody-summary {
    margin-top: 50px;
}
.gigs-basic-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr)); /* Passt die Spalten automatisch an, beginnend bei einer Mindestbreite von 240px */
    gap: 20px;
    padding: 20px;
    background-color: #121212; /* Dunkler Hintergrund */
    color: #fff;
}

.gigs-basic-grid-2 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr)); /* Passt die Spalten automatisch an, beginnend bei einer Mindestbreite von 240px */
    gap: 20px;
    padding: 20px 5%; 
    color: #fff;
}

.basic-gig-item {
    background-color: #1e1e1e;
    border-radius: 20px;
    position: relative;
    border: 1px solid rgb(61, 61, 61);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    width: 100%; /* Utilizes the full width of the grid column */
    padding-top: calc(0% * (13 / 18)); /* Enforces the 18:13 aspect ratio */
    overflow: hidden; /* Ensures content doesn't overflow */
}

.basic-gig-item.artistPage {
    background-color: #1e1e1e6e;
}

.no-gigs-message {
    background-color: rgb(29, 29, 29);
    width: 100%; 
    height: 300px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.no-gigs-message > a {
    padding: 5px 20px;
    background-color: var(--blue);
    border-radius: 100px;
    height: 50px;
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.no-gigs-message > p {
    margin: 0 25px;
}



/* Media Queries für spezifische Spaltenanpassungen */
@media (min-width: 1200px) {
    .gigs-basic-grid {
        grid-template-columns: repeat(3, 1fr);
    }
    .gigs-basic-grid-2 {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: 900px) and (max-width: 1199px) {
    .gigs-basic-grid {
        grid-template-columns: repeat(2, 1fr);
    }
    .gigs-basic-grid-2 {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 600px) and (max-width: 899px) {
    .gigs-basic-grid {
        grid-template-columns: repeat(1, 1fr);
    }
    .gigs-basic-grid-2 {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width: 599px) {
    .gigs-basic-grid {
        grid-template-columns: 1fr;
    }
    .gigs-basic-grid-2 {
        grid-template-columns: 1fr;
    }
}

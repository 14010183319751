/* ProfileImageUploader.css */
.uploader-profile-image-uploader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
}


.uploader-profile-image-container {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
}

.uploader-profile-image-container:hover .upload-icon {
    opacity: 1;
}


.upload-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    opacity: 0;
    filter: invert(1);
    transition: opacity 0.14s ease-in-out;
    pointer-events: none; /* verhindert, dass das Icon Klick-Events erhält */
}

.uploader-profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    opacity: 0.8;
    object-fit: cover;
    transition: 140ms ease-in-out;
    background-color: #333;
}


.uploader-profile-image-2 {
    width: 200px;
    height: 200px;
    border-radius: 10px;
    opacity: 0.8;
    object-fit: cover;
    transition: 140ms ease-in-out;
    background-color: #333;
}


.uploader-profile-image:hover {
    cursor: pointer;
    opacity: 1;
}

.uploader-profile-image-2:hover {
    cursor: pointer;
    opacity: 1;
}

.uploader-profile-placeholder {
    width: 150px;
    height: 150px;
    border: 1px dashed rgb(85, 85, 85);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #fff;
    font-size: 14px;
    background-color: rgb(36, 36, 36);
    text-align: center;
}


.uploader-profile-placeholder-2 {
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: #fff;
    font-size: 14px;
    background-color: rgb(36, 36, 36);
    text-align: center;
}

.uploader-profile-placeholder-2 > img {
    width: 40px;
    filter: invert(1);
}


.uploader-profile-placeholder-2 > span {
    max-width: 100px;
    margin-top: 10px;
    line-height: 120%;
}





.uploader-profile-placeholder > img {
    width: 35px;
    filter: invert(1);
}


.uploader-profile-placeholder > span {
    max-width: 100px;
    margin-top: 10px;
    line-height: 120%;
}

.uploader-profile-placeholder:hover {
    cursor: pointer;    
    background-color: rgb(51, 51, 51);

}

.uploader-file-input-2:hover {
    cursor: pointer;    
    background-color: rgb(51, 51, 51);

}

.uploader-placeholder-icon {
    width: 40px;
    margin-bottom: 8px;
}



.uploader-file-input > img {
    width: 20px;
    filter: invert(1);
    margin-left: 10px;
    opacity: 0.8;
}

.uploader-file-input {
    cursor: pointer;
    font-size: 14px;
    color: #fff;
    border: none;
    background-color: rgb(24, 24, 24);
    border-radius: 15px;
    margin-top: 10px;
    font-family: 'Jost';
    height: 60px;
    font-weight: 500;
    transition: 140ms ease-in-out;
    width: 100%;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.uploader-file-input  > h4 {
    font-weight: 400;
    font-weight: 500;
}





.uploader-file-input:hover {
    background-color: var(--blue);
}








.uploader-file-input-2 {
    height: 150px;
    background-color: rgb(20, 20, 20);
    display: flex;
    border: 1px dashed rgb(85, 85, 85);
    border-radius: 25px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: rgb(36, 36, 36);
}

.uploader-file-input-2 > div > img {
    width: 50px;
    margin-left: 0px;
    opacity: 0.8;
}

.uploader-file-input-2 > div {
    cursor: pointer;
    font-size: 14px;
    color: #fff;
    border: none;
    border-radius: 15px;
    margin-top: 10px;
    font-family: 'Jost';
    font-weight: 500;
    flex-direction: column-reverse;
    transition: 140ms ease-in-out;
    width: 100%;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.uploader-file-input-2 > div > h4 {
    font-weight: 400;
    font-weight: 500;
}


.uploader-file-input:hover {
    background-color: var(--blue);
}




















/* ImageUploaderStyles.css */
.unique-image-uploader-container {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
}

.unique-image-display {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    object-fit: cover;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    border-radius: 10px;
    overflow: hidden;
    aspect-ratio: 16 / 10; 
    position: relative

}

.unique-image-display img {
    width: 100%; 
    height: auto;
    object-fit: cover; /* Stellt sicher, dass das Bild den Container ohne Verzerrung ausfüllt */
}

.unique-image-delete-button {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
}

.unique-image-display:hover .unique-image-delete-button {
    opacity: 1;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    cursor: pointer;
}

.unique-image-delete-icon {
    width: 10px !important;
    filter: invert(1);
}

.unique-image-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
}




















/* VideoUploaderStyles.css */
.unique-video-uploader-container {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 60px;
}

.unique-video-display {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    flex-direction: column;
    background-color: black;
    padding: 20px;
}

.unique-video-display video {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-top: 20px;
}

.unique-video-display img {
    border-radius: 10px;
    width: 100%;
}

.unique-video-delete-button {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    background-color: var(--red);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
    cursor: pointer;
}

.unique-video-display:hover .unique-video-delete-button {
    opacity: 1;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.unique-video-delete-icon {
    width: 10px !important;
    filter: invert(1);
}

.unique-video-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
}






/* SettingsSingleTextarea.css */
.settings-single-textarea-container {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    color: #ffffff;
}

.settings-single-textarea-label {
    display: block;
    margin-bottom: 5px;
    color: #ccc;
    font-size: 14px;
    text-align: left;
    margin-left: 5%;
}

.settings-single-textarea-field {
    width: calc(90% - 10px);
    height: 150px; /* Größere Höhe für Textarea */
    padding: 10px 10px 0 10px; /* Padding hinzufügen für Texteingabe */
    background-color: rgb(22, 22, 22);
    border: 1px solid #555;
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
    font-family: 'Jost', sans-serif; /* Schriftart anpassen, falls Jost verfügbar */
    outline: none;
    transition: border-color 0.3s;
    resize: none; /* Verhindert das Verändern der Größe durch den Benutzer */
}

.settings-single-textarea-field::placeholder {
    color: #888;
}

.settings-single-textarea-field:focus {
    border-color: #777;
}








.input-number-field-img {
    width: 20px; /* Adjust size as needed */
    height: auto;
    filter: invert();
    margin-right: 5px; /* Spacing between the image and the input */
}


/* SettingsSingleInput.css */
.settings-single-input-container {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    color: #ffffff;
}

.settings-single-input-label {
    display: block;
    margin-bottom: 5px;
    color: #ccc;
    font-size: 14px;
    text-align: left;
    margin-left: 5%;
}

.settings-single-input-field {
    width: 90%;
    height: 50px;
    padding: 0 0 0 10px;
    background-color: rgb(22, 22, 22);
    border: 1px solid rgb(70, 70, 70);
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
    font-family: 'Jost';
    outline: none;
    transition: border-color 0.3s;
}

.settings-single-input-field::placeholder {
    color: #888;
}

.settings-single-input-field:focus {
    border-color: #777;
}









.input-number-field-container {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    color: #ffffff;
  }
  
  .input-number-field-label {
    display: block;
    margin-bottom: 5px;
    color: #ccc;
    font-size: 14px;
    text-align: left;
    margin-left: 5%;
  }
  
  .input-number-field-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .input-number-field {
    width: calc(90% - 30px);
    height: 50px;
    padding: 0 0 0 40px;
    background-color: rgb(22, 22, 22);
    border: 1px solid #555;
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    font-family: 'Jost';
    outline: none;
    transition: border-color 0.3s;
  }
  
  .input-number-field::placeholder {
    color: #888;
  }
  
  .input-number-field:focus {
    border-color: #777;
  }
  
  .input-number-field-prefix {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 23px;
    opacity: 0.5;
    pointer-events: none;
  }
  
  

  .create-earn-settings-features-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0px;
    padding: 10px;
    justify-content: space-around;
    border-radius: 8px;
  }
  
  .create-earn-settings-feature-button {
    flex: 0 1 90px;
    background-color: rgb(20, 20, 20); /* Slightly lighter background for the buttons */
    border: 1px solid rgb(43, 43, 43);
    border-radius: 8px;
    color: #fff;
    padding: 10px;
    display: flex;
    margin-bottom: 15px;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .create-earn-settings-feature-button h4 {
    font-size: 12px;
    margin-top: 5px;
    font-family: 'Jost';
    font-weight: 500;
  }
  
  .create-earn-settings-feature-button img {
    width: 40px; /* Adjust size as needed */
    height: auto;
    filter: invert(1);
    margin-bottom: 10px;
    margin-top: 10px;
    opacity: 0.8;
  }
  
  .create-earn-settings-feature-button.selected {
    background-color: #5364d4; /* Highlight color when selected */
    transform: scale(1.03);
    border-color: #777;
  }
  
  .create-earn-settings-feature-button:not(.selected):hover {
    background-color: #444; /* Hover effect for non-selected buttons */
    transform: scale(1.02);
    cursor: pointer;
  }
  











  .gig-songs-settings-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff; /* White text color for readability */
}

.gig-songs-settings-list {
    margin-bottom: 20px;
    width: 90%;
}

.gig-songs-settings-song {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.gig-songs-settings-play-pause-btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #444; /* Darker background for buttons */
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;
}


.gig-songs-settings-play-pause-btn img {
    width: 11px; /* Size of the SVG icons */
    filter: invert(1);
}

.song-name {
    flex-grow: 1; /* Makes the song name take the remaining space */
    margin-right: 10px;
}

.gig-songs-settings-delete-btn {
    background-color: #900;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.gig-songs-settings-delete-btn:hover {
    background-color: #f00; /* Brighter red on hover */
}

.gig-songs-settings-add-new {
    padding: 20px;
    background-color:var(--black); /* Slightly lighter background for add new section */
    border-radius: 15px;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.gig-songs-settings-upload-label {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 10px;
    width: 100%;
    color: #ccc;
    margin-bottom: 10px;
    background-color: rgb(39, 39, 39); /* Slightly lighter background for the buttons */
    height: 50px;
}

.gig-songs-settings-name-input {
    display: block;
    width: calc(100% - 30px);
    height: 20px;
    padding: 15px;
    font-size: 16px;
    font-family: 'Jost';
    outline: none;  /* Entfernt die bläuliche Outline, die beim Fokussieren erscheint */
    border-radius: 10px;
    border: 1px solid rgb(58, 58, 58);
    color: white !important; 
    background-color: rgb(29, 29, 29); /* Slightly lighter background for the buttons */
}


.gig-choose-file-icon > img {
    width: 17px;
    filter: invert(1);
}

.gig-choose-file-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.gig-choose-file-icon > p {
    margin-right: 10px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 500;
}

.gig-songs-settings-add-btn {
    display: block;
    width: 100%;
    margin-top: 10px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    text-transform: uppercase;
    border: 0px;
    font-size: 15px;
    font-family: 'Jost';
    color: white; 
    background-color:var(--blue)
}

.gig-songs-settings-add-btn:hover {
    cursor: pointer;
}






.mode-switch-container {
    display: none;
    justify-content: space-around;
    box-shadow: rgba(0, 0, 0, 0.36) 0px 4px 8px 0px inset;
    background-color: var(--black);
    padding: 3px;
    border-radius: 30px; /* Gerundete Ecken */
    width: 180px;
    margin: 10px auto;
    margin-bottom: 40px;
    margin-top: 40px;
}

.mode-option {
    color: white;
    padding: 5px 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    transition: 100ms ease-in-out;
}

.mode-option.active {
    background-image: linear-gradient(to left, #131313 , #242424);
    width: 110px;
    border-radius: 100px;
}








.style-grid {
    display: grid;
    grid-template-columns: repeat(3, 75px); /* 3 Spalten */
    grid-gap: 15px;
    justify-content: center;
}

.style-cell {
    width: 75px;
    height: 100px;
    background-color: #252525;
    display: flex;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: -2px;
    border: 2px solid transparent;
    transition: border-color 0.3s;
    font-size: 12px;   
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.style-cell.active {
    border-color: rgb(160, 160, 160); /* Blau wenn aktiv */
}

.style-cell.active .circle-3 {
    border: 1px solid rgb(255, 255, 255);
}


.style-cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.circle-3 {
    width: 35px;
    margin-top: 10px;
    height: 35px;
    border-radius: 100%;
    margin-bottom: 0px;
    /* Weitere Stilanpassungen für den Kreis */
}















/* PageLiveToggle.css */
.page-live-toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 26px;
    margin-right: 15px;

  }
  
  .page-live-toggle-switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }

  .page-live-status-text {
        font-size: 14px; /* Oder eine andere passende Schriftgröße */
        color: rgb(153, 153, 153); /* Farbe des Textes */
        text-align: right;

  }

  .page-live-toggle-container {
    display: flex;
    justify-content: center;
    align-items: center; /* Zentriert den Text vertikal zum Schalter */
  }

  .page-live-toggle-switch input:checked + .page-live-slider:before {
      background-color: white;
  }
  
  .page-live-slider {
    position: absolute;
    cursor: pointer;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(56, 56, 56);
    transition: .4s;
    border-radius: 34px;
  }
  
  .page-live-slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 4px;
    background-color: rgb(126, 126, 126);
    transition: .4s;
    border-radius: 50%;
  }
  
  .page-live-toggle-switch input:checked + .page-live-slider {
    background-color: var(--blue);
  }
  
  .page-live-toggle-switch input:checked + .page-live-slider:before {
    transform: translateX(26px);
  }
  





  .gig-delete-topper {
      width: 15px;
      padding: 10px;
      background-color: rgb(211, 211, 211);
      border-radius: 100px;
      filter: invert(1);
  }


  .gig-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    background-color: rgba(0, 0, 0, 0.877); /* Dunkler Hintergrund */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
}

.dollar-wallet-top {
    width: 40px;
    position: absolute;
    right: 10px;
}

.custom-checkbox > div > p {
    margin: 0px !important;
    font-size: 14px;

}


.custom-checkbox > div  {
    padding: 10px 5px;
    text-align: left;
    margin-right: 50px;
    
}



.custom-checkbox > div > h4 {
    margin: 0px !important;

}


.gig-confirmation-modal {
    background-color: rgba(0, 0, 0, 0.377); /* Dunkler Hintergrund für das Modal */
    color: #fff; /* Helle Schrift für den Dark Mode */
    padding: 20px;
    border-radius: 25px;
    backdrop-filter: blur(10px);
    width: 90%;
    max-width: 540px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    height: 380px;
    position: relative;
    border: 1px solid rgb(75, 75, 75);
}

.gig-confirmation-modal p {
    font-size: 1.1rem;
    margin-bottom: 30px;
    margin-top: -10px;
    color: gray;
    background-color: none;
}
.gig-confirmation-modal h2 {
    font-family: 'Wide', 'Jost';
    margin-top: 40px;
}

.add-gig-live-button-bar button.inactive {
    background-color: var(--black) !important;
    cursor: not-allowed;
    color: white;
}

.gig-confirmation-modal h5 {
    font-size: 14px;
    margin: 0;
    font-weight: 400;
    color: rgb(214, 214, 214);

}


.gig-confirmation-modal > div > button {
    background-color: var(--black);
    width: 150px;
    height: 50px;
    border-radius: 100px;
    margin-top: 30px;
}

.add-gig-live-button-bar {
    position: absolute;
    bottom: 15px;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Jost';

}

.gig-confirmation-modal button {
    font-family: 'Jost';
}

.gig-confirmation-modal button:nth-of-type(2) {
    background-color: var(--blue);  /* Nutzt eine CSS-Variable für die Farbe */
    width: 150px;
    height: 50px;
    border-radius: 100px;  /* Erzeugt stark abgerundete Ecken */
    margin-top: 30px;  /* Abstand nach oben */
}


.custom-checkbox {
    margin: 5px 0;
    padding: 10px;
    background-color: var(--black);
    border: 1px solid rgb(51, 51, 51);
    border-radius: 10px;
    width: 90%;
    cursor: pointer;
    text-align: left;
    position: relative;
    display: flex;
    align-items: center;  /* Zentriert den Text vertikal zum Pseudo-Element */
}


.custom-checkbox > img {
    width: 30px;
    margin-left: 5px;
    margin-right: 15px;
}


.custom-checkbox:hover {
    background-color: #0c0c0c;
}


.gig-confirmation-modal button {
    padding: 10px 20px;
    margin-top: 10px;
    margin-right: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.gig-confirmation-modal button:hover {
    transform: scale(1.02);
}

.gig-delete-topper {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}










@media (max-width: 900px) {
    .input-number-field-prefix {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 45px;
        opacity: 0.5;
        pointer-events: none;
      }
}






@media (max-width: 800px) {
    .gig-confirmation-modal {
        width: 80%;
        height: 450px;
        margin-left: 0;
    }
    .input-number-field-prefix {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 35px;
        opacity: 0.5;
        pointer-events: none;
}

}


@media (max-width: 650px) {
    .input-number-field-prefix {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 30px;
        opacity: 0.5;
        pointer-events: none;
}
}



@media (max-width: 500px) {
    .gig-confirmation-modal {
        width: 80%;
        height: 500px;
        margin-left: 0;
    }
    .gig-modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.877); /* Dunkler Hintergrund */
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1050;
    }
}



@media (max-width: 400px) {
    .input-number-field-prefix {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 20px;
        opacity: 0.5;
        pointer-events: none;
}
}







@media (max-width: 800px) {

  .page-live-status-text {
    display: none;

}

.dollar-wallet-top {
    width: 40px;
    position: absolute;
    right: 0px;
}
  


}
.jury-info-container {
    width: 100%;
    padding: 140px 0 200px 0;
    background-color: var(--black);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    overflow: hidden;
}

.jury-info-left {
    width: 100%;
    display: flex;
    align-items: center;
    color: white;
    text-align: center;
    justify-content: center;
    flex-direction: column;
}

.jury-info-left > h5 {
    font-size: 14px;
    background-color: black;
    color: rgb(155, 155, 155);
    border: 1px solid rgb(44, 44, 44);
    padding: 8px 15px;
    border-radius: 100px;
    margin: 0;
    width: fit-content;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 2px;
}



.jury-info-left > h2 {
    font-size: 62px;
    margin: 20px 0 20px 0;
    font-family: 'Wide';
    max-width: 800px;
}

.jury-info-left > p {
    margin: 0px 0 70px 0;
    max-width: 750px;
    line-height: 25px;
    color: rgb(194, 194, 194);
    margin-right: 50px;
}

.jury-info-left > h2 > span {
    background: linear-gradient(
        90deg, 
        var(--red), #f38a8a, var(--red)
    ); /* Übergang von Rot zu sehr dunklem Grau */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 300% 100%; /* Vergrößerter Hintergrund für feinere Bewegung */
    animation: shinyGradient 6s ease-in-out infinite alternate; /* Elegante Animation */
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.6); /* Subtiler Schatten für 3D-Effekt */
}

@keyframes shinyGradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 50% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}



@keyframes shinyMetallic {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 50% 50%; /* Reflexion wandert zur Mitte */
    }
    100% {
        background-position: 100% 50%;
    }
}


.jury-info-container-box {
    display: flex;
    align-items: center;
    justify-content: left;
    text-align: left;
    background: linear-gradient(to bottom, rgb(29, 29, 29), var(--black));
    margin: -10px 30px;
    width: calc(100% - 60px);
    text-decoration: none;
    color: rgb(218, 218, 218);
    border-radius: 20px;
    padding: 18px 0 40px 0;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}



.jury-info-container-boxen {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.jury-info-container-box {
    width: 100%;
    max-width: 800px;
}



.jury-info-container-box:hover {
    color: rgb(255, 255, 255);
}

#jury-info-container-next {
    rotate: 270deg;
    width: 34px;
    position: absolute;
    right: 10px;
}


.jury-info-container-box:nth-child(3) {
    padding: 25px 0 25px 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

}

.jury-info-container-box > img {
    width: 40px;
    margin-right: 15px;
    margin-left: 15px;
}


.jury-info-right {
    width: 100%;
    display: flex;
    align-items: left;
    color: white;
    justify-content: center;
}

.jury-info-right-image-box {
    position: relative;
    height: 950px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.jury-info-right-image-box > img {
    width: 480px;
    position: absolute;
}

#jury-info-rondo {
    width: 750px;
    height: 750px;
    transform-origin: center; /* Rotationszentrum */
    transition: transform 0.15s ease-out; /* Flüssige Rotation */
}




@media (max-width: 1100px) {
    .jury-info-container {
        width: 100%;
        padding: 100px 0 140px 0;
        background-color: var(--black);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
    }
    
    .jury-info-left {
        width: 100%;
        display: flex;
        align-items: center;
        color: white;
        text-align: center;
        justify-content: center;
        margin-left: 0%;
        flex-direction: column;
    }

    .jury-info-left > p {
        margin-right: 20px;
        margin-left: 20px;
    }

    .jury-info-left > h2 {
        max-width: 95%;
    }



.jury-info-right {
    width: 100%;
    margin-right: 0%;
}


.jury-info-container-boxen {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}


.jury-info-container-box {
    display: flex;
    align-items: center;
    justify-content: left;
    text-align: left;
    background: linear-gradient(to bottom, rgb(29, 29, 29), rgb(17, 17, 17));
    margin: -10px 30px;
    width: calc(100% - 60px);
    text-decoration: none;
    color: rgb(218, 218, 218);
    border-radius: 20px;
    padding: 18px 0 40px 0;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.jury-info-container-box:nth-child(3) {
    padding: 25px 0 25px 0;

}
   
    
}

@media (max-width: 800px) {
    .jury-info-right-image-box > img {
        width: 380px;
        position: absolute;
    }

    .jury-info-right-image-box {
        position: relative;
        height: 750px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    #jury-info-rondo {
        width: 550px;
        height: 550px;
        transform-origin: center; /* Rotationszentrum */
        transition: transform 0.15s ease-out; /* Flüssige Rotation */
    }
}

@media (max-width: 600px) {
    .jury-info-left {
    margin-top: -60px;
}


    .jury-info-right-image-box > img {
        width: 320px;
        position: absolute;
    }
    
    #jury-info-rondo {
        width: 450px;
        height: 450px;
        transform-origin: center; /* Rotationszentrum */
        transition: transform 0.15s ease-out; /* Flüssige Rotation */
    }

    .jury-info-container-box > p {
        margin-right: 60px;
    }



    
}

@media (max-width: 450px) {

    .jury-info-left {
        margin-top: 0px;
    }

    .jury-info-right-image-box > img {
        width: 300px;
        position: absolute;
    }
    
    #jury-info-rondo {
        width: 420px;
        height: 420px;
        transform-origin: center; /* Rotationszentrum */
        transition: transform 0.15s ease-out; /* Flüssige Rotation */
    }
    .jury-info-right {
        max-height: 600px;
    }

    .jury-info-left > h2 {
        font-size: 48px;
        margin: 20px 0 20px 0;
        font-family: 'Wide';
    }
    
    
}

@media (max-width: 420px) {
    .jury-info-right-image-box > img {
        width: 280px;
        position: absolute;
    }
    
    #jury-info-rondo {
        width: 360px;
        height: 360px;
        transform-origin: center; /* Rotationszentrum */
        transition: transform 0.15s ease-out; /* Flüssige Rotation */
    }


    
}

@media (max-width: 380px) {
    .jury-info-left > h2 {
        font-size: 38px;
        margin: 20px 0 20px 0;
        font-family: 'Wide';
    }

 
    
}


.gigs-basic-2-wrapper {
  width: 100%;
  background-color: rgba(39, 39, 39, 0.322);
  padding-bottom: 40px;

}

.gigs-basic-2-wrapper > h2 {
  font-size: 14px;
  text-align: left;
  color: rgb(129, 129, 129);
  font-family: 'Wide', 'Jost';
  margin: 0 0 20px 70px;
}


@media (max-width: 700px) {
  .gigs-basic-2-wrapper > h2 {
    margin: 0 0 20px 30px;
  }
}


.gig-songs {
    margin-top: 0px;
    margin-bottom: 20px;
    border-radius: 15px;
    border: 1px solid rgba(128, 128, 128, 0);
    transition: 140ms ease-in-out;
    padding: 10px;
}

.gig-songs.is-active {
    border-color: rgb(196, 196, 196); /* Blue border when active */
    color: white; /* Text color changes when active */
}

.gig-songs.settings-route:hover {
    cursor: pointer;
    transform: scale(1.03);
    box-shadow: var(--color-shadow-2);
}

.gig-songs:hover .edit-overlay {
    display: flex; /* Make visible on hover */
}


.gig-upload-song-button > img {
    width: 30px;
    margin-left: 20px;
    filter: var(--color-invert);
    opacity: 0.5;
    transition: 100ms ease-in-out;
    user-select: none;  /* Verhindert die Auswahl des Textes */
    -webkit-user-select: none;  /* Verhindert die Auswahl des Textes für WebKit-Browser wie Safari und Chrome */
    -ms-user-select: none;  /* Verhindert die Auswahl des Textes für Internet Explorer */
    -moz-user-select: none;
}

.gig-upload-song-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    font-size: 18px;
    background-color: var(--color-2);
    border-radius: 10px;
    padding: 0 0px;
}

.gig-upload-song-button > h5 {
    font-size: 16px;
    font-weight: 400;
    color: var(--color-oposite-4);
    font-family: 'Wide';
    transition: 100ms ease-in-out;
    user-select: none;  /* Verhindert die Auswahl des Textes */
    -webkit-user-select: none;  /* Verhindert die Auswahl des Textes für WebKit-Browser wie Safari und Chrome */
    -ms-user-select: none;  /* Verhindert die Auswahl des Textes für Internet Explorer */
    -moz-user-select: none;  

}

.gig-songs:hover .gig-upload-song-button > h5 {
    color: var(--color-oposite-1);
}

.gig-songs:hover .gig-upload-song-button > img {
    opacity: 1;
}



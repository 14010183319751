.page-offline-screen {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    font-family: 'Wide', 'Jost';
    font-size: 34px;
}



.page-offline-screen > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 600px;
    flex-direction: column;

}

.page-offline-screen > div > img {
    width: 200px;
    margin-bottom: 30px;
}

.page-offline-screen > div > h2 {
    margin-top: 0px;
}

.review-chat {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.review-category {
    display: flex;
    align-items: left;
    gap: 5px;
    flex-direction: column;
}

.review-category p {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
}

.star {
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.submit-review-button {
    background-color: var(--blue);
    height: 50px;
    border: none;
    border-radius: 100px;
    color: white;
    margin-bottom: 10px;
    font-family: 'Jost';
}

.feedback-textarea {
    width: 220px;
    height: 100px;
    margin-top: 0px;
    color: white;
    margin-bottom: 5px;
    padding: 10px;
    border: 1px solid rgb(58, 58, 58);
    border-radius: 10px;
    background-color: var(--black);
    outline: none;
    font-family: 'Jost';
    resize: none; /* Verhindert das Vergrößern/Verkleinern */
}


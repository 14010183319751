.sidebar-side {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 320px;
    background-image: linear-gradient(to left, #242424, #131313);
    margin-bottom: 0px;
    position: fixed;
    z-index: 10000;
    padding: 5px 0px;
    border-bottom: 1px solid rgb(48, 48, 48);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    
}

.circle-button.activado {
    border: 1px solid rgba(192, 192, 192, 0.507)!important; /* oder jede andere Stildefinition */
    background-color: rgba(173, 216, 230, 0);
}


.circle-button {
    width: 42%; /* Durchmesser des Kreises */
    height: 50px;
    margin: 10px 5px;
    border-radius: 12px;
    border: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Jost';
    cursor: pointer;
    background-color: rgba(14, 14, 14, 0.336); /* Dunklerer Blauton */

}

.circle-button > img {
    width: 20px;
    margin-right: 10px;
}


.circle-button:hover {
    background-color: #000000; /* Hervorheben beim Hover */

}



@media (max-width: 1300px) {
    .sidebar-side {
        width: 270px;
    }
    
  }
.home-charts {
  display: flex;
  justify-content: top;
  position: relative;
  flex-direction: column;
    text-align: center;
    width: 100%;
    padding-bottom: 50px;
    align-items: center;
    background-color: var(--black); /* Ersetzen Sie --black durch die gewünschte Farbe */
  }

  .category-nav-buttonbar > button > img {
    width: 100px;
    filter: invert(1);
    margin-left: 30px;
    margin-right: 30px;
    margin-top: -170px;
    z-index: 10;
  }

  .chart-position {
    color: rgb(168, 168, 168);
    margin-top: 23px;
    margin-right: 15px;
    font-weight: 600;
    font-size: 12px;
  }
  
  .category-nav-buttonbar {
    display: flex;
    position: absolute;
  }
 .category-nav-buttonbar > button {
    background: none;
    border: none;
  }

  .category-nav-buttonbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 98%;
  }

  .home-charts-list .chart-song:first-child {
    border-top: 1px solid grey;
  }
  

  .category-navigation-2 > button:hover {
    cursor: pointer;
  }

  .category-navigation-2 {
    display: flex;
    align-items: center;
    justify-content: center;
   width: 100%;
    margin-top: 0px;
    display: flex;
  }
  
  .home-charts h2 {
    color: var(--white); /* Ersetzen Sie --white durch die gewünschte Farbe */
    font-family: 'Wide', 'Jost', 'Josefin Sans', sans-serif;
    font-size: 108px;
    margin-top: 0px;
    text-transform: uppercase;
    z-index: 10;

  }

  
  .chart-title-link {
    color: var(--white); /* Ersetzen Sie --light-grey durch die gewünschte Farbe */
text-align: left;
z-index: 10;
font-weight: 300;
letter-spacing: 0.5px;
color: rgb(223, 223, 223) !important;


  }
  
  .home-charts-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    z-index: 10;

  }
  
  .chart-song {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(122, 122, 122);
    width: 100%; 
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .chart-song:hover {
    background-color: rgba(255, 255, 255, 0.034);
  }

  .chart-song-left {
    display: flex; 
    align-content: center;
    justify-content: center;
    margin-left: var(--edge);
  }
  
  .chart-song-left img {
    width: 60px; /* Passen Sie die Größe nach Bedarf an */
    height: 60px; /* Passen Sie die Größe nach Bedarf an */
    border-radius: 50%;
    margin-right: 15px;
    object-fit: cover;
  }

  .chart-song-middle a:hover {
    text-decoration: underline; /* Optional: Füge eine Unterstreichung beim Hover für bessere Interaktivität hinzu */
  }

  .chart-title-link:hover {
    text-decoration: underline !important;
    color: white;
  }

  .nav-arrow-2 {
    margin-top: -100px !important;
  }

  .nav-arrow-2:hover {
    cursor: pointer;
  }

  .chart-title-link {
    text-align: left;
  }


  .chart-song-middle {
    flex-grow: 1;
    text-align: left;
  }
  
  .chart-song-middle h4,
  .chart-song-middle p {
    margin: 0; /* Entfernt den Standardabstand */
    color: var(--white); /* Ersetzen Sie --white durch die gewünschte Farbe */
  }
  
  .chart-song-right .play-pause-button {
    background: none;
    border: none;
    cursor: pointer;
  }

  .chart-song-right {
    margin-right: 1vw;
  }

  .home-charts-bottom-text {
    margin-top: -50px;
    margin-bottom: 40px;
    font-size: 20px;
    letter-spacing: 1px;
    color: white;
    z-index: 100;
  }

  @media (max-width: 1400px) {

    .home-charts {
      padding-top: 30px;
    }
    .home-charts h2 {
      font-size: 82px;
      }
      .nav-arrow-2 {
        margin-top: -70px !important;
      }

 
  }

  @media (max-width: 1150px) {

    .home-charts {
      padding-top: 30px;
    }
    .home-charts h2 {
      font-size: 62px;
    }

    .nav-arrow-2 {
      margin-top: -50px !important;
    }



  }

  @media (max-width: 950px) {

    .home-charts {
      padding-top: 30px;
    }
    .home-charts h2 {
      color: var(--white); /* Ersetzen Sie --white durch die gewünschte Farbe */
      font-family: 'Wide', 'Jost', 'Josefin Sans', sans-serif;
      font-size: 42px;
      margin-top: -20px;
      text-transform: uppercase;
    }
    .home-charts-bottom-text {
      margin-top:-10px;
      margin-bottom: 40px;
      margin-left: 10px;
      margin-right: 10px;
      text-align: center;
    }
    .category-navigation-2 > button > img {
      width:50px;
     
    }
    .home-charts > p {
      text-align: center;
    }
    .nav-arrow-2 {
      margin-top: -50px !important;
    }

  }


  @media (max-width: 800px) {

    .home-charts-list {
    width: 95%;
    }
    .category-nav-buttonbar > button > img {
      width: 30px;
      filter: invert(1);
      margin-left: 30px;
      margin-right: 30px;
      margin-top: -170px;
    }
  
    .home-charts h2 {
      color: var(--white); /* Ersetzen Sie --white durch die gewünschte Farbe */
      font-family: 'Wide', 'Jost', 'Josefin Sans', sans-serif;
      font-size: 22px;
      margin-top: 0px;
      text-transform: uppercase;
    }

    .category-nav-buttonbar {
      width: 100%;
    }
    .category-navigation-2 {
      margin-bottom: -20px;

    }
    .home-charts-bottom-text {
      margin-top: 20px;
      margin-bottom: 40px;
    }
    
  }

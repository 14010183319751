/* Overlay für das Popup */
.last-chance-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.904);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(10px);
  }
  
  /* Container des Popups */
  .last-chance-popup {
    background-color: #1e1e1e00;
    padding: 0px;
    border-radius: 35px;
    width: 440px;
    max-width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 90%;
    color: #fff;
    text-align: center;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
    position: relative; /* Relative position für die Fortschrittsleiste */
  }
  
  /* Header des Popups */
/* Header des Popups */
.last-chance-popup-header h1 {
    font-size: 84px;
    color: white;
    margin: -100px 0 -20px 0;
    transition: all 2s ease-in-out; /* Übergang für alle Änderungen, einschließlich des Glow-Effekts */
    animation: floatIn 0.4s ease-out forwards; /* Schwebende Animation beim Reinkommen */
  }

  
  .last-chance-popup-header h1.final-phase {
    animation: stumble 0.5s ease-in-out;
  }
  
  @keyframes floatIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes stumble {
    0% {
      transform: translateY(-5px);
      opacity: 0.7;
    }
    50% {
      transform: translateY(5px);
      opacity: 1;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
/* Keyframe-Animation für den Schwebeeffekt */
@keyframes subtleFloat {
    0% {
      transform: translateY(0); /* Startposition */
    }
    50% {
      transform: translateY(-5px); /* Leicht nach oben bewegen */
    }
    100% {
      transform: translateY(0); /* Zurück zur Startposition */
    }
  }
  
  /* Animation anwenden */
  .subtle-float {
    animation: subtleFloat 4s ease-in-out infinite; /* Langsame, subtile Animation */
    transition: all 0.5s ease-in-out; /* Übergänge für andere Eigenschaften */
  }
  
  /* Keyframe-Animation für flackernden Glow */
@keyframes subtleFlicker {
    0% {
      text-shadow: 0 0 10px var(--red), 0 0 20px var(--red), 0 0 30px var(--red), 0 0 40px #e63946, 0 0 70px #e63946, 0 0 80px #e63946;
    }
    50% {
      text-shadow: 0 0 8px var(--red), 0 0 15px var(--red), 0 0 25px var(--red), 0 0 35px #e63946, 0 0 65px #e63946, 0 0 75px #e63946;
    }
    100% {
      text-shadow: 0 0 10px var(--red), 0 0 20px var(--red), 0 0 30px var(--red), 0 0 40px #e63946, 0 0 70px #e63946, 0 0 80px #e63946;
    }
  }

  .last-chance-popup-header h1.glow-effect {
    animation: subtleFlicker 2s infinite ease-in-out; /* Flackern hinzugefügt */
    text-shadow: 0 0 10px var(--red), 0 0 20px var(--red), 0 0 30px var(--red), 0 0 40px var(--red), 0 0 70px #e63946, 0 0 80px #e63946;
    color: white !important;
  }
  
  

  /* Body des Popups */
  .last-chance-popup-body p {
    font-size: 16px;
    line-height: 1.5;
    color: #b1b1b1;
    margin-bottom: 35px;
  }
  
  .closett-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: black;
    border: 1px solid white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  .logett-button {
    position: absolute;
    top: 20px;
    left: 20px;
    border: none;
    background: none;
  }

  .closett-button > img {
      width: 15px;
      filter: invert(1);
  }

  .last-chance-bottom-right > span {
      margin-right: 90px;
  }

  .last-chance-bottom-right {
      display: flex;
      margin-bottom: 10px;
      align-items: flex-end;
  }

  .logett-button > img {
    width: 120px;
}

  .last-chance-popup-button {
    background-color: black;
    color: hsl(0, 0%, 100%);
    border: none !important;
    padding: 12px 24px;
    border-radius: 100px;
    cursor: pointer;
    font-weight: 600;
    font-family: 'Jost';
    height:65px;
    width: 250px;
    font-size: 16px;
    text-transform: uppercase;
    transition: background-color 0.3s ease-in-out, cursor 0.3s ease-in-out; /* Smoother Hover */
  }

  .last-chance-bottom-right > img {
      width: 80px;
      position: absolute;
      bottom: 15px;
      right: 0px;
  }



  .pulse-animation {
    animation: pulsero 0.5s ease-in-out 1; /* Pulsieren für 0.5 Sekunden, nur einmal */
  }
  @keyframes pulsero {
    0% {
      transform: scale(1); /* Start mit normaler Größe */
    }
    50% {
      transform: scale(1.1); /* Kurzzeitig etwas größer */
    }
    100% {
      transform: scale(1); /* Zurück zur normalen Größe */
    }
  }
  
.last-chance-popup-footer > p {
    margin: 0;
}

.last-chance-popup-footer > p > span {
    font-size: 42px;
}

  .last-chance-popup-button:disabled {
    background-color: #313131 !important; 
    cursor: not-allowed;
  }
  
  .last-chance-popup-button:hover {
    background-color: #d62828;
  }
  
  /* Footer des Popups */
  .last-chance-popup-footer {
    margin-top: 60px;
    font-size: 14px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    color: white;
    position: absolute;
    bottom: 0;
    width: 90%;
  }
  

  
.progressora-bar {
    height: 5px;
    background-color: #313131;
    position: absolute;
    bottom: 0; /* Fortschrittsbalken klebt am unteren Rand */
    left: 0;
    right: 0;
    border-radius: 0 0 10px 10px; /* Gleiche Rundungen wie der Container */
    overflow: hidden; /* Verhindert, dass der Balken überläuft */
  }
  
  .progressora-bar-inner {
    height: 100%;
    background-color: #e63946;
    width: 0%;
    transition: width 4s linear; /* Smooth linear slide */
  }
  
  /* Standardzustand ohne Glow */
.last-chance-popup-header h2 {
    font-size: 28px;
    font-weight: 700;
    margin: 15px 0 10px 0;
    color: #ffffff;
    font-family: 'Wide';
    transition: text-shadow 2s ease-in-out;

  }
  
  /* Glow-Effekt */
  .glow-effect {
    text-shadow: 0 0 10px var(--red), 0 0 20px var(--red), 0 0 30px var(--red), 0 0 40px var(--red), 0 0 70px #e63946, 0 0 80px #e63946;
    transition: text-shadow 1s ease-in-out;
    color: white !important;
    animation: subtleFlicker 2s infinite ease-in-out; /* Flackereffekt für Glow alle 2 Sekunden */

  }

  

  @media (max-width: 500px) {
    .last-chance-popup-footer > p {
        display: none;
    }
  }